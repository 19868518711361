import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const HapBaseGreen = createIcon({
  displayName: 'HapBaseGreen',
  viewBox: '0 0 624 629',
  path: (
    <svg fill='none'>
      <path
        d='M561 140.5C561 182.75 526.75 217 484.5 217C442.25 217 408 182.75 408 140.5C408 98.2502 442.25 64 484.5 64C526.75 64 561 98.2502 561 140.5Z'
        fill='white'
      />
      <path
        d='M561 140.5C561 182.75 526.75 217 484.5 217C442.25 217 408 182.75 408 140.5C408 98.2502 442.25 64 484.5 64C526.75 64 561 98.2502 561 140.5Z'
        fill='white'
      />
      <path
        d='M561 140.5C561 182.75 526.75 217 484.5 217C442.25 217 408 182.75 408 140.5C408 98.2502 442.25 64 484.5 64C526.75 64 561 98.2502 561 140.5Z'
        fill='white'
      />
      <path
        d='M221 140.5C221 182.75 186.75 217 144.5 217C102.25 217 68 182.75 68 140.5C68 98.2502 102.25 64 144.5 64C186.75 64 221 98.2502 221 140.5Z'
        fill='white'
      />
      <path
        d='M221 140.5C221 182.75 186.75 217 144.5 217C102.25 217 68 182.75 68 140.5C68 98.2502 102.25 64 144.5 64C186.75 64 221 98.2502 221 140.5Z'
        fill='white'
      />
      <path
        d='M221 140.5C221 182.75 186.75 217 144.5 217C102.25 217 68 182.75 68 140.5C68 98.2502 102.25 64 144.5 64C186.75 64 221 98.2502 221 140.5Z'
        fill='white'
      />
      <path
        d='M417.395 252.21V263.783C417.394 298.127 417.197 332.474 417.546 366.816C417.623 374.372 415.347 376.844 407.745 376.714C384.122 376.304 360.486 376.38 336.859 376.667C329.705 376.752 327.383 374.227 327.394 367.047C327.57 249.723 327.575 132.399 327.391 15.0749C327.379 7.5202 329.79 4.99756 337.347 5.11621C360.971 5.48822 384.61 5.52823 408.23 5.08957C415.694 4.9509 418.065 7.62289 417.459 14.6189C417.066 19.1762 417.386 23.7936 417.386 30.1696C420.874 27.7762 422.913 26.6189 424.674 25.1322C453.43 0.869537 486.653 -4.03311 522.151 3.8989C564.163 13.2842 593.709 38.6669 610.637 78.0109C622.366 105.271 625.258 133.954 621.505 163.491C617.543 194.663 606.03 222.036 584.169 244.95C546.275 284.664 478.839 294.254 435.237 265.762C429.635 262.1 424.537 257.668 417.395 252.21ZM534.149 141.102C534.017 105.115 509.595 80.0336 474.966 80.3202C439.655 80.6109 416.206 104.9 416.117 141.279C416.026 178.011 439.942 202.606 475.55 202.398C510.433 202.192 534.282 177.246 534.149 141.102Z'
        fill='#5FB5AA'
      />
      <path
        d='M201.912 254.355C189.632 265.789 175.62 273.858 159.377 278.142C107.395 291.855 50.9121 269.917 22.5295 224.639C-0.129199 188.494 -3.87586 149.029 3.2828 108.086C9.60947 71.8982 27.0628 41.9568 58.3668 21.9328C99.4761 -4.36182 142.348 -7.90314 185.983 16.1529C191.849 19.3862 196.871 24.1515 204.091 29.5635C204.472 23.6835 205.189 19.2702 204.941 14.9128C204.551 8.05151 206.681 4.99951 214.2 5.11551C238.368 5.48752 262.547 5.41284 286.717 5.14484C292.907 5.0755 294.812 7.37949 294.804 13.3168C294.693 98.7488 294.681 184.181 294.841 269.613C294.852 275.958 292.363 277.491 286.527 277.435C262.356 277.201 238.179 277.161 214.008 277.447C207.259 277.529 204.457 275.31 204.941 268.443C205.256 263.982 204.633 259.454 204.431 254.957C203.591 254.757 202.752 254.555 201.912 254.355ZM147.595 80.2995C113.343 80.2888 88.0988 106.204 88.0735 141.404C88.0481 176.632 113.227 202.418 147.632 202.395C182.865 202.374 207.496 177.014 207.309 140.954C207.125 105.43 182.395 80.3115 147.595 80.2995Z'
        fill='#5FB5AA'
      />
      <path
        d='M176.549 161.391C187.789 161.391 196.899 152.28 196.899 141.04C196.899 129.8 187.789 120.688 176.549 120.688C165.309 120.688 156.198 129.8 156.198 141.04C156.198 152.28 165.309 161.391 176.549 161.391Z'
        fill='#3854FE'
      />
      <path
        d='M327.334 585.172C447.494 580.977 532.698 502.975 537.831 375.101'
        stroke='#FDD772'
        strokeWidth='87.4347'
        strokeMiterlimit='10'
      />
      <path
        d='M504.077 161.391C515.317 161.391 524.428 152.28 524.428 141.04C524.428 129.8 515.317 120.688 504.077 120.688C492.837 120.688 483.727 129.8 483.727 141.04C483.727 152.28 492.837 161.391 504.077 161.391Z'
        fill='#3854FE'
      />
    </svg>
  ),
});
