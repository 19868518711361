const idtoken = localStorage.getItem('idToken');
const clientid = localStorage.getItem('clientId');

const config = {
  API_URL: process.env.REACT_APP_API_URL,
  KEYCLOAK_URL: process.env.REACT_APP_KEYCLOAK_URL,
  KEYCLOAK_REALM: 'happify',
  KEYCLOAK_CLIENT_ID: 'happify-admin-website',
  KEYCLOAK_REFRESH_MIN_VALIDITY: 5,
  USER_NAME: `${process.env.REACT_APP_KEYCLOAK_URL}/realms/happify/protocol/openid-connect/userinfo`,
  AUTH_LOGOUT: `${process.env.REACT_APP_KEYCLOAK_URL}/realms/happify/protocol/openid-connect/logout?&post_logout_redirect_uri=${process.env.REACT_APP_HAPPIFY_URL}&id_token_hint=${idtoken}&client_id=${clientid}`,
  VERSION: process.env.REACT_APP_VERSION,
};

export default config;
