import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const HapLogo = createIcon({
  displayName: 'HapLogo',
  viewBox: '0 0 782 299',
  path: (
    <svg fill='none'>
      <path
        d='M422.477 145.808C422.383 147.671 422.261 148.964 422.26 150.257C422.245 164.205 422.181 178.153 422.305 192.099C422.331 195.079 421.555 196.256 418.371 196.196C408.889 196.016 399.403 196.065 389.92 196.171C387.044 196.203 385.745 195.488 385.752 192.236C385.843 144.592 385.837 96.9494 385.764 49.3054C385.759 46.2481 386.721 45.2227 389.8 45.2747C399.281 45.4361 408.769 45.4427 418.249 45.2694C421.299 45.2134 422.581 46.1174 422.28 49.2254C422.103 51.0601 422.248 52.9267 422.248 55.4014C423.613 54.5374 424.391 54.1707 425.019 53.6294C436.819 43.4454 450.496 41.6014 465.165 44.8614C479.528 48.0534 490.493 56.2307 497.781 68.9334C505.011 81.5334 506.987 95.2267 505.164 109.6C503.56 122.255 498.937 133.425 489.996 142.672C474.412 158.789 447.679 162.661 429.812 151.307C427.415 149.783 425.248 147.9 422.477 145.808ZM421.763 100.257C421.537 115.177 431.095 125.269 445.573 125.4C459.619 125.528 469.36 115.731 469.679 101.157C470 86.4387 460.209 75.9761 445.969 75.8201C431.695 75.6641 421.987 85.4654 421.763 100.257Z'
        fill='#3854FE'
      />
      <path
        d='M36.756 57.2365C38.056 56.1938 38.8693 55.6791 39.5147 55.0032C47.116 47.0498 56.492 43.4658 67.3987 43.4751C84.7267 43.4885 97.9013 50.2925 104.68 66.8538C106.593 71.5298 108.193 76.7071 108.296 81.6871C108.785 105.45 108.693 129.224 108.86 152.994C108.876 155.226 107.912 155.87 105.827 155.858C95.7827 155.796 85.7373 155.759 75.6933 155.876C72.8453 155.91 72.4107 154.536 72.4173 152.158C72.4707 134.523 72.4733 116.888 72.424 99.2538C72.4093 94.0005 72.0733 88.7965 69.2733 84.0698C65.4267 77.5765 59.28 74.8831 50.6107 76.0658C44.8133 76.8565 39.1173 82.2925 37.8387 88.7431C37.084 92.5458 36.5773 96.4645 36.5467 100.335C36.4107 117.522 36.4253 134.711 36.5453 151.898C36.5667 154.924 35.7427 155.968 32.592 155.904C22.9973 155.708 13.3947 155.739 3.79868 155.89C0.822679 155.936 -0.00398557 154.888 1.44279e-05 152.004C0.0720144 104.347 0.0693474 56.6898 0.00534737 9.03249C0.00134737 6.20314 0.774681 5.02982 3.78268 5.07381C13.4907 5.21381 23.2027 5.20715 32.912 5.07515C35.7227 5.03648 36.5507 6.04448 36.532 8.76848C36.4347 23.3885 36.4813 38.0098 36.4973 52.6311C36.4987 53.9231 36.6387 55.2151 36.756 57.2365Z'
        fill='#3854FE'
      />
      <path
        d='M291.12 145.628V150.328C291.12 164.276 291.039 178.225 291.181 192.172C291.212 195.241 290.288 196.245 287.2 196.192C277.607 196.027 268.008 196.056 258.413 196.173C255.507 196.208 254.564 195.181 254.568 192.265C254.64 144.619 254.643 96.9705 254.568 49.3225C254.563 46.2545 255.541 45.2292 258.611 45.2785C268.205 45.4292 277.805 45.4452 287.399 45.2679C290.429 45.2105 291.392 46.2959 291.147 49.1372C290.987 50.9879 291.116 52.8638 291.116 55.4518C292.533 54.4798 293.361 54.0105 294.076 53.4078C305.755 43.5532 319.247 41.5625 333.664 44.7838C350.725 48.5945 362.725 58.9038 369.6 74.8825C374.363 85.9532 375.537 97.6012 374.013 109.597C372.404 122.257 367.729 133.375 358.851 142.68C343.461 158.808 316.073 162.704 298.365 151.132C296.091 149.645 294.02 147.844 291.12 145.628ZM338.536 100.504C338.483 85.8892 328.564 75.7038 314.501 75.8198C300.16 75.9372 290.637 85.8025 290.6 100.577C290.564 115.495 300.277 125.483 314.739 125.399C328.904 125.315 338.591 115.184 338.536 100.504Z'
        fill='#F85165'
      />
      <path
        d='M203.607 146.5C198.62 151.142 192.93 154.42 186.332 156.16C165.222 161.729 142.283 152.82 130.755 134.432C121.554 119.752 120.032 103.724 122.939 87.0969C125.508 72.3995 132.596 60.2395 145.31 52.1075C162.006 41.4289 179.416 39.9902 197.138 49.7608C199.52 51.0729 201.559 53.0089 204.492 55.2062C204.647 52.8182 204.938 51.0262 204.838 49.2569C204.679 46.4702 205.544 45.2302 208.598 45.2769C218.412 45.4289 228.232 45.3982 238.048 45.2889C240.562 45.2622 241.335 46.1969 241.332 48.6089C241.287 83.3049 241.283 118.001 241.347 152.696C241.352 155.273 240.342 155.896 237.971 155.872C228.155 155.777 218.335 155.761 208.519 155.878C205.778 155.91 204.64 155.01 204.838 152.221C204.966 150.409 204.712 148.57 204.63 146.744C204.288 146.662 203.948 146.581 203.607 146.5ZM181.548 75.8115C167.636 75.8075 157.384 86.3315 157.375 100.628C157.364 114.934 167.59 125.406 181.563 125.398C195.871 125.389 205.875 115.089 205.799 100.445C205.724 86.0182 195.68 75.8169 181.548 75.8115Z'
        fill='#F85165'
      />
      <path
        d='M684.242 195.064C687.302 187.973 690.275 181.049 693.276 174.139C695.803 168.321 698.448 162.553 700.84 156.683C701.332 155.473 701.38 153.721 700.888 152.527C693.006 133.392 684.999 114.309 677.027 95.212C670.512 79.6066 663.998 64 657.52 48.3773C657.16 47.5106 657.139 46.5026 656.863 45.0493H663.888C672.483 45.0493 681.079 45.1533 689.671 44.9893C692.331 44.9373 693.636 45.8226 694.568 48.3013C700.127 63.0906 705.838 77.824 711.482 92.5813C713.55 97.9866 715.546 103.417 717.65 108.807C717.984 109.66 718.722 110.357 719.703 111.729C722.12 105.401 724.29 99.752 726.438 94.0933C732.36 78.4906 738.242 62.872 744.27 47.3093C744.646 46.3386 746.141 45.1773 747.129 45.1653C758.177 45.0306 769.226 45.1013 780.274 45.1346C780.587 45.1346 780.901 45.3373 781.778 45.6413C779.786 50.5826 777.853 55.4973 775.83 60.3746C765.171 86.0706 754.5 111.761 743.814 137.445C736.034 156.143 728.247 174.837 720.354 193.487C719.934 194.479 718.511 195.692 717.542 195.704C706.943 195.844 696.34 195.768 685.739 195.728C685.436 195.727 685.135 195.471 684.242 195.064Z'
        fill='#3854FE'
      />
      <path
        d='M621.827 115.348C621.826 127.51 621.758 139.67 621.875 151.83C621.904 154.772 621.167 155.976 617.94 155.906C608.462 155.702 598.974 155.703 589.494 155.904C586.136 155.975 585.392 154.698 585.404 151.615C585.502 127.518 585.46 103.419 585.455 79.3218C585.455 74.2604 585.443 74.2604 580.455 74.2444C578.002 74.2378 575.547 74.2124 573.092 74.2324C571.16 74.2484 570.219 73.3618 570.224 71.3991C570.244 63.5884 570.248 55.7791 570.228 47.9698C570.224 46.1458 571.074 45.3298 572.876 45.3431C575.888 45.3658 578.906 45.2311 581.912 45.3631C584.458 45.4738 585.471 44.5564 585.532 41.9138C585.686 35.3404 586.227 28.8471 588.648 22.5658C592.483 12.6191 599.39 5.94843 609.44 2.8591C620.959 -0.682236 632.664 -1.00491 644.375 2.28976C647.53 3.17642 649.234 4.71909 649.086 8.39108C648.811 15.1858 648.922 22.0004 649.046 28.8031C649.095 31.5364 647.983 31.7738 645.572 31.5898C641.192 31.2578 636.706 30.9484 632.388 31.5391C626.61 32.3311 623.407 36.3031 622.339 42.0978C621.89 44.5364 622.664 45.3658 625.03 45.3471C631.611 45.2911 638.198 45.4591 644.775 45.2778C647.902 45.1911 649.167 46.1044 649.068 49.4151C648.863 56.3258 648.9 63.2498 649.055 70.1631C649.122 73.1724 648.138 74.3858 645.026 74.2924C639.23 74.1191 633.424 74.2418 627.623 74.2444C621.831 74.2484 621.828 74.2498 621.828 80.2058C621.827 91.9191 621.827 103.634 621.827 115.348Z'
        fill='#3854FE'
      />
      <path
        d='M520.339 100.692C520.339 83.6289 520.403 66.5649 520.282 49.5022C520.259 46.3356 521.198 45.2062 524.459 45.2689C533.935 45.4502 543.418 45.4222 552.895 45.2782C555.73 45.2356 556.833 45.9769 556.826 49.0062C556.741 83.4676 556.751 117.929 556.815 152.389C556.819 154.957 556.082 155.92 553.398 155.884C543.586 155.754 533.77 155.76 523.957 155.878C521.203 155.912 520.277 154.984 520.291 152.216C520.387 135.041 520.339 117.866 520.339 100.692Z'
        fill='#3854FE'
      />
      <path
        d='M538.71 36.3641C533.691 36.3641 528.672 36.2975 523.655 36.3935C521.252 36.4401 520.27 35.5841 520.302 33.0828C520.407 24.8321 520.388 16.5788 520.314 8.3268C520.294 6.05747 521.124 5.09614 523.432 5.10814C533.58 5.1628 543.728 5.16413 553.876 5.10414C556.058 5.09214 556.811 5.9788 556.795 8.10147C556.73 16.5761 556.723 25.0521 556.798 33.5268C556.818 35.7641 555.835 36.4215 553.764 36.3895C548.747 36.3108 543.728 36.3641 538.71 36.3641Z'
        fill='#3854FE'
      />
      <path
        d='M193.307 108.745C197.872 108.745 201.572 105.045 201.572 100.48C201.572 95.9157 197.872 92.2143 193.307 92.2143C188.742 92.2143 185.042 95.9157 185.042 100.48C185.042 105.045 188.742 108.745 193.307 108.745Z'
        fill='#3854FE'
      />
      <path
        d='M254.544 280.852C303.344 279.148 337.946 247.468 340.032 195.537'
        stroke='#FDD772'
        strokeWidth='35.5093'
        strokeMiterlimit='10'
      />
      <path
        d='M326.323 108.744C330.888 108.744 334.588 105.044 334.588 100.479C334.588 95.9151 330.888 92.2137 326.323 92.2137C321.758 92.2137 318.058 95.9151 318.058 100.479C318.058 105.044 321.758 108.744 326.323 108.744Z'
        fill='#3854FE'
      />
    </svg>
  ),
});
