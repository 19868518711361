import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const UsuariosIcon = createIcon({
  displayName: 'UsuariosIcon',
  viewBox: '0 0 34 35',
  path: (
    <>
      <path
        d='M25.5685 9.48758C25.5685 14.3702 21.6099 18.3276 16.7285 18.3276C11.8459 18.3276 7.88721 14.3702 7.88721 9.48758C7.88721 4.60491 11.8459 0.647575 16.7285 0.647575C21.6099 0.647575 25.5685 4.60491 25.5685 9.48758Z'
        fill='#5CC9BA'
      />
      <path
        d='M16.7282 0.621008C11.8455 0.621008 7.88684 4.57967 7.88684 9.46234H25.5695C25.5695 4.57967 21.6108 0.621008 16.7282 0.621008Z'
        fill='#F2F2F5'
      />
      <path
        d='M16.7281 18.2662C7.5228 18.2662 0.0614624 25.7275 0.0614624 34.9329H33.3948C33.3948 25.7275 25.9335 18.2662 16.7281 18.2662Z'
        fill='#F2F2F5'
      />
    </>
  ),
});
