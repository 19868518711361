import {call, put, takeEvery} from 'redux-saga/effects';

import * as api from '../services/documentService';
import {
  getDocuments,
  getDocumentsError,
  getDocumentsSuccess,
} from '../slices/documentsSlice';

export function* getAllDocuments({payload}) {
  try {
    const result = yield call(api.getDocuments, payload);
    yield put(getDocumentsSuccess(result));
  } catch (e) {
    yield put(getDocumentsError(e.toString()));
  }
}

export default function* rootSaga() {
  yield takeEvery(getDocuments, getAllDocuments);
}
