import React from 'react';

import {
  Box,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  Tooltip,
} from '@chakra-ui/react';

import {InfoIcon} from '../assets/icons';
import {listColors} from '../utils/colors';

interface IColorSelectorMenu {
  colors: string | null;
  onChangeColor: (any) => void;
  tooltip: string;
}

export function ColorSelectorMenu(props: IColorSelectorMenu) {
  const {colors, onChangeColor, tooltip} = props;
  const handleColor = color => {
    for (const c of listColors) {
      if (color.includes(c.color)) {
        return c.hex;
      }
    }
  };

  return (
    <FormControl mt={3}>
      <FormLabel fontWeight='bold' alignItems='center' display='flex'>
        <Tooltip label={tooltip} placement='right-start'>
          <InfoIcon w={17} h={17} mr={3} />
        </Tooltip>
        Color
      </FormLabel>
      <Menu placement='auto'>
        <MenuButton
          p={1.5}
          pl={3}
          borderRadius='10px'
          w='100%'
          type='button'
          textAlign='left'
          bg='white'
          color='gray.200'>
          {colors}
        </MenuButton>
        <MenuList bg='white' width='340px'>
          <MenuOptionGroup
            type='radio'
            onChange={(color: any) => {
              onChangeColor({color: color, hex: handleColor(color)!});
            }}>
            {listColors.map(color => (
              <MenuItemOption key={color.hex} value={color.color}>
                <Box display='flex'>
                  <Box bg={color.hex} w={6} h={6} borderRadius='50%' />
                  <Text ml={3}>{color.color}</Text>
                </Box>
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </FormControl>
  );
}
