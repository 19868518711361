import {Objective} from '../types/objectives';
import {sortAlpha} from '../utils/sortAlpha';
import {instance} from './index';

export async function getObjectives() {
  return instance()
    .get<Objective[]>('/objective-types')
    .then(response => sortAlpha(response.data, 'name'));
}

export function createObjective(data) {
  return instance().post('/objective-types', data);
}

export function updateObjective(data) {
  return instance().put(`/objective-types/${data.id}`, data);
}

export function disableObjective(data) {
  return instance().put(`/objective-types/${data.id}`, data);
}
