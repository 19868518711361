import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const ModuloSoporteIcon = createIcon({
  displayName: 'ModuloSoporteIcon',
  viewBox: '0 0 41 37',
  path: (
    <>
      <path
        d='M6.46708 0C4.7519 0 3.10697 0.655524 1.89416 1.82236C0.681351 2.9892 0 4.57177 0 6.22193V25.4488C0 27.099 0.681351 28.6816 1.89416 29.8484C3.10697 31.0153 4.7519 31.6708 6.46708 31.6708H20.3066C20.3866 31.4898 20.4806 31.3088 20.5841 31.1346L22.3008 28.2793H6.46708C4.84443 28.2793 3.5275 27.0122 3.5275 25.4511V9.05009H29.3887V11.652C30.4807 11.0909 31.6819 10.7542 32.9162 10.6633V6.22193C32.9162 4.57177 32.2349 2.9892 31.0221 1.82236C29.8093 0.655524 28.1643 0 26.4492 0H6.46708Z'
        fill='#73B3AB'
      />
      <path
        d='M28.2717 22.8515C27.512 21.8314 27.087 20.6159 27.0511 19.3614C27.0152 18.107 27.3702 16.8708 28.0704 15.812C28.7707 14.7532 29.7841 13.9202 30.9804 13.42C32.1766 12.9197 33.5011 12.7752 34.7835 13.005L31.7004 18.141C31.4276 18.6216 31.361 19.1857 31.5148 19.7129C31.6685 20.2401 32.0305 20.6886 32.5234 20.9625C33.0162 21.2363 33.6009 21.3139 34.1524 21.1786C34.7039 21.0433 35.1785 20.7058 35.4748 20.2383L38.5555 15.1024C39.3999 16.0529 39.9307 17.2239 40.0801 18.4659C40.2295 19.7079 39.9908 20.9646 39.3944 22.0755C38.798 23.1864 37.871 24.1011 36.7318 24.7027C35.5926 25.3044 34.2929 25.5658 32.9985 25.4534L27.3381 34.8882C26.9766 35.49 26.3815 35.9291 25.6836 36.1088C24.9857 36.2885 24.2421 36.1941 23.6166 35.8463C22.991 35.4986 22.5346 34.926 22.3479 34.2546C22.1611 33.5831 22.2592 32.8678 22.6206 32.2659L28.2717 22.8515Z'
        fill='white'
      />
      <path
        d='M23.5167 19.1775V17.0227H21.2227C21.0819 16.3483 20.8105 15.707 20.4235 15.134L22.0503 13.5287L20.5064 12.0053L18.8796 13.6106C18.2989 13.2287 17.6491 12.9609 16.9656 12.8219V10.5583H14.7819V12.8219C14.0984 12.9609 13.4485 13.2287 12.8679 13.6106L11.241 12.0053L9.69718 13.5287L11.324 15.134C10.937 15.707 10.6656 16.3483 10.5248 17.0227H8.23083V19.1775H10.5248C10.6656 19.8519 10.937 20.4932 11.324 21.0661L9.69718 22.6715L11.241 24.1949L12.8679 22.5896C13.4485 22.9715 14.0984 23.2393 14.7819 23.3782V25.6418H16.9656V23.3782C17.6491 23.2393 18.2989 22.9715 18.8796 22.5896L20.5064 24.1949L22.0503 22.6715L20.4235 21.0661C20.8105 20.4932 21.0819 19.8519 21.2227 19.1775H23.5167ZM15.8737 21.3323C15.2259 21.3323 14.5926 21.1427 14.054 20.7875C13.5153 20.4324 13.0955 19.9276 12.8475 19.337C12.5996 18.7464 12.5348 18.0965 12.6611 17.4695C12.7875 16.8425 13.0995 16.2666 13.5576 15.8146C14.0157 15.3626 14.5993 15.0547 15.2347 14.93C15.8701 14.8053 16.5287 14.8693 17.1272 15.1139C17.7258 15.3586 18.2373 15.7729 18.5972 16.3044C18.9572 16.8359 19.1493 17.4608 19.1493 18.1001C19.1484 18.9571 18.803 19.7787 18.1889 20.3846C17.5748 20.9906 16.7422 21.3314 15.8737 21.3323Z'
        fill='white'
      />
    </>
  ),
});
