import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const SalidaIcon = createIcon({
  displayName: 'SalidaIcon',
  viewBox: '0 0 13 15',
  path: (
    <>
      <path
        d='M3 8C3.26522 8 3.51957 7.89464 3.70711 7.70711C3.89464 7.51957 4 7.26522 4 7C4 6.73478 3.89464 6.48043 3.70711 6.29289C3.51957 6.10536 3.26522 6 3 6C2.73478 6 2.48043 6.10536 2.29289 6.29289C2.10536 6.48043 2 6.73478 2 7C2 7.26522 2.10536 7.51957 2.29289 7.70711C2.48043 7.89464 2.73478 8 3 8Z'
        fill='#7A5700'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 1.75C0 0.784 0.784 0 1.75 0H8.25C9.216 0 10 0.784 10 1.75V5.207C8.87163 4.88666 7.67041 4.93544 6.57173 5.34623C5.47306 5.75702 4.53449 6.50829 3.89308 7.49034C3.25167 8.47239 2.94102 9.63378 3.00649 10.8049C3.07196 11.976 3.51013 13.0956 4.257 14H1.75C1.28587 14 0.840752 13.8156 0.512563 13.4874C0.184375 13.1592 0 12.7141 0 12.25V1.75ZM3.70711 7.70711C3.51957 7.89464 3.26522 8 3 8C2.73478 8 2.48043 7.89464 2.29289 7.70711C2.10536 7.51957 2 7.26522 2 7C2 6.73478 2.10536 6.48043 2.29289 6.29289C2.48043 6.10536 2.73478 6 3 6C3.26522 6 3.51957 6.10536 3.70711 6.29289C3.89464 6.48043 4 6.73478 4 7C4 7.26522 3.89464 7.51957 3.70711 7.70711Z'
        fill='#7A5700'
      />
      <path
        d='M3 8C3.26522 8 3.51957 7.89464 3.70711 7.70711C3.89464 7.51957 4 7.26522 4 7C4 6.73478 3.89464 6.48043 3.70711 6.29289C3.51957 6.10536 3.26522 6 3 6C2.73478 6 2.48043 6.10536 2.29289 6.29289C2.10536 6.48043 2 6.73478 2 7C2 7.26522 2.10536 7.51957 2.29289 7.70711C2.48043 7.89464 2.73478 8 3 8Z'
        fill='#FFB800'
      />
      <path
        d='M8.854 12.854C8.76011 12.9479 8.63278 13.0006 8.5 13.0006C8.36722 13.0006 8.23989 12.9479 8.146 12.854C8.05211 12.7601 7.99937 12.6328 7.99937 12.5C7.99937 12.3672 8.05211 12.2399 8.146 12.146L9.293 11H6.5C6.36739 11 6.24021 10.9473 6.14645 10.8536C6.05268 10.7598 6 10.6326 6 10.5C6 10.3674 6.05268 10.2402 6.14645 10.1464C6.24021 10.0527 6.36739 10 6.5 10H9.293L8.146 8.854C8.05211 8.76011 7.99937 8.63278 7.99937 8.5C7.99937 8.36722 8.05211 8.23989 8.146 8.146C8.23989 8.05211 8.36722 7.99937 8.5 7.99937C8.63278 7.99937 8.76011 8.05211 8.854 8.146L10.854 10.146C10.947 10.2392 10.9994 10.3653 11 10.497V10.503C10.9992 10.6333 10.9475 10.7582 10.856 10.851L10.853 10.854L8.853 12.854H8.854Z'
        fill='#D94D5E'
      />
      <path
        d='M8.854 12.854C8.76011 12.9479 8.63278 13.0006 8.5 13.0006C8.36722 13.0006 8.23989 12.9479 8.146 12.854C8.05211 12.7601 7.99937 12.6328 7.99937 12.5C7.99937 12.3672 8.05211 12.2399 8.146 12.146L9.293 11H6.5C6.36739 11 6.24021 10.9473 6.14645 10.8536C6.05268 10.7598 6 10.6326 6 10.5C6 10.3674 6.05268 10.2402 6.14645 10.1464C6.24021 10.0527 6.36739 10 6.5 10H9.293L8.146 8.854C8.05211 8.76011 7.99937 8.63278 7.99937 8.5C7.99937 8.36722 8.05211 8.23989 8.146 8.146C8.23989 8.05211 8.36722 7.99937 8.5 7.99937C8.63278 7.99937 8.76011 8.05211 8.854 8.146L10.854 10.146C10.947 10.2392 10.9994 10.3653 11 10.497V10.503C10.9992 10.6333 10.9475 10.7582 10.856 10.851L10.853 10.854L8.853 12.854H8.854Z'
        fill='#D94D5E'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.5 15C9.69347 15 10.8381 14.5259 11.682 13.682C12.5259 12.8381 13 11.6935 13 10.5C13 9.30653 12.5259 8.16193 11.682 7.31802C10.8381 6.47411 9.69347 6 8.5 6C7.30653 6 6.16193 6.47411 5.31802 7.31802C4.47411 8.16193 4 9.30653 4 10.5C4 11.6935 4.47411 12.8381 5.31802 13.682C6.16193 14.5259 7.30653 15 8.5 15ZM8.5 13.0006C8.63278 13.0006 8.76011 12.9479 8.854 12.854H8.853L10.853 10.854L10.856 10.851C10.9475 10.7582 10.9992 10.6333 11 10.503V10.497C10.9994 10.3653 10.947 10.2392 10.854 10.146L8.854 8.146C8.76011 8.05211 8.63278 7.99937 8.5 7.99937C8.36722 7.99937 8.23989 8.05211 8.146 8.146C8.05211 8.23989 7.99937 8.36722 7.99937 8.5C7.99937 8.63278 8.05211 8.76011 8.146 8.854L9.293 10H6.5C6.36739 10 6.24021 10.0527 6.14645 10.1464C6.05268 10.2402 6 10.3674 6 10.5C6 10.6326 6.05268 10.7598 6.14645 10.8536C6.24021 10.9473 6.36739 11 6.5 11H9.293L8.146 12.146C8.05211 12.2399 7.99937 12.3672 7.99937 12.5C7.99937 12.6328 8.05211 12.7601 8.146 12.854C8.23989 12.9479 8.36722 13.0006 8.5 13.0006Z'
        fill='#D94D5E'
      />
      <path
        d='M8.854 12.854C8.76011 12.9479 8.63278 13.0007 8.5 13.0007C8.36722 13.0007 8.23989 12.9479 8.146 12.854C8.05211 12.7601 7.99937 12.6328 7.99937 12.5C7.99937 12.3672 8.05211 12.2399 8.146 12.146L9.293 11H6.5C6.36739 11 6.24021 10.9473 6.14645 10.8536C6.05268 10.7598 6 10.6326 6 10.5C6 10.3674 6.05268 10.2402 6.14645 10.1465C6.24021 10.0527 6.36739 10 6.5 10H9.293L8.146 8.85402C8.05211 8.76013 7.99937 8.6328 7.99937 8.50002C7.99937 8.36725 8.05211 8.23991 8.146 8.14602C8.23989 8.05213 8.36722 7.99939 8.5 7.99939C8.63278 7.99939 8.76011 8.05213 8.854 8.14602L10.854 10.146C10.947 10.2392 10.9994 10.3654 11 10.497V10.503C10.9992 10.6334 10.9475 10.7582 10.856 10.851L10.853 10.854L8.853 12.854H8.854Z'
        fill='white'
      />
    </>
  ),
});
