import {getColor, mode, transparentize} from '@chakra-ui/theme-tools';

type Dict = Record<string, never>;
const parts = ['container', 'title', 'description', 'icon'];

const baseStyle = {
  container: {
    px: '23px',
    py: '15px',
    borderRadius: '12px',
  },
  title: {
    fontWeight: 'bold',
    lineHeight: '20px',
    fontSize: '17px',
    marginEnd: 2,
  },
  description: {
    marginTop: '10px',
    fontSize: '14px',
    lineHeight: 6,
  },
  icon: {
    display: 'none',
    flexShrink: 0,
    marginEnd: 3,
    w: 5,
    h: '30px',
  },
};

function getBg(props: Dict) {
  const {theme, colorScheme: c} = props;
  const lightBg = getColor(theme, `${c}.100`, c);
  const darkBg = transparentize(`${c}.200`, 0.16)(theme);
  return mode(lightBg, darkBg)(props);
}

function variantSubtle(props: Dict) {
  const {colorScheme: c} = props;
  return {
    container: {bg: getBg(props), borderRadius: '12px'},
    icon: {color: `${c}.500`},
  };
}

function variantLeftAccent(props: Dict) {
  const {colorScheme: c} = props;
  return {
    container: {
      paddingStart: 3,
      borderStartWidth: '4px',
      borderStartColor: `${c}.500`,
      bg: getBg(props),
    },
    icon: {
      color: `${c}.500`,
    },
  };
}

function variantTopAccent(props: Dict) {
  const {colorScheme: c} = props;
  return {
    container: {
      pt: 2,
      borderTopWidth: '4px',
      borderTopColor: `${c}.500`,
      bg: getBg(props),
    },
    icon: {
      color: `${c}.500`,
    },
  };
}

function variantSolid(props: Dict) {
  const {colorScheme: c} = props;
  return {
    container: {
      bg: `${c}.300`,
      color: 'white',
    },
  };
}

const variants = {
  subtle: variantSubtle,
  'left-accent': variantLeftAccent,
  'top-accent': variantTopAccent,
  solid: variantSolid,
};

const defaultProps = {
  variant: 'subtle',
  colorScheme: 'blue',
};

const alert = {
  parts,
  baseStyle,
  variants,
  defaultProps,
};

export default alert;
