import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {v4 as uuidv4} from 'uuid';

import {RootState} from '../../app/rootReducer';

export interface Section {
  id: string;
  title: string;
  description: string;
  listQuestions: any[];
}

export interface SectionState {
  data: Section[];
  error: string | null;
  isLoading: boolean;
}

const initialState = {
  data: [
    {
      id: uuidv4(),
      title: 'Nueva sección',
      description: 'Descripción de sección',
      listQuestions: [],
    },
  ],
  error: null,
  isLoading: false,
} as SectionState;

const sectionSlice = createSlice({
  name: 'section',
  initialState,
  reducers: {
    getSection(state, action: PayloadAction<Section[]>) {
      state.data = action.payload;
    },
    addSection(state, action: PayloadAction<Section>) {
      state.data.push(action.payload);
    },
    updateSection(state, action: PayloadAction<Section>) {
      const index = state.data.findIndex(
        section => section.id === action.payload.id,
      );
      state.data[index] = action.payload;
    },
    disableSection(state, action: PayloadAction<Section>) {
      const index = state.data.findIndex(
        section => section.id === action.payload.id,
      );
      state.data.splice(index, 1);
    },
  },
});

export const getSectionSelector = (state: RootState) => state.section.data;
export const isSectionLoading = state => state.section.isLoading;
export const getErrorSelector = state => state.section.error;

export default sectionSlice.reducer;
export const {getSection, addSection, updateSection, disableSection} =
  sectionSlice.actions;
