import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const TipoUbicacionIcon = createIcon({
  displayName: 'TipoUbicacionIcon',
  viewBox: '0 0 37 32',
  path: (
    <>
      <ellipse
        cx='18.6105'
        cy='10.3566'
        rx='5.63044'
        ry='5.03008'
        fill='white'
      />
      <path
        d='M29.2917 10.8113C29.2917 4.84078 24.46 -2.09808e-05 18.5008 -2.09808e-05C12.54 -2.09808e-05 7.70834 4.84078 7.70834 10.8113C7.70834 13.1125 8.42754 15.1543 9.64972 16.7952L9.64822 16.7923L17.8491 28.6126C18.1629 29.0657 18.8371 29.0657 19.1524 28.6126L27.4659 16.6373C27.5155 16.5672 27.565 16.4972 27.6131 16.4256L27.6296 16.4018H27.6281C28.6821 14.8324 29.2917 12.9321 29.2917 10.8113ZM18.5218 15.0321C15.9423 15.0321 13.8523 12.956 13.8523 10.3955C13.8523 7.83499 15.9423 5.76035 18.5218 5.76035C21.1013 5.76035 23.1928 7.83499 23.1928 10.3955C23.1928 12.956 21.1013 15.0321 18.5218 15.0321Z'
        fill='#F55266'
      />
      <ellipse
        cx='29.3706'
        cy='19.2764'
        rx='4.02174'
        ry='3.44164'
        fill='white'
      />
      <path
        d='M37 19.5877C37 15.5026 33.5488 12.1905 29.2922 12.1905C25.0345 12.1905 21.5833 15.5026 21.5833 19.5877C21.5833 21.1622 22.097 22.5592 22.97 23.6819L22.9689 23.6799L28.8267 31.7675C29.0509 32.0775 29.5324 32.0775 29.7576 31.7675L35.6959 23.5739C35.7313 23.5259 35.7666 23.478 35.801 23.4291L35.8128 23.4127H35.8117C36.5646 22.3389 37 21.0388 37 19.5877ZM29.3072 22.4756C27.4647 22.4756 25.9718 21.0551 25.9718 19.3032C25.9718 17.5512 27.4647 16.1318 29.3072 16.1318C31.1497 16.1318 32.6436 17.5512 32.6436 19.3032C32.6436 21.0551 31.1497 22.4756 29.3072 22.4756Z'
        fill='#3885FE'
      />
      <ellipse
        cx='7.78727'
        cy='19.2767'
        rx='4.02174'
        ry='3.44163'
        fill='white'
      />
      <path
        d='M15.4167 19.5877C15.4167 15.5026 11.9655 12.1905 7.70887 12.1905C3.45119 12.1905 0 15.5026 0 19.5877C0 21.1622 0.51371 22.5592 1.3867 23.682L1.38562 23.6799L7.24342 31.7675C7.46757 32.0775 7.9491 32.0775 8.17432 31.7675L14.1126 23.5739C14.1479 23.5259 14.1833 23.478 14.2177 23.4291L14.2294 23.4127H14.2284C14.9812 22.339 15.4167 21.0388 15.4167 19.5877ZM7.72388 22.4756C5.88139 22.4756 4.38852 21.0551 4.38852 19.3032C4.38852 17.5513 5.88139 16.1318 7.72388 16.1318C9.56638 16.1318 11.0603 17.5513 11.0603 19.3032C11.0603 21.0551 9.56638 22.4756 7.72388 22.4756Z'
        fill='#FFB800'
      />
    </>
  ),
});
