import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const KpiParentNegative = createIcon({
  displayName: 'KpiParentNegative',
  viewBox: '0 0 14 12',
  path: (
    <path
      d='M7.848 10.53L12.848 2.52999L13.272 2.79499L12.848 2.52999C12.9426 2.37862 12.995 2.20469 12.9997 2.02624C13.0043 1.8478 12.9612 1.67135 12.8746 1.51523C12.7881 1.3591 12.6614 1.22898 12.5076 1.13838C12.3538 1.04777 12.1785 0.999994 12 0.999994L2 0.999994C1.82149 0.999994 1.64624 1.04777 1.49244 1.13838C1.33863 1.22898 1.21189 1.3591 1.12535 1.51523C1.03882 1.67135 0.995651 1.8478 1.00034 2.02624C1.00502 2.20468 1.05739 2.37862 1.152 2.52999L6.152 10.53C6.24189 10.6738 6.36688 10.7924 6.51523 10.8746C6.66357 10.9569 6.83039 11 7 11C7.1696 11 7.33642 10.9569 7.48477 10.8746C7.63311 10.7924 7.75811 10.6738 7.848 10.53Z'
      fill='#415d7f'
    />
  ),
});
