import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';
import {Analytic} from '../types/analytics';

export interface viewReportState {
  dataReport: Analytic | null;
}

export interface viewReportStateStates {
  data: viewReportState;
}

const initialState = {
  data: {status: false, dataReport: null},
} as viewReportStateStates;
const viewReportStateSlice = createSlice({
  name: 'viewReportState',
  initialState,
  reducers: {
    viewReport(state, action: PayloadAction<viewReportState>) {
      state.data = action.payload;
    },
  },
});

export const getViewReport = (state: RootState) => state.viewReportState.data;

export default viewReportStateSlice.reducer;
export const {viewReport} = viewReportStateSlice.actions;
