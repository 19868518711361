import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const MileageIcon = createIcon({
  displayName: 'MileageIcon',
  viewBox: '0 0 110 100',
  path: (
    <>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15 16.418L42.275 29.088L42.646 94.035L15 81.191V16.418ZM21.686 77.656L23.482 74.082C25.767 75.23 28.164 76.198 30.619 77.045L29.314 80.826C26.731 79.934 24.166 78.903 21.686 77.656ZM33.254 82.051L34.318 78.195C36.811 78.884 39.325 79.418 41.846 79.701L41.4 83.676C38.62 83.363 35.9 82.782 33.254 82.051Z'
        fill='#F2F2F5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M73.953 16.129L74.068 36.465C73.0352 36.3233 71.9965 36.2295 70.955 36.184L70.807 40.18C71.895 40.22 72.992 40.338 74.092 40.498L74.322 80.732L45.646 94.055L45.277 29.451L73.953 16.129ZM62.445 37.908C63.757 37.145 65.166 36.704 66.543 36.457L67.25 40.393C66.221 40.577 65.26 40.893 64.453 41.363L64.447 41.367L64.441 41.371C63.506 41.9191 62.7368 42.71 62.215 43.66L58.713 41.728C59.5849 40.1378 60.8756 38.8167 62.445 37.908ZM57.177 46.242L57.207 46.084L61.137 46.838L61.113 46.963C60.704 49.296 60.727 51.782 60.836 54.351L56.84 54.521C56.728 51.873 56.675 49.087 57.174 46.256L57.177 46.242ZM57.084 58.564L61.074 58.294C61.0887 58.5108 61.1036 58.728 61.1185 58.9456C61.2858 61.3892 61.4563 63.8805 61.449 66.424L57.449 66.414C57.4555 64.0385 57.2913 61.6192 57.124 59.1547C57.1107 58.9581 57.0973 58.7612 57.084 58.564ZM56.736 72.954C56.976 72.064 57.14 71.137 57.25 70.184L61.223 70.638C61.095 71.748 60.9 72.872 60.6 73.988L60.598 73.994C60.123 75.798 59.306 77.581 58.018 79.096L54.971 76.506C55.793 75.538 56.382 74.302 56.732 72.967L56.734 72.959L56.736 72.954ZM45.589 79.904C48.062 79.874 50.467 79.497 52.459 78.477L54.281 82.037C51.503 83.46 48.473 83.87 45.639 83.904L45.589 79.904Z'
        fill='#F2F2F5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M76.951 15.775L78.328 16.416C78.5966 18.9148 79.5003 21.3032 80.953 23.354L91.301 41.244C92.751 43.138 93.715 42.778 94.92 41.144L102.777 27.771L105 28.805V93.58L77.322 80.723L77.096 41.047C77.1752 41.0641 77.2544 41.0811 77.3336 41.098C77.6622 41.1685 77.9904 41.2388 78.32 41.317L79.24 37.426C78.5201 37.2558 77.7974 37.0981 77.072 36.953L76.951 15.775ZM82.076 42.322L83.209 38.488C85.822 39.26 88.39 40.14 90.922 41.084L89.523 44.832C87.058 43.912 84.579 43.062 82.076 42.322Z'
        fill='#F2F2F5'
      />
      <path
        d='M93.117 6.953C97.522 6.953 101.025 10.449 101.025 14.842C101.025 19.234 97.522 22.727 93.117 22.727C88.712 22.727 85.209 19.234 85.209 14.842C85.209 10.449 88.712 6.953 93.117 6.953Z'
        fill='#F2F2F5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M62.445 37.908C63.757 37.145 65.166 36.704 66.543 36.457L67.25 40.393C66.221 40.577 65.26 40.893 64.453 41.363L64.447 41.367L64.441 41.371C63.506 41.9191 62.7368 42.71 62.215 43.66L58.713 41.728C59.5849 40.1378 60.8756 38.8167 62.445 37.908ZM82.076 42.322L83.209 38.488C85.822 39.26 88.39 40.14 90.922 41.084L89.523 44.832C87.058 43.912 84.579 43.062 82.076 42.322ZM57.177 46.242L57.207 46.084L61.137 46.838L61.113 46.963C60.704 49.296 60.727 51.782 60.836 54.351L56.84 54.521C56.728 51.873 56.675 49.087 57.174 46.256L57.177 46.242ZM57.084 58.564L61.074 58.294C61.0887 58.5108 61.1036 58.728 61.1185 58.9456C61.2858 61.3892 61.4563 63.8805 61.449 66.424L57.449 66.414C57.4555 64.0385 57.2913 61.6192 57.124 59.1547C57.1107 58.9581 57.0973 58.7612 57.084 58.564ZM56.736 72.954C56.976 72.064 57.14 71.137 57.25 70.184L61.223 70.638C61.095 71.748 60.9 72.872 60.6 73.988L60.598 73.994C60.123 75.798 59.306 77.581 58.018 79.096L54.971 76.506C55.793 75.538 56.382 74.302 56.732 72.967L56.734 72.959L56.736 72.954ZM21.686 77.656L23.482 74.082C25.767 75.23 28.164 76.198 30.619 77.045L29.314 80.826C26.731 79.934 24.166 78.903 21.686 77.656ZM33.254 82.051L34.318 78.195C36.811 78.884 39.325 79.418 41.846 79.701L41.4 83.676C38.62 83.363 35.9 82.782 33.254 82.051ZM45.589 79.904C48.062 79.874 50.467 79.497 52.459 78.477L54.281 82.037C51.503 83.46 48.473 83.87 45.639 83.904L45.589 79.904Z'
        fill='#F2F2F5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M105.323 23.441L102.777 27.771L105 28.805V93.58L77.322 80.723L77.096 41.047C77.1752 41.0641 77.2544 41.0811 77.3336 41.098C77.6622 41.1685 77.9904 41.2388 78.32 41.317L79.24 37.426C78.5201 37.2558 77.7974 37.0981 77.072 36.953L76.951 15.775L78.328 16.416C78.1869 14.4579 78.2884 13.2862 78.738 11.092L76.887 10.232C76.5568 10.0785 76.1971 9.99895 75.833 9.99895C75.4689 9.99895 75.1092 10.0785 74.779 10.232L44.166 24.453L13.553 10.233C13.1721 10.0561 12.7527 9.97802 12.3337 10.006C11.9146 10.0339 11.5093 10.167 11.1553 10.3929C10.8012 10.6188 10.5098 10.9303 10.3079 11.2985C10.106 11.6668 10.0001 12.08 10 12.5V82.787C9.99989 83.2637 10.136 83.7304 10.3924 84.1322C10.6488 84.5341 11.0147 84.8542 11.447 85.055L43.113 99.764C43.4432 99.9175 43.8029 99.997 44.167 99.997C44.5311 99.997 44.8908 99.9175 45.221 99.764L75.834 85.544L106.447 99.764C108.104 100.533 110 99.324 110 97.498V27.211C110 26.7343 109.864 26.2676 109.608 25.8658C109.351 25.4639 108.985 25.1438 108.553 24.943L105.323 23.441ZM73.953 16.129L74.068 36.465C73.0352 36.3233 71.9965 36.2295 70.955 36.184L70.807 40.18C71.895 40.22 72.992 40.338 74.092 40.498L74.322 80.732L45.646 94.055L45.277 29.451L73.953 16.129ZM15 16.418L42.275 29.088L42.646 94.035L15 81.191V16.418Z'
        fill='#F2F2F5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M93.117 0C86.237 0 80.419 4.735 78.738 11.092C78.2884 13.2862 78.1869 14.4579 78.328 16.416C78.5966 18.9148 79.5003 21.3032 80.953 23.354L91.301 41.244C92.751 43.138 93.715 42.778 94.92 41.144L102.777 27.771L105.323 23.441L106.334 21.719C106.564 21.302 106.747 20.858 106.904 20.404C107.628 18.6391 108 16.7497 108 14.842C108 6.685 101.298 0 93.117 0ZM101.025 14.842C101.025 10.449 97.522 6.953 93.117 6.953C88.712 6.953 85.209 10.449 85.209 14.842C85.209 19.234 88.712 22.727 93.117 22.727C97.522 22.727 101.025 19.234 101.025 14.842Z'
        fill='#F2F2F5'
      />
      <path
        d='M93.117 6.953C97.522 6.953 101.025 10.449 101.025 14.842C101.025 19.234 97.522 22.727 93.117 22.727C88.712 22.727 85.209 19.234 85.209 14.842C85.209 10.449 88.712 6.953 93.117 6.953Z'
        fill='#5EB5AB'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M93.117 0C86.237 0 80.419 4.735 78.738 11.092C78.2884 13.2862 78.1869 14.4579 78.328 16.416C78.5966 18.9148 79.5003 21.3032 80.953 23.354L91.301 41.244C92.751 43.138 93.715 42.778 94.92 41.144L102.777 27.771L105.323 23.441L106.334 21.719C106.564 21.302 106.747 20.858 106.904 20.404C107.628 18.6391 108 16.7497 108 14.842C108 6.685 101.298 0 93.117 0ZM101.025 14.842C101.025 10.449 97.522 6.953 93.117 6.953C88.712 6.953 85.209 10.449 85.209 14.842C85.209 19.234 88.712 22.727 93.117 22.727C97.522 22.727 101.025 19.234 101.025 14.842Z'
        fill='#5EB5AB'
      />
      <path
        d='M93.117 6.953C97.522 6.953 101.025 10.449 101.025 14.842C101.025 19.234 97.522 22.727 93.117 22.727C88.712 22.727 85.209 19.234 85.209 14.842C85.209 10.449 88.712 6.953 93.117 6.953Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15 16.418L42.275 29.088L42.646 94.035L15 81.191V16.418ZM21.686 77.656L23.482 74.082C25.767 75.23 28.164 76.198 30.619 77.045L29.314 80.826C26.731 79.934 24.166 78.903 21.686 77.656ZM33.254 82.051L34.318 78.195C36.811 78.884 39.325 79.418 41.846 79.701L41.4 83.676C38.62 83.363 35.9 82.782 33.254 82.051Z'
        fill='#FFB800'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M73.953 16.129L74.068 36.465C73.0352 36.3233 71.9965 36.2295 70.955 36.184L70.807 40.18C71.895 40.22 72.992 40.338 74.092 40.498L74.322 80.732L45.646 94.055L45.277 29.451L73.953 16.129ZM62.445 37.908C63.757 37.145 65.166 36.704 66.543 36.457L67.25 40.393C66.221 40.577 65.26 40.893 64.453 41.363L64.447 41.367L64.441 41.371C63.506 41.9191 62.7368 42.7101 62.215 43.66L58.713 41.728C59.5849 40.1379 60.8756 38.8168 62.445 37.908ZM57.177 46.242L57.207 46.084L61.137 46.838L61.113 46.963C60.704 49.296 60.727 51.782 60.836 54.351L56.84 54.521C56.728 51.873 56.675 49.087 57.174 46.256L57.177 46.242ZM57.084 58.564L61.074 58.294C61.254 60.946 61.457 63.654 61.449 66.424L57.449 66.414C57.456 63.849 57.264 61.233 57.084 58.564ZM56.736 72.954C56.976 72.064 57.14 71.137 57.25 70.184L61.223 70.638C61.095 71.748 60.9 72.872 60.6 73.988L60.598 73.994C60.123 75.798 59.306 77.581 58.018 79.096L54.971 76.506C55.793 75.538 56.382 74.302 56.732 72.967L56.734 72.959L56.736 72.954ZM45.589 79.904C48.062 79.874 50.467 79.497 52.459 78.477L54.281 82.037C51.503 83.46 48.473 83.87 45.639 83.904L45.589 79.904Z'
        fill='#FFB800'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M76.951 15.775L78.328 16.416C78.5966 18.9148 79.5003 21.3032 80.953 23.354L91.301 41.244C92.751 43.138 93.715 42.778 94.92 41.144L102.777 27.771L105 28.805V93.58L77.322 80.723L77.096 41.047C77.504 41.135 77.911 41.22 78.32 41.317L79.24 37.426C78.5201 37.2558 77.7974 37.0981 77.072 36.953L76.951 15.775ZM82.076 42.322L83.209 38.488C85.822 39.26 88.39 40.14 90.922 41.084L89.523 44.832C87.058 43.912 84.579 43.062 82.076 42.322Z'
        fill='#FFB800'
      />
      <path
        d='M23.482 74.082L21.686 77.656C24.166 78.903 26.731 79.934 29.314 80.826L30.619 77.045C28.164 76.198 25.767 75.23 23.482 74.082Z'
        fill='#FFB800'
      />
      <path
        d='M34.318 78.195L33.254 82.051C35.9 82.782 38.62 83.363 41.4 83.676L41.846 79.701C39.325 79.418 36.811 78.884 34.318 78.195Z'
        fill='#FFB800'
      />
      <path
        d='M52.459 78.477C50.467 79.497 48.062 79.874 45.589 79.904L45.639 83.904C48.473 83.87 51.503 83.46 54.281 82.037L52.459 78.477Z'
        fill='#FFB800'
      />
      <path
        d='M57.25 70.184C57.14 71.137 56.976 72.064 56.736 72.954L56.734 72.959L56.732 72.967C56.382 74.302 55.793 75.538 54.971 76.506L58.018 79.096C59.306 77.581 60.123 75.798 60.598 73.994L60.6 73.988C60.9 72.872 61.095 71.748 61.223 70.638L57.25 70.184Z'
        fill='#FFB800'
      />
      <path
        d='M61.074 58.294L57.084 58.564C57.264 61.233 57.456 63.849 57.449 66.414L61.449 66.424C61.457 63.654 61.254 60.946 61.074 58.294Z'
        fill='#FFB800'
      />
      <path
        d='M57.207 46.084L57.177 46.242L57.174 46.256C56.675 49.087 56.728 51.873 56.84 54.521L60.836 54.351C60.727 51.782 60.704 49.296 61.113 46.963L61.137 46.838L57.207 46.084Z'
        fill='#FFB800'
      />
      <path
        d='M66.543 36.457C65.166 36.704 63.757 37.145 62.445 37.908C60.8756 38.8168 59.5849 40.1379 58.713 41.728L62.215 43.66C62.7368 42.7101 63.506 41.9191 64.441 41.371L64.447 41.367L64.453 41.363C65.26 40.893 66.221 40.577 67.25 40.393L66.543 36.457Z'
        fill='#FFB800'
      />
      <path
        d='M83.209 38.488L82.076 42.322C84.579 43.062 87.058 43.912 89.523 44.832L90.922 41.084C88.39 40.14 85.822 39.26 83.209 38.488Z'
        fill='#FFB800'
      />
      <path
        d='M77.096 41.047C77.504 41.135 77.911 41.22 78.32 41.317L79.24 37.426C78.5201 37.2558 77.7974 37.0981 77.072 36.953L74.068 36.465C73.0352 36.3233 71.9965 36.2295 70.955 36.184L70.807 40.18C71.895 40.22 72.992 40.338 74.092 40.498L77.096 41.047Z'
        fill='#FFB800'
      />
      <path
        d='M23.482 74.0821L21.686 77.6561C24.166 78.9031 26.731 79.9341 29.314 80.8261L30.619 77.0451C28.164 76.1981 25.767 75.2301 23.482 74.0821Z'
        fill='#5EB5AB'
      />
      <path
        d='M34.318 78.1951L33.254 82.0511C35.9 82.7821 38.62 83.3631 41.4 83.6761L41.846 79.7011C39.325 79.4181 36.811 78.8841 34.318 78.1951Z'
        fill='#5EB5AB'
      />
      <path
        d='M52.459 78.4771C50.467 79.4971 48.062 79.8741 45.589 79.9041L45.639 83.9041C48.473 83.8701 51.503 83.4601 54.281 82.0371L52.459 78.4771Z'
        fill='#5EB5AB'
      />
      <path
        d='M57.25 70.1841C57.14 71.1371 56.976 72.0641 56.736 72.9541L56.734 72.9591L56.732 72.9671C56.382 74.3021 55.793 75.5381 54.971 76.5061L58.018 79.0961C59.306 77.5811 60.123 75.7981 60.598 73.9941L60.6 73.9881C60.9 72.8721 61.095 71.7481 61.223 70.6381L57.25 70.1841Z'
        fill='#5EB5AB'
      />
      <path
        d='M61.074 58.2941L57.084 58.5641C57.264 61.2331 57.456 63.8491 57.449 66.4141L61.449 66.4241C61.457 63.6541 61.254 60.9461 61.074 58.2941Z'
        fill='#5EB5AB'
      />
      <path
        d='M57.207 46.0841L57.177 46.2421L57.174 46.2561C56.675 49.0871 56.728 51.8731 56.84 54.5211L60.836 54.3511C60.727 51.7821 60.704 49.2961 61.113 46.9631L61.137 46.8381L57.207 46.0841Z'
        fill='#5EB5AB'
      />
      <path
        d='M66.543 36.4571C65.166 36.7041 63.757 37.1451 62.445 37.9081C60.8756 38.8168 59.5849 40.1379 58.713 41.7281L62.215 43.6601C62.7368 42.7101 63.506 41.9191 64.441 41.3711L64.447 41.3671L64.453 41.3631C65.26 40.8931 66.221 40.5771 67.25 40.3931L66.543 36.4571Z'
        fill='#5EB5AB'
      />
      <path
        d='M83.209 38.4881L82.076 42.3221C84.579 43.0621 87.058 43.9121 89.523 44.8321L90.922 41.0841C88.39 40.1401 85.822 39.2601 83.209 38.4881Z'
        fill='#5EB5AB'
      />
      <path
        d='M77.096 41.0471C77.504 41.1351 77.911 41.2201 78.32 41.3171L79.24 37.4261C78.5201 37.2559 77.7974 37.0982 77.072 36.9531L74.068 36.4651C73.0352 36.3234 71.9965 36.2296 70.955 36.1841L70.807 40.1801C71.895 40.2201 72.992 40.3381 74.092 40.4981L77.096 41.0471Z'
        fill='#5EB5AB'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M73.953 16.129L74.068 36.465C73.0352 36.3233 71.9965 36.2295 70.955 36.184L70.807 40.18C71.895 40.22 72.992 40.338 74.092 40.498L74.322 80.732L45.646 94.055L45.277 29.451L73.953 16.129ZM62.445 37.908C63.757 37.145 65.166 36.704 66.543 36.457L67.25 40.393C66.221 40.577 65.26 40.893 64.453 41.363L64.447 41.367L64.441 41.371C63.506 41.9191 62.7368 42.7101 62.215 43.66L58.713 41.728C59.5849 40.1379 60.8756 38.8168 62.445 37.908ZM57.177 46.242L57.207 46.084L61.137 46.838L61.113 46.963C60.704 49.296 60.727 51.782 60.836 54.351L56.84 54.521C56.728 51.873 56.675 49.087 57.174 46.256L57.177 46.242ZM57.084 58.564L61.074 58.294C61.254 60.946 61.457 63.654 61.449 66.424L57.449 66.414C57.456 63.849 57.264 61.233 57.084 58.564ZM56.736 72.954C56.976 72.064 57.14 71.137 57.25 70.184L61.223 70.638C61.095 71.748 60.9 72.872 60.6 73.988L60.598 73.994C60.123 75.798 59.306 77.581 58.018 79.096L54.971 76.506C55.793 75.538 56.382 74.302 56.732 72.967L56.734 72.959L56.736 72.954ZM45.589 79.904C48.062 79.874 50.467 79.497 52.459 78.477L54.281 82.037C51.503 83.46 48.473 83.87 45.639 83.904L45.589 79.904Z'
        fill='#F6704D'
        fillOpacity='0.65'
      />
      <path d='M22.1296 62H24.9591V67.2115H22.1296V62Z' fill='#3885FE' />
      <path d='M5.79272 62H8.62553V67.2115H5.79272V62Z' fill='#3885FE' />
      <path
        d='M20.7194 78.9282C19.8715 79.7215 18.7983 80.3605 17.5 80.8467V82.9583C18.6128 82.6384 19.6254 82.1005 20.5395 81.3456V91.2693H22.9257V78.9281L20.7194 78.9282Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.10352e-05 73.7168H30.7545V95.2642H6.10352e-05V73.7168ZM7.8284 82.1729C7.88186 79.7758 9.9201 78.7072 12.0809 78.6965C13.6463 78.7114 15.1279 79.4371 15.7168 80.7887C15.9938 81.5962 16.1416 82.4576 15.7967 83.2093C15.3651 84.0765 14.4892 84.5278 13.6306 84.8188C15.2421 85.2249 16.3037 86.2032 16.3134 87.7931C16.3018 88.3088 16.1748 88.816 15.9413 89.2799C15.7077 89.7438 15.3731 90.1535 14.9604 90.4809C14.112 91.1287 13.0862 91.4914 12.0809 91.4981C9.24594 91.4981 7.8284 90.2626 7.8284 87.7931H10.3313C10.3845 88.8328 10.8935 89.6236 11.941 89.6375C13.2726 89.5499 13.7996 88.7046 13.8106 87.6225C13.81 86.9689 13.5626 86.3003 12.9741 86.0485C12.2867 85.7726 11.5268 85.7752 10.8402 85.7777C10.7686 85.7779 10.6977 85.7782 10.6279 85.7781V83.8982C11.8369 83.9226 13.4983 83.7164 13.5107 82.3242C13.4637 81.3001 13.0301 80.5699 12.0009 80.557C10.9046 80.6209 10.3026 81.2222 10.2913 82.1729H7.8284ZM17.5 80.8467C18.7983 80.3605 19.8715 79.7215 20.7194 78.9282L22.9257 78.9281V91.2693H20.5395V81.3456C19.6254 82.1005 18.6128 82.6384 17.5 82.9583V80.8467Z'
        fill='white'
      />
      <path
        d='M12.0809 78.6965C9.9201 78.7072 7.88186 79.7758 7.8284 82.1729H10.2913C10.3026 81.2222 10.9046 80.6209 12.0009 80.557C13.0301 80.5699 13.4637 81.3001 13.5107 82.3242C13.4983 83.7164 11.8369 83.9226 10.6279 83.8982V85.7781C10.6977 85.7782 10.7686 85.7779 10.8402 85.7777C11.5268 85.7752 12.2867 85.7726 12.9741 86.0485C13.5626 86.3003 13.81 86.9689 13.8106 87.6225C13.7996 88.7046 13.2726 89.5499 11.941 89.6375C10.8935 89.6236 10.3845 88.8328 10.3313 87.7931H7.8284C7.8284 90.2626 9.24594 91.4981 12.0809 91.4981C13.0862 91.4914 14.112 91.1287 14.9604 90.4809C15.3731 90.1535 15.7077 89.7438 15.9413 89.2799C16.1748 88.816 16.3018 88.3088 16.3134 87.7931C16.3037 86.2032 15.2421 85.2249 13.6306 84.8188C14.4892 84.5278 15.3651 84.0765 15.7967 83.2093C16.1416 82.4576 15.9938 81.5962 15.7168 80.7887C15.1279 79.4371 13.6463 78.7114 12.0809 78.6965Z'
        fill='white'
      />
      <path
        d='M20.7194 78.928C19.8715 79.7214 18.7983 80.3603 17.5 80.8465V82.9581C18.6128 82.6382 19.6254 82.1004 20.5395 81.3454V91.2691H22.9257V78.9279L20.7194 78.928Z'
        fill='#0E3663'
      />
      <path
        d='M12.0809 78.6963C9.9201 78.707 7.88187 79.7756 7.8284 82.1727H10.2913C10.3026 81.222 10.9046 80.6207 12.0009 80.5568C13.0301 80.5697 13.4637 81.3 13.5107 82.324C13.4983 83.7163 11.8369 83.9224 10.6279 83.898V85.7779C10.6977 85.778 10.7686 85.7777 10.8402 85.7775C11.5268 85.7751 12.2867 85.7724 12.9741 86.0483C13.5626 86.3001 13.81 86.9687 13.8106 87.6223C13.7996 88.7044 13.2726 89.5497 11.941 89.6374C10.8935 89.6234 10.3845 88.8326 10.3313 87.793H7.8284C7.8284 90.2624 9.24594 91.4979 12.0809 91.4979C13.0862 91.4913 14.112 91.1285 14.9604 90.4807C15.3731 90.1533 15.7077 89.7436 15.9413 89.2797C16.1748 88.8158 16.3018 88.3086 16.3134 87.7929C16.3037 86.203 15.2421 85.2247 13.6306 84.8186C14.4892 84.5276 15.3651 84.0763 15.7967 83.2091C16.1416 82.4574 15.9938 81.596 15.7168 80.7885C15.1279 79.4369 13.6463 78.7112 12.0809 78.6963Z'
        fill='#0E3663'
      />
      <path
        d='M4.67326e-05 64.5042H3.76933V69.1652H10.648V64.5042H20.1031V69.1652H26.9851V64.5042H30.7544V71.8012H0L4.67326e-05 64.5042Z'
        fill='#F55266'
      />
    </>
  ),
});
