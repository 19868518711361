import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';

export interface CalendarShowFilters {
  type?: boolean;
}

export interface CalendarShowFiltersState {
  data: CalendarShowFilters[];
  error: string | null;
}

const initialState = {
  data: [{type: false}],
  error: null,
} as CalendarShowFiltersState;
const calendarShowFiltersSlice = createSlice({
  name: 'calendarShowFilters',
  initialState,
  reducers: {
    addCalendarShowFilters(state, action) {
      const x = '';
    },
    addCalendarShowFiltersSuccess(state, action) {
      state.data = [...action.payload];
    },
    addCalendarShowFiltersError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export const getCalendarShowFiltersSelector = (state: RootState) =>
  state.calendarShowFilters.data;
export const getErrorSelector = state => state.calendarShowFilters.error;

export default calendarShowFiltersSlice.reducer;
export const {
  addCalendarShowFilters,
  addCalendarShowFiltersSuccess,
  addCalendarShowFiltersError,
} = calendarShowFiltersSlice.actions;
