export const listColors = [
  {
    color: 'Amarillo',
    hex: '#FFB800',
  },
  {
    color: 'Naranja',
    hex: '#F6704D',
  },
  {
    color: 'Verde',
    hex: '#33A854',
  },
  {
    color: 'Azul',
    hex: '#0E3663',
  },
  {
    color: 'Rosado',
    hex: '#FABAC2',
  },
  {
    color: 'Rojo',
    hex: '#F55266',
  },
  {
    color: 'Celeste',
    hex: '#3885FE',
  },
];

export function getColorRandom(num) {
  return listColors[num].hex;
}

export function handleColorHex(hex){
  for (const c of listColors) {
    if (c.hex.includes(hex)) {
      return c.color;
    }
  }
}