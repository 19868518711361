import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const EntradaIcon = createIcon({
  displayName: 'EntradaIcon',
  viewBox: '0 0 15 15',
  path: (
    <>
      <path
        d='M8.147 10.146L8.144 10.149C8.0523 10.2417 8.0006 10.3666 8 10.497V10.503C8.0006 10.6344 8.05308 10.7602 8.146 10.853L10.146 12.853C10.2398 12.9469 10.367 12.9997 10.4996 12.9998C10.6323 12.9999 10.7596 12.9473 10.8535 12.8535C10.9474 12.7598 11.0002 12.6326 11.0003 12.4999C11.0004 12.3672 10.9478 12.2399 10.854 12.146L9.707 11H12.5C12.6326 11 12.7598 10.9473 12.8536 10.8536C12.9473 10.7598 13 10.6326 13 10.5C13 10.3674 12.9473 10.2402 12.8536 10.1465C12.7598 10.0527 12.6326 10 12.5 10H9.707L10.854 8.85402C10.9479 8.76013 11.0006 8.6328 11.0006 8.50002C11.0006 8.36725 10.9479 8.23991 10.854 8.14602C10.7601 8.05213 10.6328 7.99939 10.5 7.99939C10.3672 7.99939 10.2399 8.05213 10.146 8.14602L8.146 10.146H8.147Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.682 13.682C14.5259 12.8381 15 11.6935 15 10.5C15 9.30653 14.5259 8.16193 13.682 7.31802C12.8381 6.47411 11.6935 6 10.5 6C9.30653 6 8.16193 6.47411 7.31802 7.31802C6.47411 8.16193 6 9.30653 6 10.5C6 11.6935 6.47411 12.8381 7.31802 13.682C8.16193 14.5259 9.30653 15 10.5 15C11.6935 15 12.8381 14.5259 13.682 13.682ZM8.144 10.149L8.147 10.146H8.146L10.146 8.146C10.2399 8.05211 10.3672 7.99937 10.5 7.99937C10.6328 7.99937 10.7601 8.05211 10.854 8.146C10.9479 8.23989 11.0006 8.36722 11.0006 8.5C11.0006 8.63278 10.9479 8.76011 10.854 8.854L9.707 10H12.5C12.6326 10 12.7598 10.0527 12.8536 10.1464C12.9473 10.2402 13 10.3674 13 10.5C13 10.6326 12.9473 10.7598 12.8536 10.8536C12.7598 10.9473 12.6326 11 12.5 11H9.707L10.854 12.146C10.9478 12.2399 11.0004 12.3672 11.0003 12.4999C11.0002 12.6325 10.9474 12.7597 10.8535 12.8535C10.7596 12.9473 10.6323 12.9999 10.4996 12.9998C10.367 12.9997 10.2398 12.9469 10.146 12.853L8.146 10.853C8.05308 10.7602 8.0006 10.6344 8 10.503V10.497C8.0006 10.3666 8.0523 10.2417 8.144 10.149Z'
        fill='#235DB0'
      />
      <path
        d='M0 1.75V12.25C0 12.4489 0.0790178 12.6397 0.21967 12.7803C0.360322 12.921 0.551088 13 0.75 13H3V11.5H1.5V2.5H3V1H0.75C0.551088 1 0.360322 1.07902 0.21967 1.21967C0.0790178 1.36032 0 1.55109 0 1.75Z'
        fill='#4E3700'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 1.75C2 0.784 2.784 0 3.75 0L10 0.5C10.966 0.5 12 0.784 12 1.75V5.207C10.8716 4.88666 9.67041 4.93544 8.57173 5.34623C7.47306 5.75702 6.53449 6.50829 5.89308 7.49034C5.25167 8.47239 4.94102 9.63378 5.00649 10.8049C5.07196 11.976 5.51013 13.0956 6.257 14H3.75C3.28587 14 2.84075 13.8156 2.51256 13.4874C2.18437 13.1592 2 12.7141 2 12.25V1.75ZM5.70711 7.70711C5.51957 7.89464 5.26522 8 5 8C4.73478 8 4.48043 7.89464 4.29289 7.70711C4.10536 7.51957 4 7.26522 4 7C4 6.73478 4.10536 6.48043 4.29289 6.29289C4.48043 6.10536 4.73478 6 5 6C5.26522 6 5.51957 6.10536 5.70711 6.29289C5.89464 6.48043 6 6.73478 6 7C6 7.26522 5.89464 7.51957 5.70711 7.70711Z'
        fill='#7A5700'
      />
      <path
        d='M5 8C5.26522 8 5.51957 7.89464 5.70711 7.70711C5.89464 7.51957 6 7.26522 6 7C6 6.73478 5.89464 6.48043 5.70711 6.29289C5.51957 6.10536 5.26522 6 5 6C4.73478 6 4.48043 6.10536 4.29289 6.29289C4.10536 6.48043 4 6.73478 4 7C4 7.26522 4.10536 7.51957 4.29289 7.70711C4.48043 7.89464 4.73478 8 5 8Z'
        fill='#7A5700'
      />
      <path
        d='M5 8C5.26522 8 5.51957 7.89464 5.70711 7.70711C5.89464 7.51957 6 7.26522 6 7C6 6.73478 5.89464 6.48043 5.70711 6.29289C5.51957 6.10536 5.26522 6 5 6C4.73478 6 4.48043 6.10536 4.29289 6.29289C4.10536 6.48043 4 6.73478 4 7C4 7.26522 4.10536 7.51957 4.29289 7.70711C4.48043 7.89464 4.73478 8 5 8Z'
        fill='#FFB800'
      />
    </>
  ),
});
