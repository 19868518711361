import React, {useState} from 'react';

import {
  Box,
  Circle,
  Flex,
  FormControl,
  Input,
  Text,
  UseDisclosureProps,
  VStack,
} from '@chakra-ui/react';

import {ChevronRightIcon} from '@chakra-ui/icons';
import {AnimatePresence, motion} from 'framer-motion';
import {VariableSizeList as List} from 'react-window';

import {useEventsUser} from '../hooks/useEventsUser';
import {CalendarFilter} from '../slices/calendarFilterSlice';
import '../styles/windowing.css';
import {TourFilter} from '../types/tours';
import {FiltersUsers} from '../types/users';
import {sortAlpha} from '../utils/sortAlpha';
import {getAttribute} from '../utils/user-access';
import {AnimatedComponent} from './AnimatedComponent';
import {UserStack} from './UserStack';

interface FilterProps {
  data: any;
  wTab?: number;
  modal?: UseDisclosureProps;
  onChangeUser: (user: FiltersUsers, parent: string[]) => void;
  userSelected: CalendarFilter[] | TourFilter[];
  type: string;
}

export function UserSearch(props: FilterProps) {
  const {data, modal, onChangeUser, userSelected} = props;
  const [parentStack, setParentStack] = useState<string[]>(
    userSelected[0] ? userSelected[0].parents! : [],
  );
  const [searchString, setSearchString] = useState<string>('');

  const onDrillDown = user => {
    setParentStack([...parentStack, user.id]);
  };

  const onDrillUp = id => {
    const drillIndex = parentStack.findIndex(parentId => parentId === id);
    const slice = parentStack.slice(0, drillIndex);
    setParentStack(slice);
  };

  let filteredData;
  if (!parentStack.length) {
    if (data.filter(user => !user.managers.length).length != 0) {
      filteredData = data.filter(user => !user.managers.length);
    } else if (
      data.filter(user => user.email === getAttribute<string[]>('email', []))
    ) {
      filteredData = data.filter(
        user => user.email === getAttribute<string[]>('email', []),
      );
    } else {
      filteredData = data;
    }
  } else {
    const currentParent = parentStack[parentStack.length - 1];
    filteredData = data.filter(user => user.managers.includes(currentParent));
  }

  const events = useEventsUser(filteredData, props.type);

  const mapFilteredData =
    props.type !== 'calendar'
      ? filteredData
      : filteredData.map(u => ({
          ...u,
          number: events.data?.filter(event => event.idUser === u.id).length,
        }));

  const [[page, direction], setPage] = useState([0, 0]);

  const handleFilter = user => {
    if (modal) {
      modal?.onClose!();
    }
    onChangeUser(user, parentStack);
  };

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  const variants = {
    enter: (directionEnter: number) => {
      return {
        x: directionEnter > 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (directionExit: number) => {
      return {
        zIndex: 0,
        x: directionExit < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
  };

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
  };

  const Row = options => {
    const {data, index} = options;

    const user = data[index];

    const handleDrillDown = () => {
      if (onDrillDown) {
        setPage([index + 1, 1]);
        onDrillDown(user);
      }
    };
    const hasChild = props.data.some(u => u.managers.includes(user.id));

    const handleColor = idUser => {
      if (!userSelected[0]) {
        return '#235db0';
      }
      if (idUser === userSelected[0]['user']!['id']) {
        return '#8ac1bb';
      }
      return '#235db0';
    };

    return (
      <Flex
        key={user.id}
        id={user.id}
        mb={2}
        borderRadius={15}
        transition='all 0.3s ease-in-out'
        _hover={{bg: '#8ac1bb'}}
        py={2}
        px={2}
        alignItems='center'
        w='100%'
        bg={handleColor(user.id)}
        color='white'
        justifyContent='space-between'>
        {user.number >= 0 && (
          <Circle size='26px' bg='gray.50' color='#395b81'>
            <Text fontSize={12} fontWeight='bolder'>
              {user.number}
            </Text>
          </Circle>
        )}
        <Flex
          id={user.id}
          onClick={() => handleFilter(user)}
          ml={2}
          flexDirection='column'
          minH={8}
          minW='130px'
          w='100%'
          textAlign='left'
          justifyContent='flex-start'
          alignItems='flex-start'
          cursor='pointer'>
          <Text color='white' fontSize={14}>
            {user.name || ''}
          </Text>
          <Text fontSize={10}>{user.rol || ''}</Text>
        </Flex>
        <Circle
          size='28px'
          hidden={!hasChild}
          _hover={{bg: 'gray.50'}}
          cursor='pointer'
          background='white'
          onClick={handleDrillDown}>
          <ChevronRightIcon w={5} h={5} color='gray.700' />
        </Circle>
      </Flex>
    );
  };

  const rowSizes = mapFilteredData
    .filter(
      user =>
        !searchString.length ||
        user.name.toLowerCase().includes(searchString.toLowerCase()),
    )
    .map(() => 1);

  const getItemSize = index => rowSizes[index];

  return (
    <Box w='100%' bg='#f1f1f1' borderRadius={20} px={4} py={3}>
      <FormControl>
        <Input
          size='sm'
          type='text'
          placeholder='Buscar usuario'
          bg='white'
          borderRadius={10}
          value={searchString}
          onChange={e => setSearchString(e.target.value)}
        />
      </FormControl>
      <Box
        mt={2}
        px={1}
        overflowX='auto'
        width='100%'
        minH={8}
        textAlign='left'>
        <UserStack
          parentStack={parentStack}
          onDrillUp={onDrillUp}
          data={data}
        />
      </Box>
      <AnimatedComponent>
        <AnimatePresence initial={false} custom={direction}>
          <Box overflow='hidden' h='100%' borderRadius={20} mt={2} w='100%'>
            <VStack w='100%' bg='white' p={3} h={64} overflow='hidden'>
              <motion.section
                key={page}
                custom={direction}
                variants={variants}
                initial='enter'
                animate='center'
                exit='exit'
                style={{
                  overflow: 'hidden',
                }}
                transition={{
                  x: {
                    type: 'spring',
                    stiffness: 300,
                    damping: 30,
                    duration: 2,
                  },
                  opacity: {
                    duration: 0.2,
                  },
                }}
                drag='x'
                dragConstraints={{left: 0, right: 0}}
                dragElastic={1}
                onDragEnd={(e, {offset, velocity}) => {
                  const swipe = swipePower(offset.x, velocity.x);
                  if (swipe < -swipeConfidenceThreshold) {
                    paginate(1);
                  } else if (swipe > swipeConfidenceThreshold) {
                    paginate(-1);
                  }
                }}>
                <List
                  className='window-list list-users'
                  style={{
                    overflow: 'hidden',
                  }}
                  height={250}
                  width={215}
                  itemData={sortAlpha(mapFilteredData, 'name').filter(
                    user =>
                      !searchString.length ||
                      user.name
                        .toLowerCase()
                        .includes(searchString.toLowerCase()),
                  )}
                  itemCount={
                    mapFilteredData.filter(
                      user =>
                        !searchString.length ||
                        user.name
                          .toLowerCase()
                          .includes(searchString.toLowerCase()),
                    ).length
                  }
                  itemSize={getItemSize}>
                  {Row}
                </List>
              </motion.section>
            </VStack>
          </Box>
        </AnimatePresence>
      </AnimatedComponent>
    </Box>
  );
}
