import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const GoogleIcon = createIcon({
  displayName: 'GoogleIcon',
  viewBox: '0 0 53 53',
  path: (
    <>
      <path
        d='M52.2257 26.7534C52.2257 24.6014 52.0511 23.0308 51.6737 21.4028H27.1004V31.1148H41.5244C41.2337 33.5294 39.6631 37.1641 36.1737 39.6068L36.1244 39.9321L43.8937 45.9508L44.4324 46.0041C49.3764 41.4388 52.2257 34.7214 52.2257 26.7534Z'
        fill='#4285F5'
      />
      <path
        d='M27.1006 52.3431C34.1673 52.3431 40.0993 50.0165 44.4326 46.0045L36.174 39.6058C33.9633 41.1471 30.9966 42.2231 27.1006 42.2231C20.1793 42.2231 14.306 37.6578 12.2113 31.3471L11.9046 31.3738L3.82598 37.6258L3.72064 37.9191C8.02464 46.4685 16.8646 52.3431 27.1006 52.3431Z'
        fill='#33A854'
      />
      <path
        d='M12.212 31.3479C11.66 29.7199 11.34 27.9745 11.34 26.1719C11.34 24.3692 11.66 22.6239 12.1827 20.9959L12.168 20.6492L3.98799 14.2959L3.72132 14.4239C1.94665 17.9719 0.929321 21.9545 0.929321 26.1719C0.929321 30.3879 1.94665 34.3719 3.72132 37.9199L12.212 31.3479Z'
        fill='#FABD05'
      />
      <path
        d='M27.1006 10.12C32.0153 10.12 35.33 12.2426 37.2206 14.0173L44.606 6.80531C40.07 2.58797 34.1673 -2.67029e-05 27.1006 -2.67029e-05C16.8646 -2.67029e-05 8.02464 5.87464 3.72064 14.424L12.1833 20.996C14.306 14.6853 20.1793 10.12 27.1006 10.12Z'
        fill='#EB4236'
      />
    </>
  ),
});
