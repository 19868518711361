import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';
import {Objective} from '../types/objectives';

export interface ObjectiveState {
  data: Objective[];
  error: string | null;
  isLoading: boolean;
  startLoading: number;
  endLoading: number;
}

const initialState = {
  data: [],
  error: null,
  isLoading: false,
  startLoading: 0,
  endLoading: 0,
} as ObjectiveState;
const objectiveSlice = createSlice({
  name: 'objective',
  initialState,
  reducers: {
    getObjectives(state, action: PayloadAction<string>) {
      state.startLoading++;
    },
    getObjectivesSuccess(state, action) {
      const newIds = action.payload.map(newElements => newElements.id);
      const newData = [
        ...action.payload,
        ...state.data.filter(current => !newIds.includes(current.id)),
      ];
      newData.sort((a, b) => a.id - b.id);
      state.data = newData;
      state.endLoading++;
    },
    getObjectivesError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.endLoading++;
    },
    addObjective(state, action: PayloadAction<Objective>) {
      const x = '';
    },
    addObjectiveSuccess(state, action: PayloadAction<Objective>) {
      //state.data.push(action.payload);
    },
    addObjectiveError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    updateObjective(state, actio: PayloadAction<Objective>) {
      const x = '';
    },
    updateObjectiveSuccess(state, action: PayloadAction<Objective>) {
      const index = state.data.findIndex(
        objective => objective.id === action.payload.id,
      );
      state.data[index] = action.payload;
    },
    updateObjectiveError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    disableObjective(state, action: PayloadAction<Objective>) {
      state.isLoading = true;
    },
    disableObjectiveError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    disableObjectiveSuccess(state, action: PayloadAction<Objective>) {
      const index = state.data.findIndex(
        objective => objective.id === action.payload.id,
      );
      state.data[index].isEnabled = false;
      state.isLoading = false;
    },
  },
});

export const getObjectiveSelector = (state: RootState) => {
  if (state.objective.startLoading === state.objective.endLoading) {
    return state.objective.data;
  }
  return [];
};
export const isObjectiveLoading = state => state.objective.isLoading;
export const getErrorSelector = state => state.objective.error;

export default objectiveSlice.reducer;
export const {
  getObjectives,
  getObjectivesSuccess,
  getObjectivesError,
  addObjective,
  addObjectiveSuccess,
  addObjectiveError,
  updateObjective,
  updateObjectiveSuccess,
  updateObjectiveError,
  disableObjective,
  disableObjectiveError,
  disableObjectiveSuccess,
} = objectiveSlice.actions;
