import {getColor} from '@chakra-ui/theme-tools';

const parts = ['field', 'addon'];

const baseStyle = {
  field: {
    borderRadius: '9px',
    borderWidth: 0,
    borderStyle: 'none',
    height: '39px',
    width: '100%',
    minWidth: 0,
    outline: 0,
    position: 'relative',
    appearance: 'none',
    transition: 'all 0.2s',
    color: 'semiBlack',
  },
  addon: {
    height: '100%',
  },
};

const size = {
  lg: {
    fontSize: 'lg',
    px: 4,
    h: 12,
    borderRadius: '9px',
  },

  md: {
    fontSize: 'md',
    px: 4,
    h: 10,
    borderRadius: '9px',
  },

  sm: {
    fontSize: 'sm',
    px: 3,
    h: 8,
    borderRadius: '9px',
  },

  xs: {
    fontSize: 'xs',
    px: 2,
    h: 6,
    borderRadius: '9px',
  },
};

const sizes = {
  lg: {
    field: size.lg,
    addon: {...size.lg, height: '100%'},
  },
  md: {
    field: size.md,
    addon: {
      ...size.md,
      height: '100%',
      px: '16px',
    },
  },
  sm: {
    field: size.sm,
    addon: {...size.sm, height: '100%'},
  },
  xs: {
    field: size.xs,
    addon: {...size.xs, height: '100%'},
  },
};

function getDefaults(props: Record<string, any>) {
  const {focusBorderColor: fc, errorBorderColor: ec} = props;
  return {
    focusBorderColor: fc || '#5eb5ab',
    errorBorderColor: ec || '#f75266',
  };
}

function variantOutline(props: Record<string, any>) {
  const {theme} = props;
  const {focusBorderColor: fc, errorBorderColor: ec} = getDefaults(props);

  return {
    field: {
      border: '1px solid',
      borderColor: 'inherit',
      bg: 'white',
      _hover: {
        borderColor: 'gray.300',
      },
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      _disabled: {
        color: 'white',
        backgroundColor: 'gray.100',
        cursor: 'not-allowed',
      },
      _invalid: {
        borderColor: getColor(theme, ec),
        boxShadow: `0 0 0 1px ${getColor(theme, ec)}`,
      },
      _focus: {
        zIndex: 1,
        borderColor: getColor(theme, fc),
        boxShadow: `0 0 0 1px ${getColor(theme, fc)}`,
      },
    },
    addon: {
      border: '1px solid',
      borderColor: 'inherit',
      bg: 'gray.100',
    },
  };
}

function variantFilled(props: Record<string, any>) {
  const {theme} = props;
  const {focusBorderColor: fc, errorBorderColor: ec} = getDefaults(props);

  return {
    field: {
      border: '2px solid',
      borderColor: 'transparent',
      bg: 'white',
      _hover: {
        bg: 'white',
        borderColor: 'gray.200',
      },
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      _disabled: {
        backgroundColor: 'gray.100',
        cursor: 'not-allowed',
        _placeholder: {
          color: 'white',
          opacity: 1,
        },
      },
      _invalid: {
        borderColor: getColor(theme, ec),
      },
      _focus: {
        bg: 'white',
        borderColor: getColor(theme, fc),
      },
    },
    addon: {
      height: '100%',
      border: '2px solid',
      borderColor: 'transparent',
      bg: 'gray.100',
    },
  };
}

function variantFlushed(props: Record<string, any>) {
  const {theme} = props;
  const {focusBorderColor: fc, errorBorderColor: ec} = getDefaults(props);

  return {
    field: {
      borderBottom: '1px solid',
      borderColor: 'inherit',
      borderRadius: 0,
      px: 0,
      bg: 'transparent',
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      _invalid: {
        borderColor: getColor(theme, ec),
        boxShadow: `0px 1px 0px 0px ${getColor(theme, ec)}`,
      },
      _focus: {
        borderColor: getColor(theme, fc),
        boxShadow: `0px 1px 0px 0px ${getColor(theme, fc)}`,
      },
    },
    addon: {
      borderBottom: '2px solid',
      borderColor: 'inherit',
      borderRadius: 0,
      px: 0,
      bg: 'transparent',
    },
  };
}

const variantUnstyled = {
  field: {
    bg: 'transparent',
    px: 0,
    height: 'auto',
  },
  addon: {
    bg: 'transparent',
    px: 0,
    height: 'auto',
  },
};

const variants = {
  outline: variantOutline,
  filled: variantFilled,
  flushed: variantFlushed,
  unstyled: variantUnstyled,
};

const defaultProps = {
  size: 'md',
  variant: 'filled',
};

const input = {
  parts,
  baseStyle,
  sizes,
  variants,
  defaultProps,
};

export default input;
