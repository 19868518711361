import {Project} from '../types/projects';
import {instance} from './index';
import {getOrganizations} from './organizationsService';

export interface IProjectResponse {
  timestamp: string;
  status: string;
  data: Project[];
}

export async function getAllProjects() {
  const organizations = await getOrganizations();
  const projectPromises = organizations.map(org => {
    return instance()
      .get<IProjectResponse>(
        `directory/organizations/${org.idOrganization}/projects`,
      )
      .then(response => response.data.data);
  });
  const orgProjects = await Promise.all(projectPromises);
  return orgProjects.flat();
}

export function createProject(data: Project) {
  return instance().post(
    `directory/organizations/${data.organization}/projects`,
    data,
  );
}

export function updateProject(data: Project) {
  return instance().put(
    `directory/organizations/${data.organization}/projects/${data.idProject}`,
    data,
  );
}
