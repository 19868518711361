import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const CustomFieldsIcon = createIcon({
  displayName: 'CustomFieldsIcon',
  viewBox: '0 0 22 22',
  path: (
    <>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.5 19.494C15.709 19.482 16.72 19.441 17.559 19.299C18.587 19.124 19.431 18.787 20.109 18.109C20.857 17.36 21.189 16.411 21.347 15.239C21.5 14.1 21.5 12.644 21.5 10.806V10.694C21.5 8.856 21.5 7.401 21.347 6.261C21.189 5.089 20.857 4.14 20.109 3.392C19.431 2.713 18.587 2.376 17.559 2.202C16.72 2.059 15.71 2.018 14.5 2.006V3.506C15.686 3.518 16.59 3.558 17.308 3.68C18.15 3.823 18.663 4.067 19.048 4.452C19.471 4.875 19.725 5.455 19.86 6.461C19.998 7.489 20 8.843 20 10.75C20 12.657 19.998 14.011 19.86 15.04C19.725 16.045 19.471 16.625 19.048 17.048C18.663 17.433 18.15 17.677 17.308 17.82C16.589 17.942 15.686 17.982 14.5 17.994V19.494Z'
        fill='#73B3AB'
      />
      <path
        d='M14.2803 0.21967C14.1397 0.0790177 13.9489 0 13.75 0C13.5511 0 13.3603 0.0790177 13.2197 0.21967C13.079 0.360322 13 0.551088 13 0.75V20.75C13 20.9489 13.079 21.1397 13.2197 21.2803C13.3603 21.421 13.5511 21.5 13.75 21.5C13.9489 21.5 14.1397 21.421 14.2803 21.2803C14.421 21.1397 14.5 20.9489 14.5 20.75V19.494V17.994V3.506V2.006V0.75C14.5 0.551088 14.421 0.360322 14.2803 0.21967Z'
        fill='#73B3AB'
      />
      <path
        d='M14.5 3.506V17.994C15.686 17.982 16.589 17.942 17.308 17.82C18.15 17.677 18.663 17.433 19.048 17.048C19.471 16.625 19.725 16.045 19.86 15.04C19.998 14.011 20 12.657 20 10.75C20 8.843 19.998 7.489 19.86 6.461C19.725 5.455 19.471 4.875 19.048 4.452C18.663 4.067 18.15 3.823 17.308 3.68C16.59 3.558 15.686 3.518 14.5 3.506Z'
        fill='#73B3AB'
      />
      <path
        d='M14.5 3.50598V17.994C15.686 17.982 16.589 17.942 17.308 17.82C18.15 17.677 18.663 17.433 19.048 17.048C19.471 16.625 19.725 16.045 19.86 15.04C19.998 14.011 20 12.657 20 10.75C20 8.84298 19.998 7.48898 19.86 6.46098C19.725 5.45498 19.471 4.87498 19.048 4.45198C18.663 4.06698 18.15 3.82298 17.308 3.67998C16.59 3.55798 15.686 3.51798 14.5 3.50598Z'
        fill='white'
      />
      <path
        d='M8.694 2H10.75C10.9489 2 11.1397 2.07902 11.2803 2.21967C11.421 2.36032 11.5 2.55109 11.5 2.75C11.5 2.94891 11.421 3.13968 11.2803 3.28033C11.1397 3.42098 10.9489 3.5 10.75 3.5H8.75C6.843 3.5 5.489 3.502 4.46 3.64C3.455 3.775 2.875 4.029 2.452 4.452C2.029 4.875 1.775 5.455 1.64 6.461C1.502 7.489 1.5 8.843 1.5 10.75C1.5 12.657 1.502 14.011 1.64 15.04C1.775 16.045 2.029 16.625 2.452 17.048C2.875 17.471 3.455 17.725 4.461 17.86C5.489 17.998 6.843 18 8.75 18H10.75C10.9489 18 11.1397 18.079 11.2803 18.2197C11.421 18.3603 11.5 18.5511 11.5 18.75C11.5 18.9489 11.421 19.1397 11.2803 19.2803C11.1397 19.421 10.9489 19.5 10.75 19.5H8.694C6.856 19.5 5.4 19.5 4.261 19.347C3.089 19.189 2.14 18.857 1.391 18.109C0.643 17.36 0.311 16.411 0.153 15.239C0 14.099 0 12.644 0 10.806V10.694C0 8.856 0 7.4 0.153 6.261C0.311 5.089 0.643 4.14 1.391 3.391C2.14 2.643 3.089 2.311 4.261 2.153C5.401 2 6.856 2 8.694 2Z'
        fill='#73B3AB'
      />
      <path
        d='M10.75 3.5H8.75C6.843 3.5 5.489 3.502 4.46 3.64C3.455 3.775 2.875 4.029 2.452 4.452C2.029 4.875 1.775 5.455 1.64 6.461C1.502 7.489 1.5 8.843 1.5 10.75C1.5 12.657 1.502 14.011 1.64 15.04C1.775 16.045 2.029 16.625 2.452 17.048C2.875 17.471 3.455 17.725 4.461 17.86C5.489 17.998 6.843 18 8.75 18H10.75H14.5V3.5H10.75Z'
        fill='#73B3AB'
      />
      <path
        d='M10.75 3.5H8.75C6.843 3.5 5.489 3.502 4.46 3.64C3.455 3.775 2.875 4.029 2.452 4.452C2.029 4.875 1.775 5.455 1.64 6.461C1.502 7.489 1.5 8.843 1.5 10.75C1.5 12.657 1.502 14.011 1.64 15.04C1.775 16.045 2.029 16.625 2.452 17.048C2.875 17.471 3.455 17.725 4.461 17.86C5.489 17.998 6.843 18 8.75 18H10.75H14.5V3.5H10.75Z'
        fill='white'
      />
      <path
        d='M14.2803 0.21967C14.1397 0.0790177 13.9489 0 13.75 0C13.5511 0 13.3603 0.0790177 13.2197 0.21967C13.079 0.360322 13 0.551088 13 0.75V20.75C13 20.9489 13.079 21.1397 13.2197 21.2803C13.3603 21.421 13.5511 21.5 13.75 21.5C13.9489 21.5 14.1397 21.421 14.2803 21.2803C14.421 21.1397 14.5 20.9489 14.5 20.75V19.494V17.994V3.506V2.006V0.75C14.5 0.551088 14.421 0.360322 14.2803 0.21967Z'
        fill='#73B3AB'
      />
      <path
        d='M5.568 6.537C5.868 6.5 6.234 6.5 6.634 6.5H8.866C9.266 6.5 9.632 6.5 9.932 6.537C10.261 6.578 10.612 6.674 10.912 6.942C10.964 6.988 11.012 7.036 11.058 7.088C11.326 7.388 11.422 7.739 11.463 8.068C11.5 8.368 11.5 8.734 11.5 9.134V9.175C11.5 9.37391 11.421 9.56468 11.2803 9.70533C11.1397 9.84598 10.9489 9.925 10.75 9.925C10.5511 9.925 10.3603 9.84598 10.2197 9.70533C10.079 9.56468 10 9.37391 10 9.175C10 8.72 9.999 8.449 9.974 8.253C9.95 8.058 9.746 8.026 9.746 8.026C9.551 8.001 9.28 8 8.825 8H8.5V13.5H9.75C9.94891 13.5 10.1397 13.579 10.2803 13.7197C10.421 13.8603 10.5 14.0511 10.5 14.25C10.5 14.4489 10.421 14.6397 10.2803 14.7803C10.1397 14.921 9.94891 15 9.75 15H5.75C5.55109 15 5.36032 14.921 5.21967 14.7803C5.07902 14.6397 5 14.4489 5 14.25C5 14.0511 5.07902 13.8603 5.21967 13.7197C5.36032 13.579 5.55109 13.5 5.75 13.5H7V8H6.675C6.22 8 5.949 8.001 5.753 8.026C5.753 8.026 5.55 8.058 5.526 8.253C5.501 8.449 5.5 8.72 5.5 9.175C5.5 9.37391 5.42098 9.56468 5.28033 9.70533C5.13968 9.84598 4.94891 9.925 4.75 9.925C4.55109 9.925 4.36032 9.84598 4.21967 9.70533C4.07902 9.56468 4 9.37391 4 9.175V9.134C4 8.734 4 8.368 4.037 8.068C4.078 7.739 4.174 7.388 4.442 7.088C4.488 7.036 4.536 6.988 4.588 6.942C4.888 6.674 5.239 6.578 5.568 6.537Z'
        fill='#333333'
      />
    </>
  ),
});
