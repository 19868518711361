import React, {useRef} from 'react';

import {
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import {TiWarning} from 'react-icons/ti';

export function ModalDetails({details}) {
  const modalDetail = useDisclosure();
  const btnRef = useRef(null);

  if (
    details.taskStatusCode == 'completed' ||
    details.taskStatusCode == 'queued'
  ) {
    return <></>;
  }

  return (
    <Flex justifyContent='center'>
      <IconButton
        size='md'
        colorScheme='yellow'
        ref={btnRef}
        variant='outline'
        icon={<TiWarning />}
        onClick={modalDetail.onOpen}
        aria-label='details'
      />
      <Modal
        isCentered
        onClose={modalDetail.onClose}
        finalFocusRef={btnRef}
        isOpen={modalDetail.isOpen}
        motionPreset='slideInBottom'
        scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize='2xl'>Detalle de fallo</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontSize='sm'>
            {details.errorDescription ||
              (details.detailsJson &&
                JSON.parse(details.detailsJson).map(error => (
                  <div key={error.User}>
                    <p>
                      <strong>Usuario:</strong> {`${error.User}`}
                    </p>
                    <p>
                      <strong>Error:</strong> {`${error.Message}`}
                    </p>
                  </div>
                )))}
          </ModalBody>
          <ModalFooter>
            <Button size='sm' colorScheme='teal' onClick={modalDetail.onClose}>
              Aceptar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
