import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const SupportUsersIcon = createIcon({
  displayName: 'SupportUsersIcon',
  viewBox: '0 0 33 36',
  path: (
    <>
      <path
        d='M22 11.946C22 13.523 21.4205 15.0354 20.3891 16.1505C19.3576 17.2656 17.9587 17.8921 16.5 17.8921C15.0413 17.8921 13.6424 17.2656 12.6109 16.1505C11.5795 15.0354 11 13.523 11 11.946C11 10.3691 11.5795 8.85666 12.6109 7.74156C13.6424 6.62646 15.0413 6 16.5 6C17.9587 6 19.3576 6.62646 20.3891 7.74156C21.4205 8.85666 22 10.3691 22 11.946V11.946Z'
        fill='#73B3AB'
      />
      <path
        d='M31.1666 15.9102C31.1666 16.9615 30.7803 17.9698 30.0926 18.7132C29.405 19.4566 28.4724 19.8742 27.4999 19.8742C26.5275 19.8742 25.5948 19.4566 24.9072 18.7132C24.2196 17.9698 23.8333 16.9615 23.8333 15.9102C23.8333 14.8589 24.2196 13.8506 24.9072 13.1072C25.5948 12.3638 26.5275 11.9462 27.4999 11.9462C28.4724 11.9462 29.405 12.3638 30.0926 13.1072C30.7803 13.8506 31.1666 14.8589 31.1666 15.9102V15.9102Z'
        fill='#FFB800'
      />
      <path
        d='M23.8334 29.7843C23.8334 27.6816 23.0608 25.6651 21.6855 24.1783C20.3103 22.6915 18.445 21.8562 16.5001 21.8562C14.5552 21.8562 12.6899 22.6915 11.3146 24.1783C9.93936 25.6651 9.16675 27.6816 9.16675 29.7843V35.7303H23.8334V29.7843Z'
        fill='#73B3AB'
      />
      <path
        d='M9.16659 15.9102C9.16659 16.9615 8.78028 17.9698 8.09265 18.7132C7.40501 19.4566 6.47238 19.8742 5.49992 19.8742C4.52746 19.8742 3.59483 19.4566 2.90719 18.7132C2.21956 17.9698 1.83325 16.9615 1.83325 15.9102C1.83325 14.8589 2.21956 13.8506 2.90719 13.1072C3.59483 12.3638 4.52746 11.9462 5.49992 11.9462C6.47238 11.9462 7.40501 12.3638 8.09265 13.1072C8.78028 13.8506 9.16659 14.8589 9.16659 15.9102V15.9102Z'
        fill='#F6704D'
      />
      <path
        d='M27.5 35.7302V29.7842C27.5027 27.769 27.0294 25.7865 26.125 24.0244C26.9378 23.7996 27.7874 23.7784 28.6089 23.9624C29.4305 24.1465 30.2022 24.531 30.8654 25.0866C31.5286 25.6422 32.0656 26.3542 32.4356 27.1683C32.8055 27.9824 32.9986 28.8771 33 29.7842V35.7302H27.5Z'
        fill='#FFB800'
      />
      <path
        d='M6.875 24.0244C5.97071 25.7865 5.49743 27.769 5.5 29.7841V35.7302H4.87154e-07V29.7841C-0.000352858 28.8764 0.191516 27.9807 0.560903 27.1656C0.93029 26.3505 1.46739 25.6376 2.13104 25.0816C2.79469 24.5256 3.56727 24.1413 4.38957 23.958C5.21187 23.7748 6.06207 23.7975 6.875 24.0244V24.0244Z'
        fill='#F6704D'
      />
      <path
        d='M30.265 7.693L30.258 7.707C30.2863 7.476 30.3146 7.238 30.3146 7C30.3146 6.762 30.2934 6.538 30.265 6.307L30.2721 6.321L32 4.977L30.2792 2.023L28.2468 2.835L28.2539 2.842C27.8857 2.562 27.482 2.324 27.043 2.142H27.0501L26.7243 0H23.2757L22.9641 2.149H22.9712C22.5321 2.331 22.1285 2.569 21.7602 2.849L21.7673 2.842L19.7279 2.023L18 4.977L19.7279 6.321L19.735 6.307C19.7066 6.538 19.6854 6.762 19.6854 7C19.6854 7.238 19.7066 7.476 19.742 7.707L19.735 7.693L18.2479 8.848L18.0142 9.03L19.735 11.97L21.7744 11.165L21.7602 11.137C22.1356 11.424 22.5392 11.662 22.9853 11.844H22.9641L23.2828 14H26.7172C26.7172 14 26.7385 13.874 26.7597 13.706L27.0288 11.851H27.0217C27.4608 11.669 27.8715 11.431 28.2468 11.144L28.2327 11.172L30.2721 11.977L31.9929 9.037C31.9929 9.037 31.8938 8.953 31.7592 8.855L30.265 7.693ZM24.9965 9.45C23.6297 9.45 22.518 8.351 22.518 7C22.518 5.649 23.6297 4.55 24.9965 4.55C26.3632 4.55 27.475 5.649 27.475 7C27.475 8.351 26.3632 9.45 24.9965 9.45Z'
        fill='white'
      />
    </>
  ),
});
