import * as React from 'react';
import {ReactNode} from 'react';

import {Button, Menu, MenuButton, MenuList} from '@chakra-ui/react';

interface DropdownProps {
  actionButton: string;
  children: ReactNode;
}

export const Dropdown = function (props: DropdownProps) {
  return (
    <Menu placement='top'>
      <MenuButton as={Button} variant='solid' colorScheme='gray' size='sm'>
        {props.actionButton}
      </MenuButton>
      <MenuList bg='gray.50'>{props.children}</MenuList>
    </Menu>
  );
};

export default Dropdown;
