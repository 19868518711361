import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const DocumentosIcon = createIcon({
  displayName: 'DocumentosIcon',
  viewBox: '0 0 29 40',
  path: (
    <>
      <path
        d='M19.25 3H6.25C5.38805 3 4.5614 3.34768 3.9519 3.96655C3.34241 4.58542 3 5.42479 3 6.3V32.7C3 33.5752 3.34241 34.4146 3.9519 35.0335C4.5614 35.6523 5.38805 36 6.25 36H25.75C26.612 36 27.4386 35.6523 28.0481 35.0335C28.6576 34.4146 29 33.5752 29 32.7V12.9L19.25 3Z'
        fill='#FFB800'
      />
      <path d='M19.25 3.5V12.9H28.5' fill='#FFB800' />
      <path
        d='M19.25 3.5V12.9H28.5'
        stroke='#D29700'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.76 12.39H28.62L19.76 3.53003V12.39Z'
        fill='#FFB800'
      />
      <path d='M22.5 21.15H9.5H22.5Z' fill='#FFB800' />
      <path
        d='M22.5 21.15H9.5'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M22.5 27.75H9.5H22.5Z' fill='#FFB800' />
      <path
        d='M22.5 27.75H9.5'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M12.75 14.55H9.5H12.75Z' fill='#FFB800' />
      <path
        d='M12.75 14.55H9.5'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.25 7H3.25C2.38805 7 1.5614 7.34768 0.951903 7.96655C0.34241 8.58542 0 9.42479 0 10.3V36.7C0 37.5752 0.34241 38.4146 0.951903 39.0335C1.5614 39.6523 2.38805 40 3.25 40H22.75C23.612 40 24.4386 39.6523 25.0481 39.0335C25.6576 38.4146 26 37.5752 26 36.7V16.9L16.25 7Z'
        fill='white'
      />
      <path d='M16.25 7.5V16.9H25.5' fill='white' />
      <path
        d='M16.25 7.5V16.9H25.5'
        stroke='#F2F1F2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.76 16.39H25.62L16.76 7.53003V16.39Z'
        fill='white'
      />
      <path d='M19.5 25.15H6.5H19.5Z' fill='white' />
      <path
        d='M19.5 25.15H6.5'
        stroke='#F2F1F2'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M19.5 31.75H6.5H19.5Z' fill='white' />
      <path
        d='M19.5 31.75H6.5'
        stroke='#F2F1F2'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M9.75 18.55H6.5H9.75Z' fill='white' />
      <path
        d='M9.75 18.55H6.5'
        stroke='#F2F1F2'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.5 3C11.5 2.5 11.5 2.5 11 2C10.5 1.5 9.66317 1.5 9.00013 1.5C8.33709 1.5 7.70121 1.76339 7.23236 2.23223C6.76352 2.70107 6.50013 3.33696 6.50013 4V14.5C6.50013 15.05 6.95013 15.5 7.50013 15.5C8.05013 15.5 8.50013 15.05 8.50013 14.5C8.50013 14.5 8.50013 6.82813 8.50013 6.16407C8.50013 5.5 10.0001 5.5 10.0001 6.16407C10.0001 6.82813 10.0001 14.5 10.0001 14.5C10.0001 15.163 9.73674 15.7989 9.2679 16.2678C8.79906 16.7366 8.16317 17 7.50013 17C6.83709 17 6.20121 16.7366 5.73236 16.2678C5.26352 15.7989 5.00013 15.163 5.00013 14.5V4C5.00013 1.79 6.79013 0 9.00013 0C11.2101 0 13 0.79 13 3H11.5Z'
        fill='#3885FE'
      />
    </>
  ),
});
