import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const OrganizacionesIcon = createIcon({
  displayName: 'OrganizacionesIcon',
  viewBox: '0 0 34 34',
  path: (
    <>
      <path
        d='M23.5367 9.86062C27.2967 13.6206 27.2967 19.7166 23.5367 23.4753C19.7767 27.2353 13.6807 27.2353 9.92205 23.4753C6.16205 19.7166 6.16205 13.6206 9.92205 9.86062C13.6807 6.10062 19.7767 6.10062 23.5367 9.86062Z'
        fill='#FCD673'
      />
      <path
        d='M9.92086 9.85998C12.6435 7.13731 12.6435 2.72264 9.92086 -2.28882e-05L0.0621948 9.85998C2.78353 12.5826 7.19819 12.5826 9.92086 9.85998Z'
        fill='#5CC9BA'
      />
      <path
        d='M23.5353 23.4746C20.8127 26.1973 20.8127 30.6106 23.5353 33.3333L33.394 23.4746C30.6727 20.7519 26.258 20.7519 23.5353 23.4746Z'
        fill='#5CC9BA'
      />
      <path
        d='M9.92086 23.4746C7.19819 20.7519 2.78353 20.7519 0.0621948 23.4746L9.92086 33.3333C12.6435 30.6106 12.6435 26.1973 9.92086 23.4746Z'
        fill='#5CC9BA'
      />
      <path
        d='M23.5353 9.85998C26.258 12.5826 30.6727 12.5826 33.394 9.85998L23.5353 0.0013113C20.8127 2.72398 20.8127 7.13731 23.5353 9.85998Z'
        fill='#5CC9BA'
      />
    </>
  ),
});
