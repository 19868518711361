import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const SubKpi = createIcon({
  displayName: 'SubKpi',
  viewBox: '0 0 20 16',
  path: (
    <path
      d='M1 0C0.755067 3.23106e-05 0.518663 0.089956 0.335629 0.252715C0.152594 0.415474 0.0356572 0.639749 0.00699997 0.883L0 1V7.5C-6.91127e-05 8.39507 0.34278 9.25615 0.958057 9.90621C1.57334 10.5563 2.41428 10.9459 3.308 10.995L3.5 11H16.585L13.293 14.293C13.1208 14.4652 13.0174 14.6943 13.0021 14.9373C12.9868 15.1803 13.0607 15.4206 13.21 15.613L13.293 15.707C13.4652 15.8792 13.6943 15.9826 13.9373 15.9979C14.1803 16.0132 14.4206 15.9393 14.613 15.79L14.707 15.707L19.707 10.707C19.742 10.6721 19.7744 10.6347 19.804 10.595L19.875 10.485L19.929 10.371L19.964 10.266L19.989 10.149L19.996 10.089L20 10L19.997 9.925L19.98 9.799L19.95 9.688L19.906 9.577L19.854 9.479L19.79 9.387L19.707 9.293L14.707 4.293C14.527 4.11365 14.2856 4.00953 14.0316 4.00177C13.7777 3.99402 13.5303 4.08321 13.3397 4.25125C13.1492 4.41928 13.0297 4.65355 13.0056 4.90647C12.9815 5.1594 13.0546 5.41201 13.21 5.613L13.293 5.707L16.585 9H3.5C3.12727 9.00001 2.7679 8.86124 2.49189 8.61075C2.21589 8.36026 2.04303 8.01598 2.007 7.645L2 7.5V1C2 0.734784 1.89464 0.48043 1.70711 0.292893C1.51957 0.105357 1.26522 0 1 0Z'
      fill='#415d7f'
    />
  ),
});
