import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const PlanesIcon = createIcon({
  displayName: 'PlanesIcon',
  viewBox: '0 0 34 34',
  path: (
    <>
      <path
        d='M17.2496 26.7525L12.0096 33.5392V19.9658L17.2496 26.7525Z'
        fill='#5CC9BA'
      />
      <path
        d='M0.0614624 14.6705L5.0588 14.6892C5.2668 20.6412 8.95746 24.3358 14.9335 24.5758L14.7268 29.7065C10.4228 29.5332 6.74813 28.0052 4.0988 25.2852C1.5148 22.6332 0.206796 18.8332 0.0614624 14.6705Z'
        fill='#5CC9BA'
      />
      <path
        d='M16.2067 6.91661L21.4467 0.129944V13.7033L16.2067 6.91661Z'
        fill='#5CC9BA'
      />
      <path
        d='M33.3948 18.9986L28.3975 18.98C28.1895 13.028 24.4988 9.33329 18.5228 9.09329L18.7295 3.96263C23.0335 4.13596 26.7082 5.66529 29.3575 8.38396C31.9415 11.036 33.2495 14.8373 33.3948 18.9986Z'
        fill='#5CC9BA'
      />
    </>
  ),
});
