import {createSlice} from '@reduxjs/toolkit';

import {RootState} from '../../app/rootReducer';

const initialState = {
  data: false,
};
const formShowComponentsSlice = createSlice({
  name: 'formShowFilters',
  initialState,
  reducers: {
    addFormShowComponents(state, action) {
      state.data = action.payload;
    },
  },
});

export const getFormShowComponentsSelector = (state: RootState) =>
  state.formShowComponents.data;
export const getErrorSelector = state => state.calendarShowFilters.error;

export default formShowComponentsSlice.reducer;
export const {addFormShowComponents} = formShowComponentsSlice.actions;
