import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';
import {FiltersUsers} from '../types/users';

export interface CalendarFilter {
  organization: string;
  project?: string;
  parents?: string[];
  user: FiltersUsers | null;
}

export interface CalendarFilterState {
  data: CalendarFilter[];
  error: string | null;
}

const initialState = {data: [], error: null} as CalendarFilterState;
const calendarFilterSlice = createSlice({
  name: 'calendarFilter',
  initialState,
  reducers: {
    addCalendarFilter(state, action: PayloadAction<CalendarFilter[]>) {
      state.data = action.payload;
    },
  },
});

export const getCalendarFilterSelector = (state: RootState) =>
  state.calendarFilter.data;

export default calendarFilterSlice.reducer;
export const {addCalendarFilter} = calendarFilterSlice.actions;
