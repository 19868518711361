function baseStyle(props: Record<string, any>) {
  return {
    '--tooltip-bg': 'colors.gray.600',
    px: '8px',
    py: '3px',
    bg: 'var(--tooltip-bg)',
    '--popper-arrow-bg': 'var(--tooltip-bg)',
    color: 'white',
    borderRadius: 'md',
    fontWeight: 'medium',
    fontSize: 'sm',
    boxShadow: 'md',
    maxW: '320px',
    zIndex: 'tooltip',
  };
}

const tooltip = {
  baseStyle,
};

export default tooltip;
