import {ThemeOverride, extendTheme} from '@chakra-ui/react';

import components from './components';
import foundations from './foundations';
import styles from './styles';

const overrides = {
  styles,
  ...foundations,
  components,
} as unknown as ThemeOverride;

export default extendTheme(overrides);
