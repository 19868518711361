import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';

export interface BackEvents {
  state: boolean;
  disclosure?: any;
}

export interface BackEventsState {
  data: BackEvents;
}

const initialState = {data: {state: false}} as BackEventsState;
const backEventsSlice = createSlice({
  name: 'backEvents',
  initialState,
  reducers: {
    backEvents(state, action: PayloadAction<BackEvents>) {
      state.data = action.payload;
    },
  },
});

export const getBackEventsSelector = (state: RootState) =>
  state.backEvents.data;

export default backEventsSlice.reducer;
export const {backEvents} = backEventsSlice.actions;
