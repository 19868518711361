import {call, put, takeEvery} from 'redux-saga/effects';

import * as api from '../services/toursService';
import {
  addCoordinate,
  addCoordinateError,
  addCoordinateSuccess,
  deleteCoordinate,
  deleteCoordinateError,
  deleteCoordinateSuccess,
  getCoordinates,
  getCoordinatesError,
  getCoordinatesSuccess,
} from '../slices/coordinatesSlice';

export function* getAllCoordinates({payload}) {
  try {
    const result = yield call(api.getCoordinates, payload);
    yield put(getCoordinatesSuccess(result));
  } catch (e: any) {
    yield put(getCoordinatesError(e.toString()));
  }
}

export function* createCoordinate({payload}) {
  try {
    const result = yield call(api.createCoordinate, payload);
    yield put(addCoordinateSuccess(result));
  } catch (e: any) {
    yield put(addCoordinateError(e.toString()));
  }
}

export function* removeCoordinate({payload}) {
  try {
    const result = yield call(api.deleteCoordinate, payload);
    yield put(deleteCoordinateSuccess(result));
  } catch (e: any) {
    yield put(deleteCoordinateError(e.toString()));
  }
}

export default function* rootSaga() {
  yield takeEvery(getCoordinates, getAllCoordinates);
  yield takeEvery(addCoordinate, createCoordinate);
  yield takeEvery(deleteCoordinate, removeCoordinate);
}
