import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const ImageIcon = createIcon({
  displayName: 'ImageIcon',
  viewBox: '0 0 23 23',
  path: (
    <svg fill='none'>
      <path
        d='M22.4727 17.8008V0.3608H0.153992V22.6781H1.57399L14.1487 8.81947L22.4727 17.8008Z'
        fill='#F75266'
      />
      <path
        d='M3.37372 22.6777H22.4724V19.7604L14.1591 10.7911L3.37372 22.6777Z'
        fill='#F75266'
      />
      <path
        d='M14.1484 8.81907L1.57373 22.6777H3.37373L14.1591 10.7911L22.4724 19.7617V17.8004L14.1484 8.81907Z'
        fill='white'
      />
      <path
        d='M6.15428 3.59507C4.93428 3.59507 3.94228 4.58707 3.94228 5.80573C3.94228 7.0244 4.93428 8.0164 6.15428 8.0164C7.37428 8.0164 8.36628 7.0244 8.36628 5.80573C8.36628 4.58707 7.37428 3.59507 6.15428 3.59507ZM6.15428 9.34973C4.19961 9.34973 2.60895 7.7604 2.60895 5.80573C2.60895 3.85107 4.19961 2.26173 6.15428 2.26173C8.10895 2.26173 9.69961 3.85107 9.69961 5.80573C9.69961 7.7604 8.10895 9.34973 6.15428 9.34973Z'
        fill='white'
      />
    </svg>
  ),
});
