import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const PolicyIcon = createIcon({
  displayName: 'PolicyIcon',
  viewBox: '0 0 28 38',
  path: (
    <>
      <rect
        x='1.6875'
        y='6.80005'
        width='23.625'
        height='28.6'
        rx='2'
        fill='#73B3AB'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.5588 0.801099C12.1022 0.244546 12.7493 -0.0220136 13.5 0.00142023C14.2507 -0.0220136 14.8978 0.244546 15.4412 0.801099C15.9846 1.35765 16.2563 2.02844 16.2563 2.81348V4.50071V5.0104C16.2563 5.26817 16.2871 5.47907 16.3487 5.64311C16.4104 5.80714 16.5084 5.93603 16.6429 6.02977C16.7773 6.1235 16.9062 6.18794 17.0294 6.22309C17.1527 6.25824 17.3263 6.2934 17.5504 6.32855C18.0994 6.39885 18.5616 6.64197 18.937 7.05792C19.3123 7.47387 19.5 8.12123 19.5 9H7.5C7.5 8.12123 7.68768 7.47387 8.06303 7.05792C8.43838 6.64197 8.90056 6.39885 9.44958 6.32855C9.67367 6.2934 9.84734 6.25824 9.97059 6.22309C10.0938 6.18794 10.2227 6.1235 10.3571 6.02977C10.4916 5.93603 10.5896 5.80714 10.6513 5.64311C10.7129 5.47907 10.7437 5.26817 10.7437 5.0104V4.50071V2.81348C10.7437 2.02844 11.0154 1.35765 11.5588 0.801099ZM14.1891 2.60257C13.9986 2.39167 13.7689 2.2745 13.5 2.25107C13.2311 2.2745 13.0014 2.39167 12.8109 2.60257C12.6204 2.81348 12.5252 3.07711 12.5252 3.39346C12.5252 3.68639 12.6204 3.9383 12.8109 4.1492C13.0014 4.36011 13.2311 4.47728 13.5 4.50071C13.7689 4.47728 13.9986 4.36011 14.1891 4.1492C14.3796 3.9383 14.4748 3.68639 14.4748 3.39346C14.4748 3.07711 14.3796 2.81348 14.1891 2.60257Z'
        fill='#A67700'
      />
      <path
        d='M4.97461 30.6067C4.85742 30.6067 4.74902 30.6507 4.64941 30.7387C4.5498 30.8267 4.5 30.9249 4.5 31.0332V31.9269C4.5 32.0488 4.5498 32.1503 4.64941 32.2316C4.74902 32.3128 4.85742 32.3535 4.97461 32.3535H19.7754C19.8926 32.3535 20.001 32.3128 20.1006 32.2316C20.2002 32.1503 20.25 32.0488 20.25 31.9269V31.0332C20.25 30.9249 20.2002 30.8267 20.1006 30.7387C20.001 30.6507 19.8926 30.6067 19.7754 30.6067H4.97461Z'
        fill='white'
      />
      <path
        d='M4.92188 25.4475C4.81641 25.4475 4.71973 25.4881 4.63184 25.5694C4.54395 25.6506 4.5 25.7522 4.5 25.8741V26.7678C4.5 26.8761 4.54395 26.9709 4.63184 27.0521C4.71973 27.1334 4.81641 27.174 4.92188 27.174H17.5781C17.6836 27.174 17.7803 27.1334 17.8682 27.0521C17.9561 26.9709 18 26.8761 18 26.7678V25.8741C18 25.7522 17.9561 25.6506 17.8682 25.5694C17.7803 25.4881 17.6836 25.4475 17.5781 25.4475H4.92188Z'
        fill='white'
      />
      <path
        d='M5.04492 20.2071C4.9043 20.2071 4.77832 20.2511 4.66699 20.3391C4.55566 20.4272 4.5 20.5253 4.5 20.6337V21.5274C4.5 21.6492 4.55566 21.7508 4.66699 21.832C4.77832 21.9133 4.9043 21.9539 5.04492 21.9539H21.9551C22.0957 21.9539 22.2217 21.9133 22.333 21.832C22.4443 21.7508 22.5 21.6492 22.5 21.5274V20.6337C22.5 20.5253 22.4443 20.4272 22.333 20.3391C22.2217 20.2511 22.0957 20.2071 21.9551 20.2071H5.04492Z'
        fill='white'
      />
      <path
        d='M4.83398 15.048C4.74023 15.048 4.66113 15.0886 4.59668 15.1698C4.53223 15.2511 4.5 15.3526 4.5 15.4745V16.3682C4.5 16.4766 4.53223 16.5713 4.59668 16.6526C4.66113 16.7338 4.74023 16.7745 4.83398 16.7745H15.416C15.5098 16.7745 15.5889 16.7338 15.6533 16.6526C15.7178 16.5713 15.75 16.4766 15.75 16.3682V15.4745C15.75 15.3526 15.7178 15.2511 15.6533 15.1698C15.5889 15.0886 15.5098 15.048 15.416 15.048H4.83398Z'
        fill='white'
      />
      <path
        d='M26.5078 4.77026C26.1797 4.39111 25.793 4.20154 25.3477 4.20154H18V5.52179C17.9883 5.8874 18.0908 6.19208 18.3076 6.43581C18.5244 6.67955 18.8027 6.80142 19.1426 6.80142H23.2559C23.666 6.80142 24.0176 6.97069 24.3105 7.30921C24.6035 7.64774 24.75 8.05397 24.75 8.52791V33.6737C24.75 34.1476 24.6035 34.5538 24.3105 34.8924C24.0176 35.2309 23.666 35.4002 23.2559 35.4002H3.74414C3.33398 35.4002 2.98242 35.2309 2.68945 34.8924C2.39648 34.5538 2.25 34.1476 2.25 33.6737V8.52791C2.25 8.05397 2.39648 7.64774 2.68945 7.30921C2.98242 6.97069 3.33398 6.80142 3.74414 6.80142H7.89258C8.2207 6.80142 8.49023 6.67955 8.70117 6.43581C8.91211 6.19208 9.01172 5.8874 9 5.52179V4.20154H1.65234C1.20703 4.20154 0.820313 4.39111 0.492188 4.77026C0.164063 5.14941 0 5.59627 0 6.11083V36.0908C0 36.6053 0.164063 37.0522 0.492188 37.4313C0.820313 37.8105 1.20703 38 1.65234 38H25.3477C25.793 38 26.1797 37.8105 26.5078 37.4313C26.8359 37.0522 27 36.6053 27 36.0908V6.11083C27 5.59627 26.8359 5.14941 26.5078 4.77026Z'
        fill='#5A938C'
      />
      <path
        d='M21.572 17.3545L21.1111 18.7071C21.0267 18.955 20.7572 19.0873 20.509 19.0027L19.6105 18.6965C19.3623 18.612 19.2297 18.3427 19.3142 18.0948L19.7783 16.7327C19.1554 16.4841 18.6141 16.0531 18.2336 15.4959C18.0706 15.257 18.1709 14.9257 18.4336 14.8042L19.3076 14.4004C19.5136 14.3054 19.7473 14.3775 19.8904 14.5533C20.0615 14.7631 20.2832 14.92 20.5392 15.0072L22.1178 15.5451C22.4828 15.6695 22.8935 15.4389 23.0324 15.0312C23.1461 14.6976 23.0435 14.3346 22.7832 14.1495L20.5142 12.5314C19.5769 11.8628 19.1883 10.6138 19.5699 9.49387C20.0385 8.11882 21.4889 7.36626 22.8273 7.78289L23.2882 6.43028C23.3727 6.18241 23.6421 6.05015 23.8903 6.13473L24.7888 6.44089C25.037 6.52547 25.1696 6.79473 25.0852 7.0426L24.621 8.40475C25.244 8.65331 25.7854 9.0838 26.1658 9.6415C26.3288 9.88044 26.2284 10.2118 25.9658 10.3332L25.0917 10.737C24.8857 10.8321 24.6521 10.7599 24.5089 10.5841C24.3381 10.3738 24.1162 10.2174 23.8601 10.1302L22.2816 9.5923C21.9166 9.46793 21.5058 9.6985 21.3669 10.1062C21.2532 10.4399 21.3558 10.8028 21.6161 10.9879L23.8852 12.606C24.8225 13.2747 25.211 14.5237 24.8294 15.6435C24.3607 17.0192 22.9105 17.7712 21.572 17.3545Z'
        fill='#FFD25C'
      />
    </>
  ),
});
