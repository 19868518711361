import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../../app/rootReducer';

export interface FormState {
  state?: boolean;
  typeForm: string;
}

export interface FormStates {
  data: FormState;
}

const initialState = {
  data: {status: false, typeForm: 'event-form'},
} as FormStates;
const formStateSlice = createSlice({
  name: 'formState',
  initialState,
  reducers: {
    getFormState(state, action: PayloadAction<FormState>) {
      state.data = action.payload;
    },
  },
});

export const getFormStateSelector = (state: RootState) => state.formState.data;

export default formStateSlice.reducer;
export const {getFormState} = formStateSlice.actions;
