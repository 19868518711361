import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';
import {Plan} from '../types/plans';

export interface PlanState {
  data: Plan[];
  error: string | null;
  isLoading: boolean;
}

const initialState = {data: [], error: null, isLoading: false} as PlanState;
const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    getPlans(state, action) {
      const x = '';
    },
    getPlansSuccess(state, action) {
      const newIds = action.payload.map(newElements => newElements.code);
      const newData = [
        ...action.payload,
        ...state.data.filter(current => !newIds.includes(current.code)),
      ];
      newData.sort((a, b) => a.code - b.code);
      state.data = newData;
    },
    getPlansError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    addPlan(state, action: PayloadAction<Plan>) {
      const x = '';
    },
    addPlanSuccess(state, action: PayloadAction<Plan>) {
      //state.data.push(action.payload);
    },
    addPlanError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    updatePlan(state, action) {
      const x = '';
    },
    updatePlanSuccess(state, action: PayloadAction<Plan>) {
      const index = state.data.findIndex(
        plan => plan.code === action.payload.code,
      );
      state.data[index] = action.payload;
    },
    updatePlanError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    disablePlan(state, action: PayloadAction<Plan>) {
      state.isLoading = true;
    },
    disablePlanError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    disablePlanSuccess(state, action: PayloadAction<Plan>) {
      const index = state.data.findIndex(
        plan => plan.code === action.payload.code,
      );
      state.data[index].enabled = false;
      state.isLoading = false;
    },
  },
});

export const getPlansSelector = (state: RootState) => state.plan.data;
export const isPlanLoading = state => state.plan.isLoading;
export const getErrorSelector = state => state.plan.error;

export const getSinglePlanSelector = (state: RootState, code: string) => {
  const index = state.plan.data.findIndex(plan => plan.code === code);
  return state.plan.data[index];
};

export default planSlice.reducer;
export const {
  getPlans,
  getPlansSuccess,
  getPlansError,
  addPlan,
  addPlanSuccess,
  addPlanError,
  updatePlan,
  updatePlanSuccess,
  updatePlanError,
  disablePlan,
  disablePlanError,
  disablePlanSuccess,
} = planSlice.actions;
