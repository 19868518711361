import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const KpiParentPlus = createIcon({
  displayName: 'KpiParentPlus',
  viewBox: '0 0 12 14',
  path: (
    <path
      d='M10.53 6.152L2.52999 1.152L2.79499 0.728004L2.52999 1.152C2.37862 1.05739 2.20469 1.00503 2.02624 1.00034C1.8478 0.995659 1.67135 1.03882 1.51523 1.12536C1.3591 1.21189 1.22898 1.33864 1.13838 1.49244C1.04777 1.64625 0.999994 1.8215 0.999994 2V12C0.999994 12.1785 1.04777 12.3538 1.13838 12.5076C1.22898 12.6614 1.3591 12.7881 1.51523 12.8746C1.67135 12.9612 1.8478 13.0043 2.02624 12.9997C2.20468 12.995 2.37862 12.9426 2.52999 12.848L10.53 7.848C10.6738 7.75811 10.7924 7.63312 10.8746 7.48477C10.9569 7.33643 11 7.16961 11 7C11 6.8304 10.9569 6.66358 10.8746 6.51523C10.7924 6.36689 10.6738 6.24189 10.53 6.152Z'
      fill='#415d7f'
    />
  ),
});
