import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const ViaticosIcon = createIcon({
  displayName: 'ViaticosIcon',
  viewBox: '0 0 33 35',
  path: (
    <>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.19999 31.9006C8.82868 31.9006 8.47259 31.7374 8.21004 31.4467C7.94749 31.1561 7.79999 30.7619 7.79999 30.3509V8.23683L10.6 10.0035C10.8161 10.1284 11.0562 10.1936 11.3 10.1936C11.5438 10.1936 11.7839 10.1284 12 10.0035L16.2 7.33801L20.4 10.0035C20.6161 10.1284 20.8562 10.1936 21.1 10.1936C21.3438 10.1936 21.5839 10.1284 21.8 10.0035L24.6 8.23683V30.3509C24.6038 30.8796 24.689 31.4037 24.852 31.9006H9.19999ZM12 25.7019H20.4C20.7713 25.7019 21.1274 25.8651 21.3899 26.1558C21.6525 26.4464 21.8 26.8406 21.8 27.2516C21.8 27.6626 21.6525 28.0567 21.3899 28.3474C21.1274 28.638 20.7713 28.8012 20.4 28.8012H12C11.6287 28.8012 11.2726 28.638 11.01 28.3474C10.7475 28.0567 10.6 27.6626 10.6 27.2516C10.6 26.8406 10.7475 26.4464 11.01 26.1558C11.2726 25.8651 11.6287 25.7019 12 25.7019ZM17.6 16.4037H14.8C14.4287 16.4037 14.0726 16.2404 13.81 15.9498C13.5475 15.6592 13.4 15.265 13.4 14.854C13.4 14.443 13.5475 14.0488 13.81 13.7582C14.0726 13.4676 14.4287 13.3043 14.8 13.3043H17.6C17.9713 13.3043 18.3274 13.4676 18.5899 13.7582C18.8525 14.0488 19 14.443 19 14.854C19 15.265 18.8525 15.6592 18.5899 15.9498C18.3274 16.2404 17.9713 16.4037 17.6 16.4037ZM12 19.5031H20.4C20.7713 19.5031 21.1274 19.6664 21.3899 19.957C21.6525 20.2476 21.8 20.6418 21.8 21.0528C21.8 21.4638 21.6525 21.858 21.3899 22.1486C21.1274 22.4392 20.7713 22.6025 20.4 22.6025H12C11.6287 22.6025 11.2726 22.4392 11.01 22.1486C10.7475 21.858 10.6 21.4638 10.6 21.0528C10.6 20.6418 10.7475 20.2476 11.01 19.957C11.2726 19.6664 11.6287 19.5031 12 19.5031Z'
        fill='#73B3AB'
      />
      <path
        d='M14.8 16.4037H17.6C17.9713 16.4037 18.3274 16.2404 18.5899 15.9498C18.8525 15.6592 19 15.265 19 14.854C19 14.443 18.8525 14.0488 18.5899 13.7582C18.3274 13.4676 17.9713 13.3043 17.6 13.3043H14.8C14.4287 13.3043 14.0726 13.4676 13.81 13.7582C13.5475 14.0488 13.4 14.443 13.4 14.854C13.4 15.265 13.5475 15.6592 13.81 15.9498C14.0726 16.2404 14.4287 16.4037 14.8 16.4037Z'
        fill='#73B3AB'
      />
      <path
        d='M20.4 19.5031H12C11.6287 19.5031 11.2726 19.6664 11.01 19.957C10.7475 20.2476 10.6 20.6418 10.6 21.0528C10.6 21.4638 10.7475 21.858 11.01 22.1486C11.2726 22.4392 11.6287 22.6025 12 22.6025H20.4C20.7713 22.6025 21.1274 22.4392 21.3899 22.1486C21.6525 21.858 21.8 21.4638 21.8 21.0528C21.8 20.6418 21.6525 20.2476 21.3899 19.957C21.1274 19.6664 20.7713 19.5031 20.4 19.5031Z'
        fill='#73B3AB'
      />
      <path
        d='M20.4 25.7019H12C11.6287 25.7019 11.2726 25.8651 11.01 26.1558C10.7475 26.4464 10.6 26.8406 10.6 27.2516C10.6 27.6626 10.7475 28.0567 11.01 28.3474C11.2726 28.638 11.6287 28.8012 12 28.8012H20.4C20.7713 28.8012 21.1274 28.638 21.3899 28.3474C21.6525 28.0567 21.8 27.6626 21.8 27.2516C21.8 26.8406 21.6525 26.4464 21.3899 26.1558C21.1274 25.8651 20.7713 25.7019 20.4 25.7019Z'
        fill='#73B3AB'
      />
      <path
        d='M30.2 30.3509C30.2 30.7619 30.0525 31.1561 29.79 31.4467C29.5274 31.7373 29.1713 31.9006 28.8 31.9006C28.4287 31.9006 28.0726 31.7373 27.8101 31.4467C27.5475 31.1561 27.4 30.7619 27.4 30.3509V22.6025H30.2V30.3509Z'
        fill='#5A938C'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31.6 19.5031H27.4V5.55585C27.401 5.28278 27.3368 5.01427 27.2138 4.77751C27.0909 4.54075 26.9136 4.34415 26.7 4.20762C26.4872 4.07161 26.2458 4 26 4C25.7543 4 25.5128 4.07161 25.3 4.20762L21.1 6.87309L16.9 4.20762C16.6872 4.07161 16.4458 4 16.2 4C15.9543 4 15.7128 4.07161 15.5 4.20762L11.3 6.87309L7.10001 4.20762C6.88718 4.07161 6.64576 4 6.40001 4C6.15426 4 5.91284 4.07161 5.70001 4.20762C5.48636 4.34415 5.30911 4.54075 5.18618 4.77751C5.06326 5.01427 4.99903 5.28278 5.00001 5.55585V30.3509C5.00001 31.5839 5.44251 32.7664 6.23016 33.6383C7.01781 34.5102 8.0861 35 9.20001 35H28.8C29.9139 35 30.9822 34.5102 31.7698 33.6383C32.5575 32.7664 33 31.5839 33 30.3509V21.0528C33 20.6418 32.8525 20.2476 32.59 19.957C32.3274 19.6664 31.9713 19.5031 31.6 19.5031ZM8.21006 31.4467C8.47261 31.7373 8.82871 31.9006 9.20001 31.9006H24.852C24.689 31.4037 24.6038 30.8796 24.6 30.3509V8.23682L21.8 10.0035C21.5839 10.1284 21.3438 10.1936 21.1 10.1936C20.8562 10.1936 20.6161 10.1284 20.4 10.0035L16.2 7.338L12 10.0035C11.7839 10.1284 11.5438 10.1936 11.3 10.1936C11.0562 10.1936 10.8161 10.1284 10.6 10.0035L7.80001 8.23682V30.3509C7.80001 30.7619 7.94751 31.1561 8.21006 31.4467ZM29.79 31.4467C30.0525 31.1561 30.2 30.7619 30.2 30.3509V22.6025H27.4V30.3509C27.4 30.7619 27.5475 31.1561 27.8101 31.4467C28.0726 31.7373 28.4287 31.9006 28.8 31.9006C29.1713 31.9006 29.5274 31.7373 29.79 31.4467Z'
        fill='#5A938C'
      />
      <path
        d='M14.8 16.4037H17.6C17.9713 16.4037 18.3274 16.2404 18.59 15.9498C18.8525 15.6592 19 15.265 19 14.854C19 14.443 18.8525 14.0488 18.59 13.7582C18.3274 13.4676 17.9713 13.3043 17.6 13.3043H14.8C14.4287 13.3043 14.0726 13.4676 13.8101 13.7582C13.5475 14.0488 13.4 14.443 13.4 14.854C13.4 15.265 13.5475 15.6592 13.8101 15.9498C14.0726 16.2404 14.4287 16.4037 14.8 16.4037Z'
        fill='white'
      />
      <path
        d='M20.4 19.5031H12C11.6287 19.5031 11.2726 19.6664 11.0101 19.957C10.7475 20.2476 10.6 20.6418 10.6 21.0528C10.6 21.4638 10.7475 21.858 11.0101 22.1486C11.2726 22.4392 11.6287 22.6025 12 22.6025H20.4C20.7713 22.6025 21.1274 22.4392 21.39 22.1486C21.6525 21.858 21.8 21.4638 21.8 21.0528C21.8 20.6418 21.6525 20.2476 21.39 19.957C21.1274 19.6664 20.7713 19.5031 20.4 19.5031Z'
        fill='white'
      />
      <path
        d='M20.4 25.7019H12C11.6287 25.7019 11.2726 25.8651 11.0101 26.1558C10.7475 26.4464 10.6 26.8405 10.6 27.2516C10.6 27.6626 10.7475 28.0567 11.0101 28.3473C11.2726 28.638 11.6287 28.8012 12 28.8012H20.4C20.7713 28.8012 21.1274 28.638 21.39 28.3473C21.6525 28.0567 21.8 27.6626 21.8 27.2516C21.8 26.8405 21.6525 26.4464 21.39 26.1558C21.1274 25.8651 20.7713 25.7019 20.4 25.7019Z'
        fill='white'
      />
      <path
        d='M30.2 30.3509C30.2 30.7619 30.0525 31.1561 29.79 31.4467C29.5274 31.7373 29.1713 31.9006 28.8 31.9006C28.4287 31.9006 28.0726 31.7373 27.8101 31.4467C27.5475 31.1561 27.4 30.7619 27.4 30.3509V22.6024H30.2V30.3509Z'
        fill='#FFD25C'
      />
      <path
        d='M7.80591 17.5695L7.40594 19.5977C7.33264 19.9694 6.92461 20.2017 6.49414 20.1168L4.93585 19.8095C4.50538 19.7246 4.21608 19.3548 4.28938 18.9831L4.69217 16.9406C3.60223 16.675 2.59819 16.1328 1.82518 15.3837C1.49394 15.0624 1.5713 14.5675 1.97098 14.3519L3.30124 13.6349C3.61479 13.4661 4.01804 13.5351 4.29993 13.7681C4.63671 14.0459 5.0427 14.2395 5.48681 14.3271L8.22452 14.867C8.85757 14.9918 9.47192 14.5958 9.59248 13.9845C9.69115 13.4841 9.42645 12.9742 8.94944 12.7456L4.79064 10.7461C3.07265 9.91982 2.10336 8.17017 2.43451 6.4909C2.84112 4.42901 5.02678 3.11982 7.33764 3.52049L7.73762 1.49224C7.81091 1.12057 8.21894 0.888282 8.64942 0.973173L10.2077 1.28047C10.6382 1.36536 10.9275 1.73516 10.8542 2.10684L10.4514 4.14938C11.5413 4.41499 12.5455 4.95635 13.3184 5.7063C13.6496 6.02759 13.5723 6.52251 13.1726 6.7381L11.8423 7.45507C11.5288 7.62387 11.1255 7.55483 10.8436 7.3219C10.507 7.04323 10.1009 6.85044 9.65674 6.76286L6.91904 6.22298C6.28598 6.09814 5.67163 6.49416 5.55108 7.10549C5.45241 7.60583 5.7171 8.11579 6.19411 8.34439L10.3529 10.3439C12.0709 11.1701 13.0402 12.9198 12.709 14.5991C12.3023 16.6618 10.1168 17.9701 7.80591 17.5695Z'
        fill='#FFD25C'
      />
    </>
  ),
});
