import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const CoordsIcon = createIcon({
  displayName: 'CoordsIcon',
  viewBox: '0 0 18 18',
  path: (
    <>
      <circle
        cx='9'
        cy='9'
        r='8'
        fill='white'
        stroke='#BD4957'
        strokeWidth='2'
      />
    </>
  ),
});
