import React from 'react';

import {Box, FormControl, FormLabel} from '@chakra-ui/react';

import {Spanish} from 'flatpickr/dist/l10n/es.js';
import Flatpickr from 'react-flatpickr';

interface IDateRangeInput {
  errors: any;
  selectedDayRangeStart: Date[];
  selectedDayRangeEnd?: Date[];
  onDateRangeStartChange: (date: Date[]) => void;
  onDateRangeEndChange?: (date: Date[]) => void;
  dateStartRequired?: boolean;
  dateEndRequired?: boolean;
}

export function DatesRangeInput(props: IDateRangeInput) {
  const {
    errors,
    selectedDayRangeStart,
    selectedDayRangeEnd,
    onDateRangeStartChange,
    onDateRangeEndChange,
    dateStartRequired,
    dateEndRequired,
  } = props;

  const onDateStartChange = date => {
    onDateRangeStartChange(date);
  };

  const onDateEndChange = date => {
    if (onDateRangeEndChange) {
      onDateRangeEndChange(date);
    }
  };
  return (
    <>
      <FormControl
        mt={5}
        isRequired={dateStartRequired}
        isInvalid={!!errors['selectedDayStart']}>
        <FormLabel fontWeight='bolder'>Fecha inicio:</FormLabel>
        <Box
          borderColor='red'
          borderWidth={errors['selectedDayRangeStart'] && 2}
          borderStyle='solid'
          borderRadius={8}>
          <Flatpickr
            name='startDate'
            options={{
              mode: 'single',
              locale: Spanish,
              dateFormat: 'd-m-Y',
              maxDate: selectedDayRangeEnd,
              disableMobile: 'true',
            }}
            placeholder='Seleccione una fecha'
            value={selectedDayRangeStart}
            onChange={onDateStartChange}
          />
        </Box>
        <Box color='red.500' pt={2}>
          {errors['selectedDayRangeStart']}
        </Box>
      </FormControl>
      <FormControl
        mt={5}
        isRequired={dateEndRequired}
        isInvalid={!!errors['selectedDayRangeEnd']}>
        <FormLabel fontWeight='bolder'>Fecha fin:</FormLabel>
        <Box
          borderColor='red'
          borderWidth={errors['selectedDayRangeEnd'] && 2}
          borderStyle='solid'
          borderRadius={8}>
          <Flatpickr
            name='endDate'
            options={{
              mode: 'single',
              locale: Spanish,
              dateFormat: 'd-m-Y',
              size: 'sm',
              disableMobile: 'true',
            }}
            placeholder='Seleccione una fecha'
            value={selectedDayRangeEnd}
            onChange={onDateEndChange}
          />
        </Box>
        <Box color='red.500' pt={2}>
          {errors['selectedDayRangeEnd']}
        </Box>
      </FormControl>
    </>
  );
}
