import React, {useRef, useState} from 'react';

import {Provider} from 'react-redux';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';

import {HamburgerIcon} from '@chakra-ui/icons';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {BrowserRouter} from 'react-router-dom';

import {CerrarSesionIcon} from '../assets/icons';
import {HappifyThemeProvider} from '../components';
import {HeaderLayout} from '../components/Layout/HeaderLayout';
import {ScrollableLayout} from '../components/Layout/ScrollableLayout';
import {SidebarLayout} from '../components/Layout/SidebarLayout';
import {useUserInfo} from '../hooks/useUserInfo';
import CONFIG from './../config';
import {AppRouter} from './AppRouter';
import {MobileMenu} from './MobileMenu';
import SidebarMenu from './SidebarMenu';
import store from './store';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const onClose = () => setIsOpenAlert(false);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const userinfo = useUserInfo();
  const [isLarge] = useMediaQuery('(min-width: 720px)');
  const [isMobile] = useMediaQuery('(min-width: 720px)');
  const disclosure = useDisclosure();

  if (!userinfo.data) {
    return <></>;
  }

  const header = (
    <>
      <MobileMenu disclosure={disclosure} />
      <Flex
        justifyContent={isMobile ? 'end' : 'space-between'}
        w='100%'
        h='135px'
        alignItems='center'
        borderStyle='solid'
        borderBottom='1px'
        borderBottomColor='#dedede'>
        {!isMobile && (
          <Box>
            <HamburgerIcon
              w={12}
              h={12}
              color='darkBlue.700'
              onClick={() => disclosure.onOpen()}
            />
          </Box>
        )}
        <HStack>
          <CerrarSesionIcon
            w={12}
            h={12}
            mr={2}
            cursor='pointer'
            onClick={() => setIsOpenAlert(true)}
          />
          <AlertDialog
            isOpen={isOpenAlert}
            leastDestructiveRef={cancelRef}
            onClose={onClose}>
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontWeight='bold'>
                  Cerrar Sesión
                </AlertDialogHeader>

                <AlertDialogBody>¿Esta seguro de querer salir?</AlertDialogBody>
                <AlertDialogFooter>
                  <Button
                    colorScheme='darkBlue'
                    ref={cancelRef}
                    onClick={onClose}
                    variant='outline'>
                    Cancelar
                  </Button>
                  <Button colorScheme='red' ml={3} variant='outline'>
                    <a href={CONFIG.AUTH_LOGOUT}>Cerrar Sesión</a>
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
          <Avatar
            name={userinfo.data.name}
            mr='20px'
            bg='yellow.200'
            fontWeight='bold'
            color='white'
          />
          <Text fontSize='19px' fontWeight='bold'>
            {`${userinfo.data.given_name} ${userinfo.data.family_name}`}
          </Text>
        </HStack>
      </Flex>
    </>
  );

  const content = (
    <ScrollableLayout scrollY={true} px={isLarge ? '50px' : '20px'}>
      <HeaderLayout header={header}>
        <AppRouter />
      </HeaderLayout>
    </ScrollableLayout>
  );

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <HappifyThemeProvider>
          <BrowserRouter>
            <SidebarLayout
              sidebar={isMobile && <SidebarMenu />}
              content={content}
            />
          </BrowserRouter>
        </HappifyThemeProvider>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
