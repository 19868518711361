import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const SupportJourneysIcon = createIcon({
  displayName: 'SupportJourneysIcon',
  viewBox: '0 0 41 40',
  path: (
    <>
      <mask
        id='mask0_509_118'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='20'
        y='0'
        width='21'
        height='21'>
        <path
          d='M37.9248 3.07571C42.0251 7.17583 42.0251 13.8242 37.9248 17.9243C33.8246 22.0252 27.176 22.0252 23.0758 17.9243C18.9747 13.8242 18.9747 7.17583 23.0758 3.07571C27.176 -1.02524 33.8246 -1.02524 37.9248 3.07571Z'
          fill='#FFB800'
        />
      </mask>
      <g mask='url(#mask0_509_118)'>
        <path
          d='M37.9248 3.07571C42.0251 7.17583 42.0251 13.8242 37.9248 17.9243C33.8246 22.0252 27.176 22.0252 23.0758 17.9243C18.9747 13.8242 18.9747 7.17583 23.0758 3.07571C27.176 -1.02524 33.8246 -1.02524 37.9248 3.07571Z'
          fill='#FFB800'
        />
        <path
          d='M33.2521 11.1199H29.8945V9.96219H33.2521V11.1199Z'
          fill='#F2F2F5'
        />
        <path
          d='M31.0523 11.1199H29.8954V5.38554H31.0523V11.1199Z'
          fill='#F2F2F5'
        />
        <path
          d='M31.2278 2.97546H29.927V-0.940004H31.2278V2.97546Z'
          fill='#F2F2F5'
        />
        <path
          d='M31.2278 22.162H29.927V18.0211H31.2278V22.162Z'
          fill='#F2F2F5'
        />
        <path
          d='M17.5706 9.84674H23.0539V11.1475H17.5706V9.84674Z'
          fill='#F2F2F5'
        />
        <path
          d='M38.1001 9.84674H41.5633V11.1475H38.1001V9.84674Z'
          fill='#F2F2F5'
        />
      </g>
      <path
        d='M25.507 13.8665C25.507 18.7492 21.5483 22.7065 16.667 22.7065C11.7843 22.7065 7.82568 18.7492 7.82568 13.8665C7.82568 8.98388 11.7843 5.02654 16.667 5.02654C21.5483 5.02654 25.507 8.98388 25.507 13.8665Z'
        fill='#5CC9BA'
      />
      <path
        d='M16.6668 4.99997C11.7841 4.99997 7.82544 8.95864 7.82544 13.8413H25.5081C25.5081 8.95864 21.5494 4.99997 16.6668 4.99997Z'
        fill='#F2F2F5'
      />
      <path
        d='M16.6667 22.6452C7.46133 22.6452 0 30.1066 0 39.3119H33.3333C33.3333 30.1066 25.872 22.6452 16.6667 22.6452Z'
        fill='#F2F2F5'
      />
    </>
  ),
});
