import * as jose from 'jose';
import Keycloak from 'keycloak-js';

import {IToken} from '../utils/user-access';
import CONFIG from './../config';

const keycloak = new Keycloak({
  url: CONFIG.KEYCLOAK_URL,
  realm: CONFIG.KEYCLOAK_REALM,
  clientId: CONFIG.KEYCLOAK_CLIENT_ID,
});

interface AuthCallback {
  (success: boolean): void;
}

const setupTokenRefresher = () => {
  setTimeout(() => {
    keycloak
      .updateToken(CONFIG.KEYCLOAK_REFRESH_MIN_VALIDITY * 60)
      .then(refreshed => {
        localStorage.setItem('react-token', keycloak.token || '');
        localStorage.setItem('idToken', keycloak.idToken || '');
        localStorage.setItem('clientId', keycloak.clientId || '');
        localStorage.setItem(
          'react-refresh-token',
          keycloak.refreshToken || '',
        );
        if (window['DD_RUM'] && process.env.NODE_ENV !== 'development') {
          window['DD_RUM'].setUser({
            id: keycloak.idTokenParsed?.sub,
            name: keycloak.idTokenParsed?.name,
            username: keycloak.idTokenParsed?.preferred_username,
          });
        }
        setupTokenRefresher();
      })
      .catch(() => window.location.reload());
  }, 60000);
};

const auth = (callback: AuthCallback) => {
  keycloak
    .init({onLoad: 'login-required'})
    .then(success => {
      if (!keycloak.token) {
        return;
      }
      if (keycloak.token) {
        const decodeJwt = jose.decodeJwt(keycloak.token) as IToken;
        const allowAccess =
          decodeJwt.realm_access.roles.includes('super-admin') ||
          decodeJwt.realm_access.roles.includes('supervisor');
        if (!allowAccess) {
          alert('No tienes acceso a este apartado');
          return;
        }
      }
      if (window['DD_RUM'] && process.env.NODE_ENV !== 'development') {
        window['DD_RUM'].setUser({
          id: keycloak.idTokenParsed?.sub,
          name: keycloak.idTokenParsed?.name,
          username: keycloak.idTokenParsed?.preferred_username,
        });
      }
      localStorage.setItem('react-token', keycloak.token || '');
      localStorage.setItem('idToken', keycloak.idToken || '');
      localStorage.setItem('clientId', keycloak.clientId || '');
      localStorage.setItem('react-refresh-token', keycloak.refreshToken || '');
      setupTokenRefresher();
      callback(success);
    })
    .catch(err => alert('Error de autenticación'));
};

export default auth;
