import { format, formatISO9075, parse } from 'date-fns';

export function normalizeDateString(dateString) {
  return format(dateString, 'yyyy-MM-dd\'T\'HH:mm:ssxxx');
}

export function formatDateString(date) {
  return format(date, 'yyyy-MM-dd\'T\'HH:mm:ss');
}

export const parseDate = (date, time) => {
  return parse(`${date} ${time}`, 'yyyy-MM-dd HH:mm:ss', new Date());
};

export const parseTime = date => {
  return formatISO9075(date, {representation: 'time'});
};

export const parseDateSubString = (date) => {
  return format(new Date(`${date.substring(0,10)} 00:00`), 'dd-MM-yyyy');
};

export const parseDateNormal = (date) => {
  return format(new Date(date), 'dd-MM-yyyy HH:mm');
};
export const parseOnlyDate = (date) => {
  return format(new Date(date), 'dd-MM-yyyy');
};

export const parseDateTime = (time) =>{
  return format(new Date(`2022-08-29 ${time}`), 'HH:mm');
};

export const dateTimestamp = (date) =>{
  return date.getTime();
};
