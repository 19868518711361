const filter = [
  'all',
  ['==', 'active', 'false'],
  ['==', '$type', 'Point'],
  ['==', 'meta', 'feature'],
  ['!=', 'mode', 'static'],
];

export const styleDrawPoints = [
  {
    id: 'gl-draw-point-point-stroke-inactive',
    type: 'circle',
    filter: filter,
    paint: {
      'circle-radius': 7,
      'circle-opacity': 1,
      'circle-color': '#000',
    },
  },
  {
    id: 'gl-draw-point-inactive',
    type: 'circle',
    filter: filter,
    paint: {
      'circle-radius': 6,
      'circle-color': '#3885fe',
    },
  },
  {
    id: 'gl-draw-point-stroke-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['!=', 'meta', 'midpoint'],
    ],
    paint: {
      'circle-radius': 6,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['!=', 'meta', 'midpoint'],
      ['==', 'active', 'true'],
    ],
    paint: {
      'circle-radius': 7,
      'circle-color': '#fa7451',
    },
  },
];
