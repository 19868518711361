import {ReactNode} from 'react';

import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../../app/rootReducer';

export interface Dropped {
  id: number;
  type: number;
  children?: ReactNode;
}

export interface DroppedState {
  data: Dropped[];
}

const initialState = {data: []} as DroppedState;
const droppedSlice = createSlice({
  name: 'dropped',
  initialState,
  reducers: {
    getDropped(state, action) {
      const newData = [] as any;
      newData.push(action.payload);
      state.data = newData;
    },
    addDropped(state, action: PayloadAction<Dropped>) {
      state.data.push(action.payload);
    },
    updateDropped(state, action: PayloadAction<[]>) {
      state.data = action.payload;
    },
    disableDropped(state, action: PayloadAction<number>) {
      const index = state.data.findIndex(
        dropped => dropped.id === action.payload,
      );
      state.data.splice(index, 1);
    },
  },
});

export const getDroppedSelector = (state: RootState) => state.dropped.data;

export default droppedSlice.reducer;
export const {getDropped, addDropped, updateDropped, disableDropped} =
  droppedSlice.actions;
