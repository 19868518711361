import {Plan} from '../types/plans';
import {sortAlpha} from '../utils/sortAlpha';
import {instance} from './index';

interface IPlansResponse {
  timestamp: string;
  status: string;
  data: Plan[];
}

export async function getPlans() {
  return instance()
    .get<IPlansResponse>('directory/plans')
    .then(response => sortAlpha(response.data.data, 'name'));
}

export function createPlan(data) {
  return instance().post('directory/plans', data);
}

export function updatePlan(data) {
  return instance().put(`directory/plans/${data.code}`, data);
}

export function disablePlan(data) {
  return instance().put(`directory/plans/${data.code}`, data);
}
