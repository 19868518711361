import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const TiposIncidenciasIcon = createIcon({
  displayName: 'TiposIncidenciasIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        className='lucide lucide-shield-alert'>
        <path d='M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z' />
        <path d='M12 8v4' />
        <path d='M12 16h.01' />
      </svg>
    </>
  ),
});
