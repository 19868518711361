import {format, formatISO, lastDayOfMonth, subMonths} from 'date-fns';

import {Event} from '../types/events';
import {instance} from './index';

const intervalStart = format(lastDayOfMonth(new Date()), 'yyyy-MM-dd');
const intervalEnd = format(subMonths(new Date(intervalStart), 2), 'yyyy-MM-dd');

export function getAllEvents() {
  return instance()
    .get(`/calendar?dateInterval.start>="${intervalEnd}"`)
    .then(response => response.data);
}

export async function getEventsToday() {
  const date = format(new Date(), 'yyyy-MM-dd');
  const response = await instance().get(
    `/calendar?dateInterval.start="${date}"`,
  );
  return response.data;
}

export async function getEventsByDate(date) {
  return instance()
    .get<Event[]>(`/reports/calendar-events?date=${date}`)
    .then(r => r.data);
}

export async function getEventsU(users) {
  const eventsPromises = users.map(u => {
    return instance()
      .get(`/calendar?idUser=${u.id}&dateInterval.start>="${intervalEnd}"`)
      .then(response => response.data);
  });
  const eventsUsers = await Promise.all(eventsPromises);
  return eventsUsers.flat();
}

export async function getEventsUserDay(idUser, date) {
  return instance()
    .get(`/calendar?idUser=${idUser}&dateInterval.start="${date}"`)
    .then(response => response.data);
}

export function createEvent(data) {
  return instance().post('/calendar', {
    ...data,
    createdAt: formatISO(new Date()),
  });
}

export function copyDay(data) {
  return instance().post('/calendar/copy', data);
}

export function copyWeek(data) {
  return instance().post('/calendar/copy-week', data);
}

export function moveDay(data) {
  return instance().put('/calendar/move-date', data);
}

export function updateEvent(data) {
  return instance().put(`/calendar/${data.id}`, {
    ...data,
    updatedAt: formatISO(new Date()),
  });
}

export function deleteEvent(id: string) {
  return instance().delete(`/calendar/${id}`);
}
