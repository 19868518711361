const parts = ['popper', 'content', 'header', 'body', 'footer', 'arrow'];

type Dict = Record<string, any>;

const baseStylePopper = {
  zIndex: 50,
};

function baseStyleContent(props: Dict) {
  return {
    '--popover-bg': 'white',
    bg: 'teal.300',
    '--popper-arrow-bg': 'colors.teal.300',
    '--popper-arrow-shadow-color': 'colors.gray.200',
    width: 'xs',
    color: 'white',
    border: 'solid',
    borderRadius: 'md',
    boxShadow: 'sm',
    zIndex: 'inherit',
    _focus: {
      outline: 0,
      boxShadow: 'none',
    },
  };
}

const baseStyleHeader = {
  px: 3,
  py: 2,
  border: 'none',
  fontSize: '27px',
  fontWeight: 'semibold',
};

const baseStyleBody = {
  px: 3,
  py: 2,
  fontSize: '14px',
};

const baseStyleFooter = {
  px: 3,
  py: 2,
};

const baseStyle = (props: Dict) => ({
  popper: baseStylePopper,
  content: baseStyleContent(props),
  header: baseStyleHeader,
  body: baseStyleBody,
  footer: baseStyleFooter,
  arrow: {},
});

const popover = {
  parts,
  baseStyle,
};

export default popover;
