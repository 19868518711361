import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const ModuleKpisIcon = createIcon({
  displayName: 'ModuleKpisIcon',
  viewBox: '0 0 41 40',
  path: (
    <>
      <path
        d='M27.1583 17.0345L27.1426 17.0655C27.2054 16.554 27.2681 16.027 27.2681 15.5C27.2681 14.973 27.221 14.477 27.1583 13.9655L27.174 13.9965L31 11.0205L27.1897 4.4795L22.6894 6.2775L22.7051 6.293C21.8897 5.673 20.996 5.146 20.0238 4.743H20.0395L19.3182 0H11.6818L10.9919 4.7585H11.0076C10.0354 5.1615 9.14163 5.6885 8.32625 6.3085L8.34193 6.293L3.826 4.4795L0 11.0205L3.826 13.9965L3.84168 13.9655C3.77896 14.477 3.73192 14.973 3.73192 15.5C3.73192 16.027 3.77896 16.554 3.85736 17.0655L3.84168 17.0345L0.548812 19.592L0.031361 19.995L3.84168 26.505L8.35761 24.7225L8.32625 24.6605C9.15731 25.296 10.0511 25.823 11.0389 26.226H10.9919L11.6975 31H19.3025C19.3025 31 19.3495 30.721 19.3966 30.349L19.9924 26.2415H19.9767C20.9489 25.8385 21.8584 25.3115 22.6894 24.676L22.6581 24.738L27.174 26.5205L30.9843 20.0105C30.9843 20.0105 30.7648 19.8245 30.4669 19.6075L27.1583 17.0345ZM15.4922 20.925C12.4659 20.925 10.004 18.4915 10.004 15.5C10.004 12.5085 12.4659 10.075 15.4922 10.075C18.5185 10.075 20.9803 12.5085 20.9803 15.5C20.9803 18.4915 18.5185 20.925 15.4922 20.925Z'
        fill='white'
      />
      <path
        d='M29.8602 31.1115C31.1493 32.4005 31.8734 34.1488 31.8734 35.9717H41C41 33.8695 40.585 31.788 39.7788 29.8465C38.9726 27.905 37.791 26.1418 36.3019 24.658L29.8602 31.1115Z'
        fill='#33A854'
      />
      <path
        d='M13.6779 24.678C10.6827 27.6732 9 31.7356 9 35.9715H18.1266C18.1266 34.1486 18.8507 32.4003 20.1398 31.1113L13.6779 24.678Z'
        fill='#F55266'
      />
      <path
        d='M24.9999 29.0982C26.8229 29.0982 28.5712 29.8223 29.8602 31.1113L36.3019 24.6578C34.8127 23.174 33.0453 21.9987 31.101 21.1994C29.1566 20.4002 27.0736 19.9926 24.9714 20.0001L24.9999 29.0982Z'
        fill='#FFB800'
      />
      <path
        d='M24.9714 20C20.7355 20 16.6731 21.6827 13.6779 24.6779L20.1397 31.1112C21.4287 29.8222 23.177 29.0981 24.9999 29.0981L24.9714 20Z'
        fill='#F6704D'
      />
      <path
        d='M24.9714 20C20.7355 20 16.6731 21.6827 13.6779 24.6779L20.1397 31.1112C21.4287 29.8222 23.177 29.0981 24.9999 29.0981L24.9714 20Z'
        fill='#F6704D'
      />
      <path
        d='M24.4297 36.1142L21.3552 20.4222L25.5421 35.8518L24.4297 36.1142Z'
        fill='white'
      />
      <path
        d='M28.1716 35.983C28.1716 36.6137 27.9846 37.2302 27.6342 37.7545C27.2839 38.2789 26.7859 38.6876 26.2032 38.9289C25.6206 39.1702 24.9795 39.2334 24.361 39.1104C23.7424 38.9873 23.1743 38.6837 22.7283 38.2377C22.2824 37.7918 21.9787 37.2236 21.8557 36.6051C21.7327 35.9866 21.7958 35.3454 22.0372 34.7628C22.2785 34.1802 22.6872 33.6822 23.2115 33.3318C23.7359 32.9815 24.3524 32.7944 24.983 32.7944C25.8287 32.7944 26.6397 33.1304 27.2377 33.7284C27.8357 34.3263 28.1716 35.1374 28.1716 35.983Z'
        fill='#73B3AB'
      />
    </>
  ),
});
