import {getColor, mode, transparentize} from '@chakra-ui/theme-tools';

const baseStyle = {
  px: 1,
  textTransform: 'uppercase',
  fontSize: 'xs',
  borderRadius: 'sm',
  fontWeight: 'bold',
};

function variantSolid(props: Record<string, any>) {
  const {colorScheme: c, theme} = props;
  return {
    bg: `${c}.500`,
    color: 'white',
  };
}

function variantSubtle(props: Record<string, any>) {
  const {colorScheme: c, theme} = props;
  return {
    bg: `${c}.100`,
    color: `${c}.800`,
  };
}

function variantOutline(props: Record<string, any>) {
  const {colorScheme: c, theme} = props;
  const darkColor = transparentize(`${c}.200`, 0.8)(theme);
  const lightColor = getColor(theme, `${c}.500`);
  const color = mode(lightColor, darkColor)(props);

  return {
    color,
    boxShadow: `inset 0 0 0px 1px ${color}`,
  };
}

const variants = {
  solid: variantSolid,
  subtle: variantSubtle,
  outline: variantOutline,
};

const defaultProps = {
  variant: 'subtle',
  colorScheme: 'gray',
};

export default {
  baseStyle,
  variants,
  defaultProps,
};
