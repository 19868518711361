import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';
import {FiltersUsers} from '../types/users';

export interface BillFilter {
  organization: string;
  project?: string;
  parents?: string[];
  user: FiltersUsers | null;
}

export interface BillFilterState {
  data: BillFilter[];
  error: string | null;
}

const initialState = {data: [], error: null} as BillFilterState;
const billFilterSlice = createSlice({
  name: 'billFilter',
  initialState,
  reducers: {
    addBillFilter(state, action: PayloadAction<BillFilter[]>) {
      state.data = action.payload;
    },
  },
});

export const getBillFilterSelector = (state: RootState) =>
  state.billFilter.data;

export default billFilterSlice.reducer;
export const {addBillFilter} = billFilterSlice.actions;
