import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const DependencyIcon = createIcon({
  displayName: 'DependencyIcon',
  viewBox: '0 0 30 19',
  path: (
    <>
      <path
        d='M8.43742 4.75V8.55H4.6874C4.43876 8.55 4.2003 8.65009 4.02449 8.82825C3.84867 9.00641 3.7499 9.24804 3.7499 9.5C3.7499 9.75196 3.84867 9.99359 4.02449 10.1718C4.2003 10.3499 4.43876 10.45 4.6874 10.45H8.43742V14.25C8.43742 14.502 8.53619 14.7436 8.71201 14.9218C8.88782 15.0999 9.12628 15.2 9.37492 15.2C9.62356 15.2 9.86202 15.0999 10.0378 14.9218C10.2137 14.7436 10.3124 14.502 10.3124 14.25V10.45H14.0624C14.3111 10.45 14.5495 10.3499 14.7254 10.1718C14.9012 9.99359 14.9999 9.75196 14.9999 9.5C14.9999 9.24804 14.9012 9.00641 14.7254 8.82825C14.5495 8.65009 14.3111 8.55 14.0624 8.55H10.3124V4.75C10.3124 4.49804 10.2137 4.25641 10.0378 4.07825C9.86202 3.90009 9.62356 3.8 9.37492 3.8C9.12628 3.8 8.88782 3.90009 8.71201 4.07825C8.53619 4.25641 8.43742 4.49804 8.43742 4.75Z'
        fill='#FFB800'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.37492 19C7.56069 19 5.78544 18.4665 4.26447 17.4644C2.7435 16.4622 1.54216 15.0344 0.80615 13.354C0.0701385 11.6737 -0.168925 9.81307 0.117956 7.99778C0.404837 6.1825 1.20534 4.49056 2.42237 3.12716C3.6394 1.76377 5.22068 0.787489 6.97442 0.316735C8.72816 -0.154019 10.579 -0.0990246 12.3025 0.475048C14.026 1.04912 15.5482 2.11761 16.6843 3.55092C17.8204 4.98424 18.5217 6.7208 18.7031 8.55V10.45C18.4707 12.7933 17.3876 14.9656 15.664 16.5454C13.9403 18.1253 11.699 19 9.37492 19ZM8.43742 8.55V4.75C8.43742 4.49804 8.53619 4.25641 8.71201 4.07825C8.88782 3.90009 9.12628 3.8 9.37492 3.8C9.62356 3.8 9.86202 3.90009 10.0378 4.07825C10.2137 4.25641 10.3124 4.49804 10.3124 4.75V8.55H14.0624C14.3111 8.55 14.5495 8.65009 14.7254 8.82825C14.9012 9.00641 14.9999 9.24804 14.9999 9.5C14.9999 9.75196 14.9012 9.99359 14.7254 10.1718C14.5495 10.3499 14.3111 10.45 14.0624 10.45H10.3124V14.25C10.3124 14.502 10.2137 14.7436 10.0378 14.9218C9.86202 15.0999 9.62356 15.2 9.37492 15.2C9.12628 15.2 8.88782 15.0999 8.71201 14.9218C8.53619 14.7436 8.43742 14.502 8.43742 14.25V10.45H4.6874C4.43876 10.45 4.2003 10.3499 4.02449 10.1718C3.84867 9.99359 3.7499 9.75196 3.7499 9.5C3.7499 9.24804 3.84867 9.00641 4.02449 8.82825C4.2003 8.65009 4.43876 8.55 4.6874 8.55H8.43742Z'
        fill='#FFB800'
      />
      <path
        d='M22.5 8.55C22.5 7.79413 22.7963 7.06922 23.3237 6.53475C23.8512 6.00027 24.5666 5.7 25.3125 5.7H27.1875C27.9334 5.7 28.6488 6.00027 29.1762 6.53475C29.7037 7.06922 30 7.79413 30 8.55V10.45C30 11.2059 29.7037 11.9308 29.1762 12.4653C28.6488 12.9997 27.9334 13.3 27.1875 13.3H25.3125C24.5666 13.3 23.8512 12.9997 23.3237 12.4653C22.7963 11.9308 22.5 11.2059 22.5 10.45V8.55Z'
        fill='#FFB800'
      />
      <path d='M18.7031 8.55005H22.5V10.45H18.7031V8.55005Z' fill='#73B3AB' />
      <path
        d='M8.43748 4.75005V8.55005H4.68746C4.43882 8.55005 4.20036 8.65014 4.02454 8.8283C3.84873 9.00646 3.74996 9.24809 3.74996 9.50005C3.74996 9.752 3.84873 9.99364 4.02454 10.1718C4.20036 10.35 4.43882 10.45 4.68746 10.45H8.43748V14.25C8.43748 14.502 8.53625 14.7436 8.71206 14.9218C8.88788 15.1 9.12634 15.2 9.37498 15.2C9.62362 15.2 9.86208 15.1 10.0379 14.9218C10.2137 14.7436 10.3125 14.502 10.3125 14.25V10.45H14.0625C14.3111 10.45 14.5496 10.35 14.7254 10.1718C14.9012 9.99364 15 9.752 15 9.50005C15 9.24809 14.9012 9.00646 14.7254 8.8283C14.5496 8.65014 14.3111 8.55005 14.0625 8.55005H10.3125V4.75005C10.3125 4.49809 10.2137 4.25646 10.0379 4.0783C9.86208 3.90014 9.62362 3.80005 9.37498 3.80005C9.12634 3.80005 8.88788 3.90014 8.71206 4.0783C8.53625 4.25646 8.43748 4.49809 8.43748 4.75005Z'
        fill='white'
      />
    </>
  ),
});
