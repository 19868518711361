import React from 'react';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
} from '@chakra-ui/react';

export function ErrorMessage() {
  return (
    <Box p={5}>
      <Alert
        status='error'
        variant='subtle'
        flexDirection='column'
        justifyContent='center'
        textAlign='center'
        height='200px'>
        <AlertIcon boxSize='40px' mr={0} />
        <AlertTitle mt={4} mb={1} fontSize='lg'>
          ¡Ha ocurrido un error!
        </AlertTitle>
        <AlertDescription maxWidth='sm'>
          No se ha podido cargar los datos. <br />
          <b>Recarga la página e intenta nuevamente</b>
        </AlertDescription>
      </Alert>
    </Box>
  );
}
