import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const SelectionMultipleIcon = createIcon({
  displayName: 'SelectionMultipleIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg fill='none'>
      <path
        d='M12.1607 23.3173C18.3247 23.3173 23.3207 18.3226 23.3207 12.1586C23.3207 5.99459 18.3247 0.999928 12.1607 0.999928C5.99673 0.999928 1.00073 5.99459 1.00073 12.1586C1.00073 18.3226 5.99673 23.3173 12.1607 23.3173Z'
        stroke='#F75266'
        strokeWidth='1.33333'
      />
      <path
        d='M12.1606 19.8173C16.39 19.8173 19.8193 16.3879 19.8193 12.1586C19.8193 7.92926 16.39 4.49993 12.1606 4.49993C7.93129 4.49993 4.50195 7.92926 4.50195 12.1586C4.50195 16.3879 7.93129 19.8173 12.1606 19.8173Z'
        fill='#F75266'
      />
    </svg>
  ),
});
