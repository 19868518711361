export function sortAlpha(list, name) {
  return list.sort(function (a, b) {
    const nameA = a[name].toUpperCase();
    const nameB = b[name].toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
}
