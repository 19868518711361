import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const MapIcon = createIcon({
  displayName: 'MapIcon',
  viewBox: '0 0 27 27',
  path: (
    <>
      <path
        d='M0 5.70001H21V24.96H2.67C1.96268 24.9573 1.28509 24.6752 0.784935 24.175C0.284783 23.6749 0.00263436 22.9973 0 22.29V5.70001Z'
        fill='#A3D8B2'
      />
      ,
      <path
        d='M21.01 19.22H15.16L11.49 13.69H2.83V11.6H12.61L16.29 17.13H21.01V19.22Z'
        fill='white'
      />
      ,
      <path
        d='M0 22.25V5.13C0 4.4537 0.26866 3.80506 0.746878 3.32684C1.2251 2.84863 1.8737 2.58002 2.55 2.58002H4.11V20.11H2.11C1.82768 20.0966 1.54572 20.1431 1.2827 20.2466C1.01968 20.3501 0.781586 20.5081 0.584105 20.7103C0.386623 20.9125 0.234243 21.1543 0.137009 21.4197C0.0397748 21.6851 -0.000103885 21.9681 0.0200005 22.25'
        fill='#33A854'
      />
      ,
      <path
        d='M14.49 4.64996C14.49 5.13255 14.3469 5.6043 14.0788 6.00555C13.8107 6.40681 13.4296 6.71956 12.9837 6.90424C12.5379 7.08891 12.0473 7.13724 11.574 7.04309C11.1007 6.94894 10.6659 6.71655 10.3247 6.37531C9.98342 6.03407 9.75103 5.59935 9.65688 5.12604C9.56273 4.65272 9.61105 4.1621 9.79573 3.71625C9.98041 3.2704 10.2932 2.88933 10.6944 2.62122C11.0957 2.35311 11.5674 2.20996 12.05 2.20996C12.6971 2.20996 13.3178 2.46703 13.7753 2.92462C14.2329 3.38221 14.49 4.00284 14.49 4.64996'
        fill='white'
      />
      ,
      <path
        d='M12.11 6.54999C11.7144 6.54999 11.3278 6.43271 10.9989 6.21295C10.67 5.99319 10.4136 5.68082 10.2622 5.31537C10.1109 4.94992 10.0713 4.54775 10.1484 4.15979C10.2256 3.77183 10.4161 3.41551 10.6958 3.1358C10.9755 2.8561 11.3319 2.66561 11.7198 2.58844C12.1078 2.51127 12.5099 2.55083 12.8754 2.70221C13.2408 2.85358 13.5532 3.10994 13.7729 3.43884C13.9927 3.76774 14.11 4.15443 14.11 4.54999C14.11 5.08042 13.8993 5.58916 13.5242 5.96423C13.1491 6.33931 12.6404 6.54999 12.11 6.54999ZM16.8 4.70001C16.8 3.45349 16.3048 2.25801 15.4234 1.37659C14.542 0.495166 13.3465 0 12.1 0C10.8535 0 9.65802 0.495166 8.7766 1.37659C7.89518 2.25801 7.4 3.45349 7.4 4.70001C7.39349 5.64522 7.69153 6.56741 8.25 7.33002V7.33002L11.77 12.49C11.8024 12.5354 11.8451 12.5724 11.8947 12.598C11.9443 12.6235 11.9992 12.6368 12.055 12.6368C12.1108 12.6368 12.1657 12.6235 12.2153 12.598C12.2649 12.5724 12.3076 12.5354 12.34 12.49L15.96 7.23999L16.02 7.14996C16.4991 6.42346 16.7499 5.57023 16.74 4.70001'
        fill='#F75266'
      />
    </>
  ),
});
