import {ReactNode} from 'react';

import {Grid, GridItem} from '@chakra-ui/react';

export type MainLayoutProps = {
  sidebar: ReactNode;
  content: ReactNode;
};

export function SidebarLayout(props: MainLayoutProps) {
  return (
    <Grid
      h='100vh'
      templateRows='auto auto'
      templateColumns='auto 4fr'
      columnGap={0}
      rowGap={2}>
      <GridItem rowSpan={1} colSpan={1}>
        {props.sidebar}
      </GridItem>
      <GridItem colSpan={1} overflowX='auto'>
        {props.content}
      </GridItem>
    </Grid>
  );
}
