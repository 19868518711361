import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const AnalitysIcon = createIcon({
  displayName: 'AnalitysIcon',
  viewBox: '0 0 33 33',
  path: (
    <>
      <rect y='15' width='7' height='18' rx='2' fill='#F55266' />
      <rect x='13' y='8' width='7' height='25' rx='2' fill='#3885FE' />
      <rect x='26' width='7' height='33' rx='2' fill='#FFB800' />
    </>
  ),
});
