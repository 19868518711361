import {call, put, takeEvery} from 'redux-saga/effects';

import * as api from '../services/formsService';
import {
  addForm,
  addFormError,
  addFormSuccess,
  disableForm,
  disableFormError,
  disableFormSuccess,
  getForm,
  getFormError,
  getFormSuccess,
  updateForm,
  updateFormAccess,
  updateFormError,
} from '../slices/formSlices/formSlice';

export function* getWorker({payload}) {
  try {
    const result = yield call(api.getForms);
    yield put(getFormSuccess(result));
  } catch (e: any) {
    yield put(getFormError(e.toString()));
  }
}

export function* addWorker({payload}) {
  try {
    yield call(api.createForm, payload);
    yield put(addFormSuccess(payload));
  } catch (e: any) {
    yield put(addFormError(e.toString()));
  }
}

export function* updateWorker({payload}) {
  try {
    yield call(api.updateForm, payload);
    yield put(updateFormAccess(payload));
  } catch (e: any) {
    yield put(updateFormError(e.toString()));
  }
}

export function* disableWorker({payload}) {
  try {
    yield call(api.disableForm, payload);
    yield put(disableFormSuccess(payload));
  } catch (e: any) {
    yield put(disableFormError(e.toString()));
  }
}

export default function* rootSaga() {
  yield takeEvery(getForm, getWorker);
  yield takeEvery(addForm, addWorker);
  yield takeEvery(updateForm, updateWorker);
  yield takeEvery(disableForm, disableWorker);
}
