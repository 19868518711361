import {instance} from './index';

export async function getDocuments(data) {
  return instance()
    .get(`/drives/${data.driveName}/files/${data.path}`)
    .then(response => response.data);
}

export async function getFile(data) {
  return instance()
    .get(`/drives/${data.driveName}/files/${data.path}?temp-url=true`)
    .then(response => response.data);
}
