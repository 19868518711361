import React from 'react';

import {IconButton} from '@chakra-ui/react';

import {FaEdit} from 'react-icons/fa';

export function EditButtonTable({cell, onClick}) {
  return (
    <IconButton
      colorScheme='grayBlue'
      variant='outline'
      size='sm'
      icon={<FaEdit />}
      aria-label='Editar'
      onClick={() => onClick(cell.row.original)}
    />
  );
}
