import React from 'react';

import {Flex, Spinner} from '@chakra-ui/react';

export function LoaderSpinner() {
  return (
    <Flex
      justifyContent='center'
      alignItems='center'
      h='50vh'
      m='auto'
      w='100%'>
      <Spinner size='xl' />
    </Flex>
  );
}
