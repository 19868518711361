import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const KpisIcon = createIcon({
  displayName: 'KpisIcon',
  viewBox: '0 0 41 25',
  path: (
    <>
      <path
        d='M26.7272 14.2364C28.3787 15.888 29.3066 18.128 29.3066 20.4636H41C41 17.7702 40.4683 15.1032 39.4353 12.6157C38.4024 10.1282 36.8885 7.86906 34.9806 5.9679L26.7272 14.2364Z'
        fill='#33A854'
      />
      <path
        d='M5.99359 5.99377C2.15595 9.83142 0 15.0364 0 20.4636H11.6934C11.6934 18.128 12.6212 15.888 14.2728 14.2364L5.99359 5.99377Z'
        fill='#F55266'
      />
      <path
        d='M20.4999 11.657C22.8356 11.657 25.0756 12.5848 26.7271 14.2364L34.9805 5.96785C33.0726 4.06669 30.8081 2.56087 28.3169 1.53679C25.8257 0.512713 23.1569 -0.00948899 20.4634 0.000130519L20.4999 11.657Z'
        fill='#FFB800'
      />
      <path
        d='M20.4635 0.00012207C15.0362 0.00012207 9.83127 2.15609 5.99362 5.99374L14.2728 14.2364C15.9244 12.5848 18.1644 11.657 20.5 11.657L20.4635 0.00012207Z'
        fill='#F6704D'
      />
      <path
        d='M20.4635 0.00012207C15.0362 0.00012207 9.83127 2.15609 5.99362 5.99374L14.2728 14.2364C15.9244 12.5848 18.1644 11.657 20.5 11.657L20.4635 0.00012207Z'
        fill='#F6704D'
      />
      <path
        d='M19.7693 20.6464L15.8301 0.541016L21.1944 20.3102L19.7693 20.6464Z'
        fill='white'
      />
      <path
        d='M24.5637 20.4782C24.5637 21.2862 24.3241 22.0761 23.8752 22.7479C23.4263 23.4198 22.7882 23.9434 22.0417 24.2526C21.2952 24.5618 20.4738 24.6427 19.6813 24.4851C18.8888 24.3275 18.1608 23.9384 17.5895 23.367C17.0181 22.7957 16.629 22.0677 16.4714 21.2752C16.3138 20.4828 16.3947 19.6613 16.7039 18.9148C17.0131 18.1683 17.5367 17.5303 18.2086 17.0814C18.8804 16.6324 19.6703 16.3928 20.4783 16.3928C21.5618 16.3928 22.6009 16.8233 23.3671 17.5894C24.1333 18.3556 24.5637 19.3947 24.5637 20.4782Z'
        fill='#73B3AB'
      />
    </>
  ),
});
