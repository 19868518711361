import React from 'react';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from '@chakra-ui/react';

import {ChevronRightIcon} from '@chakra-ui/icons';

export function UserStack({parentStack, onDrillUp, data}) {
  const onDrillUpHandler = id => {
    if (onDrillUp) {
      onDrillUp(id);
    }
  };

  const crums = parentStack
    // eslint-disable-next-line react/prop-types
    .map(parentId => data.find(user => user.id === parentId))
    .map(user => (
      <BreadcrumbItem key={user?.id}>
        <BreadcrumbLink onClick={() => onDrillUpHandler(user?.id)}>
          <Text fontSize={14}> {user?.name}</Text>
          <Text fontSize={10}>{user?.rol}</Text>
        </BreadcrumbLink>
      </BreadcrumbItem>
    ));

  return (
    <Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
      {crums}
    </Breadcrumb>
  );
}
