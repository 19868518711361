import Accordion from './accordion';
import Alert from './alert';
import Avatar from './avatar';
import Breadcrumb from './breadcrumb';
import Button from './button';
import Calendar from './calendar';
import Checkbox from './checkbox';
import CloseButton from './close-button';
import Divider from './divider';
import Drawer from './drawer';
import Editable from './editable';
import Input from './input';
import Menu from './menu';
import Modal from './modal';
import NumberInput from './number-input';
import Popover from './popover';
import Radio from './radio';
import Select from './select';
import SideBar from './sideBar';
import Switch from './switch';
import Table from './table';
import Tag from './tag';
import Textarea from './textarea';
import Tooltip from './tooltip';

const components = {
  Button,
  Input,
  Alert,
  Modal,
  Popover,
  Tooltip,
  Checkbox,
  Radio,
  Textarea,
  Switch,
  CloseButton,
  Divider,
  Tag,
  Avatar,
  NumberInput,
  Drawer,
  Select,
  Table,
  SideBar,
  Menu,
  Calendar,
  Breadcrumb,
  Accordion,
  Editable,
};

export default components;
