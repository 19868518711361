import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const NumericIcon = createIcon({
  displayName: 'NumericIcon',
  viewBox: '0 0 18 18',
  path: (
    <>
      <path
        d='M8.85996 17.7178C13.7536 17.7178 17.7199 13.7526 17.7199 8.85893C17.7199 3.96531 13.7536 3.05176e-05 8.85996 3.05176e-05C3.96634 3.05176e-05 0 3.96531 0 8.85893C0 13.7526 3.96634 17.7178 8.85996 17.7178Z'
        fill='#F75266'
      />
      <path
        d='M11.4805 2.57057L9.38461 15.146H10.6803L12.7762 2.57057H11.4805ZM3.15405 11.6211H13.7288V10.4588H3.15405V11.6211ZM7.02195 2.57057L4.92605 15.146H6.22064L8.3176 2.57057H7.02195ZM3.99241 7.23872H14.5672V6.07539H3.99241V7.23872Z'
        fill='white'
      />
    </>
  ),
});
