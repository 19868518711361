import React from 'react';

import Select from 'react-select';

export const MultiSelect = props => {
  /* eslint-disable react/prop-types */
  const valOptions = !props.enableAll
    ? props.options
    : [{label: 'Todos', value: 'all'}, ...props.options];
  const options = props.multi ? valOptions : props.options;
  const validateChange = selected => {
    return !props.multi
      ? props.handleChange((selected && selected.value) || null)
      : props.handleChange(selected);
  };
  return (
    <Select
      isClearable
      placeholder={props.title}
      options={options}
      isMulti={props.multi}
      value={props.value ? props.value : null}
      onChange={selected => {
        props.multi &&
        selected.length &&
        selected.find(option => option.value === 'all')
          ? props.handleChange(options.slice(1))
          : validateChange(selected);
      }}
      components={{
        IndicatorSeparator: () => null,
      }}
      styles={{
        option: provided => ({
          ...provided,
          justifyContent: 'left',
        }),
        control: provided => ({
          ...provided,
          size: 'sm',
          borderRadius: '8px',
          height: 'auto',
        }),
        menu: provided => ({
          ...provided,
          fontSize: 'sm',
          color: 'black',
        }),
      }}
    />
  );
};
