import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';

const date = new Date();

export interface PaginationWeek {
  type: string;
}

export interface PaginationWeekState {
  data: PaginationWeek;
}

const initialState = {data: {type: date.toString()}} as PaginationWeekState;
const paginationWeekSlice = createSlice({
  name: 'paginationWeek',
  initialState,
  reducers: {
    addPaginationWeek(state, action: PayloadAction<PaginationWeek>) {
      state.data = action.payload;
    },
  },
});

export const getPaginationWeekSelector = (state: RootState) =>
  state.paginationWeek.data;

export default paginationWeekSlice.reducer;
export const {addPaginationWeek} = paginationWeekSlice.actions;
