import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const CheckboxIcon = createIcon({
  displayName: 'CheckboxIcon',
  viewBox: '0 0 32 32',
  path: (
    <svg fill='none'>
      <path d='M0 32H32V0H0V32Z' fill='#F75266' />
      <path
        d='M4.2804 16.041L12.4497 23.2263L27.6551 7.88232'
        stroke='white'
        strokeWidth='2.66667'
      />
    </svg>
  ),
});
