import React, {ReactNode} from 'react';

import {Flex} from '@chakra-ui/react';

interface HeaderLayoutProps {
  header: ReactNode;
  children?: ReactNode;
}

export function HeaderLayout(props: HeaderLayoutProps) {
  return (
    <>
      <Flex
        justifyContent='end'
        w='100%'
        h='135px'
        alignItems='center'
        borderStyle='solid'
        borderBottom='1px'
        borderBottomColor='#dedede'>
        {props.header}
      </Flex>
      {props.children}
    </>
  );
}
