type Dict = Record<string, any>;

const baseStyle = {
  lineHeight: '1.2',
  borderRadius: '40px',
  fontWeight: 'semibold',
  height: '48px',
  paddingLeft: '38px',
  paddingRight: '38px',
  paddingTop: '15px',
  fontSize: '18px',
  paddingBottom: '15px',
  _focus: {
    boxShadow: 'outline',
  },
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
    boxShadow: 'none',
  },
  _hover: {
    _disabled: {
      bg: 'initial',
    },
  },
};

function variantSolid(props: Dict) {
  const {colorScheme: c} = props;

  if (c === 'gray') {
    const bg = 'gray.100';
    return {
      bg,
      _hover: {
        bg: 'gray.200',
        _disabled: {
          bg,
        },
      },
      _active: 'gray.300',
    };
  }

  const {
    bg = `${c}.500`,
    color = 'white',
    hoverBg = `${c}.400`,
    activeBg = `${c}.500`,
  } = accessibleColorMap[c] || {};

  const background = bg;

  return {
    bg: background,
    color: color,
    _hover: {
      bg: hoverBg,
      _disabled: {
        bg: background,
      },
    },
    _active: {
      bg: activeBg,
    },
  };
}

function variantOutline(props: Dict) {
  const {colorScheme: c} = props;
  const borderColor = 'gray.200';

  return {
    border: '2px solid',
    borderColor: c === 'gray' ? borderColor : `${c}.500`,
    //...variantGhost(props),
    color: `${c}.500`,
    bg: 'gray.50',
    _hover: {
      bg: `${c}.500`,
      color: 'gray.50',
      _disabled: {
        bg: 'gray.50',
        color: `${c}.500`,
      },
    },
    _active: {
      bg: `${c}.400`,
    },
  };
}

function variantGhost(props: Dict) {
  const {colorScheme: c} = props;

  if (c === 'gray') {
    return {
      color: 'inherit',
      _hover: {
        bg: 'gray.100',
      },
      _active: {bg: 'gray.200'},
    };
  }
  return {
    color: `${c}.500`,
    bg: 'transparent',
    _hover: {
      bg: `${c}.50`,
    },
    _active: {
      bg: `${c}.100`,
    },
  };
}

function variantLink(props: Dict) {
  const {colorScheme: c} = props;
  return {
    padding: 0,
    height: 'auto',
    lineHeight: 'normal',
    verticalAlign: 'baseline',
    borderRadius: 0,
    color: `${c}.500`,
    _hover: {
      textDecoration: 'underline',
      _disabled: {
        textDecoration: 'none',
      },
    },
    _active: {
      color: `${c}.700`,
    },
  };
}

const variantUnstyled = {
  bg: 'none',
  color: 'inherit',
  display: 'inline',
  lineHeight: 'inherit',
  m: 0,
  p: 0,
};

type AccessibleColor = {
  bg?: string;
  color?: string;
  hoverBg?: string;
  activeBg?: string;
};

/** Accessible color overrides for less accessible colors. */
const accessibleColorMap: {[key: string]: AccessibleColor} = {
  yellow: {
    bg: 'yellow.400',
    color: 'black',
    hoverBg: 'yellow.500',
    activeBg: 'yellow.600',
  },
  cyan: {
    bg: 'cyan.400',
    color: 'black',
    hoverBg: 'cyan.500',
    activeBg: 'cyan.600',
  },
};

const variants = {
  ghost: variantGhost,
  outline: variantOutline,
  solid: variantSolid,
  link: variantLink,
  unstyled: variantUnstyled,
};

const sizes = {
  lg: {
    h: 10,
    minW: 10,
    fontSize: 'md',
    px: 4,
  },
  md: {
    h: 8,
    minW: 8,
    fontSize: 'sm',
    px: 3,
  },
  sm: {
    h: 6,
    minW: 6,
    fontSize: 'xs',
    px: 2,
  },
};

const defaultProps = {
  variant: 'solid',
  size: 'md',
  colorScheme: 'gray',
};

const button = {
  baseStyle,
  variants,
  sizes,
  defaultProps,
};

export default button;
