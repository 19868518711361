import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const FacturasIcon = createIcon({
  displayName: 'FacturasIcon',
  viewBox: '0 0 32 35',
  path: (
    <>
      <path
        d='M10.2431 7.00657H0V3.01294C0.00341544 2.21495 0.365152 1.4505 1.00635 0.886229C1.64755 0.321957 2.51623 0.00361603 3.42302 0.000610366H5.12156C6.48591 0.00051656 7.79536 0.473539 8.76616 1.31718C9.73695 2.16082 10.2909 3.30713 10.3079 4.5077L10.2431 7.00657Z'
        fill='#FFD970'
      />
      <path
        d='M4.64178 0.000281145V34.2314H6.21066C6.21066 33.6624 6.46748 33.1168 6.92462 32.7145C7.38176 32.3122 8.00178 32.0862 8.64827 32.0862C9.29476 32.0862 9.91477 32.3122 10.3719 32.7145C10.8291 33.1168 11.0859 33.6624 11.0859 34.2314H12.6547C12.6547 33.6624 12.9116 33.1168 13.3687 32.7145C13.8258 32.3122 14.4459 32.0862 15.0924 32.0862C15.7388 32.0862 16.3589 32.3122 16.816 32.7145C17.2731 33.1168 17.5299 33.6624 17.5299 34.2314H19.0988C19.0988 33.6624 19.3556 33.1168 19.8128 32.7145C20.2699 32.3122 20.8899 32.0862 21.5364 32.0862C22.1829 32.0862 22.8029 32.3122 23.2601 32.7145C23.7172 33.1168 23.974 33.6624 23.974 34.2314H25.5429C25.5429 33.6624 25.7997 33.1168 26.2569 32.7145C26.714 32.3122 27.334 32.0862 27.9805 32.0862C28.627 32.0862 29.247 32.3122 29.7042 32.7145C30.1613 33.1168 30.4181 33.6624 30.4181 34.2314H32V2.07697C31.9983 1.80082 31.9344 1.52769 31.812 1.27341C31.6895 1.01914 31.511 0.788767 31.2867 0.595627C31.0624 0.402487 30.7968 0.250413 30.5053 0.148221C30.2137 0.0460289 29.9021 -0.00424998 29.5883 0.000281145H4.64178Z'
        fill='#5EB5AB'
      />
      <path
        d='M4.64185 3.86848V2.59052C4.64185 2.59052 4.64185 0.21716 2.69696 0.0688256C2.86771 0.0349129 3.04106 0.012034 3.2156 0.000366211C3.44899 0.000366211 3.48788 0.000366211 3.64347 0.000366211H5.13456L7.06649 2.3509L4.64185 3.86848Z'
        fill='#5EB5AB'
      />
      <path
        d='M22.9109 10.1784C22.9109 10.984 22.6394 11.7716 22.1308 12.4415C21.6222 13.1114 20.8992 13.6335 20.0534 13.9418C19.2076 14.2501 18.2769 14.3308 17.379 14.1736C16.4811 14.0164 15.6563 13.6284 15.009 13.0588C14.3616 12.4891 13.9207 11.7632 13.7421 10.9731C13.5635 10.1829 13.6552 9.36384 14.0055 8.6195C14.3559 7.87517 14.9492 7.23898 15.7104 6.79138C16.4716 6.34378 17.3665 6.10487 18.282 6.10487C18.8904 6.10337 19.4931 6.2077 20.0555 6.41188C20.6178 6.61606 21.1288 6.91605 21.559 7.29461C21.9891 7.67317 22.33 8.12283 22.562 8.61773C22.794 9.11263 22.9126 9.643 22.9109 10.1784Z'
        fill='#73B3AB'
        stroke='white'
        strokeWidth='3'
        strokeMiterlimit='10'
      />
      <path
        d='M26.1653 19.7631H10.3857C10.1917 19.7413 10.0134 19.658 9.88419 19.5288C9.75497 19.3996 9.68369 19.2334 9.68369 19.0613C9.68369 18.8892 9.75497 18.723 9.88419 18.5939C10.0134 18.4647 10.1917 18.3814 10.3857 18.3596H26.1653C26.2785 18.3469 26.3935 18.3555 26.5027 18.3849C26.6119 18.4144 26.7126 18.4639 26.7983 18.5303C26.884 18.5967 26.9527 18.6784 26.9997 18.77C27.0467 18.8615 27.071 18.9609 27.071 19.0613C27.071 19.1618 27.0467 19.2611 26.9997 19.3527C26.9527 19.4442 26.884 19.5259 26.7983 19.5923C26.7126 19.6587 26.6119 19.7083 26.5027 19.7377C26.3935 19.7672 26.2785 19.7758 26.1653 19.7631'
        fill='white'
      />
      <path
        d='M26.1653 25.6166H10.3857C10.176 25.6166 9.9748 25.5432 9.82647 25.4127C9.67815 25.2822 9.59482 25.1051 9.59482 24.9205C9.59482 24.7359 9.67815 24.5589 9.82647 24.4284C9.9748 24.2978 10.176 24.2245 10.3857 24.2245H26.1653C26.3751 24.2245 26.5763 24.2978 26.7246 24.4284C26.8729 24.5589 26.9562 24.7359 26.9562 24.9205C26.9562 25.1051 26.8729 25.2822 26.7246 25.4127C26.5763 25.5432 26.3751 25.6166 26.1653 25.6166'
        fill='white'
      />
    </>
  ),
});
