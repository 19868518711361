import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';

export interface PlanState {
  state?: boolean;
}

export interface PlanStates {
  data: PlanState;
}

const initialState = {data: {status: false}} as PlanStates;
const planStateSlice = createSlice({
  name: 'planState',
  initialState,
  reducers: {
    getPlanState(state, action: PayloadAction<PlanState>) {
      state.data = action.payload;
    },
  },
});

export const getPlanStateSelector = (state: RootState) => state.planState.data;

export default planStateSlice.reducer;
export const {getPlanState} = planStateSlice.actions;
