import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const DateTimeIcon = createIcon({
  displayName: 'DateTimeIcon',
  viewBox: '0 0 36 36',
  path: (
    <>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29 34C30.3261 34 31.5979 33.4732 32.5355 32.5355C33.4732 31.5979 34 30.3261 34 29C34 27.6739 33.4732 26.4021 32.5355 25.4645C31.5979 24.5268 30.3261 24 29 24C27.6739 24 26.4021 24.5268 25.4645 25.4645C24.5268 26.4021 24 27.6739 24 29C24 30.3261 24.5268 31.5979 25.4645 32.5355C26.4021 33.4732 27.6739 34 29 34ZM29 36C30.8565 36 32.637 35.2625 33.9497 33.9497C35.2625 32.637 36 30.8565 36 29C36 27.1435 35.2625 25.363 33.9497 24.0503C32.637 22.7375 30.8565 22 29 22C27.1435 22 25.363 22.7375 24.0503 24.0503C22.7375 25.363 22 27.1435 22 29C22 30.8565 22.7375 32.637 24.0503 33.9497C25.363 35.2625 27.1435 36 29 36V36Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29 25.5C29.2652 25.5 29.5196 25.6054 29.7071 25.7929C29.8946 25.9804 30 26.2348 30 26.5V28.586L30.707 29.293C30.8892 29.4816 30.99 29.7342 30.9877 29.9964C30.9854 30.2586 30.8802 30.5094 30.6948 30.6948C30.5094 30.8802 30.2586 30.9854 29.9964 30.9877C29.7342 30.99 29.4816 30.8892 29.293 30.707L28 29.414V26.5C28 26.2348 28.1054 25.9804 28.2929 25.7929C28.4804 25.6054 28.7348 25.5 29 25.5Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 17H6V19H8V17ZM6 15C5.46957 15 4.96086 15.2107 4.58579 15.5858C4.21071 15.9609 4 16.4696 4 17V19C4 19.5304 4.21071 20.0391 4.58579 20.4142C4.96086 20.7893 5.46957 21 6 21H8C8.53043 21 9.03914 20.7893 9.41421 20.4142C9.78929 20.0391 10 19.5304 10 19V17C10 16.4696 9.78929 15.9609 9.41421 15.5858C9.03914 15.2107 8.53043 15 8 15H6Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 17H14V19H16V17ZM14 15C13.4696 15 12.9609 15.2107 12.5858 15.5858C12.2107 15.9609 12 16.4696 12 17V19C12 19.5304 12.2107 20.0391 12.5858 20.4142C12.9609 20.7893 13.4696 21 14 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V17C18 16.4696 17.7893 15.9609 17.4142 15.5858C17.0391 15.2107 16.5304 15 16 15H14Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 17H22V19H24V17ZM22 15C21.4696 15 20.9609 15.2107 20.5858 15.5858C20.2107 15.9609 20 16.4696 20 17V19C20 19.5304 20.2107 20.0391 20.5858 20.4142C20.9609 20.7893 21.4696 21 22 21H24C24.5304 21 25.0391 20.7893 25.4142 20.4142C25.7893 20.0391 26 19.5304 26 19V17C26 16.4696 25.7893 15.9609 25.4142 15.5858C25.0391 15.2107 24.5304 15 24 15H22Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 25H6V27H8V25ZM6 23C5.46957 23 4.96086 23.2107 4.58579 23.5858C4.21071 23.9609 4 24.4696 4 25V27C4 27.5304 4.21071 28.0391 4.58579 28.4142C4.96086 28.7893 5.46957 29 6 29H8C8.53043 29 9.03914 28.7893 9.41421 28.4142C9.78929 28.0391 10 27.5304 10 27V25C10 24.4696 9.78929 23.9609 9.41421 23.5858C9.03914 23.2107 8.53043 23 8 23H6Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 25H14V27H16V25ZM14 23C13.4696 23 12.9609 23.2107 12.5858 23.5858C12.2107 23.9609 12 24.4696 12 25V27C12 27.5304 12.2107 28.0391 12.5858 28.4142C12.9609 28.7893 13.4696 29 14 29H16C16.5304 29 17.0391 28.7893 17.4142 28.4142C17.7893 28.0391 18 27.5304 18 27V25C18 24.4696 17.7893 23.9609 17.4142 23.5858C17.0391 23.2107 16.5304 23 16 23H14Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 6C2 5.73478 2.10536 5.48043 2.29289 5.29289C2.48043 5.10536 2.73478 5 3 5H8V3H3C2.20435 3 1.44129 3.31607 0.87868 3.87868C0.316071 4.44129 0 5.20435 0 6V30C0 30.7956 0.316071 31.5587 0.87868 32.1213C1.44129 32.6839 2.20435 33 3 33H23.255C22.8285 32.3892 22.5028 31.7139 22.29 31H3C2.73478 31 2.48043 30.8946 2.29289 30.7071C2.10536 30.5196 2 30.2652 2 30V6ZM28 22.07C28.6633 21.9751 29.3367 21.9751 30 22.07V6C30 5.20435 29.6839 4.44129 29.1213 3.87868C28.5587 3.31607 27.7956 3 27 3H24V5H27C27.2652 5 27.5196 5.10536 27.7071 5.29289C27.8946 5.48043 28 5.73478 28 6V22.07ZM10 5H20.563V3H10V5Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22 3H10V6C10 6.26522 9.89464 6.51957 9.70711 6.70711C9.51957 6.89464 9.26522 7 9 7C8.73478 7 8.48043 6.89464 8.29289 6.70711C8.10536 6.51957 8 6.26522 8 6V3H3C2.46957 3 1.96086 3.21071 1.58579 3.58579C1.21071 3.96086 1 4.46957 1 5V10C1 10.5304 1.21071 11.0391 1.58579 11.4142C1.96086 11.7893 2.46957 12 3 12H27C27.5304 12 28.0391 11.7893 28.4142 11.4142C28.7893 11.0391 29 10.5304 29 10V5C29 4.46957 28.7893 3.96086 28.4142 3.58579C28.0391 3.21071 27.5304 3 27 3H24V6C24 6.26522 23.8946 6.51957 23.7071 6.70711C23.5196 6.89464 23.2652 7 23 7C22.7348 7 22.4804 6.89464 22.2929 6.70711C22.1054 6.51957 22 6.26522 22 6V3Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30 12H2V10H30V12Z'
        fill='currentColor'
      />
      <path
        d='M6 1C6 0.734784 6.10536 0.48043 6.29289 0.292893C6.48043 0.105357 6.73478 0 7 0C7.26522 0 7.51957 0.105357 7.70711 0.292893C7.89464 0.48043 8 0.734784 8 1V5C8 5.26522 7.89464 5.51957 7.70711 5.70711C7.51957 5.89464 7.26522 6 7 6C6.73478 6 6.48043 5.89464 6.29289 5.70711C6.10536 5.51957 6 5.26522 6 5V1Z'
        fill='currentColor'
      />
      <path
        d='M20 1C20 0.734784 20.1054 0.48043 20.2929 0.292893C20.4804 0.105357 20.7348 0 21 0C21.2652 0 21.5196 0.105357 21.7071 0.292893C21.8946 0.48043 22 0.734784 22 1V5C22 5.26522 21.8946 5.51957 21.7071 5.70711C21.5196 5.89464 21.2652 6 21 6C20.7348 6 20.4804 5.89464 20.2929 5.70711C20.1054 5.51957 20 5.26522 20 5V1Z'
        fill='currentColor'
      />
    </>
  ),
});
