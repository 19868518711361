import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const CerrarSesionIcon = createIcon({
  displayName: 'CerrarSesionIcon',
  viewBox: '0 0 71 71',
  path: (
    <>
      <path
        d='M60.3187 10.3489C74.1173 24.1476 74.1173 46.5196 60.3187 60.3182C46.5187 74.1169 24.148 74.1169 10.348 60.3182C-3.44933 46.5196 -3.44933 24.1476 10.348 10.3489C24.148 -3.44976 46.5187 -3.44976 60.3187 10.3489Z'
        fill='#F75266'
      />
      <path
        d='M49.0843 21.5833C56.6763 29.1767 56.6763 41.4887 49.0843 49.0833C41.4909 56.6767 29.1776 56.6767 21.5843 49.0833C13.9909 41.4887 13.9909 29.1767 21.5843 21.5833C29.1776 13.99 41.4909 13.99 49.0843 21.5833Z'
        fill='#5CC9BA'
      />
      <path
        d='M41.0998 29.5663C44.2852 32.7517 44.2852 37.9143 41.0998 41.0997C37.9145 44.2837 32.7518 44.2837 29.5678 41.0997C26.3825 37.9143 26.3825 32.7517 29.5678 29.5663C32.7518 26.381 37.9145 26.381 41.0998 29.5663Z'
        fill='#F75266'
      />
      <path
        d='M40.4983 8.90692V21.3643C40.4983 21.8403 40.113 22.2256 39.637 22.2256H30.1557C29.6823 22.2256 29.297 21.8403 29.297 21.3643V8.90692C29.297 8.43225 29.6823 8.04559 30.1557 8.04559H39.637C40.113 8.04559 40.4983 8.43225 40.4983 8.90692Z'
        fill='#5CC9BA'
      />
    </>
  ),
});
