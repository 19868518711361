import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';

export interface EventCodeState {
  event: any;
}

export interface EventCodeStates {
  data: EventCodeState;
}

const initialState = {data: {event: []}} as EventCodeStates;
const eventCodeSlice = createSlice({
  name: 'eventCode',
  initialState,
  reducers: {
    getEventCode(state, action: PayloadAction<EventCodeState>) {
      state.data = action.payload;
    },
  },
});

export const getEventCodeSelector = (state: RootState) => state.eventCode.data;

export default eventCodeSlice.reducer;
export const {getEventCode} = eventCodeSlice.actions;
