import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const AdministrationIcon = createIcon({
  displayName: 'AdministrationIcon',
  viewBox: '0 0 29 34',
  path: (
    <>
      <path
        d='M14.3165 0L0 6.01653V15.0413C0 23.3893 6.10835 31.1957 14.3165 33.0909C22.5246 31.1957 28.6329 23.3893 28.6329 15.0413V6.01653L14.3165 0ZM14.3165 5.86612C15.2603 5.86612 16.1829 6.13076 16.9677 6.62659C17.7525 7.12242 18.3642 7.82716 18.7253 8.65169C19.0865 9.47623 19.181 10.3835 18.9969 11.2588C18.8128 12.1342 18.3583 12.9382 17.6909 13.5693C17.0235 14.2003 16.1732 14.6301 15.2475 14.8042C14.3218 14.9783 13.3622 14.889 12.4902 14.5474C11.6182 14.2059 10.8729 13.6275 10.3486 12.8855C9.82419 12.1434 9.5443 11.271 9.5443 10.3785C9.5443 9.18175 10.0471 8.034 10.942 7.18777C11.837 6.34153 13.0508 5.86612 14.3165 5.86612ZM14.3165 17.7488C17.4979 17.7488 23.8608 19.3883 23.8608 22.3815C22.816 23.8708 21.3978 25.0924 19.7324 25.9375C18.0671 26.7826 16.2065 27.2249 14.3165 27.2249C12.4265 27.2249 10.5658 26.7826 8.9005 25.9375C7.23516 25.0924 5.81689 23.8708 4.77215 22.3815C4.77215 19.3883 11.135 17.7488 14.3165 17.7488Z'
        fill='#3885FE'
      />
      <path
        d='M14.3165 5.86612C15.2603 5.86612 16.1829 6.13076 16.9677 6.62659C17.7525 7.12242 18.3642 7.82716 18.7253 8.65169C19.0865 9.47623 19.181 10.3835 18.9969 11.2588C18.8128 12.1342 18.3583 12.9382 17.6909 13.5693C17.0235 14.2003 16.1732 14.6301 15.2475 14.8042C14.3218 14.9783 13.3622 14.889 12.4902 14.5474C11.6182 14.2059 10.8729 13.6275 10.3486 12.8855C9.82419 12.1434 9.5443 11.271 9.5443 10.3785C9.5443 9.18175 10.0471 8.034 10.942 7.18777C11.837 6.34153 13.0508 5.86612 14.3165 5.86612Z'
        fill='#3885FE'
      />
      <path
        d='M14.3165 17.7488C17.4979 17.7488 23.8608 19.3883 23.8608 22.3815C22.816 23.8708 21.3978 25.0924 19.7324 25.9375C18.0671 26.7826 16.2065 27.2249 14.3165 27.2249C12.4265 27.2249 10.5658 26.7826 8.9005 25.9375C7.23516 25.0924 5.81689 23.8708 4.77215 22.3815C4.77215 19.3883 11.135 17.7488 14.3165 17.7488Z'
        fill='#3885FE'
      />
      <path
        d='M14.3165 5.86609C15.2603 5.86609 16.183 6.13074 16.9677 6.62656C17.7525 7.12239 18.3642 7.82713 18.7254 8.65167C19.0865 9.4762 19.1811 10.3835 18.9969 11.2588C18.8128 12.1341 18.3583 12.9382 17.6909 13.5692C17.0235 14.2003 16.1732 14.6301 15.2475 14.8042C14.3218 14.9783 13.3622 14.8889 12.4902 14.5474C11.6182 14.2059 10.8729 13.6275 10.3486 12.8854C9.82419 12.1434 9.54431 11.271 9.54431 10.3785C9.54431 9.18172 10.0471 8.03398 10.942 7.18774C11.837 6.3415 13.0508 5.86609 14.3165 5.86609Z'
        fill='#07213F'
      />
      <path
        d='M14.3165 17.7487C17.4979 17.7487 23.8608 19.3882 23.8608 22.3815C22.816 23.8707 21.3978 25.0924 19.7324 25.9375C18.0671 26.7826 16.2065 27.2248 14.3165 27.2248C12.4265 27.2248 10.5658 26.7826 8.9005 25.9375C7.23517 25.0924 5.81689 23.8707 4.77216 22.3815C4.77216 19.3882 11.135 17.7487 14.3165 17.7487Z'
        fill='#07213F'
      />
    </>
  ),
});
