import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const JerarquiaUsuariosIcon = createIcon({
  displayName: 'JerarquiaUsuariosIcon',
  viewBox: '0 0 31 40',
  path: (
    <>
      <path
        d='M12.1382 30.1669C11.2331 28.8985 9.87179 27.9859 8.30867 27.5997H4.98611C3.41957 27.9826 2.05417 28.8942 1.14596 30.1634C0.23776 31.4326 -0.150862 32.9724 0.0529751 34.4939C0.256813 36.0154 1.03911 37.4141 2.25316 38.4278C3.46721 39.4415 5.02962 40.0005 6.64739 40C8.26272 39.9965 9.82144 39.4355 11.0323 38.4219C12.2431 37.4082 13.0232 36.0113 13.2267 34.4921C13.4302 32.9729 13.0433 31.4354 12.1382 30.1669Z'
        fill='#FFD25C'
      />
      <path
        d='M29.8585 30.1669C28.9534 28.8985 27.5921 27.9859 26.029 27.5997H22.7064C21.1399 27.9826 19.7745 28.8942 18.8663 30.1634C17.9581 31.4326 17.5695 32.9724 17.7733 34.4939C17.9771 36.0154 18.7594 37.4141 19.9735 38.4278C21.1875 39.4415 22.7499 40.0005 24.3677 40C25.983 39.9965 27.5418 39.4355 28.7526 38.4219C29.9634 37.4082 30.7435 36.0113 30.947 34.4921C31.1505 32.9729 30.7636 31.4354 29.8585 30.1669Z'
        fill='#FFD25C'
      />
      <path
        d='M17.1688 21.1004V16.4595L15.5076 17.062L13.8463 16.4595V21.1004H9.96995C8.64816 21.1004 7.3805 21.5982 6.44585 22.4843C5.5112 23.3703 4.98611 24.5721 4.98611 25.8253V27.5997H8.30867V25.8253C8.30867 25.4076 8.4837 25.007 8.79525 24.7116C9.1068 24.4162 9.52936 24.2503 9.96995 24.2503H21.0452C21.4857 24.2503 21.9083 24.4162 22.2199 24.7116C22.5314 25.007 22.7064 25.4076 22.7064 25.8253V27.5997H26.029V25.8253C26.029 24.5721 25.5039 23.3703 24.5693 22.4843C23.6346 21.5982 22.3669 21.1004 21.0452 21.1004H17.1688Z'
        fill='#73B3AB'
      />
      <circle cx='15.5' cy='9' r='9' fill='#FFD25C' />
      <path
        d='M15.5871 4.84692C15.9814 4.84692 16.3668 4.95901 16.6946 5.169C17.0225 5.379 17.278 5.67747 17.4289 6.02668C17.5797 6.37589 17.6192 6.76015 17.5423 7.13087C17.4654 7.50159 17.2755 7.84212 16.9967 8.10939C16.7179 8.37667 16.3627 8.55868 15.976 8.63242C15.5893 8.70616 15.1885 8.66832 14.8242 8.52367C14.4599 8.37902 14.1486 8.13407 13.9295 7.81979C13.7105 7.50551 13.5935 7.13602 13.5935 6.75803C13.5935 6.25118 13.8036 5.76508 14.1774 5.40667C14.5513 5.04827 15.0584 4.84692 15.5871 4.84692Z'
        fill='#F55266'
      />
      <path
        d='M15.5871 9.87951C16.9161 9.87951 19.5741 10.5739 19.5741 11.8416C19.1377 12.4723 18.5452 12.9897 17.8496 13.3476C17.1539 13.7056 16.3766 13.8929 15.5871 13.8929C14.7975 13.8929 14.0203 13.7056 13.3246 13.3476C12.6289 12.9897 12.0364 12.4723 11.6 11.8416C11.6 10.5739 14.2581 9.87951 15.5871 9.87951Z'
        fill='#F55266'
      />
      <path
        d='M24.5449 30.6309C24.8078 30.6309 25.0648 30.711 25.2833 30.861C25.5019 31.011 25.6722 31.2242 25.7728 31.4736C25.8734 31.723 25.8997 31.9975 25.8484 32.2623C25.7972 32.5271 25.6706 32.7703 25.4847 32.9613C25.2988 33.1522 25.062 33.2822 24.8042 33.3348C24.5464 33.3875 24.2792 33.3605 24.0364 33.2572C23.7935 33.1538 23.5859 32.9789 23.4399 32.7544C23.2939 32.5299 23.2159 32.266 23.2159 31.996C23.2159 31.634 23.3559 31.2867 23.6052 31.0307C23.8544 30.7747 24.1925 30.6309 24.5449 30.6309Z'
        fill='#F55266'
      />
      <path
        d='M24.5449 34.2256C25.431 34.2256 27.203 34.7216 27.203 35.6271C26.912 36.0776 26.5171 36.4472 26.0533 36.7029C25.5895 36.9585 25.0713 37.0923 24.5449 37.0923C24.0186 37.0923 23.5004 36.9585 23.0366 36.7029C22.5728 36.4472 22.1779 36.0776 21.8869 35.6271C21.8869 34.7216 23.6589 34.2256 24.5449 34.2256Z'
        fill='#F55266'
      />
      <path
        d='M6.47021 30.6309C6.73307 30.6309 6.99002 30.711 7.20858 30.861C7.42713 31.011 7.59748 31.2242 7.69807 31.4736C7.79866 31.723 7.82498 31.9975 7.7737 32.2623C7.72242 32.5271 7.59584 32.7703 7.40997 32.9613C7.22411 33.1522 6.9873 33.2822 6.72949 33.3348C6.47169 33.3875 6.20446 33.3605 5.96162 33.2572C5.71877 33.1538 5.5112 32.9789 5.36517 32.7544C5.21913 32.5299 5.14119 32.266 5.14119 31.996C5.14119 31.634 5.28121 31.2867 5.53045 31.0307C5.77969 30.7747 6.11773 30.6309 6.47021 30.6309Z'
        fill='#F55266'
      />
      <path
        d='M6.47021 34.2256C7.35623 34.2256 9.12826 34.7216 9.12826 35.6271C8.8373 36.0776 8.44232 36.4472 7.97853 36.7029C7.51475 36.9585 6.99657 37.0923 6.47021 37.0923C5.94385 37.0923 5.42568 36.9585 4.96189 36.7029C4.4981 36.4472 4.10312 36.0776 3.81216 35.6271C3.81216 34.7216 5.5842 34.2256 6.47021 34.2256Z'
        fill='#F55266'
      />
    </>
  ),
});
