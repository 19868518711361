import {useQuery} from '@tanstack/react-query';

import * as UserInfoApi from '../services/userinfoService';

export const useUserInfo = () => {
  const query = useQuery(['userInfo'], UserInfoApi.getUserInfo, {
    refetchOnWindowFocus: false,
  });

  return {
    ...query,
    data: query.data || [],
  };
};
