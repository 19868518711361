function baseStyle(props: Record<string, any>) {
  const {colorScheme} = props;
  return {
    content: '"X"',
    bg: 'gray.100',
    color: 'gray.400',
    opacity: 0.4,
    borderRadius: '50%',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    _disabled: {
      opacity: 0.5,
      cursor: 'not-allowed',
      boxShadow: 'none',
    },
    _hover: {bg: `${colorScheme}.100`},
    _active: {bg: `${colorScheme}.200`},
    _focus: {
      boxShadow: 'outline',
    },
  };
}

const sizes = {
  lg: {
    w: '40px',
    h: '40px',
    fontSize: '16px',
  },
  md: {
    w: '32px',
    h: '32px',
    fontSize: '12px',
  },
  sm: {
    w: '24px',
    h: '24px',
    fontSize: '10px',
  },
};

const defaultProps = {
  size: 'md',
};

export default {
  baseStyle,
  sizes,
  defaultProps,
};
