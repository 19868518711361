import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const CalendarizacionIcon = createIcon({
  displayName: 'CalendarizacionIcon',
  viewBox: '0 0 34 35',
  path: (
    <>
      <path
        d='M24.3165 1C24.3165 0.447716 24.7642 0 25.3165 0H26.4256C26.9779 0 27.4256 0.447715 27.4256 1V4.48341C27.4256 5.0357 26.9779 5.48341 26.4256 5.48341H25.3165C24.7642 5.48341 24.3165 5.0357 24.3165 4.48342V1Z'
        fill='#3885FE'
      />
      <path
        d='M6.36517 1C6.36517 0.447716 6.81289 0 7.36517 0H8.47792C9.03021 0 9.47792 0.447715 9.47792 1V4.48341C9.47792 5.0357 9.03021 5.48341 8.47792 5.48341H7.36517C6.81289 5.48341 6.36517 5.0357 6.36517 4.48342V1Z'
        fill='#3885FE'
      />
      <path
        d='M23.1494 17.8115C22.9031 17.8115 22.6671 17.9043 22.4764 18.0601C21.7487 18.6543 20.8851 19.1595 19.8857 19.5762C19.496 19.7386 19.2294 20.1117 19.2294 20.5339V20.7395C19.2294 21.3998 19.8605 21.8793 20.4665 21.617C20.587 21.5649 20.7062 21.5102 20.8243 21.453C21.5765 21.0886 22.5692 21.5849 22.5692 22.4208V29.7966C22.5692 30.3489 23.0169 30.7966 23.5692 30.7966H24.1912C24.7435 30.7966 25.1912 30.3489 25.1912 29.7966V18.8115C25.1912 18.2592 24.7435 17.8115 24.1912 17.8115L23.1494 17.8115Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 13.3282C0 12.7759 0.447715 12.3282 1 12.3282H32.7937C33.346 12.3282 33.7937 12.7759 33.7937 13.3282V34C33.7937 34.5523 33.346 35 32.7937 35H1C0.447716 35 0 34.5523 0 34V13.3282ZM9.62552 21.2256C9.06412 21.2256 8.59477 20.7536 8.75856 20.2166C9.31241 18.401 11.2443 17.5773 13.2747 17.5677C14.9948 17.5834 16.6228 18.3469 17.2699 19.7691C17.5743 20.6187 17.7367 21.5251 17.3578 22.3161C16.9637 23.0741 17.0005 24.8329 17.4689 25.5474C17.7601 25.9916 17.9216 26.5215 17.9255 27.139C17.9127 27.6816 17.7732 28.2153 17.5166 28.7034C17.2599 29.1916 16.8923 29.6226 16.4387 29.9671C15.5065 30.6487 14.3794 31.0304 13.2747 31.0374C10.5866 31.0374 9.0583 30.0694 8.68978 28.1345C8.58645 27.592 9.04967 27.1391 9.60196 27.1391H10.4042C10.9357 27.1391 11.343 27.5723 11.5522 28.0609C11.8123 28.6688 12.3253 29.0696 13.1209 29.0797C14.5842 28.9875 15.1633 28.0981 15.1754 26.9595C15.1747 26.2719 14.9028 25.5684 14.2562 25.3034C13.5009 25.0131 12.6659 25.0159 11.9114 25.0184C11.7825 25.0189 11.6781 24.9145 11.6781 24.7856V24.0399C11.6781 23.4913 12.1301 23.0604 12.6759 23.0049C13.7703 22.8936 14.8354 22.5024 14.8458 21.3847C14.7941 20.3073 14.3177 19.5389 13.1868 19.5253C12.4208 19.5681 11.8743 19.8395 11.5748 20.2887C11.2711 20.7442 10.868 21.2256 10.3206 21.2256H9.62552ZM19.2294 20.5339C19.2294 20.1117 19.496 19.7386 19.8857 19.5762C20.8851 19.1595 21.7487 18.6543 22.4764 18.0601C22.6671 17.9043 22.9031 17.8115 23.1494 17.8115L24.1912 17.8115C24.7435 17.8115 25.1912 18.2592 25.1912 18.8115V29.7966C25.1912 30.3489 24.7435 30.7966 24.1912 30.7966H23.5692C23.0169 30.7966 22.5692 30.3489 22.5692 29.7966V22.4208C22.5692 21.5849 21.5765 21.0886 20.8243 21.453C20.7062 21.5102 20.587 21.5649 20.4665 21.617C19.8605 21.8793 19.2294 21.3998 19.2294 20.7395V20.5339Z'
        fill='white'
      />
      <path
        d='M13.2747 17.5677C11.2443 17.5773 9.31241 18.401 8.75856 20.2166C8.59477 20.7536 9.06412 21.2256 9.62552 21.2256H10.3206C10.868 21.2256 11.2711 20.7442 11.5748 20.2887C11.8743 19.8395 12.4208 19.5681 13.1868 19.5253C14.3177 19.5389 14.7941 20.3073 14.8458 21.3847C14.8354 22.5024 13.7703 22.8936 12.6759 23.0049C12.1301 23.0604 11.6781 23.4913 11.6781 24.0399V24.7856C11.6781 24.9145 11.7825 25.0189 11.9114 25.0184C12.6659 25.0159 13.5009 25.0131 14.2562 25.3034C14.9028 25.5684 15.1747 26.2719 15.1754 26.9595C15.1633 28.0981 14.5842 28.9875 13.1209 29.0797C12.3253 29.0696 11.8123 28.6688 11.5522 28.0609C11.343 27.5723 10.9357 27.1391 10.4042 27.1391H9.60196C9.04967 27.1391 8.58645 27.592 8.68978 28.1345C9.0583 30.0694 10.5866 31.0374 13.2747 31.0374C14.3794 31.0304 15.5065 30.6487 16.4387 29.9671C16.8923 29.6226 17.2599 29.1916 17.5166 28.7034C17.7732 28.2153 17.9127 27.6816 17.9255 27.139C17.9216 26.5215 17.7601 25.9916 17.4689 25.5474C17.0005 24.8329 16.9637 23.0741 17.3578 22.3161C17.7367 21.5251 17.5743 20.6187 17.2699 19.7691C16.6228 18.3469 14.9948 17.5834 13.2747 17.5677Z'
        fill='white'
      />
      <path
        d='M22.7669 17.8116C21.8352 18.6463 20.6559 19.3186 19.2294 19.8302V22.052C20.4521 21.7154 21.5648 21.1494 22.5692 20.3551V30.7967H25.1912V17.8115L22.7669 17.8116Z'
        fill='#0E3663'
      />
      <path
        d='M13.2747 17.5677C10.9004 17.579 8.66071 18.7034 8.60196 21.2256H11.3083C11.3207 20.2252 11.9822 19.5926 13.1868 19.5254C14.3177 19.5389 14.7941 20.3073 14.8458 21.3848C14.8322 22.8497 13.0066 23.0666 11.6781 23.0409V25.0189C11.7549 25.019 11.8327 25.0187 11.9114 25.0185C12.6659 25.0159 13.5009 25.0131 14.2562 25.3034C14.9028 25.5684 15.1747 26.2719 15.1754 26.9596C15.1633 28.0981 14.5842 28.9875 13.1209 29.0798C11.97 29.0651 11.4106 28.233 11.3522 27.1391H8.60196C8.60196 29.7375 10.1596 31.0374 13.2747 31.0374C14.3794 31.0304 15.5065 30.6487 16.4387 29.9671C16.8923 29.6227 17.2599 29.1916 17.5166 28.7035C17.7732 28.2153 17.9127 27.6817 17.9255 27.139C17.9148 25.4662 16.7483 24.4369 14.9775 24.0095C15.921 23.7034 16.8834 23.2286 17.3578 22.3161C17.7367 21.5252 17.5743 20.6188 17.2699 19.7692C16.6228 18.347 14.9948 17.5834 13.2747 17.5677Z'
        fill='#0E3663'
      />
      <path
        d='M4.46627e-05 3.63494C4.83565e-05 3.08266 0.447763 2.63495 1.00004 2.63495H3.14183C3.69412 2.63495 4.14183 3.08266 4.14183 3.63495V6.53918C4.14183 7.09146 4.58955 7.53918 5.14183 7.53918H10.7003C11.2526 7.53918 11.7003 7.09146 11.7003 6.53918V3.63495C11.7003 3.08266 12.148 2.63495 12.7003 2.63495H21.0897C21.642 2.63495 22.0897 3.08266 22.0897 3.63495V6.53918C22.0897 7.09146 22.5375 7.53918 23.0897 7.53918H28.6519C29.2042 7.53918 29.6519 7.09146 29.6519 6.53918V3.63495C29.6519 3.08266 30.0996 2.63495 30.6519 2.63495H32.7937C33.346 2.63495 33.7937 3.08266 33.7937 3.63495V9.31275C33.7937 9.86503 33.346 10.3127 32.7937 10.3127H1.00001C0.447721 10.3127 2.99445e-06 9.86503 6.68827e-06 9.31274L4.46627e-05 3.63494Z'
        fill='#F55266'
      />
    </>
  ),
});
