import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';

export interface CalendarType {
  type: 'month' | 'week' | 'day';
}

export interface CalendarTypeState {
  data: CalendarType[];
  error: string | null;
}

const initialState = {
  data: [{type: 'month'}],
  error: null,
} as CalendarTypeState;
const calendarTypeSlice = createSlice({
  name: 'calendarType',
  initialState,
  reducers: {
    addCalendarType(state, action) {
      const x = '';
    },
    addCalendarTypeSuccess(state, action) {
      const newData = [] as any;
      newData.push(action.payload);
      state.data = newData;
    },
    addCalendarTypeError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export const getCalendarTypeSelector = (state: RootState) =>
  state.calendarType.data;
export const getErrorSelector = state => state.calendarType.error;

export default calendarTypeSlice.reducer;
export const {addCalendarType, addCalendarTypeSuccess, addCalendarTypeError} =
  calendarTypeSlice.actions;
