import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const HapProcessLogo = createIcon({
  displayName: 'HapProcessLogo',
  viewBox: '0 0 996 353',
  path: (
    <svg fill='none'>
      <path
        d='M404.937 129.739C410.619 129.739 415.224 125.324 415.224 119.878C415.224 114.433 410.619 110.017 404.937 110.017C399.256 110.017 394.651 114.433 394.651 119.878C394.651 125.324 399.256 129.739 404.937 129.739Z'
        fill='#F7F7F7'
      />
      <path
        d='M525.785 173.957C525.669 176.179 525.516 177.722 525.514 179.265C525.496 195.906 525.416 212.547 525.571 229.184C525.604 232.74 524.638 234.143 520.674 234.073C508.876 233.858 497.068 233.917 485.266 234.042C481.688 234.079 480.071 233.228 480.079 229.348C480.192 172.506 480.187 115.666 480.095 58.8239C480.087 55.1763 481.287 53.953 485.117 54.0151C496.918 54.2076 508.726 54.2155 520.523 54.0087C524.318 53.9419 525.914 55.0204 525.541 58.7285C525.32 60.9173 525.499 63.1444 525.499 66.0968C527.2 65.066 528.168 64.6285 528.948 63.9827C543.633 51.8326 560.657 49.6326 578.913 53.522C596.786 57.3302 610.435 67.0862 619.505 82.2412C628.5 97.2737 630.961 113.611 628.693 130.759C626.696 145.857 620.943 159.184 609.816 170.216C590.419 189.444 557.149 194.064 534.913 180.517C531.929 178.699 529.233 176.453 525.785 173.957ZM524.897 119.613C524.615 137.413 536.509 149.453 554.528 149.609C572.008 149.762 584.132 138.073 584.528 120.686C584.93 103.126 572.745 90.6435 555.021 90.4574C537.256 90.2713 525.176 101.965 524.897 119.613Z'
        fill='#3854FE'
      />
      <path
        d='M45.7423 68.2862C47.3619 67.0422 48.3724 66.4282 49.1756 65.6217C58.6373 56.1329 70.3061 51.857 83.8781 51.8681C105.445 51.884 121.84 60.0016 130.276 79.7602C132.657 85.3389 134.648 91.5157 134.776 97.4572C135.385 125.807 135.271 154.172 135.478 182.53C135.5 185.193 134.298 185.961 131.705 185.947C119.204 185.874 106.701 185.829 94.201 185.969C90.6566 186.009 90.1157 184.37 90.124 181.533C90.1903 160.493 90.1937 139.454 90.1323 118.415C90.114 112.148 89.6958 105.939 86.2128 100.3C81.4239 92.5529 73.7758 89.3396 62.9866 90.7506C55.7699 91.6939 48.6811 98.1794 47.0897 105.875C46.1505 110.412 45.52 115.087 45.4835 119.705C45.3126 140.21 45.3308 160.718 45.4818 181.222C45.5067 184.833 44.4812 186.079 40.5601 186.003C28.6209 185.769 16.6684 185.805 4.72592 185.985C1.02385 186.041 -0.00496017 184.79 1.7956e-05 181.35C0.0879648 124.492 0.0846456 67.6339 0.00665497 10.7761C0.00167685 7.40054 0.964114 6.00069 4.70766 6.05318C16.7896 6.22021 28.8748 6.21227 40.9584 6.05478C44.458 6.00865 45.4884 7.21124 45.4636 10.4611C45.3441 27.9036 45.4005 45.3477 45.4221 62.7917C45.4237 64.3332 45.5963 65.8746 45.7423 68.2862Z'
        fill='#3854FE'
      />
      <path
        d='M362.307 173.742V179.349C362.307 195.99 362.204 212.632 362.383 229.272C362.42 232.933 361.272 234.131 357.428 234.068C345.489 233.87 333.543 233.905 321.603 234.045C317.985 234.088 316.812 232.862 316.817 229.384C316.907 172.538 316.91 115.691 316.817 58.8444C316.81 55.1841 318.027 53.9608 321.848 54.0197C333.787 54.1994 345.736 54.2185 357.676 54.0069C361.446 53.9385 362.646 55.2334 362.34 58.6233C362.139 60.8312 362.302 63.0694 362.302 66.157C364.066 64.9974 365.096 64.4374 365.986 63.7184C380.52 51.9612 397.312 49.5863 415.254 53.4295C436.488 57.9758 451.422 70.2755 459.976 89.3389C465.905 102.547 467.367 116.443 465.47 130.755C463.467 145.859 457.65 159.123 446.6 170.225C427.447 189.466 393.362 194.115 371.324 180.309C368.493 178.535 365.916 176.386 362.307 173.742ZM421.318 119.907C421.25 102.47 408.907 90.3188 391.406 90.4572C373.556 90.5972 361.706 102.367 361.658 119.994C361.615 137.791 373.704 149.707 391.701 149.607C409.33 149.507 421.386 137.421 421.318 119.907Z'
        fill='#FA714D'
      />
      <path
        d='M253.394 174.782C247.188 180.321 240.106 184.231 231.896 186.307C205.623 192.951 177.075 182.322 162.728 160.384C151.276 142.87 149.383 123.748 153.001 103.911C156.198 86.3766 165.019 71.869 180.842 62.1671C201.62 49.4268 223.288 47.7104 245.343 59.3674C248.31 60.9327 250.846 63.2424 254.496 65.864C254.689 63.015 255.05 60.877 254.926 58.7661C254.73 55.4414 255.805 53.9621 259.605 54.0177C271.82 54.1991 284.041 54.1625 296.258 54.032C299.386 54.0002 300.348 55.1153 300.345 57.993C300.288 99.3873 300.283 140.782 300.365 182.174C300.371 185.249 299.112 185.992 296.162 185.963C283.945 185.85 271.724 185.831 259.509 185.971C256.096 186.009 254.68 184.936 254.926 181.608C255.087 179.446 254.77 177.252 254.669 175.073C254.242 174.976 253.819 174.879 253.394 174.782ZM225.942 90.4473C208.628 90.4425 195.869 102.998 195.857 120.054C195.844 137.123 208.57 149.617 225.96 149.607C243.767 149.596 256.217 137.307 256.124 119.836C256.03 102.624 243.531 90.4537 225.942 90.4473Z'
        fill='#FA714D'
      />
      <path
        d='M851.559 232.722C855.368 224.262 859.067 216.002 862.802 207.757C865.947 200.817 869.239 193.935 872.216 186.931C872.828 185.488 872.888 183.398 872.276 181.973C862.466 159.144 852.501 136.377 842.581 113.593C834.472 94.9751 826.364 76.3555 818.303 57.7168C817.855 56.6828 817.83 55.4802 817.487 53.7463H826.228C836.926 53.7463 847.622 53.8703 858.315 53.6747C861.626 53.6126 863.25 54.6689 864.41 57.6261C871.328 75.2706 878.435 92.8483 885.461 110.455C888.033 116.903 890.519 123.383 893.137 129.812C893.554 130.83 894.47 131.662 895.693 133.299C898.7 125.75 901.399 119.01 904.073 112.258C911.445 93.6436 918.763 75.0097 926.265 56.4426C926.735 55.2845 928.595 53.899 929.823 53.8846C943.572 53.724 957.324 53.8083 971.073 53.8481C971.465 53.8481 971.853 54.0899 972.945 54.4525C970.466 60.3478 968.06 66.2113 965.543 72.0302C952.278 102.687 938.998 133.337 925.699 163.98C916.017 186.287 906.326 208.591 896.501 230.84C895.98 232.025 894.208 233.471 893.003 233.486C879.811 233.653 866.616 233.562 853.422 233.514C853.045 233.513 852.672 233.207 851.559 232.722Z'
        fill='#3854FE'
      />
      <path
        d='M773.882 137.617C773.881 152.126 773.796 166.634 773.942 181.141C773.979 184.652 773.061 186.089 769.045 186.004C757.249 185.761 745.441 185.762 733.642 186.003C729.464 186.087 728.538 184.563 728.553 180.885C728.674 152.136 728.623 123.385 728.616 94.6352C728.616 88.5968 728.601 88.5968 722.394 88.5777C719.34 88.5697 716.285 88.5395 713.23 88.5634C710.826 88.5825 709.655 87.5246 709.661 85.1831C709.686 75.8645 709.691 66.5475 709.666 57.2306C709.661 55.0544 710.72 54.0809 712.962 54.0968C716.71 54.1238 720.467 53.9632 724.207 54.1207C727.375 54.2527 728.636 53.1583 728.712 50.0054C728.905 42.1631 729.577 34.4162 732.59 26.9222C737.363 15.0553 745.958 7.09677 758.467 3.41103C772.802 -0.81398 787.37 -1.19894 801.944 2.73178C805.87 3.78963 807.991 5.63011 807.807 10.011C807.465 18.1174 807.602 26.2477 807.757 34.3637C807.818 37.6247 806.434 37.9078 803.436 37.6883C797.983 37.2922 792.399 36.9232 787.026 37.6279C779.834 38.5728 775.85 43.3116 774.519 50.2249C773.96 53.1344 774.924 54.1238 777.868 54.1016C786.059 54.0348 794.256 54.2352 802.442 54.0188C806.333 53.9155 807.908 55.0051 807.785 58.9549C807.529 67.1997 807.576 75.4604 807.768 83.7084C807.851 87.2987 806.627 88.7463 802.754 88.635C795.54 88.4282 788.316 88.5745 781.096 88.5777C773.887 88.5825 773.884 88.5841 773.884 95.6899C773.882 109.665 773.882 123.641 773.882 137.617Z'
        fill='#3854FE'
      />
      <path
        d='M647.577 120.13C647.577 99.7738 647.658 79.4155 647.506 59.0588C647.477 55.2808 648.647 53.9334 652.706 54.0082C664.498 54.2245 676.301 54.1911 688.094 54.0193C691.622 53.9684 692.994 54.8529 692.986 58.467C692.881 99.5813 692.894 140.696 692.972 181.808C692.977 184.872 692.061 186.021 688.719 185.978C676.51 185.823 664.292 185.83 652.079 185.971C648.652 186.011 647.499 184.904 647.519 181.601C647.637 161.111 647.577 140.621 647.577 120.13Z'
        fill='#3854FE'
      />
      <path
        d='M670.441 43.3842C664.195 43.3842 657.949 43.3047 651.705 43.4192C648.714 43.4749 647.491 42.4537 647.531 39.4694C647.661 29.6259 647.639 19.7793 647.546 9.93419C647.52 7.22675 648.555 6.07983 651.427 6.09415C664.057 6.15937 676.687 6.16096 689.316 6.08937C692.031 6.07506 692.968 7.1329 692.947 9.66536C692.867 19.7761 692.859 29.8884 692.952 39.9992C692.977 42.6684 691.754 43.4526 689.177 43.4145C682.932 43.3206 676.687 43.3842 670.441 43.3842Z'
        fill='#3854FE'
      />
      <path
        d='M226.332 89.7683C209.02 89.7635 196.261 102.319 196.248 119.375C196.235 136.444 208.962 148.938 226.351 148.926C244.159 148.917 256.609 136.628 256.513 119.157C256.42 101.945 243.923 89.7747 226.332 89.7683Z'
        fill='white'
      />
      <path
        d='M391.876 89.7683C374.562 89.7635 361.805 102.319 361.792 119.375C361.778 136.444 374.506 148.938 391.894 148.926C409.703 148.917 422.153 136.628 422.055 119.157C421.964 101.945 409.465 89.7747 391.876 89.7683Z'
        fill='white'
      />
      <path
        d='M240.575 129.739C246.258 129.739 250.861 125.324 250.861 119.878C250.861 114.433 246.258 110.017 240.575 110.017C234.893 110.017 230.29 114.433 230.29 119.878C230.29 125.324 234.893 129.739 240.575 129.739Z'
        fill='#3854FE'
      />
      <path
        d='M406.119 129.739C411.8 129.739 416.405 125.324 416.405 119.878C416.405 114.433 411.8 110.017 406.119 110.017C400.437 110.017 395.832 114.433 395.832 119.878C395.832 125.324 400.437 129.739 406.119 129.739Z'
        fill='#3854FE'
      />
      <path
        d='M316.786 335.072C377.521 333.039 420.585 295.243 423.178 233.287'
        stroke='#FDD772'
        strokeWidth='35.5093'
        strokeMiterlimit='10'
      />
      <path
        d='M733.33 289.092C735.517 289.092 737.236 288.318 738.479 286.767C739.722 285.217 740.344 282.774 740.344 279.437C740.344 276.099 739.733 273.656 738.515 272.105C737.297 270.557 735.593 269.781 733.404 269.781C732.012 269.781 730.794 270.152 729.749 270.891C728.705 271.629 727.897 272.714 727.324 274.144C726.752 275.574 726.467 277.339 726.467 279.437C726.467 281.438 726.727 283.167 727.25 284.621C727.771 286.076 728.556 287.185 729.599 287.947C730.645 288.711 731.888 289.092 733.33 289.092ZM713.784 312.335V261.557H725.199L725.869 274.502L724.377 273.001C724.724 270.616 725.496 268.518 726.691 266.706C727.885 264.894 729.424 263.466 731.317 262.414C733.207 261.366 735.369 260.841 737.807 260.841C740.891 260.841 743.576 261.57 745.865 263.022C748.151 264.477 749.93 266.576 751.199 269.316C752.467 272.059 753.101 275.385 753.101 279.294C753.101 283.204 752.454 286.565 751.161 289.379C749.869 292.191 748.065 294.337 745.752 295.815C743.44 297.293 740.742 298.031 737.657 298.031C734.274 298.031 731.541 297.067 729.45 295.136C727.363 293.205 725.969 290.761 725.273 287.804L726.467 286.088V312.335H713.784Z'
        fill='#FA714D'
      />
      <path
        d='M760.56 297.316V261.558H771.751L772.274 269.281C773.218 266.61 774.624 264.538 776.49 263.058C778.354 261.58 780.728 260.841 783.614 260.841C784.558 260.841 785.366 260.912 786.038 261.055C786.71 261.199 787.243 261.39 787.641 261.628L786.375 271.64C785.876 271.451 785.244 271.297 784.472 271.176C783.7 271.058 782.768 270.998 781.676 270.998C780.181 270.998 778.789 271.308 777.496 271.927C776.202 272.547 775.171 273.465 774.4 274.682C773.628 275.896 773.245 277.434 773.245 279.293V297.316H760.56Z'
        fill='#FA714D'
      />
      <path
        d='M809.949 269.495C808.507 269.495 807.263 269.83 806.219 270.496C805.175 271.166 804.379 272.238 803.831 273.714C803.284 275.194 803.01 277.101 803.01 279.436C803.01 281.773 803.284 283.669 803.831 285.121C804.379 286.577 805.175 287.651 806.219 288.341C807.263 289.031 808.507 289.378 809.949 289.378C811.441 289.378 812.711 289.031 813.754 288.341C814.798 287.651 815.593 286.577 816.142 285.121C816.688 283.669 816.963 281.773 816.963 279.436C816.963 277.101 816.688 275.194 816.142 273.714C815.593 272.238 814.798 271.166 813.754 270.496C812.711 269.83 811.441 269.495 809.949 269.495ZM809.949 260.842C813.877 260.842 817.322 261.57 820.282 263.023C823.241 264.478 825.554 266.599 827.22 269.389C828.886 272.177 829.719 275.528 829.719 279.436C829.719 283.346 828.886 286.696 827.22 289.485C825.554 292.273 823.241 294.395 820.282 295.849C817.322 297.305 813.877 298.03 809.949 298.03C806.118 298.03 802.713 297.305 799.729 295.849C796.744 294.395 794.419 292.273 792.753 289.485C791.087 286.696 790.254 283.346 790.254 279.436C790.254 275.528 791.087 272.177 792.753 269.389C794.419 266.599 796.744 264.478 799.729 263.023C802.713 261.57 806.118 260.842 809.949 260.842Z'
        fill='#FA714D'
      />
      <path
        d='M854.563 260.841C858.341 260.841 861.487 261.353 864 262.379C866.51 263.404 868.478 264.764 869.894 266.455C871.311 268.149 872.195 269.972 872.542 271.927L860.829 275.646C860.48 273.597 859.848 272.059 858.927 271.033C858.006 270.009 856.675 269.495 854.936 269.495C853.343 269.495 852.014 269.842 850.944 270.532C849.875 271.224 849.067 272.32 848.519 273.822C847.972 275.323 847.7 277.245 847.7 279.579C847.7 281.917 847.998 283.812 848.594 285.265C849.191 286.721 850.036 287.769 851.133 288.412C852.225 289.056 853.517 289.378 855.013 289.378C856.254 289.378 857.288 289.152 858.107 288.698C858.927 288.247 859.611 287.59 860.158 286.731C860.706 285.873 861.053 284.825 861.202 283.586L872.617 287.088C872.27 289.187 871.336 291.059 869.819 292.704C868.303 294.347 866.3 295.648 863.814 296.601C861.325 297.554 858.416 298.031 855.086 298.031C851.005 298.031 847.451 297.306 844.417 295.85C841.384 294.396 839.044 292.274 837.403 289.486C835.764 286.696 834.942 283.345 834.942 279.435C834.942 275.527 835.764 272.177 837.403 269.388C839.044 266.598 841.344 264.478 844.303 263.022C847.263 261.57 850.683 260.841 854.563 260.841Z'
        fill='#FA714D'
      />
      <path
        d='M897.386 298.031C893.257 298.031 889.688 297.293 886.68 295.814C883.67 294.337 881.345 292.215 879.705 289.449C878.063 286.684 877.241 283.347 877.241 279.437C877.241 275.528 878.063 272.189 879.705 269.425C881.345 266.658 883.646 264.536 886.605 263.059C889.564 261.581 892.957 260.841 896.789 260.841C900.716 260.841 904.063 261.57 906.823 263.022C909.584 264.478 911.683 266.48 913.127 269.032C914.569 271.582 915.291 274.502 915.291 277.79C915.291 278.649 915.264 279.507 915.216 280.367C915.166 281.225 915.091 281.941 914.992 282.512H885.448V276.002H909.396L903.355 278.222C903.355 275.36 902.819 273.168 901.75 271.641C900.68 270.115 899.027 269.353 896.789 269.353C895.197 269.353 893.842 269.709 892.723 270.425C891.605 271.141 890.757 272.261 890.186 273.787C889.613 275.314 889.328 277.267 889.328 279.65C889.328 281.988 889.652 283.883 890.299 285.339C890.944 286.792 891.865 287.841 893.058 288.483C894.251 289.128 895.693 289.449 897.386 289.449C899.276 289.449 900.781 289.093 901.899 288.377C903.018 287.661 903.851 286.66 904.398 285.373L914.545 288.59C913.849 290.64 912.642 292.37 910.926 293.776C909.211 295.184 907.17 296.243 904.81 296.959C902.447 297.673 899.971 298.031 897.386 298.031Z'
        fill='#FA714D'
      />
      <path
        d='M938.045 298.031C934.315 298.031 930.845 297.447 927.638 296.278C924.43 295.112 921.78 293.431 919.691 291.237L925.66 284.514C926.903 286.042 928.555 287.316 930.62 288.34C932.686 289.368 934.985 289.878 937.523 289.878C939.062 289.878 940.332 289.664 941.328 289.236C942.322 288.805 942.819 288.138 942.819 287.233C942.819 286.565 942.57 286.029 942.074 285.623C941.575 285.218 940.581 284.872 939.089 284.586L933.867 283.586C929.191 282.68 925.872 281.331 923.906 279.543C921.941 277.755 920.959 275.408 920.959 272.5C920.959 270.545 921.581 268.672 922.825 266.886C924.067 265.098 925.982 263.644 928.569 262.523C931.156 261.403 934.464 260.841 938.493 260.841C942.272 260.841 945.604 261.366 948.489 262.414C951.372 263.466 953.66 264.99 955.353 266.993L949.311 273.572C948.114 272.142 946.574 271.022 944.684 270.211C942.793 269.401 940.904 268.995 939.014 268.995C937.821 268.995 936.814 269.102 935.994 269.317C935.173 269.531 934.562 269.829 934.166 270.211C933.767 270.592 933.568 271.07 933.568 271.641C933.568 272.261 933.877 272.799 934.499 273.25C935.121 273.704 936.28 274.097 937.971 274.431L944.759 275.718C948.589 276.386 951.35 277.601 953.041 279.365C954.73 281.129 955.575 283.251 955.575 285.731C955.575 288.067 954.931 290.165 953.637 292.024C952.342 293.884 950.403 295.349 947.817 296.423C945.23 297.495 941.973 298.031 938.045 298.031Z'
        fill='#FA714D'
      />
      <path
        d='M977.809 298.031C974.078 298.031 970.609 297.447 967.401 296.278C964.194 295.112 961.544 293.431 959.455 291.237L965.423 284.514C966.666 286.042 968.319 287.316 970.383 288.34C972.449 289.368 974.749 289.878 977.286 289.878C978.826 289.878 980.095 289.664 981.091 289.236C982.085 288.805 982.583 288.138 982.583 287.233C982.583 286.565 982.334 286.029 981.838 285.623C981.338 285.218 980.344 284.872 978.852 284.586L973.63 283.586C968.954 282.68 965.636 281.331 963.669 279.543C961.705 277.755 960.722 275.408 960.722 272.5C960.722 270.545 961.345 268.672 962.589 266.886C963.83 265.098 965.745 263.644 968.332 262.523C970.919 261.403 974.228 260.841 978.257 260.841C982.035 260.841 985.367 261.366 988.253 262.414C991.135 263.466 993.423 264.99 995.116 266.993L989.074 273.572C987.878 272.142 986.338 271.022 984.448 270.211C982.556 269.401 980.668 268.995 978.778 268.995C977.585 268.995 976.577 269.102 975.758 269.317C974.936 269.531 974.326 269.829 973.929 270.211C973.531 270.592 973.332 271.07 973.332 271.641C973.332 272.261 973.64 272.799 974.263 273.25C974.885 273.704 976.043 274.097 977.734 274.431L984.522 275.718C988.352 276.386 991.113 277.601 992.804 279.365C994.494 281.129 995.338 283.251 995.338 285.731C995.338 288.067 994.694 290.165 993.4 292.024C992.106 293.884 990.166 295.349 987.581 296.423C984.994 297.495 981.736 298.031 977.809 298.031Z'
        fill='#FA714D'
      />
    </svg>
  ),
});
