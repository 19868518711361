import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';

export interface TourState {
  data: any;
  error: string | null;
  isLoading: boolean;
}

const initialState = {data: [], error: null, isLoading: false} as TourState;
const tourSlice = createSlice({
  name: 'tour',
  initialState,
  reducers: {
    getTours(state, action) {
      const x = '';
      state.isLoading = true;
    },
    getToursSuccess(state, action) {
      state.data = action.payload;
      state.isLoading = false;
    },
    getToursError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    addTours(state, action) {
      state.data = [];
    },
  },
});

export const getToursSelector = (state: RootState) => state.tour.data;
export const isTourLoading = state => state.tour.isLoading;
export const getErrorSelector = state => state.tour.error;

export default tourSlice.reducer;
export const {getTours, getToursSuccess, getToursError, addTours} =
  tourSlice.actions;
