import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const AssignsKpisIcon = createIcon({
  displayName: 'AssignsKpisIcon',
  viewBox: '0 0 41 45',
  path: (
    <>
      <path
        d='M25.5071 8.86654C25.5071 13.7492 21.5484 17.7065 16.6671 17.7065C11.7844 17.7065 7.82574 13.7492 7.82574 8.86654C7.82574 3.98388 11.7844 0.0265421 16.6671 0.0265421C21.5484 0.0265421 25.5071 3.98388 25.5071 8.86654Z'
        fill='#5CC9BA'
      />
      <path
        d='M16.6667 -2.5059e-05C11.784 -2.5059e-05 7.82538 3.95864 7.82538 8.84131H25.508C25.508 3.95864 21.5494 -2.5059e-05 16.6667 -2.5059e-05Z'
        fill='#F2F2F5'
      />
      <path
        d='M16.6667 17.6451C7.46133 17.6451 0 25.1064 0 34.3118H33.3333C33.3333 25.1064 25.872 17.6451 16.6667 17.6451Z'
        fill='#F2F2F5'
      />
      <path
        d='M30.2084 36.5393C31.4571 37.788 32.1586 39.4817 32.1586 41.2477H41C41 39.2112 40.598 37.1947 39.817 35.3139C39.036 33.4331 37.8913 31.7249 36.4487 30.2875L30.2084 36.5393Z'
        fill='#33A854'
      />
      <path
        d='M14.5317 30.3069C11.6301 33.2085 10 37.144 10 41.2475H18.8414C18.8414 39.4815 19.5429 37.7879 20.7916 36.5391L14.5317 30.3069Z'
        fill='#F55266'
      />
      <path
        d='M25.5 34.589C27.266 34.589 28.9597 35.2905 30.2084 36.5393L36.4488 30.2874C35.0062 28.85 33.294 27.7114 31.4104 26.9371C29.5268 26.1628 27.5089 25.768 25.4724 25.7752L25.5 34.589Z'
        fill='#FFB800'
      />
      <path
        d='M25.4724 25.7751C21.3689 25.7751 17.4334 27.4053 14.5318 30.3069L20.7917 36.5392C22.0404 35.2904 23.7341 34.5889 25.5001 34.5889L25.4724 25.7751Z'
        fill='#F6704D'
      />
      <path
        d='M25.4724 25.7751C21.3689 25.7751 17.4334 27.4053 14.5318 30.3069L20.7917 36.5392C22.0404 35.2904 23.7341 34.5889 25.5001 34.5889L25.4724 25.7751Z'
        fill='#F6704D'
      />
      <path
        d='M24.9476 41.3858L21.9691 26.1842L26.0251 41.1316L24.9476 41.3858Z'
        fill='white'
      />
      <path
        d='M28.5725 41.2586C28.5725 41.8696 28.3914 42.4668 28.052 42.9748C27.7125 43.4827 27.2301 43.8787 26.6657 44.1124C26.1012 44.3462 25.4802 44.4074 24.881 44.2882C24.2818 44.169 23.7314 43.8749 23.2994 43.4429C22.8674 43.0109 22.5732 42.4605 22.454 41.8613C22.3348 41.2621 22.396 40.641 22.6298 40.0765C22.8636 39.5121 23.2595 39.0297 23.7675 38.6903C24.2755 38.3509 24.8727 38.1697 25.4836 38.1697C26.3029 38.1697 27.0885 38.4951 27.6678 39.0744C28.2471 39.6537 28.5725 40.4394 28.5725 41.2586Z'
        fill='#73B3AB'
      />
    </>
  ),
});
