import colors from './colors';
import sizes from './sizes';
import {spacing} from './spacing';
import typography from './typography';

const foundations = {
  colors,
  typography,
  space: spacing,
  sizes,
};

export default foundations;
