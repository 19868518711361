import {ReactNode} from 'react';

import {Box} from '@chakra-ui/react';

type ScrollableLayout = {
  children: ReactNode;
  scrollY?: boolean;
  scrollX?: boolean;
  w?: string;
  h?: string;
  px?: string;
};

export function ScrollableLayout(props: ScrollableLayout) {
  const {children, scrollX, scrollY, ...rest} = props;
  return (
    <Box
      h={scrollY ? '100vh' : 'auto'}
      w={scrollX ? '100vw' : 'auto'}
      {...rest}>
      {children}
    </Box>
  );
}
