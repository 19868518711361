import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const MarkerStart = createIcon({
  displayName: 'MarkerStart',
  viewBox: '0 0 14 20',
  path: (
    <>
      <ellipse
        cx='7.00435'
        cy='6.90099'
        rx='3.61739'
        ry='3.35167'
        fill='white'
      />
      <path
        d='M13.8667 7.20389C13.8667 3.22558 10.7625 3.46741e-05 6.93382 3.46741e-05C3.1042 3.46741e-05 0 3.22558 0 7.20389C0 8.73722 0.462062 10.0977 1.24728 11.1911L1.24631 11.1891L6.51516 19.0653C6.71677 19.3672 7.14989 19.3672 7.35247 19.0653L12.6937 11.0859C12.7255 11.0392 12.7573 10.9925 12.7882 10.9448L12.7988 10.929H12.7978C13.475 9.88324 13.8667 8.61705 13.8667 7.20389ZM6.94732 10.0163C5.29007 10.0163 3.9473 8.63294 3.9473 6.92682C3.9473 5.2207 5.29007 3.83832 6.94732 3.83832C8.60457 3.83832 9.94831 5.2207 9.94831 6.92682C9.94831 8.63294 8.60457 10.0163 6.94732 10.0163Z'
        fill='#33A854'
      />
    </>
  ),
});
