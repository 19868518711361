import {ReactNode, useEffect, useState} from 'react';

import * as jose from 'jose';
import {JWTPayload} from 'jose';

type ProtectedComponentProps = {
  allowedRoles: string[];
  debugKey?: string;
  children: ReactNode;
};

export function ProtectedComponent(props: ProtectedComponentProps) {
  const {allowedRoles, children, debugKey} = props;
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    setRoles(getUserRoles());
  }, []);

  if (roles.length === 0) {
    if (debugKey) {
      console.debug(`[${debugKey}]`, '⏳ User roles not loaded yet');
    }
    return <></>;
  }

  if (allowedRoles.some(role => roles.includes(role))) {
    if (debugKey) {
      console.debug(
        `[${debugKey}]`,
        '✅ User has one of the required roles',
        allowedRoles,
      );
    }
    return <>{children}</>;
  }
  if (debugKey) {
    console.debug(
      `[${debugKey}]`,
      '❌ User does not have any of the required roles',
      allowedRoles,
    );
  }
  return <></>;
}

type IToken = JWTPayload & {
  realm_access: {
    roles: string[];
  };
  resource_access: {
    [resourceName: string]: {
      roles: string[];
    };
  };
};

export function getUserRoles() {
  const currentToken = localStorage.getItem('react-token');
  if (!currentToken) {
    return [];
  }
  const decodedJWT = jose.decodeJwt(currentToken) as IToken;
  const roles = decodedJWT.realm_access.roles;
  Object.entries(decodedJWT.resource_access).forEach(
    ([resourceName, resource]) => {
      const resourceRoles = resource.roles.map(
        role => `${resourceName}.${role}`,
      );
      roles.push(...resourceRoles);
    },
  );
  return roles;
}
