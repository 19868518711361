import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const HapBaseRed = createIcon({
  displayName: 'HapBaseRed',
  viewBox: '0 0 624 629',
  path: (
    <svg fill='none'>
      <path
        d='M417.395 252.21V263.783C417.394 298.127 417.196 332.474 417.546 366.816C417.623 374.372 415.347 376.844 407.744 376.714C384.122 376.304 360.486 376.38 336.859 376.667C329.704 376.752 327.383 374.227 327.394 367.047C327.57 249.723 327.575 132.399 327.391 15.0748C327.379 7.52014 329.79 4.9975 337.347 5.11615C360.971 5.48816 384.61 5.52817 408.23 5.08951C415.694 4.95084 418.064 7.62283 417.459 14.6188C417.066 19.1762 417.386 23.7935 417.386 30.1695C420.874 27.7762 422.912 26.6188 424.674 25.1321C453.43 0.869476 486.652 -4.03317 522.151 3.89883C564.163 13.2842 593.708 38.6668 610.637 78.0108C622.366 105.271 625.258 133.954 621.505 163.491C617.543 194.663 606.03 222.036 584.169 244.949C546.275 284.664 478.839 294.254 435.236 265.762C429.635 262.1 424.536 257.668 417.395 252.21ZM534.148 141.102C534.017 105.115 509.595 80.0335 474.966 80.3202C439.655 80.6108 416.206 104.9 416.116 141.279C416.026 178.011 439.942 202.605 475.55 202.397C510.432 202.192 534.282 177.245 534.148 141.102Z'
        fill='#F85165'
      />
      <path
        d='M201.912 254.355C189.632 265.789 175.62 273.858 159.377 278.142C107.395 291.855 50.9121 269.917 22.5295 224.639C-0.129199 188.494 -3.87586 149.029 3.2828 108.086C9.60947 71.8981 27.0628 41.9568 58.3668 21.9328C99.4761 -4.36188 142.348 -7.9032 185.983 16.1528C191.849 19.3861 196.871 24.1515 204.091 29.5634C204.472 23.6834 205.189 19.2701 204.941 14.9128C204.551 8.05145 206.681 4.99945 214.2 5.11545C238.368 5.48746 262.547 5.41278 286.717 5.14478C292.907 5.07544 294.812 7.37943 294.804 13.3168C294.693 98.7488 294.681 184.181 294.841 269.613C294.852 275.958 292.363 277.491 286.527 277.435C262.356 277.201 238.179 277.161 214.008 277.447C207.259 277.529 204.457 275.31 204.941 268.443C205.256 263.982 204.633 259.454 204.431 254.957C203.591 254.757 202.752 254.555 201.912 254.355ZM147.595 80.2994C113.343 80.2888 88.0988 106.203 88.0735 141.403C88.0481 176.631 113.227 202.418 147.632 202.395C182.865 202.374 207.496 177.014 207.309 140.954C207.125 105.43 182.395 80.3114 147.595 80.2994Z'
        fill='#F85165'
      />
      <path
        d='M176.549 161.391C187.789 161.391 196.899 152.28 196.899 141.04C196.899 129.8 187.789 120.688 176.549 120.688C165.309 120.688 156.198 129.8 156.198 141.04C156.198 152.28 165.309 161.391 176.549 161.391Z'
        fill='#3854FE'
      />
      <path
        d='M327.334 585.172C447.494 580.977 532.698 502.974 537.831 375.101'
        stroke='#FDD772'
        strokeWidth='87.4347'
        strokeMiterlimit='10'
      />
      <path
        d='M504.077 161.391C515.317 161.391 524.428 152.28 524.428 141.04C524.428 129.8 515.317 120.688 504.077 120.688C492.837 120.688 483.727 129.8 483.727 141.04C483.727 152.28 492.837 161.391 504.077 161.391Z'
        fill='#3854FE'
      />
    </svg>
  ),
});
