import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const PeriodIcon = createIcon({
  displayName: 'PeriodIcon',
  viewBox: '0 0 45 39',
  path: (
    <>
      <path
        d='M4.77223 19.1091H7.15834V21.4978H4.77223V19.1091Z'
        fill='#73B3AB'
      />
      <path
        d='M4.77223 14.3319H7.15834V16.7205H4.77223V14.3319Z'
        fill='#73B3AB'
      />
      <path
        d='M9.54446 14.3319H11.9306V16.7205H9.54446V14.3319Z'
        fill='#73B3AB'
      />
      <path
        d='M16.7028 16.7205H14.3167V14.3319H16.7028V16.7205Z'
        fill='#73B3AB'
      />
      <path
        d='M21.475 16.7205H19.0889V14.3319H21.475V16.7205Z'
        fill='#73B3AB'
      />
      <path
        d='M14.3167 21.4978H16.1249L16.7028 20.714V19.1091H14.3167V21.4978Z'
        fill='#73B3AB'
      />
      <path
        d='M9.54446 19.1091H11.9306V21.4978H9.54446V19.1091Z'
        fill='#73B3AB'
      />
      <path
        d='M9.54446 23.8864H11.9306V26.2751H9.54446V23.8864Z'
        fill='#73B3AB'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.15834 19.1091H4.77223V21.4978H7.15834V19.1091ZM11.9306 19.1091H9.54446V21.4978H11.9306V19.1091ZM11.9306 14.3319H9.54446V16.7205H11.9306V14.3319ZM11.9306 23.8864H9.54446V26.2751H11.9306V23.8864ZM14.3167 16.7205H16.7028V14.3319H14.3167V16.7205ZM19.0889 16.7205H21.475V14.3319H19.0889V16.7205ZM7.15834 14.3319H4.77223V16.7205H7.15834V14.3319Z'
        fill='#73B3AB'
      />
      <path
        d='M4.77223 0V2.38864V4.77728V7.16593H7.15834V4.77728V2.38864V0H4.77223Z'
        fill='#73B3AB'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31.0195 18.5306V2.38864H26.2473V4.77728H28.6334V9.55457H2.38611V4.77728H4.77223V2.38864H0V31.0523H14.9319L14.3726 28.6637H2.38611V11.9432H28.6334V17.3363L31.0195 18.5306ZM23.8611 2.38864H7.15834V4.77728H23.8611V2.38864Z'
        fill='#73B3AB'
      />
      <path
        d='M23.8611 0V2.38864V4.77728V7.16593H26.2473V4.77728V2.38864V0H23.8611Z'
        fill='#73B3AB'
      />
      <path
        d='M2.38611 4.77728V9.55457H28.6334V4.77728H26.2473V7.16593H23.8611V4.77728H7.15834V7.16593H4.77223V4.77728H2.38611Z'
        fill='#73B3AB'
      />
      <path
        d='M14.3726 28.6637L14.9319 31.0523H31.0195V18.5306L28.6334 17.3363V28.6637H23.8611H14.3726Z'
        fill='#73B3AB'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.6334 17.3363V11.9432H2.38611V28.6637H14.3726H23.8611H28.6334V17.3363ZM14.3167 21.4978H16.1249L16.7028 20.714V19.1091H14.3167V21.4978ZM7.15834 19.1091H4.77223V21.4978H7.15834V19.1091ZM11.9306 19.1091H9.54446V21.4978H11.9306V19.1091ZM11.9306 14.3319H9.54446V16.7205H11.9306V14.3319ZM11.9306 23.8864H9.54446V26.2751H11.9306V23.8864ZM14.3167 16.7205H16.7028V14.3319H14.3167V16.7205ZM19.0889 16.7205H21.475V14.3319H19.0889V16.7205ZM7.15834 14.3319H4.77223V16.7205H7.15834V14.3319Z'
        fill='#73B3AB'
      />
      <path
        d='M4.77225 19.1092H7.15837V21.4978H4.77225V19.1092Z'
        fill='white'
      />
      <path
        d='M4.77225 14.3319H7.15837V16.7205H4.77225V14.3319Z'
        fill='white'
      />
      <path
        d='M9.54448 14.3319H11.9306V16.7205H9.54448V14.3319Z'
        fill='white'
      />
      <path
        d='M16.7028 16.7205H14.3167V14.3319H16.7028V16.7205Z'
        fill='white'
      />
      <path
        d='M21.4751 16.7205H19.0889V14.3319H21.4751V16.7205Z'
        fill='white'
      />
      <path
        d='M14.3167 21.4978H16.1249L16.7028 20.714V19.1092H14.3167V21.4978Z'
        fill='white'
      />
      <path
        d='M9.54448 19.1092H11.9306V21.4978H9.54448V19.1092Z'
        fill='white'
      />
      <path
        d='M9.54448 23.8864H11.9306V26.2751H9.54448V23.8864Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.15837 19.1092H4.77225V21.4978H7.15837V19.1092ZM11.9306 19.1092H9.54448V21.4978H11.9306V19.1092ZM11.9306 14.3319H9.54448V16.7205H11.9306V14.3319ZM11.9306 23.8864H9.54448V26.2751H11.9306V23.8864ZM14.3167 16.7205H16.7028V14.3319H14.3167V16.7205ZM19.0889 16.7205H21.4751V14.3319H19.0889V16.7205ZM7.15837 14.3319H4.77225V16.7205H7.15837V14.3319Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.6334 17.3363V11.9432H2.38614V28.6637H14.3726H23.8612H28.6334V17.3363ZM14.3167 21.4978H16.1249L16.7028 20.714V19.1092H14.3167V21.4978ZM7.15837 19.1092H4.77225V21.4978H7.15837V19.1092ZM11.9306 19.1092H9.54448V21.4978H11.9306V19.1092ZM11.9306 14.3319H9.54448V16.7205H11.9306V14.3319ZM11.9306 23.8864H9.54448V26.2751H11.9306V23.8864ZM14.3167 16.7205H16.7028V14.3319H14.3167V16.7205ZM19.0889 16.7205H21.4751V14.3319H19.0889V16.7205ZM7.15837 14.3319H4.77225V16.7205H7.15837V14.3319Z'
        fill='white'
      />
      <path
        d='M2.38614 4.77722V9.55451H28.6334V4.77722H26.2473V7.16586H23.8612V4.77722H7.15837V7.16586H4.77225V4.77722H2.38614Z'
        fill='#F55266'
      />
      <path
        d='M4.77225 0V2.38864V4.77728V7.16593H7.15836V4.77728V2.38864V0H4.77225Z'
        fill='#3885FE'
      />
      <path
        d='M23.8612 0V2.38864V4.77728V7.16593H26.2473V4.77728V2.38864V0H23.8612Z'
        fill='#3885FE'
      />
      <path
        d='M4.77225 19.1092H7.15837V21.4978H4.77225V19.1092Z'
        fill='white'
      />
      <path
        d='M4.77225 14.3319H7.15837V16.7205H4.77225V14.3319Z'
        fill='white'
      />
      <path
        d='M9.54448 14.3319H11.9306V16.7205H9.54448V14.3319Z'
        fill='white'
      />
      <path
        d='M16.7028 16.7205H14.3167V14.3319H16.7028V16.7205Z'
        fill='white'
      />
      <path
        d='M21.4751 16.7205H19.0889V14.3319H21.4751V16.7205Z'
        fill='white'
      />
      <path
        d='M9.54448 19.1092H11.9306V21.4978H9.54448V19.1092Z'
        fill='white'
      />
      <path
        d='M9.54448 23.8864H11.9306V26.2751H9.54448V23.8864Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.3726 28.6637C14.3354 28.2781 14.3167 27.88 14.3167 27.4694C14.3167 26.3995 14.4658 25.3607 14.7641 24.353C15.0624 23.3453 15.516 22.3935 16.1249 21.4978H14.3167V19.1092H16.7028V20.714C17.2124 20.0795 17.7778 19.5197 18.3992 19.0345C19.0206 18.5493 19.6917 18.1326 20.4125 17.7842C21.1333 17.4359 21.8852 17.1746 22.6681 17.0004C23.4511 16.8263 24.2464 16.733 25.0542 16.7205C26.297 16.7205 27.49 16.9258 28.6334 17.3363V11.9432H2.38614V28.6637H14.3726ZM7.15837 19.1092H4.77225V21.4978H7.15837V19.1092ZM11.9306 19.1092H9.54448V21.4978H11.9306V19.1092ZM11.9306 14.3319H9.54448V16.7205H11.9306V14.3319ZM11.9306 23.8864H9.54448V26.2751H11.9306V23.8864ZM14.3167 16.7205H16.7028V14.3319H14.3167V16.7205ZM19.0889 16.7205H21.4751V14.3319H19.0889V16.7205ZM7.15837 14.3319H4.77225V16.7205H7.15837V14.3319Z'
        fill='white'
      />
      <path
        d='M4.77225 19.1092H7.15836V21.4978H4.77225V19.1092Z'
        fill='#FFB800'
      />
      <path
        d='M4.77225 14.3319H7.15836V16.7206H4.77225V14.3319Z'
        fill='#FFB800'
      />
      <path
        d='M9.54448 14.3319H11.9306V16.7206H9.54448V14.3319Z'
        fill='#FFB800'
      />
      <path
        d='M16.7028 16.7206H14.3167V14.3319H16.7028V16.7206Z'
        fill='#FFB800'
      />
      <path
        d='M21.475 16.7206H19.0889V14.3319H21.475V16.7206Z'
        fill='#FFB800'
      />
      <path
        d='M14.3167 21.4978H16.1249L16.7028 20.7141V19.1092H14.3167V21.4978Z'
        fill='#FFB800'
      />
      <path
        d='M9.54448 19.1092H11.9306V21.4978H9.54448V19.1092Z'
        fill='#FFB800'
      />
      <path
        d='M9.54448 23.8865H11.9306V26.2751H9.54448V23.8865Z'
        fill='#FFB800'
      />
      <path
        d='M33.5144 30.762C34.7607 32.0083 35.4608 33.6986 35.4608 35.4611H44.2847C44.2847 33.4286 43.8835 31.4161 43.104 29.539C42.3245 27.6619 41.1822 25.9572 39.7424 24.5226L33.5144 30.762Z'
        fill='#33A854'
      />
      <path
        d='M17.8688 24.5421C14.973 27.438 13.3461 31.3657 13.3461 35.4611H22.1699C22.1699 33.6986 22.8701 32.0083 24.1164 30.762L17.8688 24.5421Z'
        fill='#F55266'
      />
      <path
        d='M28.8154 28.8156C30.5779 28.8156 32.2682 29.5157 33.5144 30.762L39.7425 24.5225C38.3027 23.0879 36.5939 21.9516 34.7141 21.1788C32.8342 20.4061 30.8203 20.012 28.7878 20.0193L28.8154 28.8156Z'
        fill='#FFB800'
      />
      <path
        d='M28.7878 20.0192C24.6924 20.0192 20.7647 21.6461 17.8689 24.542L24.1164 30.7619C25.3626 29.5156 27.0529 28.8155 28.8154 28.8155L28.7878 20.0192Z'
        fill='#F6704D'
      />
      <path
        d='M28.7878 20.0192C24.6924 20.0192 20.7647 21.6461 17.8689 24.542L24.1164 30.7619C25.3626 29.5156 27.0529 28.8155 28.8154 28.8155L28.7878 20.0192Z'
        fill='#F6704D'
      />
      <path
        d='M28.2639 35.5989L25.2913 20.4274L29.3393 35.3452L28.2639 35.5989Z'
        fill='white'
      />
      <path
        d='M31.8819 35.4721C31.8819 36.0819 31.7011 36.6779 31.3623 37.1849C31.0236 37.6918 30.5421 38.087 29.9788 38.3203C29.4155 38.5536 28.7956 38.6147 28.1976 38.4957C27.5996 38.3768 27.0503 38.0832 26.6191 37.652C26.188 37.2209 25.8944 36.6716 25.7754 36.0736C25.6565 35.4756 25.7175 34.8557 25.9509 34.2924C26.1842 33.7291 26.5793 33.2476 27.0863 32.9088C27.5933 32.5701 28.1893 32.3893 28.799 32.3893C29.6167 32.3893 30.4008 32.7141 30.9789 33.2922C31.5571 33.8704 31.8819 34.6545 31.8819 35.4721Z'
        fill='#73B3AB'
      />
    </>
  ),
});
