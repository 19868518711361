const parts = ['item', 'command', 'list', 'button', 'groupTitle', 'divider'];

function baseStyleList(props: Record<string, any>) {
  return {
    bg: 'teal',
    boxShadow: 'sm',
    color: 'gray',
    minW: '100',
    zIndex: 1,
    borderRadius: 'md',
    borderWidth: 0,
  };
}

function baseStyleItem(props: Record<string, any>) {
  return {
    fontWeight: 'bold',
    justifyContent: 'center',
    alignItems: 'center',
    py: '0.4rem',
    px: '0.8rem',
    transitionProperty: 'background',
    transitionDuration: 'ultra-fast',
    transitionTimingFunction: 'ease-in',
    _focus: {
      bg: 'gray.100',
    },
    _active: {
      bg: 'gray.200',
    },
    _expanded: {
      bg: 'gray.100',
    },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
  };
}

const baseStyleGroupTitle = {
  mx: 4,
  my: 2,
  fontWeight: 'semibold',
  fontSize: 'sm',
};

const baseStyleCommand = {
  opacity: 0.6,
};

const baseStyleDivider = {
  border: 0,
  borderBottom: '1px solid',
  borderColor: 'inherit',
  my: '0.5rem',
  opacity: 0.6,
};

const baseStyleButton = {
  transitionProperty: 'common',
  transitionDuration: 'normal',
};

const baseStyle = (props: Record<string, any>) => ({
  button: baseStyleButton,
  list: baseStyleList(props),
  item: baseStyleItem(props),
  groupTitle: baseStyleGroupTitle,
  command: baseStyleCommand,
  divider: baseStyleDivider,
});

export default {
  parts,
  baseStyle,
};
