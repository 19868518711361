import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const HierarchyBg = createIcon({
  displayName: 'HierarchyBg',
  viewBox: '0 0 73 64',
  path: (
    <>
      <path
        d='M61.4611 23.0779C67.8339 23.0779 73 17.9118 73 11.539C73 5.16618 67.8339 0 61.4611 0C55.0883 0 49.9221 5.16618 49.9221 11.539C49.9221 17.9118 55.0883 23.0779 61.4611 23.0779Z'
        fill='#F55266'
      />
      <path
        d='M8.09331 62.3827C12.5631 62.3827 16.1866 58.7592 16.1866 54.2894C16.1866 49.8195 12.5631 46.196 8.09331 46.196C3.6235 46.196 0 49.8195 0 54.2894C0 58.7592 3.6235 62.3827 8.09331 62.3827Z'
        fill='#3885FE'
      />
      <path
        d='M52.0857 58.8968C49.3006 60.6245 46.2573 61.8959 43.0709 62.663C35.4372 64.52 27.3841 63.4309 20.5179 59.6128C13.6518 55.7948 8.47745 49.5285 6.02713 42.0641C3.57682 34.5997 4.03067 26.4859 7.29814 19.3413C10.5656 12.1968 16.4065 6.54664 23.6556 3.51812C30.9047 0.489611 39.0291 0.305341 46.408 3.00208C53.787 5.69882 59.878 11.0783 63.4661 18.0674C67.0541 25.0565 67.8754 33.1414 65.766 40.7092C63.6566 48.277 58.7717 54.7714 52.0857 58.8968Z'
        fill='#FFC727'
      />
    </>
  ),
});
