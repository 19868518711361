import {Form} from '../types/forms';
import {sortAlpha} from '../utils/sortAlpha';
import {instance} from './index';

export async function getForms() {
  return instance()
    .get<Form[]>('/forms')
    .then(response => sortAlpha(response.data, 'name'));
}

export function createForm(data) {
  return instance().post('/forms', data);
}

export function updateForm(data) {
  return instance().put(`/forms/${data.id}`, data);
}

export function disableForm(id) {
  return instance().delete(`/forms/${id}`);
}
