const parts = ['container', 'button', 'panel', 'icon'];

const baseStyleContainer = {
  borderColor: 'inherit',
};

const baseStyleButton = {
  transitionProperty: 'common',
  transitionDuration: 'normal',
  fontSize: '1rem',
  _focus: {
    boxShadow: 'outline',
  },
  _hover: {
    bg: 'blackAlpha.50',
  },
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
  },
  px: 4,
  py: 2,
  '& > div > svg': {
    width: '25px',
    height: '25px',
  },
};

const baseStylePanel = {
  pt: 2,
  px: 4,
  pb: 5,
  '& > div > a': {
    display: 'flex',
    justifyContent: 'center',
    px: '20px',
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    py: '8px',
    alignItems: 'center',
    fontSize: '18px',
    _hover: {
      textDecoration: 'none',
      backgroundColor: 'darkBlue.400',
    },
  },
  '& > div > a > span': {
    ml: '16px',
    flexGrow: '1',
    fontSize: '14px',
  },
  '& > div > a > svg': {
    width: '25px',
    height: '25px',
  },
};

const baseStyleIcon = {
  fontSize: '1.25em',
};

const baseStyle = {
  container: baseStyleContainer,
  button: baseStyleButton,
  panel: baseStylePanel,
  icon: baseStyleIcon,
};

export default {
  parts,
  baseStyle,
};
