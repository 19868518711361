import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {Coordinate} from '../types/tours';

export interface CoordinateState {
  data: Coordinate[];
  error: string | number | null;
  isLoading: boolean;
}

const initialState = {
  data: [],
  error: null,
  isLoading: false,
} as CoordinateState;
const coordinateSlice = createSlice({
  name: 'coordinate',
  initialState,
  reducers: {
    getCoordinates(state, action) {
      state.isLoading = true;
    },
    getCoordinatesSuccess(state, action) {
      state.data = action.payload;
      state.isLoading = false;
    },
    getCoordinatesError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    addCoordinate(state, action: PayloadAction<Coordinate>) {
      const x = '';
    },
    addCoordinateSuccess(state, action: PayloadAction<Coordinate>) {
      state.data.push(action.payload);
    },
    addCoordinateError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    deleteCoordinate(state, action: PayloadAction<string>) {
      state.isLoading = true;
    },
    deleteCoordinateSuccess(state, action: PayloadAction<string>) {
      state.isLoading = false;
      const newData = state.data.filter(
        coordinate => coordinate.remoteId !== action.payload,
      );
      state.data = [...newData];
    },
    deleteCoordinateError(state, action: PayloadAction<number>) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const getErrorSelector = state => state.coordinate.error;

export default coordinateSlice.reducer;
export const {
  getCoordinates,
  getCoordinatesSuccess,
  getCoordinatesError,
  addCoordinate,
  addCoordinateSuccess,
  addCoordinateError,
  deleteCoordinate,
  deleteCoordinateSuccess,
  deleteCoordinateError,
} = coordinateSlice.actions;
