import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const UbicacionesIcon = createIcon({
  displayName: 'UbicacionesIcon',
  viewBox: '0 0 34 34',
  path: (
    <>
      <path
        d='M32.544 33.4703H0.105286V28.929H32.544V33.4703Z'
        fill='#5CC9BA'
      />
      <path d='M6.67863 33.4703H0V28.929H6.67863V33.4703Z' fill='#FA704D' />
      <path
        d='M20.0067 33.4703H13.3279V28.929H20.0067V33.4703Z'
        fill='#FA704D'
      />
      <path
        d='M33.3333 33.4703H26.6546V28.929H33.3333V33.4703Z'
        fill='#FA704D'
      />
      <ellipse cx='16.9386' cy='9.26514' rx='5' ry='4.5' fill='#5CC9BA' />
      <path
        d='M26.4238 9.67191C26.4238 4.33058 22.1331 -8.7738e-05 16.8411 -8.7738e-05C11.5478 -8.7738e-05 7.25714 4.33058 7.25714 9.67191C7.25714 11.7306 7.89581 13.5572 8.98114 15.0252L8.97981 15.0226L16.2625 25.5972C16.5411 26.0026 17.1398 26.0026 17.4198 25.5972L24.8025 14.8839C24.8465 14.8212 24.8905 14.7586 24.9331 14.6946L24.9478 14.6732H24.9465C25.8825 13.2692 26.4238 11.5692 26.4238 9.67191ZM16.8598 13.4479C14.5691 13.4479 12.7131 11.5906 12.7131 9.29991C12.7131 7.00925 14.5691 5.15325 16.8598 5.15325C19.1505 5.15325 21.0078 7.00925 21.0078 9.29991C21.0078 11.5906 19.1505 13.4479 16.8598 13.4479Z'
        fill='#FA704D'
      />
    </>
  ),
});
