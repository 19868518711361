import {call, put, takeEvery} from 'redux-saga/effects';

import * as api from '../services/objectivesService';
import {
  addObjective,
  addObjectiveError,
  addObjectiveSuccess,
  disableObjective,
  disableObjectiveError,
  disableObjectiveSuccess,
  getObjectives,
  getObjectivesError,
  getObjectivesSuccess,
  updateObjective,
  updateObjectiveError,
  updateObjectiveSuccess,
} from '../slices/objectiveSlice';

export function* getAllObjectives({payload}) {
  try {
    const result = yield call(api.getObjectives);
    yield put(getObjectivesSuccess(result));
  } catch (e) {
    yield put(getObjectivesError);
  }
}

function* addWorker({payload}) {
  try {
    yield call(api.createObjective, payload);
    yield put(addObjectiveSuccess(payload));
  } catch (e: any) {
    yield put(addObjectiveError(e.toString()));
  }
}

function* updateWorker({payload}) {
  try {
    yield call(api.updateObjective, payload);
    yield put(updateObjectiveSuccess(payload));
  } catch (e: any) {
    yield put(updateObjectiveError(e.toString()));
  }
}

function* disableWorker({payload}) {
  try {
    yield call(api.disableObjective, payload);
    yield put(disableObjectiveSuccess(payload));
  } catch (e: any) {
    yield put(disableObjectiveError(e.toString()));
  }
}

export default function* rootSaga() {
  yield takeEvery(getObjectives, getAllObjectives);
  yield takeEvery(addObjective, addWorker);
  yield takeEvery(updateObjective, updateWorker);
  yield takeEvery(disableObjective, disableWorker);
}
