import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';
import {TourFilter} from '../types/tours';

export interface TourFilterState {
  data: TourFilter[];
  error: string | null;
}

const initialState = {data: [], error: null} as TourFilterState;
const tourFilterSlice = createSlice({
  name: 'tourFilter',
  initialState,
  reducers: {
    addTourFilter(state, action: PayloadAction<TourFilter[]>) {
      state.data = action.payload;
    },
  },
});

export const getTourFilterSelector = (state: RootState) =>
  state.tourFilter.data;
export const getErrorSelector = state => state.tourFilter.error;

export default tourFilterSlice.reducer;
export const {addTourFilter} = tourFilterSlice.actions;
