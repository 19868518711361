import React, {ReactNode} from 'react';

import {Box} from '@chakra-ui/react';

interface PropsContainerPanel {
  children: ReactNode;
}

export function ContainerPanel(props: PropsContainerPanel) {
  const {children} = props;
  return (
    <Box
      display='flex'
      ml={5}
      w='100%'
      h='auto'
      overflow='hidden'
      flexDirection='column'
      flexWrap='wrap'>
      {children}
    </Box>
  );
}
