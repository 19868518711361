import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';
import {Incident} from '../types/incidents';

export interface IncidentState {
  data: Incident[];
  error: string | null;
  isLoading: boolean;
  startLoading: number;
  endLoading: number;
}

const initialState = {
  data: [],
  error: null,
  isLoading: false,
  startLoading: 0,
  endLoading: 0,
} as IncidentState;
const incidentSlice = createSlice({
  name: 'incident',
  initialState,
  reducers: {
    getIncidents(state, action: PayloadAction<string>) {
      state.startLoading++;
    },
    getIncidentsSuccess(state, action) {
      const newIds = action.payload.map(newElements => newElements.id);
      const newData = [
        ...action.payload,
        ...state.data.filter(current => !newIds.includes(current.id)),
      ];
      newData.sort((a, b) => a.id - b.id);
      state.data = newData;
      state.endLoading++;
    },
    getIncidentsError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.endLoading++;
    },
    addIncident(state, action: PayloadAction<Incident>) {
      const x = '';
    },
    addIncidentSuccess(state, action: PayloadAction<Incident>) {
      //state.data.push(action.payload);
    },
    addIncidentError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    updateIncident(state, action: PayloadAction<Incident>) {
      const x = '';
    },
    updateIncidentSuccess(state, action: PayloadAction<Incident>) {
      const index = state.data.findIndex(
        incident => incident.id === action.payload.id,
      );
      state.data[index] = action.payload;
    },
    updateIncidentError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    disableIncident(state, action: PayloadAction<string>) {
      state.isLoading = true;
    },
    disableIncidentError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    disableIncidentSuccess(state, action: PayloadAction<string>) {
      state.isLoading = false;
      const newData = state.data.filter(
        incident => incident.id !== action.payload,
      );
      state.data = [...newData];
    },
  },
});

export const getIncidentSelector = (state: RootState) => {
  if (state.incident.startLoading === state.incident.endLoading) {
    return state.incident.data;
  }
  return [];
};
export const isIncidentLoading = state => state.incident.isLoading;
export const getErrorSelector = state => state.incident.error;

export default incidentSlice.reducer;
export const {
  getIncidents,
  getIncidentsSuccess,
  getIncidentsError,
  addIncident,
  addIncidentSuccess,
  addIncidentError,
  updateIncident,
  updateIncidentSuccess,
  updateIncidentError,
  disableIncident,
  disableIncidentError,
  disableIncidentSuccess,
} = incidentSlice.actions;
