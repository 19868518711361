import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const RolesIcon = createIcon({
  displayName: 'RolesIcon',
  viewBox: '0 0 33 30',
  path: (
    <>
      <path
        d='M22 5.94605C22 7.52304 21.4205 9.03544 20.3891 10.1505C19.3576 11.2656 17.9587 11.8921 16.5 11.8921C15.0413 11.8921 13.6424 11.2656 12.6109 10.1505C11.5795 9.03544 11 7.52304 11 5.94605C11 4.36906 11.5795 2.85666 12.6109 1.74156C13.6424 0.626457 15.0413 0 16.5 0C17.9587 0 19.3576 0.626457 20.3891 1.74156C21.4205 2.85666 22 4.36906 22 5.94605Z'
        fill='#73B3AB'
      />
      <path
        d='M31.1667 9.91014C31.1667 10.9615 30.7804 11.9697 30.0928 12.7131C29.4051 13.4565 28.4725 13.8742 27.5 13.8742C26.5276 13.8742 25.5949 13.4565 24.9073 12.7131C24.2197 11.9697 23.8334 10.9615 23.8334 9.91014C23.8334 8.85881 24.2197 7.85054 24.9073 7.10714C25.5949 6.36374 26.5276 5.94611 27.5 5.94611C28.4725 5.94611 29.4051 6.36374 30.0928 7.10714C30.7804 7.85054 31.1667 8.85881 31.1667 9.91014Z'
        fill='#FFB800'
      />
      <path
        d='M23.8334 23.7842C23.8334 21.6815 23.0607 19.665 21.6855 18.1782C20.3102 16.6914 18.4449 15.8561 16.5 15.8561C14.5551 15.8561 12.6898 16.6914 11.3146 18.1782C9.9393 19.665 9.16669 21.6815 9.16669 23.7842V29.7302H23.8334V23.7842Z'
        fill='#73B3AB'
      />
      <path
        d='M9.16665 9.91014C9.16665 10.9615 8.78034 11.9697 8.09271 12.7131C7.40507 13.4565 6.47244 13.8742 5.49998 13.8742C4.52752 13.8742 3.59489 13.4565 2.90725 12.7131C2.21962 11.9697 1.83331 10.9615 1.83331 9.91014C1.83331 8.85881 2.21962 7.85054 2.90725 7.10714C3.59489 6.36374 4.52752 5.94611 5.49998 5.94611C6.47244 5.94611 7.40507 6.36374 8.09271 7.10714C8.78034 7.85054 9.16665 8.85881 9.16665 9.91014Z'
        fill='#F6704D'
      />
      <path
        d='M27.5 29.7302V23.7842C27.5027 21.769 27.0294 19.7865 26.125 18.0244C26.9378 17.7996 27.7874 17.7784 28.6089 17.9624C29.4305 18.1465 30.2022 18.531 30.8654 19.0866C31.5286 19.6422 32.0656 20.3542 32.4356 21.1683C32.8055 21.9824 32.9986 22.8771 33 23.7842V29.7302H27.5Z'
        fill='#FFB800'
      />
      <path
        d='M6.875 18.0244C5.97071 19.7865 5.49743 21.769 5.5 23.7842V29.7302H4.87154e-07V23.7842C-0.000352858 22.8765 0.191516 21.9808 0.560903 21.1657C0.93029 20.3505 1.46739 19.6377 2.13104 19.0817C2.79469 18.5257 3.56727 18.1413 4.38957 17.9581C5.21187 17.7748 6.06207 17.7975 6.875 18.0244Z'
        fill='#F6704D'
      />
    </>
  ),
});
