const parts = [
  'boardWeek',
  'boardDay',
  'event',
  'eventLastPiece',
  'eventFirstPiece',
  'eventPiece',
  'day',
  'dayHeader',
  'pagination',
];

type Dict = Record<string, any>;

const baseStyleEvent = (props: Dict) => {
  const {colorScheme} = props;
  return {
    width: '100%',
    height: '65px',
    borderRadius: '0 12px 12px 0',
    color: 'white',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingLeft: '20px',
    paddingRight: '10px',
    overflow: 'hidden',
    backgroundColor: `${colorScheme}.500`,
    textAlign: 'left',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '10px',
      backgroundColor: `${colorScheme}.200`,
    },
    '&>h1': {
      fontSize: '14px',
      fontWeight: 'bold',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '&>h2': {
      fontSize: '13px',
      textOverflow: 'clip',
      whiteSpace: 'pre',
    },
    '&>h5': {
      fontSize: '12px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  };
};
const baseStyleEventLastPiece = (props: Dict) => {
  const {colorScheme} = props;
  return {
    width: '100%',
    height: '120px',
    borderRadius: '0 12px 12px 0',
    color: 'white',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingLeft: '20px',
    overflow: 'auto',
    backgroundColor: `${colorScheme}.500`,
    textAlign: 'left',
    '&>h1': {
      fontSize: '14px',
      fontWeight: 'bold',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '&>h2': {
      fontSize: '12px',
    },
    '&>h5': {
      fontSize: '10px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  };
};
const baseStyleEventPiece = (props: Dict) => {
  const {colorScheme} = props;
  return {
    width: '100%',
    height: '120px',
    color: 'white',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingLeft: '20px',
    overflow: 'auto',
    backgroundColor: `${colorScheme}.500`,
    textAlign: 'left',
    '&>h1': {
      fontSize: '14px',
      fontWeight: 'bold',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '&>h2': {
      fontSize: '12px',
    },
    '&>h5': {
      fontSize: '10px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  };
};
const baseStyleEventFirstPiece = (props: Dict) => {
  const {colorScheme} = props;
  return {
    width: '100%',
    height: '120px',
    color: 'white',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingLeft: '20px',
    overflow: 'auto',
    backgroundColor: `${colorScheme}.500`,
    textAlign: 'left',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '10px',
      backgroundColor: `${colorScheme}.200`,
    },
    '&>h1': {
      fontSize: '14px',
      fontWeight: 'bold',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '&>h2': {
      fontSize: '12px',
    },
    '&>h5': {
      fontSize: '10px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  };
};

const baseStyle = (props: Dict) => ({
  boardWeek: {
    zIndex: -1,
    position: 'relative',
    display: 'flex',
    width: '100%',
    borderRadius: '0 0 10px 10px',
    minWidth: '700px',
  },
  boardDay: {
    zIndex: -1,
    position: 'relative',
    display: 'flex',
    width: '100%',
    borderRadius: '0 0 10px 10px',
    '& > *': {
      width: '100%',
    },
  },
  time: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    width: '50px',
    '& > div': {
      height: '70px',
      display: 'flex',
      alignItems: 'center',
    },
  },
  event: baseStyleEvent(props),
  eventPiece: baseStyleEventPiece(props),
  eventFirstPiece: baseStyleEventFirstPiece(props),
  eventLastPiece: baseStyleEventLastPiece(props),
  day: {
    minWidth: '100px',
    width: '100%',
    bg: 'white',
  },
  dayHeader: {
    backgroundColor: 'blue.700',
    textAlign: 'center',
    paddingLeft: '0',
    minWidth: '100px',
    width: '100%',
    color: 'white',
    py: '10px',
    '& > h1': {
      fontSize: '22px',
      fontWeight: 'bold',
    },
    '& > h4': {
      fontSize: '12px',
      fontWeight: 'bold',
    },
  },
  hour: {
    backgroundColor: 'transparent',
    height: '70px',
    border: '1px solid #d9d9d9',
  },
  pagination: {
    textAlign: 'center',
    color: 'gray.500',
    fontSize: '20px',
    fontWeight: 'bold',
    display: 'flex',
    minWidth: '755px',
    marginBottom: '10px',
    justifyContent: 'space-start',
    padding: '3px',
    '& > h1': {
      fontSize: '20px',
      marginRight: '5px',
    },
    '& > p': {
      marginLeft: '3px',
    },
  },
});

const variants = {};

const defaultProps = {
  colorScheme: 'green',
};

const calendar = {
  parts,
  baseStyle,
  variants,
  defaultProps,
};

export default calendar;
