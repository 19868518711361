import {call, put, takeEvery} from 'redux-saga/effects';

import * as api from '../services/plansService';
import {
  addPlan,
  addPlanError,
  addPlanSuccess,
  disablePlan,
  disablePlanError,
  disablePlanSuccess,
  getPlans,
  getPlansError,
  getPlansSuccess,
  updatePlan,
  updatePlanError,
  updatePlanSuccess,
} from '../slices/planSlice';

export function* getAllPlans({payload}) {
  try {
    const result = yield call(api.getPlans);
    yield put(getPlansSuccess(result));
  } catch (e) {
    yield put(getPlansError);
  }
}

function* addWorker({payload}) {
  try {
    yield call(api.createPlan, payload);
    yield put(addPlanSuccess(payload));
  } catch (e: any) {
    yield put(addPlanError(e.toString()));
  }
}

function* updateWorker({payload}) {
  try {
    yield call(api.updatePlan, payload);
    yield put(updatePlanSuccess(payload));
  } catch (e: any) {
    yield put(updatePlanError(e.toString()));
  }
}

function* disableWorker({payload}) {
  try {
    yield call(api.disablePlan, payload);
    yield put(disablePlanSuccess(payload));
  } catch (e: any) {
    yield put(disablePlanError(e.toString()));
  }
}

export default function* rootSaga() {
  yield takeEvery(getPlans, getAllPlans);
  yield takeEvery(addPlan, addWorker);
  yield takeEvery(updatePlan, updateWorker);
  yield takeEvery(disablePlan, disableWorker);
}
