import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const RecorridosIcon = createIcon({
  displayName: 'RecorridosIcon',
  viewBox: '0 0 34 34',
  path: (
    <>
      <circle cx='23.9385' cy='9.51453' r='5' fill='white' />
      <path
        d='M33.3333 9.61336C33.3333 4.30402 29.0693 2.09808e-05 23.8093 2.09808e-05C18.5493 2.09808e-05 14.2853 4.30402 14.2853 9.61336C14.2853 11.6574 14.9187 13.4747 15.9987 14.932C15.9973 14.932 15.9973 14.9307 15.996 14.9307L23.2347 25.4387C23.512 25.8427 24.1067 25.844 24.384 25.4387L31.7213 14.7934C31.7653 14.7307 31.8093 14.6667 31.852 14.604L31.8653 14.584C32.7947 13.1894 33.3333 11.4974 33.3333 9.61336ZM23.828 13.364C21.552 13.364 19.7067 11.52 19.7067 9.24402C19.7067 6.96802 21.552 5.12136 23.828 5.12136C26.1053 5.12136 27.9507 6.96802 27.9507 9.24402C27.9507 11.52 26.1053 13.364 23.828 13.364Z'
        fill='#FFB800'
      />
      <path
        d='M17.2858 26.5611L12.0151 33.3865V19.7358L17.2858 26.5611Z'
        fill='#5CC9BA'
      />
      <path
        d='M0 14.4102L5.02533 14.4302C5.23467 20.4142 8.94667 24.1302 14.956 24.3715L14.7493 29.5315C10.4213 29.3582 6.72533 27.8222 4.06 25.0848C1.46133 22.4182 0.146667 18.5968 0 14.4102Z'
        fill='#5CC9BA'
      />
    </>
  ),
});
