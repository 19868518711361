import React from 'react';

import {Avatar, Flex, Text, VStack, useMediaQuery} from '@chakra-ui/react';

import {DevelopIcon, HapIcon} from '../../assets/icons';
import {useUserInfo} from '../../hooks/useUserInfo';

export function Content() {
  const {data} = useUserInfo();
  const [isLarge] = useMediaQuery('(min-width: 1024px)');

  return (
    <Flex
      justifyContent={isLarge ? 'space-between' : 'center'}
      alignItems='center'
      h='70vh'
      w='100%'
      gap={14}
      mr='auto'
      ml='auto'>
      <VStack>
        <Avatar
          size='lg'
          name={data.name}
          mr='20px'
          bg='yellow.200'
          fontWeight='bold'
          color='white'
        />
        <Text fontSize='16px' fontWeight='bold'>
          {`¡Hola ${data.given_name} ${data.family_name}!`}
        </Text>
        <Text>Te damos la bienvenida a Happify</Text>
      </VStack>
      {isLarge && (
        <>
          <HapIcon w={28} h={28} />
          <DevelopIcon w={64} h={64} />
        </>
      )}
    </Flex>
  );
}
