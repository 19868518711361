import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const CancelIcon = createIcon({
  displayName: 'CancelIcon',
  viewBox: '0 0 12 12',
  path: (
    <>
      <path
        d='M11.5391 11.6985L9.32924 11.6884L6.00112 7.72077L2.67634 11.6851L0.463169 11.6951C0.315848 11.6951 0.195312 11.5779 0.195312 11.4272C0.195312 11.3636 0.21875 11.3034 0.258928 11.2531L4.61495 6.06341L0.258928 0.877023C0.218469 0.827951 0.196021 0.766512 0.195312 0.702915C0.195312 0.555594 0.315848 0.435059 0.463169 0.435059L2.67634 0.445104L6.00112 4.41274L9.32589 0.448452L11.5357 0.438407C11.683 0.438407 11.8036 0.555594 11.8036 0.706264C11.8036 0.76988 11.7801 0.830148 11.74 0.880371L7.39062 6.06676L11.7433 11.2565C11.7835 11.3067 11.8069 11.367 11.8069 11.4306C11.8069 11.5779 11.6864 11.6985 11.5391 11.6985Z'
        fill='white'
      />
    </>
  ),
});
