import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';

export interface WorkdayState {
  state: number;
}

export interface WorkdayStates {
  data: WorkdayState;
}

const initialState = {data: {state: 490}} as WorkdayStates;
const workdayStateSlice = createSlice({
  name: 'workdayState',
  initialState,
  reducers: {
    getWorkdayState(state, action: PayloadAction<WorkdayState>) {
      state.data = action.payload;
    },
  },
});

export const getWorkdayStateSelector = (state: RootState) =>
  state.workdayState.data;

export default workdayStateSlice.reducer;
export const {getWorkdayState} = workdayStateSlice.actions;
