import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const TiposEventosIcon = createIcon({
  displayName: 'TiposEventosIcon',
  viewBox: '0 0 34 34',
  path: (
    <>
      <path
        d='M30.2241 30.8H3.77778V11.6H30.2222L30.2241 30.8Z'
        fill='#3885FE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30.2222 5.2H26.4444H22.6667H11.3333H7.55555H3.77778C1.69433 5.2 0 6.6352 0 8.4V30.8C0 32.5648 1.69433 34 3.77778 34H30.2222C32.3057 34 34 32.5648 34 30.8V8.4C34 6.6352 32.3057 5.2 30.2222 5.2ZM30.2241 30.8H3.77778V11.6H30.2222L30.2241 30.8Z'
        fill='#3885FE'
      />
      <path d='M30.2241 31.6H3.77777V11.2H30.2222L30.2241 31.6Z' fill='white' />
      <path d='M15.1111 18H26.4444V28.2H15.1111V18Z' fill='#FFB800' />
      <path
        d='M6.8 4C6.8 3.44772 6.35229 3 5.8 3H1C0.447715 3 0 3.44772 0 4V8C0 8.55228 0.447716 9 1 9H33C33.5523 9 34 8.55228 34 8V4C34 3.44772 33.5523 3 33 3H28.2C27.6477 3 27.2 3.44772 27.2 4V5C27.2 5.55228 26.7523 6 26.2 6H24.8C24.2477 6 23.8 5.55228 23.8 5V4C23.8 3.44772 23.3523 3 22.8 3H11.2C10.6477 3 10.2 3.44772 10.2 4V5C10.2 5.55228 9.75229 6 9.2 6H7.8C7.24772 6 6.8 5.55228 6.8 5V4Z'
        fill='#F55266'
      />
      <path
        d='M27.5 2.33333V1C27.5 0.447715 27.0523 0 26.5 0H24.6667C24.1144 0 23.6667 0.447715 23.6667 1V2.33333V4.66667V6C23.6667 6.55228 24.1144 7 24.6667 7H26.5C27.0523 7 27.5 6.55228 27.5 6V4.66667V2.33333Z'
        fill='#3885FE'
      />
      <path
        d='M10.3333 2.33333V1C10.3333 0.447715 9.88562 0 9.33333 0H7.5C6.94771 0 6.5 0.447715 6.5 1V2.33333V4.66667V6C6.5 6.55228 6.94772 7 7.5 7H9.33333C9.88562 7 10.3333 6.55228 10.3333 6V4.66667V2.33333Z'
        fill='#3885FE'
      />
    </>
  ),
});
