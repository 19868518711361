import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const ChecklistIcon = createIcon({
  displayName: 'ChecklistIcon',
  viewBox: '0 0 23 23',
  path: (
    <svg fill='none'>
      <path d='M0 22.3173H22.3187V2.09808e-05H0V22.3173Z' fill='#F75266' />
      <path
        d='M3.12695 11.062L8.71895 15.7567L19.127 5.72865'
        stroke='white'
        strokeWidth='2.66667'
      />
    </svg>
  ),
});
