import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const TrueFalseIcon = createIcon({
  displayName: 'TrueFalseIcon',
  viewBox: '0 0 48 24',
  path: (
    <svg fill='none'>
      <path
        d='M11.8547 0.15453C5.32274 0.15453 0.0280762 5.4492 0.0280762 11.9812C0.0280762 18.5132 5.32274 23.8079 11.8547 23.8079H35.5081C42.0401 23.8079 47.3347 18.5132 47.3347 11.9812C47.3347 5.4492 42.0401 0.15453 35.5081 0.15453H11.8547Z'
        fill='#F75266'
      />
      <path
        d='M34.8091 21.8368C40.2184 21.8368 44.6024 17.4248 44.6024 11.9808C44.6024 6.53813 40.2184 2.12613 34.8091 2.12613C29.4011 2.12613 25.0171 6.53813 25.0171 11.9808C25.0171 17.4248 29.4011 21.8368 34.8091 21.8368Z'
        fill='white'
      />
      <path
        d='M4.19189 11.8843L9.78256 16.579L20.1919 6.55098'
        stroke='white'
        strokeWidth='2.66667'
      />
    </svg>
  ),
});
