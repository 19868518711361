import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const CheckIcon = createIcon({
  displayName: 'CheckIcon',
  viewBox: '0 0 15 12',
  path: (
    <>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.2515 2.46341C14.3712 2.32656 14.4624 2.16714 14.5196 1.99455C14.5768 1.82195 14.5989 1.63966 14.5846 1.45839C14.5703 1.27712 14.5199 1.10054 14.4364 0.939039C14.3528 0.777535 14.2378 0.634372 14.0981 0.517967C13.9585 0.401563 13.7969 0.314268 13.623 0.261222C13.4491 0.208177 13.2663 0.19045 13.0854 0.209084C12.9045 0.227718 12.7292 0.282337 12.5698 0.369728C12.4103 0.457119 12.27 0.575515 12.1569 0.717954L6.2933 7.75295L3.25921 4.7175C3.00203 4.4691 2.65757 4.33166 2.30003 4.33476C1.94249 4.33787 1.60047 4.48128 1.34764 4.73411C1.09481 4.98694 0.951401 5.32896 0.948294 5.6865C0.945187 6.04404 1.08263 6.3885 1.33103 6.64568L5.42194 10.7366C5.55593 10.8705 5.71634 10.975 5.89296 11.0435C6.06958 11.112 6.25853 11.1429 6.44776 11.1343C6.63699 11.1257 6.82236 11.0778 6.99205 10.9936C7.16173 10.9094 7.31201 10.7907 7.4333 10.6452L14.2515 2.46341Z'
        fill='white'
      />
    </>
  ),
});
