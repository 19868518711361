import {call, put, takeEvery} from 'redux-saga/effects';

import * as api from '../services/featuresService';
import {
  getFeatures,
  getFeaturesError,
  getFeaturesSuccess,
} from '../slices/featureSlice';

export function* getAllFeatures({payload}) {
  try {
    const result = yield call(api.getFeatures);
    yield put(getFeaturesSuccess(result));
  } catch (e: any) {
    yield put(getFeaturesError(e.toString()));
  }
}

export default function* rootSaga() {
  yield takeEvery(getFeatures, getAllFeatures);
}
