import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const ReportsIcon = createIcon({
  displayName: 'ReportsIcon',
  viewBox: '0 0 68 84',
  path: (
    <>
      <svg
        width='68'
        height='84'
        viewBox='0 0 68 84'
        fill='white'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7 28C7 16.9543 15.9543 8 27 8H41C52.0457 8 61 16.9543 61 28V76H7V28Z'
          fill='white'
        />
        <path
          d='M21.5 12.8334H13.1667C10.9565 12.8334 8.83692 13.7113 7.27412 15.2742C5.71132 16.837 4.83334 18.9566 4.83334 21.1667V71.1667C4.83334 73.3768 5.71132 75.4965 7.27412 77.0593C8.83692 78.6221 10.9565 79.5 13.1667 79.5H54.8333C57.0435 79.5 59.1631 78.6221 60.7259 77.0593C62.2887 75.4965 63.1667 73.3768 63.1667 71.1667V21.1667C63.1667 18.9566 62.2887 16.837 60.7259 15.2742C59.1631 13.7113 57.0435 12.8334 54.8333 12.8334H46.5'
          stroke='#73B3AB'
          strokeWidth='8.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.5 62.8333V42M34 62.8333V58.6667M46.5 62.8333V50.3333M21.5 12.8333C21.5 10.6232 22.378 8.50358 23.9408 6.94078C25.5036 5.37797 27.6232 4.5 29.8333 4.5H38.1667C40.3768 4.5 42.4964 5.37797 44.0592 6.94078C45.622 8.50358 46.5 10.6232 46.5 12.8333C46.5 15.0435 45.622 17.1631 44.0592 18.7259C42.4964 20.2887 40.3768 21.1667 38.1667 21.1667H29.8333C27.6232 21.1667 25.5036 20.2887 23.9408 18.7259C22.378 17.1631 21.5 15.0435 21.5 12.8333Z'
          stroke='#5A938C'
          strokeWidth='8.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M46.5 62.8334V50.3334'
          stroke='#FFC52E'
          strokeWidth='8.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M34 62.8333V58.6666'
          stroke='#3885FE'
          strokeWidth='8.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.5 62.8333V42'
          stroke='#F55266'
          strokeWidth='8.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </>
  ),
});
