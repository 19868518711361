import React, {useEffect, useRef, useState} from 'react';

import {Box} from '@chakra-ui/react';

import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import {styleDrawPoints} from '../styles/drawPoints';
import {Tour} from '../types/tours';
import {parseDateNormal} from '../utils/parseDates';
import {hasAccess} from '../utils/user-access';

mapboxgl.accessToken =
  'pk.eyJ1IjoiY21vcmVuby1wcm90ZWxlY29tIiwiYSI6ImNsYmVuamdtdjBjdjEzcHJ5d3ZkdTMzYzYifQ.-wyGhKWmZIhyDsZlEDHttA';

interface MapaProps {
  zoomLvl?: number;
  center?: number[];
  size?: string;
  dataMap?: Tour[];
  type: 'empty' | 'full' | 'simple';
  hasMorePoints?: (boolean) => void;
}

export function Mapa(props: MapaProps) {
  const mapContainer = useRef(null);
  const map = useRef<any>(null);
  function center() {
    const centerFeatures = props.dataMap?.[0].data?.data.features.length / 2;
    if (centerFeatures < 1) {
      return [
        props.dataMap?.[0].initialPoint.longitude,
        props.dataMap?.[0].initialPoint.latitude,
      ];
    }
    const centerGeometry = Math.round(
      props.dataMap?.[0].data?.data.features[Math.round(centerFeatures)]
        .geometry.coordinates.length / 2,
    );

    return props.dataMap?.[0].data?.data.features[Math.round(centerFeatures)]
      .geometry.coordinates[centerGeometry];
  }

  const [viewport] = useState({
    zoom: props.zoomLvl || 12,
    center: props.center || center(),
  });

  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      attributionControl: false,
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/outdoors-v11',
      ...viewport,
    });
  }, []);

  let viewPoints = false;
  function flyToStore(currentFeature) {
    map.current.flyTo({
      center: [currentFeature.longitude, currentFeature.latitude],
      zoom: 20,
    });
  }

  function activateDraw(draw) {
    return map.current.addControl(draw, 'top-left');
  }

  function validateFPoints(prop) {
    return prop.hasMorePoints && prop.hasMorePoints(false);
  }
  function validatePoints(prop, draw, e) {
    if (draw.getAll().features.length !== 1) {
      prop.hasMorePoints && prop.hasMorePoints(true);
      draw.delete(e.features[0].id);
    }
  }
  function validateRol() {
    return !hasAccess('super-admin') ? 'none' : 'inherit';
  }

  function validateVisibility() {
    return viewPoints ? 'visible' : 'hidden';
  }
  function validateVPoints() {
    return viewPoints ? 'block' : 'none';
  }
  function validateNumeration(child, index) {
    return props.dataMap!.length > 1 && child.append(`${index + 1}`);
  }
  function bounds(boundy) {
    if (props.dataMap!.length > 1) {
      return boundy;
    } else {
      return [props.dataMap?.[0].bounds.ne, props.dataMap?.[0].bounds.sw];
    }
  }

  function full() {
    const coordinates = document.getElementById('coordinates');
    const contDrawPoint = document.getElementById('contDrawPoint');
    const btnCoord = document.getElementById('btn-coords');
    const tableCoords = document.getElementById('tableCoords');

    if (!props.dataMap) {
      return;
    }
    map.current.addSource('places', props.dataMap[0].data);
    map.current.addControl(new mapboxgl.NavigationControl(), 'top-left');
    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        point: true,
        trash: true,
      },
      styles: styleDrawPoints,
    });
    activateDraw(draw);

    function drawOptions(e) {
      validateFPoints(props);
      const lngLat = e.features[0].geometry.coordinates;
      contDrawPoint!.id = JSON.stringify(lngLat);
      contDrawPoint!.style.display = 'inherit';
      btnCoord!.classList.add('add-coordinate');
      btnCoord!.classList.remove('remove-coordinate');
      btnCoord!.style.background = 'green';
      btnCoord!.innerHTML = '<div class="positive add-coordinate" />';
      coordinates!.innerHTML = `<strong>Longitud:</strong> ${lngLat[0]}<br /><strong>Latitud:</strong> ${lngLat[1]}`;
      validatePoints(props, draw, e);
    }

    map.current.on('draw.create', drawOptions);

    map.current.on('draw.selectionchange', function (e) {
      if (e.features.length != 0) {
        drawOptions(e);
      } else {
        contDrawPoint!.style.display = 'none';
      }
    });

    map.current.on('draw.update', drawOptions);

    map.current.on('draw.delete', function () {
      contDrawPoint!.style.display = 'none';
    });

    map.current.addLayer({
      id: 'places',
      type: 'line',
      source: 'places',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': ['get', 'color'],
        'line-width': 7,
        'line-opacity': 1,
      },
    });

    const listCoords = [] as any;

    props.dataMap.forEach(
      c =>
        c.coords &&
        c.coords.map(point => {
          const el = document.createElement('div');
          el.className = 'coords';
          el.style.width = '10px';
          el.style.height = '10px';
          el.style.background = 'white';
          el.style.border = '1.5px solid #d94d5e';
          el.style.borderRadius = '50%';
          el.style.cursor = 'pointer';

          const tag = document.createElement('a');
          tag.href = `#listing-${point.idTracking}`;
          tag.title = `${parseDateNormal(point.collectedAt)}`;
          tag.style.visibility = 'hidden';
          tag.append(el);
          el.addEventListener('click', e => {
            flyToStore(point);
            const activeItem = document.getElementsByClassName('active');
            e.stopPropagation();
            if (activeItem[0]) {
              activeItem[0].classList.remove('active');
            }
            const listing = document.getElementById(
              `listing-${point.idTracking}`,
            );
            listing!.classList.add('active');
            contDrawPoint!.id = JSON.stringify(point.idTracking);
            btnCoord!.classList.remove('add-coordinate');
            btnCoord!.classList.add('remove-coordinate');
            btnCoord!.style.background = 'red';
            btnCoord!.innerHTML = '<div class="negative remove-coordinate" />';
            contDrawPoint!.style.display = validateRol();
            coordinates!.innerHTML = `<strong>Longitud:</strong> ${point.longitude}<br /><strong>Latitud:</strong> ${point.latitude}`;
          });

          listCoords.push(
            new mapboxgl.Marker(tag)
              .setLngLat([point.longitude, point.latitude])
              .addTo(map.current),
          );
          new mapboxgl.Marker(tag)
            .setLngLat([point.longitude, point.latitude])
            .addTo(map.current);
        }),
    );

    const createButton = onclick => {
      const button = document.createElement('div');
      button.title = 'Desactiva las coordenadas';
      button.className = 'btnCoords';
      button.addEventListener('click', onclick);
      return button;
    };

    const markButton = createButton(() => {
      viewPoints = !viewPoints;
      contDrawPoint!.style.display = 'none';
      tableCoords!.style.display = validateVPoints();
      listCoords.forEach(
        c => (c.getElement().style.visibility = validateVisibility()),
      );
    });

    const mapboxglLatLngControl = {
      onAdd: () => {
        const latLonContainer = document.createElement('div');
        latLonContainer.classList.add('mapboxgl-ctrl');
        latLonContainer.append(markButton);
        return latLonContainer;
      },
    };
    map.current.addControl(mapboxglLatLngControl, 'top-left');

    const boundy = map.current && map.current.getBounds();

    const btnCenter = document.createElement('div');
    btnCenter.title = 'Centrar recorrido';
    btnCenter.className = 'btnCenter';
    btnCenter.addEventListener('click', () => {
      map.current.fitBounds(bounds(boundy), {
        padding: 150,
      });
    });

    const centerControl = {
      onAdd: () => {
        const centerContainer = document.createElement('button');
        centerContainer.classList.add('mapboxgl-ctrl');
        centerContainer.append(btnCenter);
        return centerContainer;
      },
    };
    map.current.addControl(centerControl, 'top-left');

    props.dataMap.forEach((i, index) => {
      if (i.initialPoint) {
        const childInit = document.createElement('div');
        childInit.style.display = 'flex';
        childInit.style.justifyContent = 'center';
        childInit.style.alignItems = 'center';
        childInit.style.marginTop = '-6px';
        childInit.style.marginLeft = '-6px';
        childInit.style.fontSize = '10px';
        childInit.style.width = '100%';
        childInit.style.height = '100%';
        childInit.style.fontWeight = '900';
        validateNumeration(childInit, index);
        const el = document.createElement('div');
        el.className = 'markerStart';
        el.append(childInit);

        new mapboxgl.Marker(el)
          .setLngLat([i.initialPoint.longitude, i.initialPoint.latitude])
          .setPopup(
            new mapboxgl.Popup({offset: 25}).setHTML(
              `<span><strong>Fecha:</strong><p>${parseDateNormal(
                i.initialPointData.collectedAt,
              )}</p></span>` +
                `<span><strong>Longitud:</strong><p>${i.initialPointData.latitude}</p></span>` +
                `<span><strong>Latitud:</strong><p>${i.initialPointData.longitude}</p></span>`,
            ),
          )
          .addTo(map.current);
      }
    });

    props.dataMap.forEach(
      p =>
        p.points &&
        p.points.map((point, index) => {
          const child = document.createElement('div');
          child.style.display = 'flex';
          child.style.justifyContent = 'center';
          child.style.alignItems = 'center';
          child.style.marginTop = '-6px';
          child.style.marginLeft = '-6px';
          child.style.fontSize = '10px';
          child.style.width = '100%';
          child.style.height = '100%';
          child.style.fontWeight = '900';
          child.append(`${index + 1}`);
          const el = document.createElement('div');
          el.className = 'markerPoint';
          el.append(child);

          new mapboxgl.Marker(el)
            .setLngLat([point.longitude, point.latitude])
            .setPopup(
              new mapboxgl.Popup({offset: 25}).setHTML(
                `<span><strong>Nombre:</strong><p>${point.name}</p></span>` +
                  `<span><strong>Código externo:</strong><p>${point.external_code}</p></span>` +
                  `<span><strong>Dirección:</strong><p>${point.address}</p></span>` +
                  `<span><strong>Longitud:</strong><p>${point.longitude}</p></span>` +
                  `<span><strong>Latitud:</strong><p>${point.latitude}</p></span>` +
                  `<span><strong>Entrada:</strong><p>${point.checkin}</p></span>` +
                  `<span><strong>Salida:</strong><p>${point.checkout}</p></span>`,
              ),
            )
            .addTo(map.current);
        }),
    );

    props.dataMap.forEach((f, index) => {
      if (f.finalPoint) {
        const childFinal = document.createElement('div');
        childFinal.style.display = 'flex';
        childFinal.style.justifyContent = 'center';
        childFinal.style.alignItems = 'center';
        childFinal.style.marginTop = '-6px';
        childFinal.style.marginLeft = '-6px';
        childFinal.style.fontSize = '10px';
        childFinal.style.width = '100%';
        childFinal.style.height = '100%';
        childFinal.style.fontWeight = '900';
        validateNumeration(childFinal, index);
        const final = document.createElement('div');
        final.className = 'markerEnd';
        final.append(childFinal);

        new mapboxgl.Marker(final)
          .setLngLat([f.finalPoint.longitude, f.finalPoint.latitude])
          .setPopup(
            new mapboxgl.Popup({offset: 25}).setHTML(
              `<span><strong>Fecha:</strong><p>${parseDateNormal(
                f.finalPointData.collectedAt,
              )}</p></span>` +
                `<span><strong>Longitud:</strong><p>${f.finalPointData.latitude}</p></span>` +
                `<span><strong>Latitud:</strong><p>${f.finalPointData.longitude}</p></span>`,
            ),
          )
          .addTo(map.current);
      }
    });

    map.current.fitBounds(bounds(boundy), {
      padding: 150,
    });
  }

  function simple() {
    map.current.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showUserHeading: true,
      }),
      'top-left',
    );
    map.current.addControl(new mapboxgl.NavigationControl(), 'top-left');
    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        point: true,
        trash: true,
      },
      styles: styleDrawPoints,
    });
    activateDraw(draw);
    const coordinates = document.getElementById('coords');

    function drawSimple(e) {
      const lngLat = e.features[0].geometry.coordinates;
      coordinates!.style.display = 'inherit';
      coordinates!.innerHTML = `<strong>Longitud:</strong> ${lngLat[0].toFixed(
        6,
      )}&nbsp; &nbsp; <strong>Latitud:</strong> ${lngLat[1].toFixed(6)}`;
      coordinates!.title = JSON.stringify(lngLat);
      validatePoints(props, draw, e);
    }

    map.current.on('draw.create', drawSimple);
    map.current.on('draw.selectionchange', function (e) {
      if (e.features.length != 0) {
        drawSimple(e);
      }
    });
    map.current.on('draw.update', drawSimple);
    map.current.on('draw.delete', function () {
      coordinates!.title = '';
      coordinates!.style.display = 'none';
    });
  }

  function loading() {
    if (props.type === 'full') {
      full();
    }
    if (props.type === 'simple') {
      simple();
    }
  }

  function buildLocationList(coords) {
    for (const coord of coords) {
      const listings = document.getElementById('listings');
      const listing = listings!.appendChild(document.createElement('div'));
      listing.id = `listing-${coord.idTracking}`;
      listing.className = 'itemCord';

      const contCoords = listing.appendChild(document.createElement('div'));
      contCoords.className = 'cont-coords';

      const iconCoords = contCoords.appendChild(document.createElement('div'));
      iconCoords.className = 'icon-coords';

      const link = contCoords.appendChild(document.createElement('a'));
      link.href = `#listing-${coord.idTracking}`;
      link.className = 'titleCord';
      link.id = `listing-${coord.idTracking}`;
      link.innerHTML = `<strong>Coordenadas:</strong> ${coord.latitude} ${coord.longitude}`;

      link.addEventListener('click', function () {
        for (const feature of coords) {
          if (this.id === `listing-${feature.idTracking}`) {
            flyToStore(feature);
          }
        }
        const activeItem = document.getElementsByClassName('active');
        if (activeItem[0]) {
          activeItem[0].classList.remove('active');
        }
        this.parentNode?.parentNode?.['classList'].add('active');
      });

      const contDetail = listing.appendChild(document.createElement('div'));
      contDetail.className = 'cont-detail';

      const contDate = contDetail.appendChild(document.createElement('div'));
      contDate.className = 'cont-date';

      const iconDate = contDate.appendChild(document.createElement('div'));
      iconDate.className = 'icon-date';

      const date = contDate.appendChild(document.createElement('div'));
      date.className = 'detailCord';
      date.innerHTML = `<strong>Fecha:</strong> ${parseDateNormal(
        coord.collectedAt,
      )}`;

      const contAccuracy = contDetail.appendChild(
        document.createElement('div'),
      );
      contAccuracy.className = 'cont-accuracy';

      const iconAccuracy = contAccuracy.appendChild(
        document.createElement('div'),
      );
      iconAccuracy.className = 'icon-accuracy';

      const accuracy = contAccuracy.appendChild(document.createElement('div'));
      accuracy.className = 'detailCord';
      accuracy.innerHTML += `<strong>Certeza: </strong> ${coord.accuracy}`;
    }
  }

  function validateData() {
    return props.dataMap && buildLocationList(props.dataMap[0].coords);
  }

  useEffect(() => {
    if (!map.current) return;

    map.current.on('load', loading);

    if (props.type === 'full') {
      validateData();
    }
  }, [viewPoints]);

  return <Box ref={mapContainer} className={`map-container ${props.size}`} />;
}
