const parts = ['container', 'header', 'body', 'footer'];

const baseStyle = {
  container: {
    color: 'white',
    backgroundColor: 'darkBlue.500',
    height: '100vh',
    overflowY: 'auto',
    width: '100%',
    maxWidth: '198px',
    minWidth: '100px',
    pt: '30px',
    '& > *': {
      px: '16px',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  menu: {
    px: '0px',
    textAlign: 'left',
    my: '10px',
    width: '100%',
    '& > a': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      px: '15px',
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      py: '16px',
      fontSize: '14px',
      _hover: {
        textDecoration: 'none',
        backgroundColor: 'darkBlue.400',
      },
    },
    '& > a > span': {
      ml: '16px',
      flexGrow: '1',
    },
    '& > a > svg': {
      width: '25px',
      height: '25px',
    },
  },
};

const variants = {};

const defaultProps = {};

const sideBar = {
  parts,
  baseStyle,
  variants,
  defaultProps,
};

export default sideBar;
