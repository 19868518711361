import axios from 'axios';

import config from '../config';

export const instance = () => {
  return axios.create({
    baseURL: config.API_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('react-token')}`,
    },
  });
};
