import React from 'react';

import {HStack, IconButton, Text} from '@chakra-ui/react';

import {FaCopy} from 'react-icons/fa';

interface IClipboardMessage {
  value: string;
  onShowMessage: () => void;
}

export function ClipboardMessage(props: IClipboardMessage) {
  if (!props.value) {
    return <></>;
  }

  return (
    <HStack style={{marginLeft: '-7px', padding: 0}}>
      <IconButton
        style={{
          height: 0,
          width: 0,
          display: 'flex',
          justifyContent: 'center',
        }}
        _focus={{
          outline: 'none',
          boxShadow: 'none',
        }}
        colorScheme='darkBlue'
        variant='unstyled'
        size='xs'
        icon={<FaCopy />}
        aria-label='copy'
        onClick={props.onShowMessage}
      />
      <Text style={{margin: 0}} fontSize={10} fontWeight='bolder'>
        Id:
      </Text>
      <Text style={{margin: '2px'}} fontSize={10}>
        {props.value}
      </Text>
    </HStack>
  );
}
