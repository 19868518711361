import React from 'react';

import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {createRoot} from 'react-dom/client';

import App from './app/App';
import auth from './app/auth';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container!);

const queryClient = new QueryClient();

auth(success => {
  if (success) {
    root.render(
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </React.StrictMode>,
    );
  } else {
    root.render(
      <React.StrictMode>
        <h1>Error al autenticar</h1>
      </React.StrictMode>,
    );
  }
});

reportWebVitals();
