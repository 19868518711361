import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const HapControlLogo = createIcon({
  displayName: 'HapControlLogo',
  viewBox: '0 0 976 353',
  path: (
    <svg fill='none'>
      <path
        d='M404.937 129.739C410.619 129.739 415.224 125.324 415.224 119.878C415.224 114.433 410.619 110.017 404.937 110.017C399.255 110.017 394.651 114.433 394.651 119.878C394.651 125.324 399.255 129.739 404.937 129.739Z'
        fill='#F7F7F7'
      />
      <path
        d='M525.785 173.957C525.668 176.179 525.516 177.722 525.514 179.265C525.496 195.906 525.416 212.547 525.571 229.184C525.604 232.74 524.638 234.144 520.674 234.073C508.876 233.858 497.067 233.917 485.266 234.042C481.688 234.081 480.07 233.228 480.079 229.348C480.192 172.506 480.187 115.666 480.095 58.8239C480.087 55.1763 481.287 53.953 485.117 54.0151C496.918 54.2076 508.726 54.2155 520.523 54.0087C524.318 53.9419 525.914 55.0204 525.541 58.7284C525.32 60.9173 525.499 63.1443 525.499 66.0967C527.2 65.0659 528.167 64.6285 528.947 63.9827C543.633 51.8326 560.656 49.6326 578.913 53.5219C596.786 57.3302 610.434 67.0862 619.504 82.2412C628.5 97.2737 630.961 113.611 628.692 130.759C626.696 145.856 620.943 159.184 609.815 170.215C590.419 189.444 557.148 194.064 534.913 180.517C531.929 178.699 529.233 176.453 525.785 173.957ZM524.897 119.612C524.615 137.413 536.509 149.453 554.528 149.609C572.008 149.762 584.131 138.073 584.528 120.686C584.93 103.126 572.745 90.6435 555.021 90.4574C537.256 90.2712 525.176 101.965 524.897 119.612Z'
        fill='#3854FE'
      />
      <path
        d='M45.7423 68.2861C47.3618 67.0422 48.3724 66.4281 49.1755 65.6216C58.6373 56.1329 70.306 51.8569 83.878 51.8681C105.445 51.884 121.839 60.0015 130.276 79.7601C132.657 85.3389 134.648 91.5157 134.776 97.4571C135.385 125.807 135.27 154.172 135.478 182.53C135.499 185.193 134.298 185.961 131.704 185.947C119.204 185.874 106.701 185.829 94.201 185.969C90.6565 186.009 90.1156 184.37 90.1239 181.533C90.1903 160.493 90.1936 139.454 90.1322 118.415C90.1139 112.148 89.6958 105.939 86.2127 100.3C81.4238 92.5529 73.7757 89.3396 62.9865 90.7506C55.7699 91.6939 48.681 98.1793 47.0897 105.875C46.1505 110.412 45.5199 115.087 45.4834 119.705C45.3125 140.21 45.3308 160.718 45.4818 181.222C45.5067 184.833 44.4812 186.079 40.5601 186.003C28.6209 185.769 16.6684 185.805 4.72591 185.985C1.02385 186.041 -0.00496016 184.79 1.7956e-05 181.35C0.0879647 124.492 0.0846455 67.6339 0.00665497 10.7761C0.00167685 7.40053 0.964113 6.00067 4.70766 6.05316C16.7896 6.22018 28.8748 6.21225 40.9583 6.05476C44.4579 6.00864 45.4884 7.21123 45.4635 10.4611C45.344 27.9036 45.4005 45.3476 45.422 62.7917C45.4237 64.3331 45.5963 65.8746 45.7423 68.2861Z'
        fill='#3854FE'
      />
      <path
        d='M362.307 173.742V179.349C362.307 195.99 362.204 212.632 362.383 229.271C362.42 232.933 361.272 234.131 357.428 234.068C345.489 233.87 333.543 233.905 321.602 234.045C317.985 234.087 316.812 232.862 316.817 229.383C316.906 172.538 316.91 115.691 316.817 58.8443C316.81 55.184 318.026 53.9607 321.848 54.0196C333.787 54.1993 345.736 54.2184 357.676 54.0069C361.446 53.9385 362.645 55.2333 362.34 58.6232C362.139 60.8311 362.302 63.0693 362.302 66.1569C364.066 64.9973 365.096 64.4373 365.986 63.7183C380.52 51.9612 397.312 49.5862 415.254 53.4294C436.488 57.9757 451.422 70.2754 459.976 89.3388C465.905 102.547 467.367 116.443 465.47 130.755C463.468 145.859 457.65 159.123 446.6 170.225C427.447 189.466 393.362 194.115 371.324 180.308C368.493 178.535 365.916 176.386 362.307 173.742ZM421.318 119.906C421.25 102.47 408.907 90.3187 391.406 90.4571C373.556 90.5971 361.706 102.367 361.658 119.994C361.615 137.791 373.704 149.707 391.701 149.607C409.33 149.507 421.386 137.421 421.318 119.906Z'
        fill='#5FB5AA'
      />
      <path
        d='M253.394 174.782C247.188 180.321 240.106 184.231 231.895 186.307C205.623 192.951 177.075 182.322 162.728 160.384C151.276 142.87 149.383 123.748 153 103.911C156.198 86.3766 165.019 71.869 180.841 62.1671C201.62 49.4269 223.288 47.7105 245.343 59.3674C248.31 60.9327 250.846 63.2425 254.496 65.864C254.689 63.015 255.05 60.877 254.926 58.7661C254.73 55.4415 255.805 53.9621 259.605 54.0177C271.82 54.1991 284.041 54.1625 296.258 54.0321C299.386 54.0003 300.348 55.1154 300.345 57.993C300.288 99.3873 300.283 140.782 300.365 182.174C300.371 185.249 299.112 185.992 296.161 185.963C283.945 185.85 271.724 185.831 259.509 185.971C256.096 186.009 254.68 184.936 254.926 181.608C255.087 179.446 254.77 177.252 254.669 175.073C254.242 174.976 253.819 174.879 253.394 174.782ZM225.942 90.4473C208.628 90.4425 195.869 102.998 195.857 120.054C195.844 137.123 208.57 149.617 225.96 149.607C243.767 149.596 256.217 137.307 256.124 119.836C256.029 102.624 243.531 90.4537 225.942 90.4473Z'
        fill='#5FB5AA'
      />
      <path
        d='M851.559 232.722C855.369 224.262 859.067 216.002 862.803 207.757C865.947 200.816 869.239 193.935 872.216 186.931C872.828 185.488 872.888 183.398 872.276 181.973C862.466 159.144 852.501 136.377 842.581 113.593C834.472 94.975 826.364 76.3553 818.303 57.7166C817.855 56.6826 817.83 55.48 817.487 53.7461H826.228C836.926 53.7461 847.623 53.8702 858.316 53.6745C861.626 53.6125 863.251 54.6688 864.41 57.6259C871.328 75.2704 878.436 92.8481 885.461 110.454C888.033 116.903 890.519 123.382 893.138 129.812C893.554 130.83 894.47 131.662 895.693 133.299C898.7 125.749 901.4 119.009 904.073 112.258C911.445 93.6435 918.763 75.0096 926.265 56.4424C926.735 55.2844 928.595 53.8988 929.823 53.8845C943.573 53.7239 957.324 53.8082 971.073 53.8479C971.465 53.8479 971.853 54.0897 972.945 54.4524C970.466 60.3477 968.06 66.2112 965.543 72.0301C952.278 102.687 938.998 133.337 925.699 163.98C916.017 186.287 906.326 208.59 896.501 230.84C895.98 232.024 894.208 233.471 893.003 233.486C879.811 233.653 866.616 233.562 853.422 233.514C853.045 233.513 852.672 233.207 851.559 232.722Z'
        fill='#3854FE'
      />
      <path
        d='M773.882 137.617C773.881 152.126 773.796 166.634 773.942 181.141C773.978 184.652 773.061 186.089 769.045 186.004C757.249 185.761 745.44 185.762 733.642 186.003C729.464 186.087 728.538 184.563 728.553 180.885C728.674 152.136 728.623 123.385 728.616 94.6352C728.616 88.5968 728.601 88.5968 722.393 88.5777C719.34 88.5697 716.285 88.5395 713.23 88.5634C710.826 88.5825 709.654 87.5246 709.661 85.1831C709.686 75.8645 709.691 66.5475 709.666 57.2306C709.661 55.0544 710.72 54.0809 712.962 54.0968C716.71 54.1238 720.467 53.9632 724.207 54.1207C727.375 54.2527 728.636 53.1583 728.712 50.0054C728.905 42.1631 729.577 34.4162 732.59 26.9222C737.363 15.0553 745.958 7.09677 758.467 3.41103C772.802 -0.81398 787.37 -1.19894 801.944 2.73178C805.87 3.78963 807.991 5.63011 807.806 10.011C807.465 18.1174 807.602 26.2477 807.757 34.3637C807.818 37.6247 806.434 37.9078 803.436 37.6883C797.983 37.2922 792.399 36.9232 787.026 37.6279C779.834 38.5728 775.85 43.3116 774.519 50.2249C773.96 53.1344 774.924 54.1238 777.868 54.1016C786.059 54.0348 794.256 54.2352 802.442 54.0188C806.333 53.9155 807.908 55.0051 807.785 58.9549C807.529 67.1997 807.576 75.4604 807.768 83.7084C807.851 87.2987 806.627 88.7463 802.754 88.635C795.54 88.4282 788.315 88.5745 781.095 88.5777C773.887 88.5825 773.884 88.5841 773.884 95.6899C773.882 109.665 773.882 123.641 773.882 137.617Z'
        fill='#3854FE'
      />
      <path
        d='M647.577 120.13C647.577 99.7737 647.658 79.4154 647.506 59.0587C647.477 55.2807 648.647 53.9334 652.706 54.0081C664.498 54.2245 676.301 54.1911 688.094 54.0193C691.622 53.9684 692.994 54.8528 692.986 58.467C692.881 99.5813 692.894 140.696 692.972 181.808C692.977 184.872 692.061 186.02 688.719 185.978C676.51 185.823 664.292 185.83 652.079 185.971C648.652 186.011 647.499 184.904 647.519 181.601C647.637 161.111 647.577 140.621 647.577 120.13Z'
        fill='#3854FE'
      />
      <path
        d='M670.44 43.3842C664.194 43.3842 657.948 43.3047 651.704 43.4192C648.714 43.4749 647.491 42.4537 647.531 39.4694C647.66 29.6259 647.639 19.7792 647.546 9.93415C647.519 7.22671 648.555 6.07979 651.427 6.0941C664.057 6.15932 676.686 6.16092 689.316 6.08933C692.03 6.07502 692.968 7.13285 692.946 9.66531C692.867 19.7761 692.858 29.8884 692.951 39.9992C692.976 42.6684 691.753 43.4526 689.176 43.4145C682.932 43.3206 676.686 43.3842 670.44 43.3842Z'
        fill='#3854FE'
      />
      <path
        d='M226.332 89.7682C209.02 89.7634 196.261 102.319 196.248 119.375C196.234 136.444 208.962 148.937 226.35 148.926C244.159 148.917 256.609 136.628 256.513 119.157C256.42 101.945 243.923 89.7746 226.332 89.7682Z'
        fill='white'
      />
      <path
        d='M391.876 89.7682C374.562 89.7634 361.805 102.319 361.791 119.375C361.778 136.444 374.506 148.937 391.894 148.926C409.703 148.917 422.153 136.628 422.055 119.157C421.964 101.945 409.465 89.7746 391.876 89.7682Z'
        fill='white'
      />
      <path
        d='M240.575 129.739C246.258 129.739 250.861 125.324 250.861 119.878C250.861 114.433 246.258 110.017 240.575 110.017C234.893 110.017 230.29 114.433 230.29 119.878C230.29 125.324 234.893 129.739 240.575 129.739Z'
        fill='#3854FE'
      />
      <path
        d='M406.118 129.739C411.8 129.739 416.405 125.324 416.405 119.878C416.405 114.433 411.8 110.017 406.118 110.017C400.437 110.017 395.832 114.433 395.832 119.878C395.832 125.324 400.437 129.739 406.118 129.739Z'
        fill='#3854FE'
      />
      <path
        d='M316.786 335.072C377.521 333.039 420.585 295.243 423.178 233.287'
        stroke='#FDD772'
        strokeWidth='35.5093'
        strokeMiterlimit='10'
      />
      <path
        d='M731.466 260.841C735.244 260.841 738.391 261.353 740.903 262.379C743.414 263.404 745.38 264.764 746.795 266.455C748.214 268.149 749.097 269.972 749.445 271.927L737.732 275.646C737.383 273.597 736.75 272.059 735.83 271.033C734.908 270.009 733.578 269.495 731.839 269.495C730.246 269.495 728.916 269.842 727.845 270.532C726.777 271.224 725.969 272.32 725.421 273.822C724.873 275.323 724.601 277.245 724.601 279.579C724.601 281.917 724.9 283.812 725.496 285.265C726.095 286.721 726.938 287.769 728.034 288.412C729.126 289.056 730.421 289.378 731.914 289.378C733.155 289.378 734.189 289.152 735.009 288.698C735.83 288.246 736.512 287.59 737.06 286.731C737.607 285.873 737.956 284.825 738.105 283.586L749.52 287.088C749.172 289.187 748.237 291.059 746.721 292.704C745.204 294.347 743.201 295.648 740.715 296.601C738.228 297.554 735.319 298.031 731.987 298.031C727.908 298.031 724.352 297.306 721.319 295.85C718.286 294.396 715.948 292.274 714.305 289.486C712.665 286.696 711.846 283.345 711.846 279.435C711.846 275.527 712.665 272.177 714.305 269.388C715.948 266.598 718.247 264.478 721.206 263.022C724.166 261.57 727.586 260.841 731.466 260.841Z'
        fill='#5FB5AA'
      />
      <path
        d='M773.84 269.496C772.396 269.496 771.155 269.83 770.11 270.496C769.064 271.166 768.271 272.238 767.722 273.714C767.174 275.194 766.902 277.101 766.902 279.436C766.902 281.773 767.174 283.669 767.722 285.121C768.271 286.577 769.064 287.651 770.11 288.341C771.155 289.031 772.396 289.378 773.84 289.378C775.332 289.378 776.6 289.031 777.645 288.341C778.691 287.651 779.484 286.577 780.031 285.121C780.581 283.669 780.853 281.773 780.853 279.436C780.853 277.101 780.581 275.194 780.031 273.714C779.484 272.238 778.691 271.166 777.645 270.496C776.6 269.83 775.332 269.496 773.84 269.496ZM773.84 260.842C777.77 260.842 781.213 261.571 784.175 263.023C787.132 264.478 789.443 266.599 791.111 269.389C792.777 272.178 793.612 275.528 793.612 279.436C793.612 283.346 792.777 286.696 791.111 289.485C789.443 292.273 787.132 294.395 784.175 295.849C781.213 297.305 777.77 298.03 773.84 298.03C770.01 298.03 766.605 297.305 763.62 295.849C760.635 294.395 758.31 292.273 756.644 289.485C754.976 286.696 754.145 283.346 754.145 279.436C754.145 275.528 754.976 272.178 756.644 269.389C758.31 266.599 760.635 264.478 763.62 263.023C766.605 261.571 770.01 260.842 773.84 260.842Z'
        fill='#5FB5AA'
      />
      <path
        d='M800.773 297.316V261.558H812.111L812.783 273.858L811.366 272.499C811.864 269.924 812.747 267.779 814.013 266.063C815.284 264.345 816.875 263.047 818.789 262.164C820.703 261.283 822.856 260.841 825.244 260.841C827.829 260.841 830.079 261.318 831.994 262.272C833.91 263.225 835.414 264.631 836.509 266.491C837.601 268.351 838.149 270.687 838.149 273.5V297.316H825.468V276.719C825.468 274.239 824.968 272.523 823.974 271.569C822.978 270.616 821.711 270.139 820.169 270.139C818.976 270.139 817.858 270.414 816.812 270.963C815.767 271.51 814.947 272.38 814.351 273.571C813.754 274.764 813.457 276.362 813.457 278.364V297.316H800.773Z'
        fill='#5FB5AA'
      />
      <path
        d='M862.394 250.185V284.586C862.394 286.302 862.831 287.532 863.7 288.269C864.572 289.008 865.778 289.379 867.32 289.379C868.514 289.379 869.545 289.248 870.416 288.984C871.284 288.723 872.069 288.329 872.766 287.804L874.108 295.314C872.865 296.171 871.297 296.841 869.409 297.317C867.519 297.792 865.577 298.031 863.589 298.031C860.755 298.031 858.304 297.649 856.24 296.887C854.176 296.125 852.584 294.91 851.466 293.24C850.346 291.572 849.762 289.355 849.712 286.589V253.546L862.394 250.185ZM873.511 270.21H843.295V261.558H873.511V270.21Z'
        fill='#5FB5AA'
      />
      <path
        d='M879.406 297.316V261.558H890.595L891.12 269.281C892.062 266.61 893.469 264.538 895.333 263.058C897.2 261.58 899.573 260.841 902.46 260.841C903.402 260.841 904.211 260.912 904.884 261.055C905.555 261.199 906.089 261.389 906.487 261.628L905.219 271.64C904.722 271.451 904.086 271.297 903.316 271.176C902.543 271.058 901.612 270.998 900.518 270.998C899.027 270.998 897.633 271.308 896.34 271.927C895.048 272.547 894.015 273.465 893.245 274.682C892.474 275.895 892.089 277.434 892.089 279.293V297.316H879.406Z'
        fill='#5FB5AA'
      />
      <path
        d='M928.867 269.496C927.425 269.496 926.182 269.83 925.137 270.496C924.093 271.166 923.298 272.238 922.75 273.714C922.203 275.194 921.929 277.101 921.929 279.436C921.929 281.773 922.203 283.669 922.75 285.121C923.298 286.577 924.093 287.651 925.137 288.341C926.182 289.031 927.425 289.378 928.867 289.378C930.36 289.378 931.628 289.031 932.672 288.341C933.717 287.651 934.512 286.577 935.06 285.121C935.607 283.669 935.881 281.773 935.881 279.436C935.881 277.101 935.607 275.194 935.06 273.714C934.512 272.238 933.717 271.166 932.672 270.496C931.628 269.83 930.36 269.496 928.867 269.496ZM928.867 260.842C932.796 260.842 936.239 261.571 939.201 263.023C942.158 264.478 944.472 266.599 946.138 269.389C947.804 272.178 948.638 275.528 948.638 279.436C948.638 283.346 947.804 286.696 946.138 289.485C944.472 292.273 942.158 294.395 939.201 295.849C936.239 297.305 932.796 298.03 928.867 298.03C925.037 298.03 921.632 297.305 918.648 295.849C915.663 294.395 913.337 292.273 911.671 289.485C910.005 286.696 909.172 283.346 909.172 279.436C909.172 275.528 910.005 272.178 911.671 269.389C913.337 266.599 915.663 264.478 918.648 263.023C921.632 261.571 925.037 260.842 928.867 260.842Z'
        fill='#5FB5AA'
      />
      <path
        d='M968.334 246.394V285.157C968.334 286.731 968.606 287.852 969.155 288.519C969.699 289.187 970.62 289.521 971.913 289.521C972.66 289.521 973.28 289.475 973.78 289.378C974.276 289.284 974.872 289.139 975.568 288.948L974.749 296.243C973.854 296.77 972.684 297.198 971.242 297.53C969.8 297.863 968.382 298.031 966.989 298.031C963.01 298.031 960.126 297.15 958.337 295.386C956.545 293.622 955.649 290.736 955.649 286.731V246.394H968.334Z'
        fill='#5FB5AA'
      />
    </svg>
  ),
});
