const baseStylePreview = {
  borderRadius: 'md',
  py: '3px',
  transitionProperty: 'common',
  transitionDuration: 'normal',
};

const baseStyleInput = {
  borderRadius: 'md',
  py: '3px',
  transitionProperty: 'common',
  transitionDuration: 'normal',
  width: 'full',
  _focus: {boxShadow: 'outline'},
  _placeholder: {opacity: 0.6},
};

const baseStyle = {
  preview: baseStylePreview,
  input: baseStyleInput,
};

export default {
  baseStyle,
};
