import {all} from 'redux-saga/effects';

import sagaCalendarType from './sagaCalendarType';
import sagaCoordinates from './sagaCoordinates';
import sagaDocuments from './sagaDocuments';
import sagaFeatures from './sagaFeatures';
import sagaForm from './sagaForm';
import sagaIncidents from './sagaIncidents';
import sagaObjectives from './sagaObjective';
import sagaPlans from './sagaPlans';
import sagaTours from './sagaTours';

export default function* rootSaga() {
  yield all([
    sagaPlans(),
    sagaFeatures(),
    sagaCalendarType(),
    sagaForm(),
    sagaObjectives(),
    sagaIncidents(),
    sagaTours(),
    sagaDocuments(),
    sagaCoordinates(),
  ]);
}
