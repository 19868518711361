import {formatISO} from 'date-fns';

import {Incident} from '../types/incidents';
import {sortAlpha} from '../utils/sortAlpha';
import {instance} from './index';

export async function getIncidents() {
  return instance()
    .get<Incident[]>('/incident-types')
    .then(response => sortAlpha(response.data, 'name'));
}

export function createIncident(data) {
  return instance().post('/incident-types', {
    ...data,
    createdAt: formatISO(new Date()),
  });
}

export function updateIncident(data) {
  return instance().put(`/incident-types/${data.id}`, {
    ...data,
    updatedAt: formatISO(new Date()),
  });
}

export function disableIncident(id: string) {
  return instance().delete(`/incident-types/${id}`);
}
