import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const DevelopIcon = createIcon({
  displayName: 'DevelopIcon',
  viewBox: '0 0 482 313',
  path: (
    <>
      <path
        d='M403.484 299.876C387.727 288.018 385.689 268.091 387.921 250.488C388.296 247.547 388.217 244.418 389.993 241.948C391.769 239.478 395.526 237.981 398.327 239.447C400.604 240.661 401.469 243.287 402.63 245.496C404.192 248.462 406.63 250.965 409.666 252.717C411.647 253.847 414.152 254.632 416.292 253.764C419.218 252.571 419.856 249.086 420.095 246.155C420.55 240.692 420.998 235.225 421.438 229.755C421.513 226.809 422.105 223.894 423.192 221.121C424.444 218.421 426.892 216.003 430.023 215.459C433.154 214.915 436.854 216.799 437.218 219.719C437.233 220.943 437.157 222.167 436.99 223.382C436.926 223.989 437.055 224.6 437.362 225.141C437.669 225.682 438.142 226.129 438.721 226.427C439.973 226.867 441.339 226.134 442.387 225.381C445.984 222.68 448.592 219.038 451.039 215.407C453.487 211.775 455.889 208.081 459.203 205.046C462.516 202.01 466.933 199.813 471.578 199.917C476.223 200.022 480.925 203.005 481.722 207.243C482.519 211.482 479.502 215.459 476.314 218.578C471.566 223.185 466.122 227.14 460.147 230.32C459.003 230.79 458.05 231.581 457.426 232.581C456.55 234.486 458.417 236.579 460.478 237.385C462.823 238.295 465.464 238.264 467.958 238.724C470.451 239.185 473.092 240.399 473.855 242.628C474.903 245.768 471.863 248.656 468.971 250.509C463.143 254.218 456.671 256.989 449.833 258.704C447.385 259.311 444.823 259.813 442.774 261.184C440.725 262.555 439.358 265.098 440.303 267.254C441.248 269.41 444.186 270.289 446.713 270.059C449.241 269.829 451.575 268.772 454.022 268.144C458.576 266.961 464.269 267.673 466.842 271.367C467.603 272.627 468.031 274.033 468.092 275.473C468.154 276.913 467.846 278.346 467.195 279.656C465.87 282.262 463.988 284.597 461.662 286.522C453.912 293.676 444.242 298.817 433.632 301.425C423.044 303.884 413.423 304.041 403.484 299.876'
        fill='#385B82'
      />
      <path
        d='M402.107 312.948C403.743 304.83 406.233 296.877 409.541 289.201C413.629 279.825 418.308 270.676 423.556 261.801L427.666 254.81C429.01 252.508 430.342 250.226 431.799 248.07C434.592 243.938 437.632 239.951 440.907 236.129C444.015 232.455 447.123 229.064 450.015 225.925C452.907 222.785 455.707 219.907 458.406 217.374C462.604 213.37 467.138 209.675 471.965 206.322C473.673 205.129 475.039 204.229 475.961 203.664L477.02 202.994C477.141 202.918 477.267 202.848 477.396 202.785C477.396 202.785 477.282 202.879 477.054 203.036L476.018 203.737C475.108 204.344 473.741 205.244 472.079 206.458C467.307 209.855 462.82 213.578 458.656 217.594C456.003 220.127 453.226 223.015 450.311 226.165C447.396 229.316 444.322 232.707 441.203 236.38C437.929 240.196 434.889 244.175 432.095 248.301C430.649 250.446 429.328 252.717 427.985 255.02L423.909 262.021C418.705 270.883 414.045 280.006 409.951 289.347C406.639 296.996 404.123 304.915 402.437 313'
        fill='#FFD25C'
      />
      <path
        d='M431.89 248.018C431.783 247.599 431.722 247.171 431.708 246.741C431.617 245.925 431.526 244.721 431.434 243.246C431.241 240.294 431.127 236.213 431.013 231.733C430.899 227.254 430.717 223.151 430.455 220.221C430.341 218.745 430.216 217.563 430.125 216.736C430.059 216.313 430.028 215.886 430.034 215.459C430.175 215.868 430.27 216.289 430.319 216.715C430.467 217.531 430.638 218.724 430.797 220.19C431.15 223.141 431.389 227.223 431.503 231.702C431.617 236.181 431.696 240.211 431.765 243.214C431.822 244.627 431.856 245.81 431.89 246.71C431.936 247.145 431.936 247.583 431.89 248.018V248.018Z'
        fill='#FFD25C'
      />
      <path
        d='M474.048 244.125H473.616H472.375C471.305 244.125 469.756 244.125 467.821 244.177C463.984 244.25 458.713 244.522 452.849 245.077C446.986 245.632 441.772 246.343 437.992 246.961C436.102 247.264 434.576 247.537 433.518 247.725L432.311 247.945C432.026 247.945 431.878 248.008 431.878 247.945C432.006 247.882 432.145 247.84 432.288 247.819L433.483 247.516C434.519 247.275 436.045 246.95 437.935 246.616C441.703 245.914 446.963 245.15 452.804 244.585C458.644 244.02 463.961 243.811 467.821 243.821C469.745 243.821 471.236 243.821 472.375 243.915L473.604 243.989C473.759 244.012 473.909 244.058 474.048 244.125V244.125Z'
        fill='#FFD25C'
      />
      <path
        d='M410.065 288.531C409.975 288.373 409.899 288.208 409.837 288.039C409.678 287.673 409.496 287.202 409.257 286.626C408.756 285.391 408.118 283.581 407.276 281.393C405.67 276.924 403.655 270.687 401.515 263.769C399.374 256.851 397.428 250.614 395.902 246.124C395.139 243.926 394.524 242.126 394.069 240.807C393.864 240.221 393.705 239.761 393.579 239.363C393.509 239.197 393.46 239.025 393.431 238.85C393.533 239.001 393.613 239.162 393.67 239.331C393.818 239.708 394.012 240.179 394.251 240.755C394.752 241.99 395.39 243.79 396.221 245.988C397.837 250.457 399.853 256.694 401.982 263.612C404.111 270.53 406.035 276.788 407.606 281.257C408.357 283.455 408.983 285.255 409.427 286.563C409.632 287.16 409.792 287.61 409.928 288.018C409.992 288.185 410.038 288.356 410.065 288.531Z'
        fill='#FFD25C'
      />
      <path
        d='M466.694 276.569C466.518 276.646 466.329 276.699 466.136 276.726L464.485 277.081L458.406 278.358C453.282 279.405 446.224 281.006 438.425 282.764C430.626 284.523 423.556 286.051 418.41 287.055C415.837 287.558 413.753 287.945 412.307 288.186L410.645 288.458C410.451 288.499 410.253 288.523 410.053 288.531C410.234 288.455 410.426 288.402 410.622 288.374L412.273 288.018L418.342 286.741C423.465 285.695 430.524 284.094 438.311 282.335C446.098 280.577 453.18 279.049 458.326 278.044C460.899 277.542 462.982 277.155 464.428 276.914L466.102 276.631C466.297 276.596 466.495 276.575 466.694 276.569V276.569Z'
        fill='#FFD25C'
      />
      <path
        d='M253.919 0.820535L336.233 23.5796L319.545 74.5828L237.231 51.8238L253.919 0.820535Z'
        fill='white'
      />
      <path
        d='M237.217 51.7743C237.217 51.7743 237.342 51.4499 237.547 50.8429C237.752 50.2358 238.06 49.3149 238.458 48.1427C239.244 45.7774 240.405 42.3133 241.874 37.8967C244.811 29.0322 249.012 16.3163 254.135 0.837399L253.839 0.983919L336.131 23.8307L335.926 23.4853L319.258 74.4955L319.599 74.3281L260.306 58.0538L243.331 53.4384L238.777 52.2139L237.638 51.8999L237.229 51.8057L237.593 51.9208L238.731 52.2557L243.194 53.5116L260.067 58.2526L319.451 74.799L319.713 74.8723L319.793 74.6316C324.882 59.0899 330.552 41.8109 336.506 23.6319L336.586 23.3702L336.313 23.2969L253.987 0.586214L253.76 0.523422L253.691 0.732742C248.648 16.2953 244.515 29.0741 241.623 37.9804C240.211 42.397 239.096 45.8507 238.344 48.2264C237.969 49.3777 237.695 50.2672 237.49 50.8952C237.286 51.5231 237.217 51.7743 237.217 51.7743Z'
        fill='#FFD25C'
      />
      <path
        d='M253.907 0.806002C253.937 0.885812 253.976 0.962882 254.021 1.03624L254.408 1.68512C254.784 2.28167 255.296 3.10847 255.934 4.15505L261.683 13.2289L280.97 43.297L281.106 43.4959L281.334 43.4122L283.85 42.5226L320.931 29.2206L332.145 25.1389L335.174 23.9982L335.959 23.6946C336.053 23.6658 336.142 23.6234 336.221 23.5691C336.122 23.577 336.026 23.6018 335.937 23.6423L335.14 23.8935L332.054 24.9401L320.783 28.8647L283.645 42.0097L281.118 42.9098L281.482 43.0249C273.854 31.2509 267 20.7014 262.036 13.03L256.127 4.05039C255.444 3.00381 254.898 2.21888 254.511 1.62233L254.067 1.00485C253.976 0.868791 253.919 0.795536 253.907 0.806002Z'
        fill='#FFD25C'
      />
      <path
        d='M14.3886 204.575C14.3886 204.575 19.7282 199.718 30.1342 211.848C35.5897 217.989 40.5986 224.453 45.1283 231.2C45.1283 231.2 47.7241 233.669 49.4318 231.43C51.1396 229.19 52.9043 223.413 56.7069 223.706C60.5095 223.999 62.6954 227.599 63.2077 233.345C63.7201 239.091 65.3254 251.66 65.4848 252.79C65.6441 253.921 66.6233 255.396 68.0919 254.046C69.5606 252.696 76.9495 241.205 82.6989 244.742C88.4484 248.28 86.9911 264.543 86.8659 266.312C86.7406 268.081 87.2644 268.751 88.2207 269.002C89.177 269.253 91.2833 265.171 93.5489 261.979C95.8145 258.787 99.0365 255.428 102.156 257.741C105.275 260.054 108.589 271.43 100.71 292.33L96.3724 301.749L85.272 303.204C61.2837 304.973 50.1263 299.185 48.4755 295.826C46.8247 292.466 51.151 290.436 55.0902 289.222C59.0294 288.008 63.8567 287.129 63.8225 286.239C63.7884 285.349 63.2305 284.701 61.3292 284.366C59.4279 284.031 41.9746 281.226 39.6976 275.25C37.4206 269.274 51.3673 265.527 53.1547 264.585C54.9422 263.643 53.6215 262.356 52.483 261.948C51.3445 261.54 38.5022 256.945 32.5706 255.061C26.639 253.178 23.3715 250.436 24.0432 246.856C24.7149 243.277 31.2158 243.287 34.0051 242.314C36.7944 241.341 34.8362 238.431 34.8362 238.431C28.8385 232.725 23.259 226.659 18.1343 220.273C7.96748 207.965 14.5936 204.376 14.5936 204.376'
        fill='#385B82'
      />
      <path
        d='M23.7697 215.375C23.8208 215.468 23.8818 215.556 23.9519 215.637L24.5325 216.39C25.079 217.06 25.8418 218.013 26.8095 219.227C28.8019 221.686 31.7051 225.224 35.3256 229.577C42.5551 238.285 52.665 250.237 63.8907 263.382L92.6038 297.092L101.268 307.338L103.636 310.111L104.262 310.823C104.41 310.98 104.478 311.064 104.49 311.053C104.439 310.961 104.378 310.873 104.308 310.792L103.727 310.049L101.45 307.202C99.4689 304.743 96.5544 301.205 92.9453 296.851C85.7158 288.144 75.5945 276.192 64.3574 263.047C53.1204 249.902 43.0218 237.992 35.6557 229.337C32.0239 225.035 29.0638 221.55 26.9917 219.101C25.9898 217.918 25.1929 217.008 24.635 216.317L23.9974 215.606C23.8608 215.449 23.7811 215.365 23.7697 215.375Z'
        fill='#FFD25C'
      />
      <path
        d='M54.5893 231.88C54.3416 232.777 54.2004 233.697 54.1681 234.622C53.9973 236.317 53.8493 238.672 53.7468 241.278C53.6443 243.884 53.5988 246.04 53.5419 247.934C53.458 248.854 53.458 249.778 53.5419 250.697C53.7885 249.8 53.926 248.88 53.9517 247.955C54.0997 246.249 54.225 243.894 54.3274 241.299C54.4299 238.703 54.4982 236.537 54.5779 234.643C54.6686 233.724 54.6724 232.799 54.5893 231.88V231.88Z'
        fill='#FFD25C'
      />
      <path
        d='M52.5854 251.064C48.6897 250.485 44.7643 250.091 40.8247 249.881C34.3238 249.389 29.0298 249.106 29.0184 249.253C32.9147 249.825 36.84 250.216 40.7792 250.425C47.28 250.927 52.5627 251.21 52.5854 251.064Z'
        fill='#FFD25C'
      />
      <path
        d='M71.4734 271.242C70.4521 271.314 69.4391 271.464 68.445 271.692C66.5778 272.016 64.0162 272.466 61.1699 272.885C58.3236 273.303 55.7392 273.617 53.8607 273.837C52.836 273.912 51.8194 274.063 50.8209 274.287C51.845 274.368 52.8756 274.347 53.8949 274.224C55.7962 274.099 58.4489 273.848 61.261 273.418C64.0731 272.989 66.6916 272.487 68.536 272.068C69.5445 271.89 70.5293 271.613 71.4734 271.242Z'
        fill='#FFD25C'
      />
      <path
        d='M77.439 254.266C76.9664 255.025 76.5846 255.828 76.3005 256.663C75.6971 258.18 74.8887 260.273 74.0234 262.597C73.1582 264.92 72.4295 267.034 71.9172 268.583C71.5964 269.402 71.3601 270.246 71.2113 271.106C71.691 270.346 72.073 269.539 72.3498 268.698C72.9646 267.191 73.7616 265.098 74.6269 262.775C75.4921 260.451 76.2208 258.327 76.7331 256.788C77.0675 255.974 77.3041 255.128 77.439 254.266V254.266Z'
        fill='#FFD25C'
      />
      <path
        d='M96.5774 270.132C96.0292 271.127 95.5649 272.159 95.1884 273.22C94.4028 275.156 93.3554 277.835 92.2283 280.807C91.1012 283.78 90.1221 286.48 89.4276 288.437C88.9994 289.484 88.668 290.562 88.4371 291.66C88.9947 290.651 89.4593 289.601 89.8261 288.521C90.6116 286.595 91.659 283.916 92.7862 280.943C93.9133 277.971 94.9038 275.271 95.5869 273.303C96.0132 272.274 96.3445 271.213 96.5774 270.132V270.132Z'
        fill='#FFD25C'
      />
      <path
        d='M88.4712 291.095C87.1743 291.163 85.8845 291.317 84.6116 291.556C82.2436 291.901 78.9191 292.372 75.3328 292.812C71.7465 293.251 68.4107 293.586 66.0312 293.858C64.7348 293.936 63.4455 294.094 62.1717 294.329C63.4699 294.402 64.7724 294.374 66.0654 294.245C68.4677 294.099 71.7579 293.827 75.4125 293.377C79.0671 292.927 82.3346 292.403 84.6913 291.974C85.9752 291.776 87.2394 291.482 88.4712 291.095V291.095Z'
        fill='#FFD25C'
      />
      <path
        d='M64.6424 101.604L67.3401 182.77L2.81695 184.583L0.119286 103.417L64.6424 101.604Z'
        fill='#F5F5F5'
      />
      <path
        d='M76.5213 112.394L79.219 193.56L14.6959 195.372L11.9982 114.206L76.5213 112.394Z'
        fill='white'
      />
      <path
        d='M14.7074 195.354C14.7149 195.222 14.7149 195.089 14.7074 194.957C14.7074 194.684 14.7074 194.308 14.7074 193.816C14.7074 192.769 14.6505 191.304 14.5935 189.378C14.4797 185.496 14.3203 179.854 14.1154 172.706C13.6714 158.421 13.0452 138.169 12.3052 114.192L12.0433 114.443L76.5623 112.654L76.255 112.392C77.291 142.743 78.236 170.781 79.0102 193.554L79.2492 193.324L32.3884 194.758L19.3525 195.176L15.937 195.302L14.7415 195.354H15.88L19.2956 195.281L32.2745 194.977L79.2834 193.774H79.5225V193.554C78.7824 170.77 77.8717 142.701 76.8812 112.382V112.109H76.5737L12.066 113.951H11.7928V114.203C12.6467 138.274 13.3639 158.557 13.8763 172.874C14.1381 179.99 14.3544 185.621 14.4911 189.483C14.5708 191.388 14.6277 192.853 14.6732 193.868C14.6732 194.35 14.6732 194.726 14.7301 194.988C14.7871 195.25 14.7074 195.354 14.7074 195.354Z'
        fill='#FFD25C'
      />
      <path
        d='M37.1587 131.168C37.1587 131.325 44.1833 131.241 52.8359 131C61.4886 130.759 68.5132 130.435 68.5132 130.289C68.5132 130.142 61.4772 130.215 52.8246 130.456C44.1719 130.697 37.1587 131.021 37.1587 131.168Z'
        fill='#FFD25C'
      />
      <path
        d='M20.4911 138.703C20.4911 138.85 31.3068 138.703 44.6501 138.295C57.9934 137.887 68.8092 137.489 68.7978 137.342C68.7865 137.196 57.982 137.342 44.6274 137.751C31.2727 138.159 20.4797 138.546 20.4911 138.703Z'
        fill='#FFD25C'
      />
      <path
        d='M20.7302 146.06C20.7302 146.207 31.5573 146.06 44.9006 145.652C58.2439 145.244 69.0483 144.846 69.0483 144.7C69.0483 144.553 58.2211 144.7 44.8779 145.108C31.5346 145.516 20.7302 145.903 20.7302 146.06Z'
        fill='#FFD25C'
      />
      <path
        d='M20.9806 153.418C20.9806 153.564 31.7964 153.418 45.1397 153.01C58.483 152.602 69.2874 152.204 69.2874 152.057C69.2874 151.911 58.4602 152.057 45.1169 152.465C31.7736 152.874 20.9692 153.292 20.9806 153.418Z'
        fill='#FFD25C'
      />
      <path
        d='M21.2199 160.775C21.2199 160.922 32.0471 160.775 45.379 160.367C58.7109 159.959 69.5381 159.561 69.5381 159.415C69.5381 159.268 58.7109 159.415 45.3676 159.823C32.0243 160.231 21.2199 160.618 21.2199 160.775Z'
        fill='#FFD25C'
      />
      <path
        d='M21.4587 168.133C21.4587 168.279 32.2859 168.133 45.6292 167.725C58.9725 167.316 69.7769 166.919 69.7769 166.772C69.7769 166.626 58.9497 166.772 45.6064 167.18C32.2631 167.589 21.4587 167.944 21.4587 168.133Z'
        fill='#FFD25C'
      />
      <path
        d='M21.7091 175.49C21.7091 175.637 32.5363 175.49 45.8682 175.082C59.2001 174.674 70.0272 174.276 70.0159 174.13C70.0045 173.983 59.2001 174.13 45.8568 174.538C32.5135 174.946 21.6977 175.333 21.7091 175.49Z'
        fill='#FFD25C'
      />
      <path
        d='M44.2973 182.22C44.2973 182.366 50.1151 182.324 57.2877 182.125C64.4602 181.927 70.2666 181.634 70.2666 181.487C70.2666 181.341 64.4489 181.382 57.2763 181.581C50.1037 181.78 44.2973 182.063 44.2973 182.22Z'
        fill='#FFD25C'
      />
      <path
        d='M205.824 38.1338L226.014 84.7479L162.591 107.961L142.402 61.3466L205.824 38.1338Z'
        fill='white'
      />
      <path
        d='M142.414 61.3505L142.721 61.2458L143.609 60.9319L147.024 59.676L160.038 54.9141L205.874 38.1688L205.737 38.1165L225.911 84.7206L225.98 84.5741L162.565 107.808H162.69C156.588 93.763 151.522 82.1251 147.992 73.9932C146.216 69.9324 144.827 66.7404 143.882 64.574L142.744 62.0936L142.368 61.2354L142.721 62.0831C142.96 62.6483 143.313 63.4751 143.78 64.553C144.713 66.7299 146.057 69.922 147.833 73.9722C151.351 82.1146 156.394 93.7839 162.463 107.86V107.944H162.554L226.059 84.8462H226.173V84.7415C218.876 67.9125 211.988 52.0046 205.976 38.1374V38.0432H205.885L159.935 54.9141L146.99 59.6864L143.575 60.9423L142.698 61.2668L142.414 61.3505Z'
        fill='#FFD25C'
      />
      <path
        d='M204.957 41.9204L222.603 82.6615L163.633 104.244L145.987 63.5033L204.957 41.9204Z'
        fill='#385B82'
      />
      <path
        d='M222.371 82.7844L190.8 64.9926L184.903 85.5055L172.584 80.2098L163.396 104.365L222.371 82.7844Z'
        fill='#FFD25C'
      />
      <path
        d='M171.286 68.5195C171.652 69.3641 172.282 70.0899 173.096 70.6053C173.911 71.1206 174.873 71.4023 175.862 71.4146C176.851 71.427 177.821 71.1695 178.651 70.6746C179.48 70.1798 180.131 69.47 180.522 68.6349C180.912 67.7998 181.024 66.877 180.844 65.9833C180.664 65.0895 180.2 64.265 179.509 63.614C178.819 62.963 177.935 62.5149 176.967 62.3262C176 62.1375 174.994 62.2168 174.076 62.5541C173.466 62.7766 172.909 63.1076 172.439 63.5281C171.968 63.9486 171.593 64.4503 171.334 65.0045C171.075 65.5587 170.937 66.1545 170.929 66.7577C170.921 67.3609 171.042 67.9596 171.286 68.5195V68.5195Z'
        fill='#FFD25C'
      />
      <path
        d='M369.09 133.261C368.661 133.423 368.197 133.491 367.735 133.46C367.181 133.401 366.666 133.168 366.278 132.8C365.827 132.227 365.427 131.622 365.082 130.99L357.693 119.142L355.234 115.228L353.925 113.135C353.697 112.802 353.499 112.452 353.333 112.089C353.213 111.727 353.213 111.341 353.333 110.979C353.435 110.607 353.662 110.274 353.982 110.027C354.347 109.789 354.727 109.573 355.12 109.378L357.659 108.08L362.942 105.359L386.463 93.2292C386.927 93.0011 387.467 92.939 387.978 93.055C388.489 93.1709 388.935 93.4567 389.23 93.8572L390.995 96.6411C392.133 98.4831 393.272 100.315 394.41 102.104L401.013 112.476C401.275 112.884 401.537 113.292 401.788 113.7C402.041 114.054 402.172 114.47 402.163 114.893C402.145 115.307 401.982 115.704 401.697 116.024C401.373 116.332 400.986 116.577 400.558 116.746L395.583 119.341L386.68 123.988L373.792 130.76L370.297 132.622L369.397 133.114C369.192 133.229 369.09 133.292 369.101 133.303L369.42 133.167L370.353 132.717L373.894 130.937L386.885 124.323L395.788 119.687L400.774 117.123C401.258 116.926 401.695 116.646 402.061 116.296C402.417 115.891 402.62 115.392 402.641 114.872C402.666 114.355 402.515 113.843 402.209 113.407C401.958 112.999 401.697 112.591 401.435 112.183L394.911 101.832L391.496 96.369L389.708 93.5537C389.331 93.0402 388.76 92.6735 388.106 92.5246C387.452 92.3757 386.761 92.4553 386.167 92.7478L362.669 104.92L357.386 107.662L354.87 108.98C354.435 109.183 354.017 109.414 353.617 109.671C353.22 109.982 352.938 110.399 352.809 110.864C352.672 111.309 352.672 111.78 352.809 112.225C352.991 112.621 353.208 113.002 353.458 113.365L354.79 115.459L357.272 119.362C360.448 124.302 363.022 128.311 364.82 131.126C365.194 131.759 365.62 132.364 366.095 132.936C366.519 133.318 367.075 133.551 367.667 133.596C368.024 133.62 368.383 133.566 368.714 133.439C368.848 133.396 368.974 133.336 369.09 133.261Z'
        fill='#FFD25C'
      />
      <path
        d='M375.101 111.806L372.232 116.484L378.153 116.589L384.073 116.683L381.022 111.9L377.97 107.117L375.101 111.806Z'
        fill='#FFD25C'
      />
      <path
        d='M355.018 244.857L389.389 213.115L372.107 183.936C354.824 163.591 318.233 164.899 318.233 164.899C300.18 163.771 282.045 164.795 264.279 167.944C235.213 173.177 237.558 190.823 237.558 190.823L233.664 256.307L229.486 309.63L336.676 311.618L355.018 244.899'
        fill='#89C1BA'
      />
      <path
        d='M297.273 308.5L393.283 309.546C398.572 309.603 403.79 308.423 408.447 306.117C413.103 303.81 417.046 300.454 419.904 296.363C422.762 292.272 424.442 287.58 424.786 282.728C425.131 277.876 424.127 273.023 421.871 268.625L380.817 198.64L343.417 221.874L368.931 270.509L291.216 271.786L297.273 308.5Z'
        fill='#89C1BA'
      />
      <path
        d='M240.951 180.147L163.909 289.494L208.857 292.194C208.857 292.194 235.703 253.314 235.099 254.109C234.029 255.574 240.951 180.147 240.951 180.147Z'
        fill='#89C1BA'
      />
      <path
        d='M345.352 228.164C345.349 227.972 345.315 227.782 345.25 227.599C345.136 227.181 345.01 226.657 344.84 226.008C344.487 224.637 343.963 222.649 343.428 220.179C342.893 217.709 342.289 214.737 341.914 211.43C341.451 207.845 341.451 204.224 341.914 200.639C342.531 197.085 343.88 193.672 345.887 190.582C347.5 188.038 349.409 185.662 351.58 183.496C353.043 182.016 354.602 180.618 356.248 179.31C356.875 178.859 357.475 178.377 358.046 177.866C357.861 177.951 357.686 178.052 357.523 178.169C357.204 178.389 356.692 178.693 356.1 179.143C354.388 180.385 352.774 181.739 351.272 183.193C349.028 185.342 347.057 187.719 345.398 190.278C343.32 193.416 341.928 196.897 341.299 200.524C340.848 204.153 340.879 207.818 341.39 211.44C341.775 214.387 342.322 217.314 343.03 220.21C343.622 222.691 344.168 224.669 344.635 226.029C344.84 226.71 345.022 227.233 345.147 227.589C345.195 227.786 345.264 227.979 345.352 228.164V228.164Z'
        fill='#263238'
      />
      <path
        d='M348.449 231.932C348.613 232.477 348.834 233.006 349.109 233.512C349.564 234.559 350.247 235.961 351.124 237.699C352.866 241.247 355.337 246.124 358.183 251.461C361.029 256.799 363.716 261.582 365.708 265.067C366.71 266.783 367.53 268.206 368.111 269.117C368.388 269.624 368.708 270.11 369.067 270.572C368.862 270.038 368.611 269.52 368.315 269.023C367.735 267.976 366.983 266.574 366.038 264.889L358.672 251.283C355.883 245.977 353.344 241.184 351.454 237.594C350.566 235.909 349.838 234.507 349.268 233.408C349.04 232.896 348.766 232.402 348.449 231.932V231.932Z'
        fill='#263238'
      />
      <path
        d='M297.273 308.5C297.273 308.5 297.273 308.437 297.273 308.322C297.273 308.207 297.273 307.997 297.273 307.778C297.216 307.275 297.136 306.574 297.045 305.684C296.817 303.821 296.499 301.174 296.1 297.835C295.258 291.064 294.051 281.54 292.685 270.31L292.411 270.582H320.772L370.308 270.509L385.336 270.425L389.423 270.373H390.494H390.858C390.737 270.359 390.615 270.359 390.494 270.373H389.423L385.336 270.321L370.308 270.237L320.692 270.164H292.332H292.024V270.436C293.527 281.655 294.791 291.179 295.702 297.94C296.157 301.268 296.533 303.916 296.84 305.779C296.962 306.658 297.064 307.355 297.148 307.872L297.239 308.405C297.25 308.437 297.261 308.5 297.273 308.5Z'
        fill='#263238'
      />
      <path
        d='M368.259 271.283C368.947 270.585 369.585 269.848 370.172 269.075C373.24 265.534 377.039 262.586 381.352 260.399C382.273 259.989 383.17 259.535 384.039 259.038C383.028 259.256 382.058 259.609 381.158 260.085C376.667 262.125 372.783 265.138 369.841 268.866C369.195 269.601 368.662 270.414 368.259 271.283V271.283Z'
        fill='#263238'
      />
      <path
        d='M232.071 278.212C232.154 277.913 232.208 277.609 232.23 277.301C232.31 276.663 232.413 275.794 232.549 274.695C232.811 272.435 233.164 269.159 233.54 265.109C234.325 256.998 235.225 245.778 236.022 233.387C236.819 220.995 237.377 209.755 237.695 201.623C237.855 197.552 237.969 194.297 238.014 191.984C238.014 190.885 238.014 190.017 238.071 189.368C238.088 189.065 238.088 188.761 238.071 188.457C238.008 188.757 237.974 189.062 237.969 189.368C237.969 190.006 237.877 190.885 237.809 191.984C237.684 194.308 237.513 197.583 237.297 201.602C236.876 209.734 236.249 220.954 235.452 233.355C234.655 245.757 233.813 256.956 233.175 265.077C232.834 269.086 232.561 272.403 232.367 274.685C232.276 275.731 232.208 276.652 232.151 277.291C232.094 277.595 232.067 277.903 232.071 278.212V278.212Z'
        fill='#263238'
      />
      <path
        d='M317.777 218.714C317.772 218.234 317.731 217.755 317.652 217.28C317.516 216.286 317.345 214.957 317.128 213.324C316.628 209.87 315.99 205.14 315.147 199.656L314.715 199.907L322.081 204.648L329.572 209.441L330.107 209.787L330.016 209.19C329.106 203.758 328.331 199.07 327.739 195.584C327.443 193.983 327.216 192.665 327.033 191.681C326.972 191.209 326.866 190.743 326.715 190.289C326.714 190.766 326.76 191.242 326.851 191.712C326.988 192.696 327.159 194.015 327.386 195.637C327.899 199.07 328.616 203.769 329.436 209.242L329.868 208.991L322.388 204.177L315.011 199.457L314.476 199.111L314.567 199.708C315.443 205.171 316.206 209.902 316.753 213.314C317.037 214.936 317.265 216.265 317.436 217.249C317.502 217.745 317.616 218.236 317.777 218.714V218.714Z'
        fill='#E0E0E0'
      />
      <path
        d='M274.924 222.952C275.334 222.652 275.715 222.319 276.063 221.958C276.803 221.226 277.804 220.252 279.034 219.059L289.201 208.928L288.711 208.771C288.95 211.335 289.201 214.119 289.463 217.008C289.725 219.896 289.998 222.764 290.237 225.381L290.294 225.988L290.726 225.538L300.62 215.281L303.444 212.288C303.803 211.945 304.123 211.57 304.4 211.168C303.99 211.488 303.61 211.838 303.261 212.215L300.324 215.103L290.282 225.224L290.772 225.381C290.533 222.775 290.282 219.949 290.009 217.008C289.736 214.067 289.485 211.335 289.246 208.771L289.189 208.175L288.757 208.614L278.738 218.881L275.88 221.885C275.532 222.217 275.212 222.574 274.924 222.952Z'
        fill='#E0E0E0'
      />
      <path
        d='M253.361 221.55C253.573 221.991 253.832 222.412 254.135 222.806L256.412 226.176L264.541 237.772L264.814 237.374L255.979 235.972L246.985 234.58L246.336 234.475L246.643 234.925L254.954 246.291L257.459 249.577C257.737 249.98 258.049 250.361 258.393 250.718C258.168 250.29 257.909 249.877 257.619 249.483L255.342 246.134L247.201 234.622L246.917 235.02L255.911 236.453L264.78 237.835L265.429 237.929L265.064 237.437L256.765 225.925L254.328 222.628C254.029 222.251 253.706 221.891 253.361 221.55V221.55Z'
        fill='#E0E0E0'
      />
      <path
        d='M282.04 241.833C282.254 242.27 282.513 242.687 282.814 243.078L285.091 246.459L293.208 258.044L293.493 257.646L284.635 256.244L275.63 254.842L274.981 254.747L275.357 255.239L283.668 266.616L286.115 269.881C286.387 270.284 286.7 270.662 287.049 271.011C286.821 270.584 286.563 270.172 286.275 269.776C285.683 268.939 284.909 267.819 283.998 266.438L275.858 254.925L275.573 255.323L284.567 256.757L293.436 258.128L294.085 258.233L293.721 257.73L285.421 246.218C284.419 244.857 283.599 243.748 282.973 242.921C282.691 242.538 282.379 242.174 282.04 241.833V241.833Z'
        fill='#E0E0E0'
      />
      <path
        d='M220.071 221.32C220.297 221.751 220.555 222.167 220.845 222.565L223.122 225.946L231.24 237.531L231.524 237.134L222.667 235.731L213.661 234.329L213.012 234.235L213.376 234.726L221.699 246.103C222.701 247.442 223.521 248.541 224.147 249.358C224.416 249.765 224.729 250.147 225.08 250.498C224.851 250.07 224.588 249.657 224.295 249.263L222.018 245.925L213.877 234.413L213.593 234.81L222.587 236.234L231.456 237.615L232.094 237.72L231.741 237.217L223.441 225.705L220.993 222.408C220.725 222.019 220.416 221.654 220.071 221.32V221.32Z'
        fill='#E0E0E0'
      />
      <path
        d='M184.322 274.005C184.729 273.72 185.11 273.405 185.461 273.063L188.58 270.3L199.293 260.65L198.815 260.472C198.906 263.047 198.997 265.831 199.1 268.73C199.202 271.629 199.328 274.507 199.43 277.102V277.709L199.897 277.28L210.325 267.495L213.308 264.638C213.679 264.314 214.021 263.964 214.333 263.591C213.928 263.877 213.547 264.192 213.195 264.533L210.098 267.286L199.51 276.935L199.999 277.113C199.908 274.496 199.806 271.671 199.703 268.74C199.601 265.81 199.476 263.057 199.373 260.483V259.886L198.918 260.305L188.364 270.101L185.347 272.968C184.974 273.287 184.632 273.633 184.322 274.005V274.005Z'
        fill='#E0E0E0'
      />
      <path
        d='M365.174 204.512C365.396 204.945 365.655 205.361 365.948 205.757L368.225 209.138L376.342 220.734L376.627 220.336L367.769 218.923L358.764 217.531L358.115 217.427L358.479 217.929L366.802 229.295L369.238 232.56C369.515 232.96 369.827 233.338 370.172 233.69C369.954 233.262 369.694 232.852 369.398 232.466L367.121 229.117C365.06 226.218 362.236 222.241 358.98 217.604L358.696 218.002L367.69 219.436L376.559 220.817L377.196 220.912L376.843 220.42C373.508 215.815 370.639 211.838 368.544 208.907L366.096 205.611C365.82 205.223 365.512 204.855 365.174 204.512Z'
        fill='#E0E0E0'
      />
      <path
        d='M410.19 282.775C409.806 283.093 409.456 283.444 409.143 283.821L406.365 286.888L396.926 297.594L397.427 297.72C397.017 295.177 396.585 292.414 396.118 289.536C395.651 286.658 395.196 283.821 394.775 281.236L394.684 280.64L394.274 281.121L385.097 291.922L382.49 295.062C382.159 295.425 381.862 295.814 381.602 296.223C381.976 295.899 382.322 295.549 382.638 295.177L385.371 292.121L394.649 281.435L394.148 281.32C394.558 283.905 395.002 286.71 395.469 289.619C395.936 292.529 396.38 295.25 396.801 297.793L396.892 298.39L397.302 297.919L406.592 287.097L409.245 283.957C409.589 283.583 409.904 283.188 410.19 282.775V282.775Z'
        fill='#E0E0E0'
      />
      <path
        d='M307.383 232.1C307.893 232.2 308.411 232.263 308.932 232.288L313.258 232.633L328.218 233.68L328.036 233.24L321.478 238.892L314.841 244.648L314.362 245.067H315.023L329.903 245.82L334.207 245.988C334.722 246.029 335.24 246.029 335.755 245.988C335.248 245.894 334.734 245.835 334.218 245.81L329.926 245.496L315.057 244.554L315.228 244.993L321.888 239.258L328.423 233.586L328.901 233.167H328.252L313.281 232.34L308.954 232.141C308.433 232.091 307.907 232.077 307.383 232.1V232.1Z'
        fill='#E0E0E0'
      />
      <path
        d='M332.612 284.847C332.232 285.175 331.875 285.525 331.542 285.894L328.707 288.908L319.018 299.436L319.519 299.562C319.166 297.008 318.779 294.245 318.381 291.367C317.982 288.489 317.572 285.632 317.242 283.047L317.151 282.44L316.741 282.911L307.314 293.534L304.639 296.673C304.307 297.038 304.003 297.422 303.728 297.825C304.112 297.503 304.466 297.153 304.787 296.778L307.587 293.774L317.151 283.309L316.661 283.173C317.014 285.768 317.401 288.573 317.8 291.493C318.198 294.413 318.597 297.144 318.938 299.698L319.029 300.295L319.439 299.834L328.969 289.18L331.678 286.04C332.031 285.672 332.344 285.272 332.612 284.847V284.847Z'
        fill='#E0E0E0'
      />
      <path
        d='M377.709 246.166C378.218 246.268 378.736 246.331 379.257 246.354L383.572 246.689L398.532 247.735L398.361 247.285L391.792 252.947L385.154 258.704L384.676 259.122H385.337L400.217 259.876L404.52 260.043C405.024 260.082 405.531 260.082 406.035 260.043C405.529 259.943 405.015 259.883 404.498 259.865L400.206 259.551L385.337 258.599L385.507 259.049L392.168 253.314L398.714 247.641L399.181 247.223H398.532L383.561 246.385L379.234 246.197C378.727 246.151 378.217 246.141 377.709 246.166Z'
        fill='#E0E0E0'
      />
      <path
        d='M338.965 283.664C339.475 283.764 339.993 283.823 340.514 283.842L344.84 284.188L359.8 285.234L359.618 284.784L353.06 290.446L346.423 296.202L345.944 296.621H346.593L361.485 297.364L365.789 297.542C366.304 297.583 366.822 297.583 367.337 297.542C366.831 297.442 366.317 297.382 365.8 297.364L361.508 297.05L346.639 296.098L346.81 296.548L353.47 290.813L360.005 285.161L360.483 284.753H359.834L344.863 283.916L340.525 283.727C340.008 283.663 339.486 283.642 338.965 283.664Z'
        fill='#E0E0E0'
      />
      <path
        d='M251.517 188.258C252.027 188.359 252.544 188.422 253.065 188.447L257.391 188.782L272.34 189.828L272.169 189.389L265.611 195.04L258.974 200.796L258.496 201.215H259.145L274.036 201.969L278.34 202.136C278.855 202.177 279.373 202.177 279.888 202.136C279.382 202.036 278.868 201.976 278.351 201.958L274.059 201.644L259.179 200.702L259.361 201.142L265.998 195.417L272.533 189.745L273.012 189.326H272.363L257.391 188.489L253.054 188.3C252.543 188.251 252.029 188.237 251.517 188.258V188.258Z'
        fill='#E0E0E0'
      />
      <path
        d='M294.051 283.664C294.051 283.664 274.901 278.588 268.56 277.102C266.089 276.527 255.513 281.655 248.203 285.925C248.203 285.925 238.139 291.409 238.947 293.094C239.847 294.957 243.501 294.58 249.479 292.047C249.479 292.047 247.406 297.563 251.471 297.437C251.471 297.437 249.456 303.403 253.258 304.02C257.061 304.638 257.949 297.029 261.296 296.621C264.643 296.213 271.543 295.794 271.543 295.794C271.543 295.794 277.315 304.585 281.789 305.213C286.264 305.841 296.59 304.722 296.59 304.722L294.051 283.664Z'
        fill='#FFBE9D'
      />
      <path
        d='M261.364 284.878C259.49 285.646 257.698 286.575 256.013 287.652C254.226 288.581 252.541 289.668 250.981 290.896C254.648 289.208 258.126 287.192 261.364 284.878V284.878Z'
        fill='#EB996E'
      />
      <path
        d='M262.492 288.301C260.304 288.934 258.179 289.735 256.139 290.697C254.03 291.485 251.996 292.434 250.059 293.534C252.248 292.908 254.373 292.109 256.412 291.147C258.525 290.364 260.56 289.411 262.492 288.301Z'
        fill='#EB996E'
      />
      <path
        d='M260.442 296.475C257.55 296.465 254.67 296.817 251.881 297.521C254.773 297.529 257.653 297.177 260.442 296.475V296.475Z'
        fill='#EB996E'
      />
      <path
        d='M318.973 102.01C318.747 102.688 318.744 103.412 318.964 104.092C319.183 104.772 319.615 105.378 320.207 105.833C320.798 106.288 321.522 106.574 322.289 106.653C323.055 106.733 323.83 106.603 324.517 106.28C327.113 105.076 327.443 101.78 326.931 99.1529C326.419 96.5259 325.451 93.7107 326.646 91.2721C327.568 89.4092 329.561 88.2266 331.109 86.7614C332.476 85.4878 333.502 83.9405 334.109 82.2409C334.715 80.5412 334.885 78.7355 334.604 76.9654C334.276 75.2006 333.424 73.5534 332.143 72.2053C330.861 70.8572 329.199 69.8606 327.341 69.3254C323.527 68.3521 318.825 69.4301 315.956 66.8869C313.895 65.0449 313.77 62.0412 312.176 59.8434C309.705 56.4316 304.48 55.8874 300.062 56.442C295.645 56.9967 291.159 58.3049 286.833 57.3107C283.224 56.4839 280.264 54.171 276.871 52.7476C273.173 51.1938 269.04 50.7412 265.048 51.4531C261.057 52.1649 257.408 54.0056 254.61 56.7177C251.813 59.4299 250.008 62.8779 249.447 66.5796C248.886 70.2812 249.598 74.0515 251.482 77.3631C252.739 79.7866 254.877 81.7301 257.528 82.8576'
        fill='#263238'
      />
      <path
        d='M297 188.468C294.097 188.462 291.223 187.928 288.547 186.894C285.87 185.86 283.443 184.349 281.406 182.446C279.37 180.544 277.765 178.289 276.683 175.813C275.602 173.336 275.066 170.686 275.106 168.018L275.391 148.782C275.391 148.782 257.812 147.159 256.651 131.147C255.49 115.134 256.321 78.1794 256.321 78.1794C265.993 73.7685 276.77 71.8087 287.541 72.5023C298.312 73.1959 308.686 76.5176 317.595 82.125L320.282 83.831L318.973 168.541C318.904 173.853 316.557 178.926 312.443 182.657C308.329 186.388 302.779 188.476 297 188.468V188.468Z'
        fill='#FFBE9D'
      />
      <path
        d='M260.92 107.243C261.556 107.176 262.143 106.896 262.568 106.457C262.994 106.018 263.229 105.45 263.229 104.862C263.229 104.274 262.994 103.706 262.568 103.267C262.143 102.828 261.556 102.548 260.92 102.481C260.782 102.449 260.637 102.449 260.499 102.481C259.863 102.548 259.277 102.828 258.851 103.267C258.425 103.706 258.19 104.274 258.19 104.862C258.19 105.45 258.425 106.018 258.851 106.457C259.277 106.896 259.863 107.176 260.499 107.243C260.638 107.264 260.781 107.264 260.92 107.243Z'
        fill='#263238'
      />
      <path
        d='M257.505 102.073C257.79 102.345 259.429 101.026 261.831 100.911C264.233 100.796 266.044 101.832 266.283 101.539C266.522 101.246 266.112 100.922 265.315 100.43C264.25 99.8015 262.994 99.5042 261.732 99.5814C260.469 99.6587 259.267 100.106 258.302 100.859C257.585 101.445 257.357 102.01 257.505 102.073Z'
        fill='#263238'
      />
      <path
        d='M285.273 102.617C284.86 102.88 284.541 103.25 284.357 103.679C284.172 104.109 284.131 104.579 284.237 105.03C284.344 105.482 284.593 105.894 284.955 106.215C285.316 106.535 285.773 106.75 286.267 106.833C286.761 106.915 287.271 106.86 287.732 106.676C288.193 106.491 288.583 106.186 288.855 105.797C289.126 105.408 289.266 104.955 289.257 104.493C289.248 104.031 289.09 103.582 288.803 103.203C288.419 102.696 287.831 102.349 287.17 102.239C286.508 102.129 285.826 102.265 285.273 102.617V102.617Z'
        fill='#263238'
      />
      <path
        d='M283.691 102.711C283.987 102.983 285.626 101.665 288.017 101.55C290.408 101.434 292.229 102.471 292.48 102.177C292.605 102.041 292.298 101.56 291.512 101.068C290.447 100.44 289.191 100.143 287.929 100.22C286.666 100.297 285.464 100.745 284.499 101.497C283.782 102.083 283.554 102.596 283.691 102.711Z'
        fill='#263238'
      />
      <path
        d='M275.107 121.403C275.107 121.236 273.615 121.026 271.179 120.838C270.553 120.838 270.04 120.702 269.983 120.398C269.938 119.821 270.059 119.243 270.336 118.724C270.871 117.227 271.406 115.72 271.976 114.14C274.253 107.62 275.744 102.251 275.391 102.146C275.038 102.041 272.921 107.243 270.678 113.763C270.154 115.354 269.642 116.903 269.152 118.368C268.791 119.13 268.719 119.981 268.947 120.786C269.049 121.003 269.205 121.195 269.403 121.346C269.601 121.497 269.835 121.602 270.086 121.654C270.441 121.727 270.805 121.752 271.167 121.728C273.627 121.686 275.107 121.581 275.107 121.403Z'
        fill='#263238'
      />
      <path
        d='M283.076 123.454C279.417 125.181 275.517 126.438 271.486 127.191C272.37 128.307 273.639 129.115 275.086 129.483C276.533 129.852 278.073 129.76 279.456 129.221C284.59 127.201 283.076 123.454 283.076 123.454Z'
        fill='white'
      />
      <path
        d='M275.323 148.551C283.382 148.287 291.174 145.824 297.706 141.476C297.706 141.476 293.015 153.271 275.539 152.706L275.323 148.551Z'
        fill='#EB996E'
      />
      <path
        d='M256.173 82.2297C256.173 82.2297 266.704 87.1486 280.651 81.0575C289.759 77.0596 300.427 75.2071 302.726 76.934C305.026 78.6609 305.334 87.2428 314.26 89.9116C314.26 89.9116 313.292 106.301 320.419 105.84C327.546 105.38 323.629 79.9168 323.629 79.9168L306.404 71.7953L287.493 68.1637L269.562 70.1627L255.649 73.3966L256.173 82.2297Z'
        fill='#263238'
      />
      <path
        d='M319.121 100.576C319.405 100.44 330.904 96.7143 331.166 107.693C331.428 118.671 319.394 116.652 319.371 116.338C319.349 116.024 319.121 100.576 319.121 100.576Z'
        fill='#FFBE9D'
      />
      <path
        d='M322.924 112.099C322.924 112.099 323.14 112.235 323.493 112.371C323.993 112.558 324.552 112.558 325.053 112.371C326.305 111.921 327.33 110.058 327.33 108.08C327.331 107.137 327.11 106.205 326.681 105.349C326.558 105.009 326.348 104.701 326.069 104.451C325.789 104.202 325.448 104.018 325.075 103.915C324.834 103.874 324.586 103.907 324.366 104.007C324.147 104.107 323.968 104.269 323.857 104.469C323.709 104.773 323.789 104.993 323.732 105.014C323.675 105.035 323.47 104.836 323.55 104.386C323.612 104.112 323.773 103.865 324.005 103.685C324.327 103.452 324.736 103.343 325.144 103.381C325.637 103.459 326.099 103.652 326.488 103.942C326.877 104.231 327.178 104.608 327.364 105.035C327.89 105.977 328.163 107.021 328.161 108.08C328.161 110.288 326.954 112.392 325.246 112.884C324.927 112.974 324.59 112.997 324.259 112.954C323.929 112.911 323.613 112.801 323.333 112.633C322.924 112.371 322.924 112.12 322.924 112.099Z'
        fill='#EB996E'
      />
      <path
        d='M325.201 66.2171C323.838 65.8497 322.383 65.8979 321.053 66.3545C319.723 66.8111 318.59 67.6515 317.823 68.7498C318.885 68.0107 320.03 67.3791 321.239 66.8659C322.531 66.5237 323.859 66.3062 325.201 66.2171V66.2171Z'
        fill='#263238'
      />
      <path
        d='M318.017 59.6446C317.8 59.6446 318.131 61.9575 317.721 64.6995C317.366 66.3481 316.805 67.9531 316.047 69.4824C316.047 69.4824 316.548 69.0952 317.049 68.2788C317.692 67.1924 318.116 66.0082 318.301 64.7832C318.633 63.0728 318.536 61.3152 318.017 59.6446Z'
        fill='#263238'
      />
      <path
        d='M268.958 96.7771C269.027 96.6515 268.514 96.2643 267.547 95.9085C264.978 94.9952 262.126 95.0214 259.577 95.9817C258.621 96.3585 258.108 96.7457 258.177 96.8713C258.325 97.1644 260.624 96.3376 263.573 96.3376C266.522 96.3376 268.822 97.1016 268.958 96.7771Z'
        fill='#263238'
      />
      <path
        d='M295.599 94.4956C295.668 94.37 295.155 93.9932 294.188 93.6269C292.914 93.1743 291.557 92.9504 290.192 92.9676C288.82 92.9673 287.462 93.2163 286.195 93.7002C285.239 94.077 284.738 94.4642 284.795 94.6002C284.852 94.7363 287.243 94.0665 290.203 94.056C293.163 94.0456 295.44 94.7886 295.599 94.4956Z'
        fill='#263238'
      />
      <path
        d='M271.019 98.5354C270.993 98.751 270.993 98.9686 271.019 99.1842C271.019 99.6447 271.019 100.231 270.962 100.953C270.905 101.675 270.962 102.648 270.894 103.695C270.937 104.903 270.744 106.108 270.324 107.253C269.775 108.739 268.825 110.072 267.563 111.129C266.3 112.185 264.767 112.929 263.106 113.292C262.153 113.506 261.17 113.584 260.192 113.522C259.174 113.48 258.17 113.286 257.22 112.947C255.195 112.249 253.469 110.968 252.302 109.294C251.197 107.696 250.641 105.829 250.708 103.936C250.708 102.062 250.708 100.262 250.708 98.5354L250.435 98.7865L265.235 98.661L269.527 98.5877H270.666H271.087C270.6 98.5379 270.109 98.5169 269.619 98.5249L265.395 98.4516L250.435 98.326H250.059V98.5877C250.059 100.315 250.059 102.115 250.059 103.988C249.983 105.984 250.566 107.955 251.733 109.64C252.97 111.411 254.8 112.767 256.947 113.501C257.956 113.858 259.021 114.059 260.101 114.098C261.13 114.154 262.163 114.066 263.163 113.836C264.905 113.449 266.509 112.657 267.821 111.535C269.133 110.413 270.109 109 270.655 107.431C271.063 106.234 271.233 104.979 271.156 103.726C271.156 102.68 271.156 101.706 271.099 100.911C271.042 100.116 271.099 99.6238 271.042 99.1633C271.052 98.9538 271.045 98.7438 271.019 98.5354V98.5354Z'
        fill='#263238'
      />
      <path
        d='M298.924 97.9912C298.924 97.9912 298.924 98.2319 298.924 98.64V100.409C298.924 101.183 298.924 102.104 298.867 103.161C298.897 104.368 298.704 105.571 298.298 106.72C297.773 108.098 296.912 109.347 295.782 110.372C294.476 111.539 292.851 112.36 291.079 112.748C289.121 113.182 287.065 113.061 285.182 112.403C283.163 111.706 281.442 110.428 280.275 108.76C279.166 107.163 278.606 105.296 278.67 103.402C278.67 101.529 278.67 99.718 278.67 97.9912L278.397 98.2423L293.197 98.1167L297.478 98.0539H298.616H299.038C298.554 98.0044 298.067 97.9834 297.58 97.9912L293.356 97.9284L278.397 97.8028H278.123V98.0539C278.123 99.7808 278.123 101.591 278.123 103.465C278.055 105.461 278.642 107.431 279.808 109.116C281.046 110.882 282.871 112.233 285.011 112.968C286.02 113.323 287.085 113.524 288.165 113.564C289.198 113.62 290.234 113.532 291.239 113.303C293.099 112.881 294.8 112.002 296.157 110.759C297.332 109.675 298.213 108.352 298.73 106.898C299.139 105.7 299.309 104.445 299.231 103.193C299.231 102.146 299.231 101.173 299.163 100.377C299.095 99.5819 299.163 99.0901 299.163 98.6296C299.121 98.4063 299.041 98.1907 298.924 97.9912V97.9912Z'
        fill='#263238'
      />
      <path
        d='M270.768 99.2994C273.372 99.6625 276.02 99.6625 278.624 99.2994C276.021 98.9227 273.371 98.9227 270.768 99.2994V99.2994Z'
        fill='#263238'
      />
      <path
        d='M320.646 99.4354C317.027 99.158 313.394 99.0567 309.762 99.1319C306.128 99.0372 302.492 99.1175 298.867 99.3726C302.486 99.663 306.12 99.7643 309.751 99.6761C313.385 99.7829 317.022 99.7025 320.646 99.4354V99.4354Z'
        fill='#263238'
      />
      <path
        d='M203.529 296.935C200.97 301.3 197.095 304.893 192.369 307.282C187.644 309.672 182.268 310.756 176.888 310.404V310.404C170.531 309.995 164.517 307.606 159.837 303.632C155.157 299.657 152.091 294.334 151.146 288.541L144.543 219.059L170.99 213.052L181.795 267.474C181.795 267.474 187.441 290.279 203.529 296.935Z'
        fill='#FFBE9D'
      />
      <path
        d='M171.229 218.086L170.352 205.893C170.352 205.893 166.607 178.536 166.607 175.856C166.607 172.78 165.001 172.026 163.76 172.11C161.836 172.225 160.47 174.412 160.345 177.06C160.345 177.971 160.436 192.434 160.436 192.434C159.526 191.068 158.51 189.763 157.396 188.531C155.119 185.82 149.609 184.271 143.996 184.438C138.383 184.606 136.846 185.108 136.812 187.107C136.832 187.489 136.944 187.862 137.141 188.199C137.338 188.536 137.615 188.828 137.95 189.054C138.986 189.848 140.303 190.265 141.651 190.226C150.633 190.079 153.036 194.486 153.036 194.486L153.15 196.087C153.15 196.087 147.696 192.497 143.347 193.994C142.705 194.212 142.116 194.547 141.616 194.977C140.392 194.982 139.19 195.281 138.13 195.844C137.069 196.407 136.188 197.216 135.572 198.189C134.957 199.162 134.628 200.266 134.62 201.392C134.612 202.517 134.924 203.625 135.525 204.606C135.76 205.109 136.156 205.534 136.66 205.824C137.165 206.114 137.754 206.255 138.349 206.228C138.951 206.062 139.496 205.756 139.931 205.339C140.186 205.14 140.457 204.958 140.74 204.794L141.742 209.221C141.742 209.221 144.577 219.059 145.282 223.476'
        fill='#FFBE9D'
      />
      <path
        d='M168.645 217.134C167.005 217.552 165.309 216.443 164.421 215.103C163.533 213.764 163.157 212.183 162.383 210.781C160.038 206.594 154.413 204.606 152.262 200.315C151.966 199.718 151.806 198.902 152.375 198.504C152.945 198.107 153.651 198.431 154.231 198.724L169.032 206.26'
        fill='#FFBE9D'
      />
      <path
        d='M169.043 206.239C168.582 205.863 168.074 205.54 167.529 205.276C166.527 204.7 165.058 203.905 163.237 202.931L156.804 199.551L154.949 198.588C154.257 198.085 153.376 197.856 152.501 197.95C152.23 198.03 151.993 198.184 151.82 198.392C151.647 198.599 151.547 198.85 151.533 199.111C151.503 199.542 151.585 199.972 151.772 200.367C152.114 201.066 152.542 201.726 153.047 202.335C155.097 204.784 157.749 206.322 159.662 208.112C160.592 208.922 161.383 209.857 162.007 210.885C162.565 211.848 162.918 212.853 163.351 213.764C163.617 214.523 164.06 215.221 164.65 215.807C165.24 216.392 165.962 216.852 166.766 217.154C167.984 217.489 168.668 217.154 168.622 217.092C168.032 217.108 167.444 217.019 166.891 216.83C166.179 216.496 165.551 216.029 165.045 215.46C164.539 214.891 164.168 214.231 163.954 213.523C163.59 212.509 163.168 211.513 162.69 210.54C162.062 209.426 161.244 208.412 160.265 207.536C158.307 205.653 155.711 204.072 153.799 201.801C153.348 201.253 152.966 200.659 152.66 200.032C152.376 199.436 152.421 198.839 152.785 198.777C153.15 198.714 153.867 198.996 154.493 199.321L156.383 200.263L162.907 203.528C164.774 204.439 166.322 205.15 167.347 205.621C167.878 205.9 168.449 206.107 169.043 206.239V206.239Z'
        fill='#EB996E'
      />
      <path
        d='M153.355 196.202C153.254 195.212 152.899 194.258 152.319 193.418C151.644 192.557 150.686 191.919 149.586 191.597C148.208 191.181 146.767 190.969 145.317 190.969C143.828 190.983 142.341 190.864 140.877 190.613C139.65 190.383 138.511 189.86 137.575 189.096C137.264 188.832 137.022 188.506 136.868 188.143C136.714 187.781 136.652 187.391 136.687 187.003C136.703 186.717 136.806 186.442 136.983 186.207C137.04 186.207 136.983 186.511 136.926 187.013C136.931 187.364 137.02 187.709 137.185 188.026C137.35 188.342 137.588 188.621 137.882 188.845C138.792 189.503 139.863 189.948 141.002 190.142C142.435 190.359 143.887 190.457 145.339 190.435C146.844 190.42 148.341 190.636 149.768 191.074C150.962 191.456 151.983 192.192 152.671 193.167C153.117 193.823 153.376 194.572 153.423 195.344C153.488 195.63 153.464 195.927 153.355 196.202V196.202Z'
        fill='#EB996E'
      />
      <path
        d='M160.425 176.034C160.521 176.992 160.521 177.955 160.425 178.913C160.425 180.88 160.425 183.235 160.345 185.83C160.265 188.426 160.345 190.781 160.345 192.748C160.413 193.708 160.391 194.671 160.277 195.626C160.028 194.688 159.902 193.725 159.901 192.759C159.764 190.99 159.673 188.572 159.685 185.83C159.696 183.088 159.81 180.66 159.947 178.892C159.994 177.926 160.154 176.968 160.425 176.034V176.034Z'
        fill='#EB996E'
      />
      <path
        d='M149.802 199.415C149.484 199.345 149.178 199.236 148.892 199.091C148.521 198.916 148.113 198.817 147.697 198.798C147.281 198.78 146.865 198.844 146.478 198.986C146.24 199.105 146.04 199.278 145.896 199.489C145.752 199.7 145.67 199.941 145.658 200.189C145.622 200.814 145.672 201.44 145.806 202.052C145.995 202.823 145.86 203.63 145.431 204.313C145.158 204.664 144.8 204.951 144.385 205.151C143.969 205.351 143.509 205.459 143.04 205.464C142.579 205.461 142.123 205.373 141.701 205.203C141.278 205.034 140.897 204.788 140.58 204.48C139.987 203.933 139.59 203.232 139.442 202.471C139.263 201.24 139.283 199.992 139.499 198.766C139.617 197.937 139.89 197.133 140.307 196.39C140.626 195.867 140.922 195.668 140.945 195.689C140.968 195.71 140.763 195.972 140.524 196.485C140.211 197.237 140.016 198.026 139.943 198.829C139.793 200.009 139.812 201.202 140 202.377C140.145 203.042 140.52 203.647 141.07 204.1C141.62 204.553 142.315 204.829 143.051 204.889C143.421 204.887 143.786 204.805 144.116 204.651C144.446 204.496 144.733 204.273 144.952 203.999C145.296 203.425 145.405 202.757 145.26 202.115C145.133 201.455 145.102 200.783 145.169 200.116C145.202 199.798 145.324 199.492 145.523 199.23C145.723 198.968 145.993 198.757 146.307 198.62C146.75 198.465 147.227 198.408 147.698 198.456C148.169 198.503 148.621 198.652 149.017 198.892C149.552 199.185 149.871 199.342 149.802 199.415Z'
        fill='#EB996E'
      />
      <path
        d='M140.843 204.041C140.922 204.314 140.89 204.605 140.752 204.857C140.591 205.216 140.344 205.537 140.028 205.794C139.713 206.052 139.337 206.24 138.93 206.343C138.317 206.464 137.68 206.437 137.081 206.265C136.483 206.093 135.943 205.781 135.514 205.36C134.488 204.323 133.937 202.962 133.977 201.56C133.978 200.176 134.447 198.826 135.321 197.699C136.034 196.779 137.006 196.055 138.133 195.605C138.872 195.299 139.668 195.125 140.478 195.093C141.048 195.093 141.355 195.093 141.355 195.176C140.304 195.301 139.28 195.569 138.315 195.972C137.304 196.433 136.435 197.121 135.788 197.971C135.031 199.026 134.634 200.265 134.649 201.529C134.611 202.784 135.082 204.008 135.97 204.962C136.331 205.337 136.79 205.622 137.304 205.789C137.818 205.955 138.37 205.999 138.907 205.914C139.266 205.84 139.603 205.697 139.895 205.493C140.188 205.289 140.43 205.029 140.604 204.732C140.763 204.334 140.763 204.041 140.843 204.041Z'
        fill='#EB996E'
      />
      <path
        d='M151.658 213.711C152.147 213.569 152.672 213.569 153.161 213.711C154.443 213.969 155.596 214.609 156.44 215.532L155.916 215.679C155.986 215.031 156.1 214.388 156.258 213.753C156.711 211.503 157.834 209.413 159.503 207.714C158.388 209.677 157.502 211.744 156.861 213.879C156.702 214.528 156.588 215.155 156.508 215.742L156.417 216.39L155.973 215.878C155.218 215.022 154.21 214.386 153.07 214.046C152.216 213.805 151.658 213.784 151.658 213.711Z'
        fill='#EB996E'
      />
      <path
        d='M151.374 189.106C151.522 189.169 150.918 190.153 149.529 190.749C148.14 191.346 146.911 191 146.968 190.854C147.025 190.707 148.106 190.718 149.245 190.226C150.383 189.734 151.226 189.002 151.374 189.106Z'
        fill='#EB996E'
      />
      <path
        d='M175.783 225.977L142.55 228.164C142.55 228.164 147.662 282.22 148.653 290.603C149.643 298.986 155.484 311.629 175.783 311.629C196.083 311.629 199.863 305.276 208.891 292.194C198.246 285.412 181.339 265.161 181.339 265.161L175.783 225.977Z'
        fill='#89C1BA'
      />
      <path
        d='M160.117 233.251C160.288 233.706 160.494 234.151 160.732 234.58L162.588 238.18L169.146 250.624L169.476 250.258L160.857 247.903L152.102 245.538L151.465 245.37L151.772 245.904L158.603 258.097L160.618 261.603C160.841 262.031 161.104 262.441 161.404 262.827C161.23 262.379 161.024 261.942 160.789 261.519C160.311 260.619 159.651 259.426 158.922 257.95L152.307 245.674L151.977 246.04L160.721 248.426L169.351 250.75L169.977 250.917L169.681 250.383L162.884 238.065C162.053 236.61 161.381 235.417 160.88 234.528C160.665 234.083 160.41 233.656 160.117 233.251V233.251Z'
        fill='#E0E0E0'
      />
      <path
        d='M163.26 285.245C163.769 285.345 164.287 285.408 164.808 285.433L169.134 285.778L184.094 286.825L183.912 286.375L177.354 292.027L170.717 297.783L170.239 298.201H170.899L185.779 298.955L190.083 299.122C190.598 299.163 191.116 299.163 191.631 299.122C191.124 299.03 190.61 298.974 190.094 298.955L185.802 298.63L170.933 297.689L171.104 298.139L177.764 292.393L184.299 286.72L184.777 286.312H184.128L169.157 285.485L164.819 285.287C164.301 285.236 163.78 285.222 163.26 285.245V285.245Z'
        fill='#E0E0E0'
      />
      <path
        d='M104.091 92.1408L137.985 80.9529L171.502 91.9943C175.783 93.8467 179.54 94.9666 179.54 98.6296V116.903C179.619 129.737 175.807 142.338 168.531 153.292C161.26 164.347 150.487 173.093 137.654 178.358C123.992 174.266 113.746 163.339 107.427 154.004C100.131 143.055 96.2988 130.454 96.3609 117.614V101.183C96.3609 95.9503 99.3893 93.3967 104.091 92.1408Z'
        fill='#89C1BA'
      />
      <g opacity='0.3'>
        <path
          opacity='0.3'
          d='M137.654 167.725C147.823 163.509 156.345 156.533 162.075 147.735C167.844 139.078 170.875 129.116 170.83 118.965V106.196C170.83 103.287 168.918 100.566 165.138 98.9435L137.973 89.6081V80.9947L171.502 91.9943C175.02 93.4071 179.54 94.9665 179.54 98.6296V116.903C179.619 129.737 175.807 142.338 168.531 153.292C161.26 164.347 150.487 173.093 137.654 178.358V167.725Z'
          fill='black'
        />
      </g>
      <g opacity='0.4'>
        <path
          opacity='0.4'
          d='M137.655 178.326C135.355 177.636 133.126 176.76 130.994 175.71C125.751 172.984 120.984 169.547 116.854 165.516C113.119 161.818 109.833 157.758 107.052 153.407C99.6696 142.378 95.9539 129.606 96.361 116.662V101.581C96.361 94.0665 100.573 93.5118 104.103 92.0989L137.996 80.911V89.5244L110.513 99.0168C106.733 100.639 104.82 103.36 104.82 106.259V119.08C104.775 129.228 107.807 139.187 113.575 147.84C119.182 156.597 127.602 163.544 137.677 167.725L137.655 178.326Z'
          fill='black'
        />
      </g>
      <path
        opacity='0.5'
        d='M128.991 118.148V115.678C128.991 113.533 129.918 111.475 131.568 109.958C133.219 108.441 135.457 107.588 137.791 107.588C140.125 107.588 142.364 108.441 144.014 109.958C145.665 111.475 146.592 113.533 146.592 115.678V118.19C147.757 118.35 148.82 118.89 149.589 119.71C150.358 120.53 150.781 121.576 150.781 122.659V137.206C150.781 138.406 150.263 139.556 149.341 140.403C148.419 141.251 147.168 141.728 145.863 141.728H129.378C128.073 141.728 126.822 141.251 125.9 140.403C124.977 139.556 124.459 138.406 124.459 137.206V122.659C124.458 121.521 124.924 120.424 125.764 119.588C126.604 118.752 127.756 118.238 128.991 118.148V118.148ZM135.4 133.575H139.704L139.032 130.718C139.503 130.436 139.862 130.021 140.056 129.537C140.25 129.052 140.268 128.522 140.108 128.027C139.948 127.532 139.619 127.098 139.168 126.789C138.718 126.48 138.171 126.313 137.609 126.313C137.047 126.313 136.5 126.48 136.05 126.789C135.599 127.098 135.27 127.532 135.11 128.027C134.95 128.522 134.968 129.052 135.162 129.537C135.356 130.021 135.715 130.436 136.186 130.718C135.89 131.785 135.4 133.575 135.4 133.575ZM130.846 118.127H144.691V115.678C144.618 114.039 143.859 112.49 142.572 111.354C141.285 110.218 139.57 109.583 137.785 109.583C136.001 109.583 134.286 110.218 132.999 111.354C131.712 112.49 130.953 114.039 130.88 115.678L130.846 118.127Z'
        fill='white'
      />
      <path
        d='M165.332 117.97C165.628 117.152 165.8 116.299 165.844 115.438C166.097 113.378 166.189 111.303 166.117 109.231C166.117 108.017 165.992 106.866 165.878 105.82C165.822 104.865 165.562 103.93 165.115 103.067C164.795 102.476 164.276 101.995 163.635 101.696C163.168 101.497 162.884 101.508 162.884 101.539C163.702 101.884 164.359 102.485 164.74 103.235C165.103 104.071 165.304 104.961 165.332 105.861C165.423 106.908 165.491 108.049 165.525 109.252C165.605 111.649 165.525 113.826 165.423 115.406C165.319 116.257 165.289 117.115 165.332 117.97V117.97Z'
        fill='white'
      />
      <path
        d='M164.842 125.914C165.001 125.914 165.149 125.171 165.172 124.239C165.195 123.308 165.07 122.554 164.91 122.554C164.751 122.554 164.603 123.297 164.58 124.229C164.557 125.16 164.671 125.914 164.842 125.914Z'
        fill='white'
      />
      <path
        d='M198.462 221.561L236.249 307.464H304.389V311.346H101.427L59.9061 221.561H198.462Z'
        fill='#263238'
      />
      <path
        d='M140.694 272.278C142.06 276.6 146.045 279.332 149.597 278.39C153.149 277.448 154.914 273.157 153.548 268.834C152.182 264.512 148.185 261.78 144.633 262.722C141.081 263.664 139.317 267.955 140.694 272.278Z'
        fill='#FAFAFA'
      />
    </>
  ),
});
