import {configureStore} from '@reduxjs/toolkit';

import saga from '../sagas';
import {middleware, sagaMiddleware} from '../slices';
import rootReducer from './rootReducer';

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

sagaMiddleware.run(saga);

export type AppDispatch = typeof store.dispatch;

export default store;
