import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const FotoIcon = createIcon({
  displayName: 'FotoIcon',
  viewBox: '0 0 23 24',
  path: (
    <>
      <path
        d='M11.3464 0.882798C11.3357 0.882798 11.3251 0.881465 11.3144 0.881465C7.56107 0.881465 4.24773 2.74013 2.22507 5.58013L4.4664 10.2428L11.3464 0.882798Z'
        fill='#F75266'
      />
      <path
        d='M2.55148 18.9361C4.51148 21.4228 7.50348 23.0481 10.8848 23.1775L13.9661 18.9361H2.55148Z'
        fill='#F75266'
      />
      <path
        d='M1.39721 6.93612C0.607874 8.46678 0.154541 10.1988 0.154541 12.0401C0.154541 14.0695 0.703874 15.9641 1.64921 17.6028H6.52654L1.39721 6.93612Z'
        fill='#F75266'
      />
      <path
        d='M20.4205 5.60278C18.6899 3.16145 16.0059 1.45345 12.9085 1.00945L9.53119 5.60278H20.4205Z'
        fill='#F75266'
      />
      <path
        d='M12.5709 23.1237C15.8016 22.7624 18.6096 21.0197 20.4029 18.5011L18.6229 14.7984L12.5709 23.1237Z'
        fill='#F75266'
      />
      <path
        d='M21.2305 17.1472C22.0212 15.6165 22.4745 13.8832 22.4745 12.0405C22.4745 10.1992 22.0212 8.46719 21.2319 6.93652H16.3212L21.2305 17.1472Z'
        fill='#F75266'
      />
      <path
        d='M8.55081 6.93612L5.12015 11.6028L8.00548 17.6028H14.9348L17.9668 13.4335L14.8428 6.93612H8.55081Z'
        fill='#F75266'
      />
      <path
        d='M14.9349 17.6028H8.00561L5.12028 11.6028L8.55095 6.93613H14.8429L17.9656 13.4335L14.9349 17.6028ZM16.3216 6.93613H21.2323C20.9909 6.47079 20.7216 6.02679 20.4203 5.60279H9.53095L12.9083 1.00946C12.3976 0.934793 11.8776 0.88546 11.3469 0.882793L4.46561 10.2415L2.22561 5.58012C1.91895 6.01079 1.64161 6.46413 1.39761 6.93613L6.52561 17.6028H1.64961C1.91761 18.0708 2.21895 18.5161 2.55095 18.9361H13.9656L10.8843 23.1775C11.0283 23.1828 11.1696 23.1988 11.3136 23.1988C11.7403 23.1988 12.1576 23.1708 12.5709 23.1241L18.6229 14.7975L20.4029 18.5015C20.7096 18.0721 20.9856 17.6201 21.2309 17.1468L16.3216 6.93613Z'
        fill='white'
      />
    </>
  ),
});
