import {useQuery} from '@tanstack/react-query';

import {getEventsU} from '../services/eventService';

export const useEventsUser = (users, type) => {
  const query = useQuery({
    queryKey: ['users-events', users],
    queryFn: () => {
      if (!users || !users.length || type !== 'calendar') {
        return null;
      }
      return getEventsU(users);
    },
    refetchOnWindowFocus: false,
  });

  return {
    ...query,
    data: query.data || [],
  };
};
