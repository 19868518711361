import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const MicrosoftIcon = createIcon({
  displayName: 'MicrosoftIcon',
  viewBox: '0 0 52 65',
  path: (
    <>
      <path
        d='M33.7427 0.821678L0 13.3043V50.227L11.1173 46.071L11.1533 15.691L33.768 10.355V55.6657L0 50.7043L33.5467 64.6003L51.296 58.359V5.89235L33.7427 0.821678Z'
        fill='#D72C16'
      />
    </>
  ),
});
