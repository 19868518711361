import {Colors, theme} from '@chakra-ui/react';

const colors: Colors = {
  ...theme.colors,
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000000',
  white: '#FFFFFF',
  semiBlack: '#333333',
  darkBlue: {
    50: '#e3f3ff',
    100: '#b8cddf',
    200: '#8da7c0',
    300: '#6381a1',
    400: '#385b82',
    500: '#0e3663',
    600: '#0a2b51',
    700: '#07213f',
    800: '#03172d',
    900: '#000d1c',
  },
  //blue: '#3885ff',
  blue: {
    50: '#d2dff3',
    100: '#a1c3f6',
    200: '#87b3f8',
    300: '#6ca4fa',
    400: '#5294fc',
    500: '#3885fe',
    600: '#2d71d7',
    700: '#235db0',
    800: '#184989',
    900: '#0e3562',
  },
  //grayBlue: '#395E82',
  grayBlue: {
    50: '#e6f3ff',
    100: '#c5d5e5',
    200: '#a4b7cb',
    300: '#8399b2',
    400: '#627b98',
    500: '#415d7f',
    600: '#354f6d',
    700: '#2a415b',
    800: '#1e3349',
    900: '#132638',
  },
  //green: '#33a854',
  green: {
    50: '#e2fcea',
    100: '#bfeecd',
    200: '#9be2af',
    300: '#75d590',
    400: '#4fca72',
    500: '#33a854',
    600: '#288943',
    700: '#1a6230',
    800: '#0b3c1a',
    900: '#001603',
  },
  //teal: '#5eb5ab',
  teal: {
    50: '#e1faf8',
    100: '#cbebe8',
    200: '#b5ddd9',
    300: '#9fcfc9',
    400: '#89c1ba',
    500: '#73b3ab',
    600: '#5a938c',
    700: '#41746d',
    800: '#28544f',
    900: '#0f3531',
  },
  //red: '#f75266',
  red: {
    50: '#f1d7d9',
    100: '#f2bcc2',
    200: '#f3a2ab',
    300: '#f38794',
    400: '#f46c7d',
    500: '#f55266',
    600: '#d94d5e',
    700: '#bd4957',
    800: '#a1444f',
    900: '#854048',
  },
  //orange: '#fa704d',
  orange: {
    50: '#ffe8e0',
    100: '#ffc2b3',
    200: '#fd9b82',
    300: '#fa7451',
    400: '#f84d21',
    500: '#de3308',
    600: '#ae2705',
    700: '#7d1b02',
    800: '#4c0f00',
    900: '#1f0300',
  },
  //pink: '#fabac2',
  pink: {
    50: '#ffe5e9',
    100: '#fedce1',
    200: '#fdd3d9',
    300: '#fccbd1',
    400: '#fbc2c9',
    500: '#fabac2',
    600: '#f7a2ac',
    700: '#f48b97',
    800: '#f27382',
    900: '#f05c6d',
  },
  //yellow: '#ffb800',
  yellow: {
    50: '#FFF8E5',
    100: '#FFEBB8',
    200: '#FFDE8A',
    300: '#FFD25C',
    400: '#FFC52E',
    500: '#FFB800',
    600: '#d29700',
    700: '#a67700',
    800: '#7a5700',
    900: '#4e3700',
  },
  //lightYellow: '#fcd673',
  lightYellow: {
    50: '#fff8de',
    100: '#fef1c8',
    200: '#fdeab3',
    300: '#fde39d',
    400: '#fcdc88',
    500: '#fcd673',
    600: '#fbcc52',
    700: '#f9bd24',
    800: '#e0a410',
    900: '#af7f08',
  },
  //gray: '#D9D9D9',
  gray: {
    50: '#f2f1f2',
    100: '#dbdadb',
    200: '#c4c3c4',
    300: '#adacad',
    400: '#969596',
    500: '#807E80',
    600: '#6c6b6c',
    700: '#595859',
    800: '#464546',
    900: '#333233',
  },
};

export default colors;
