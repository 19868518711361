import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';

export interface DisclosureFormCalendarState {
  disclosure: any;
}

export interface DisclosureFormCalendarStates {
  data: DisclosureFormCalendarState;
}

const initialState = {data: {disclosure: ''}} as DisclosureFormCalendarStates;
const disclosureFormCalendarStateSlice = createSlice({
  name: 'disclosureFormCalendarState',
  initialState,
  reducers: {
    getDisclosureFormCalendarState(
      state,
      action: PayloadAction<DisclosureFormCalendarState>,
    ) {
      state.data = action.payload;
    },
  },
});

export const getDisclosureFormCalendarStateSelector = (state: RootState) =>
  state.disclosureFormCalendarState.data;

export default disclosureFormCalendarStateSlice.reducer;
export const {getDisclosureFormCalendarState} =
  disclosureFormCalendarStateSlice.actions;
