import {call, put, takeEvery} from 'redux-saga/effects';

import * as api from '../services/toursService';
import {getTours, getToursError, getToursSuccess} from '../slices/toursSlice';

export function* getTour({payload}) {
  try {
    const result = yield call(api.getTours, payload);
    yield put(getToursSuccess(result));
  } catch (e: any) {
    yield put(getToursError(e.toString()));
  }
}

export default function* rootSaga() {
  yield takeEvery(getTours, getTour);
}
