import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const KilometrajeIcon = createIcon({
  displayName: 'KilometrajeIcon',
  viewBox: '0 0 41 40',
  path: (
    <>
      <path
        d='M20.7947 5.90199C23.906 5.90827 26.9456 6.82452 29.5293 8.53495C32.113 10.2454 34.1247 12.6731 35.3102 15.5114C36.4958 18.3498 36.8018 21.4711 36.1898 24.4811C35.5777 27.491 34.075 30.2543 31.8716 32.4217C29.6681 34.5891 26.8629 36.0633 23.8105 36.658C20.7581 37.2527 17.5955 36.9411 14.7225 35.7627C11.8495 34.5843 9.39509 32.5919 7.66949 30.0375C5.94389 27.483 5.02457 24.481 5.02775 21.4111C5.02775 19.3731 5.43485 17.3551 6.2258 15.4724C7.01674 13.5897 8.17601 11.8793 9.63732 10.4389C11.0986 8.99854 12.8334 7.85641 14.7423 7.07793C16.6512 6.29945 18.6969 5.8999 20.7625 5.90199'
        fill='#A3D8B2'
      />
      <path
        d='M11.4632 12.1088C11.4632 11.7805 11.5954 11.4656 11.8308 11.2334C12.0661 11.0012 12.3853 10.8706 12.7181 10.8706H15.244C15.4088 10.8706 15.572 10.9027 15.7243 10.9649C15.8765 11.0271 16.0149 11.1184 16.1314 11.2334C16.2479 11.3483 16.3404 11.4847 16.4034 11.635C16.4665 11.7852 16.499 11.9462 16.499 12.1088C16.499 12.2715 16.4665 12.4325 16.4034 12.5827C16.3404 12.733 16.2479 12.8694 16.1314 12.9843C16.0149 13.0993 15.8765 13.1906 15.7243 13.2528C15.572 13.315 15.4088 13.3471 15.244 13.3471H12.7181C12.3853 13.3471 12.0661 13.2165 11.8308 12.9843C11.5954 12.7521 11.4632 12.4372 11.4632 12.1088Z'
        fill='#33A854'
      />
      <path
        d='M18.8962 31.9676C18.8962 32.296 18.764 32.6109 18.5286 32.8431C18.2933 33.0753 17.9741 33.2058 17.6413 33.2058H15.1314C14.7986 33.2058 14.4794 33.0753 14.2441 32.8431C14.0087 32.6109 13.8765 32.296 13.8765 31.9676C13.8765 31.6392 14.0087 31.3242 14.2441 31.092C14.4794 30.8598 14.7986 30.7294 15.1314 30.7294H17.6413C17.9741 30.7294 18.2933 30.8598 18.5286 31.092C18.764 31.3242 18.8962 31.6392 18.8962 31.9676Z'
        fill='#33A854'
      />
      <path
        d='M20.1512 19.5538C20.1512 19.8822 20.0189 20.1971 19.7836 20.4293C19.5483 20.6615 19.2291 20.7919 18.8962 20.7919H13.8605V22.0302C13.8626 22.1941 13.8317 22.3568 13.7696 22.5089C13.7075 22.661 13.6154 22.7994 13.4987 22.916C13.3819 23.0327 13.2429 23.1253 13.0895 23.1885C12.9362 23.2517 12.7717 23.2842 12.6056 23.2842H11.3506C11.0178 23.2842 10.6986 23.4147 10.4633 23.6469C10.2279 23.8791 10.0957 24.1941 10.0957 24.5225C10.0957 24.8508 10.2279 25.1657 10.4633 25.3979C10.6986 25.6302 11.0178 25.7606 11.3506 25.7606H15.7589C15.9237 25.7606 16.0869 25.7927 16.2392 25.8549C16.3914 25.9171 16.5298 26.0083 16.6463 26.1233C16.7628 26.2382 16.8553 26.3747 16.9183 26.525C16.9814 26.6752 17.0139 26.8362 17.0139 26.9988C17.0139 27.1614 16.9814 27.3224 16.9183 27.4726C16.8553 27.6229 16.7628 27.7594 16.6463 27.8743C16.5298 27.9893 16.3914 28.0806 16.2392 28.1428C16.0869 28.205 15.9237 28.237 15.7589 28.237H6.63665C5.69052 26.3145 5.15384 24.2212 5.05976 22.0861C4.96567 19.951 5.31618 17.8196 6.08964 15.8234H15.1154C15.2816 15.8234 15.4461 15.8559 15.5994 15.9191C15.7527 15.9823 15.8917 16.0749 16.0085 16.1915C16.1252 16.3082 16.2173 16.4466 16.2794 16.5987C16.3416 16.7507 16.3724 16.9135 16.3703 17.0774V18.3155H18.8802C19.213 18.3155 19.5322 18.446 19.7675 18.6782C20.0029 18.9104 20.1351 19.2254 20.1351 19.5538H20.1512Z'
        fill='#33A854'
      />
      <path
        d='M36.5133 21.4111C36.5211 22.4769 36.4187 23.5407 36.2076 24.586H28.9677C28.8029 24.586 28.6397 24.554 28.4875 24.4917C28.3352 24.4295 28.1969 24.3383 28.0803 24.2234C27.9638 24.1084 27.8714 23.9719 27.8083 23.8216C27.7452 23.6714 27.7128 23.5105 27.7128 23.3479V22.0937H25.1869C24.854 22.0937 24.5348 21.9633 24.2995 21.7311C24.0642 21.4989 23.9319 21.184 23.9319 20.8556C23.9319 20.5272 24.0642 20.2122 24.2995 19.98C24.5348 19.7478 24.854 19.6173 25.1869 19.6173H27.7128C27.8776 19.6173 28.0408 19.5854 28.193 19.5232C28.3453 19.4609 28.4836 19.3697 28.6001 19.2547C28.7167 19.1397 28.8091 19.0032 28.8722 18.853C28.9352 18.7028 28.9677 18.5418 28.9677 18.3792C28.9677 18.2166 28.9352 18.0555 28.8722 17.9053C28.8091 17.7551 28.7167 17.6186 28.6001 17.5036C28.4836 17.3886 28.3453 17.2974 28.193 17.2352C28.0408 17.173 27.8776 17.141 27.7128 17.141H27.0853C26.9075 17.1608 26.7274 17.1434 26.5569 17.0898C26.3864 17.0362 26.2293 16.9478 26.0958 16.8301C25.9623 16.7125 25.8556 16.5684 25.7824 16.4072C25.7093 16.246 25.6716 16.0714 25.6716 15.8949C25.6716 15.7183 25.7093 15.5437 25.7824 15.3825C25.8556 15.2213 25.9623 15.0772 26.0958 14.9596C26.2293 14.842 26.3864 14.7535 26.5569 14.6999C26.7274 14.6464 26.9075 14.6288 27.0853 14.6487H34.9205C35.9733 16.7746 36.5235 19.1086 36.5294 21.4747'
        fill='#33A854'
      />
      <path
        d='M26.4579 6.9338V9.61656C26.4579 9.94916 26.324 10.2681 26.0856 10.5033C25.8472 10.7385 25.524 10.8706 25.1869 10.8706H21.4221C21.1117 10.836 20.8251 10.6898 20.617 10.46C20.4089 10.2301 20.2939 9.93271 20.2939 9.62451C20.2939 9.3163 20.4089 9.01888 20.617 8.78903C20.8251 8.55918 21.1117 8.41304 21.4221 8.37842C21.5869 8.37842 21.7501 8.34637 21.9024 8.28414C22.0546 8.22192 22.1929 8.13074 22.3095 8.01576C22.426 7.90078 22.5184 7.76428 22.5815 7.61406C22.6446 7.46383 22.677 7.30277 22.677 7.14017V6.01306C23.9716 6.16168 25.2419 6.47106 26.4579 6.9338Z'
        fill='#33A854'
      />
      <path
        d='M35.4675 26.999C34.2687 30.0597 32.1169 32.6664 29.3217 34.4441H25.1869C24.854 34.4441 24.5348 34.3136 24.2995 34.0814C24.0642 33.8492 23.9319 33.5342 23.9319 33.2058C23.9319 32.8775 24.0642 32.5626 24.2995 32.3304C24.5348 32.0981 24.854 31.9677 25.1869 31.9677H27.7128C27.8776 31.9677 28.0408 31.9357 28.193 31.8734C28.3453 31.8112 28.4836 31.72 28.6001 31.605C28.7167 31.4901 28.8091 31.3536 28.8722 31.2033C28.9352 31.0531 28.9677 30.8921 28.9677 30.7295C28.9677 30.5669 28.9352 30.4059 28.8722 30.2557C28.8091 30.1054 28.7167 29.9689 28.6001 29.854C28.4836 29.739 28.3453 29.6478 28.193 29.5856C28.0408 29.5234 27.8776 29.4913 27.7128 29.4913C27.535 29.5111 27.3549 29.4937 27.1844 29.4402C27.0139 29.3866 26.8567 29.298 26.7233 29.1804C26.5898 29.0628 26.483 28.9186 26.4099 28.7575C26.3368 28.5963 26.299 28.4217 26.299 28.2451C26.299 28.0685 26.3368 27.894 26.4099 27.7329C26.483 27.5717 26.5898 27.4276 26.7233 27.3099C26.8567 27.1923 27.0139 27.1038 27.1844 27.0502C27.3549 26.9966 27.535 26.9792 27.7128 26.999H35.4675Z'
        fill='#33A854'
      />
      <path
        d='M19.5076 39.9999C18.6534 39.9392 17.8041 39.8225 16.9656 39.6506C16.883 39.6375 16.804 39.6078 16.7335 39.5634C16.663 39.5189 16.6025 39.4606 16.5558 39.3922C16.509 39.3237 16.477 39.2465 16.4617 39.1654C16.4464 39.0842 16.4482 39.0009 16.4668 38.9204C16.4821 38.8404 16.5136 38.7642 16.5594 38.6964C16.6053 38.6287 16.6646 38.5708 16.7338 38.5263C16.8029 38.4818 16.8805 38.4516 16.9618 38.4374C17.0431 38.4233 17.1265 38.4257 17.2069 38.4442C17.9935 38.5967 18.7886 38.7027 19.5881 38.7616C19.7545 38.7616 19.9141 38.8269 20.0317 38.943C20.1494 39.0591 20.2155 39.2166 20.2155 39.3808C20.2155 39.5449 20.1494 39.7024 20.0317 39.8185C19.9141 39.9346 19.7545 39.9999 19.5881 39.9999H19.5076Z'
        fill='#F75266'
      />
      <path
        d='M22.0336 40C21.8685 39.996 21.7112 39.9294 21.5944 39.8142C21.4776 39.6989 21.4102 39.5438 21.4061 39.3809C21.4059 39.222 21.4676 39.0692 21.5784 38.954C21.6892 38.8387 21.8406 38.7699 22.0014 38.7617C22.8013 38.6985 23.5964 38.5872 24.3825 38.4284C24.5464 38.4002 24.7151 38.4356 24.8531 38.5274C24.9911 38.6191 25.0877 38.76 25.1226 38.9205C25.156 39.0805 25.124 39.2469 25.0336 39.3837C24.9432 39.5204 24.8017 39.6164 24.64 39.6507C23.7958 39.8215 22.9413 39.9382 22.0819 40'
        fill='#F75266'
      />
      <path
        d='M14.6808 38.9998C14.6119 39.0149 14.5405 39.0149 14.4717 38.9998C13.6611 38.7141 12.8709 38.3746 12.1066 37.9838C12.0327 37.9485 11.9667 37.8988 11.9127 37.8377C11.8587 37.7767 11.8177 37.7055 11.7921 37.6285C11.7666 37.5514 11.7571 37.4701 11.7641 37.3893C11.7712 37.3086 11.7946 37.2301 11.8331 37.1584C11.906 37.0114 12.0349 36.8986 12.1916 36.8451C12.3484 36.7916 12.5203 36.8014 12.6697 36.8726C13.3834 37.2356 14.1196 37.5538 14.8739 37.8251C15.0057 37.8789 15.115 37.9752 15.1839 38.0985C15.2527 38.2217 15.2769 38.3644 15.2526 38.5031C15.2282 38.6417 15.1567 38.768 15.0499 38.8612C14.943 38.9544 14.8072 39.0089 14.6647 39.0157L14.6808 38.9998Z'
        fill='#F75266'
      />
      <path
        d='M26.9083 38.9999C26.8253 39.0021 26.7427 38.9876 26.6656 38.9572C26.5885 38.9269 26.5184 38.8814 26.4597 38.8234C26.401 38.7655 26.3549 38.6964 26.3241 38.6203C26.2934 38.5442 26.2786 38.4626 26.2809 38.3807C26.2824 38.2526 26.3232 38.128 26.3978 38.0231C26.4725 37.9183 26.5775 37.8382 26.6992 37.7934C27.4532 37.5275 28.1894 37.2147 28.9033 36.8569C28.9749 36.8183 29.0536 36.7943 29.1348 36.7862C29.2159 36.7782 29.2979 36.7862 29.3759 36.8099C29.4538 36.8336 29.5262 36.8723 29.5887 36.924C29.6513 36.9757 29.7027 37.0392 29.7399 37.1108C29.7793 37.1827 29.8039 37.2615 29.8123 37.3428C29.8207 37.424 29.8127 37.5062 29.7888 37.5844C29.7648 37.6626 29.7255 37.7353 29.6729 37.7984C29.6203 37.8616 29.5556 37.9139 29.4825 37.9522C28.7182 38.343 27.9281 38.6823 27.1175 38.9681H26.9083'
        fill='#F75266'
      />
      <path
        d='M10.2726 36.7458C10.1451 36.7506 10.0199 36.7113 9.91863 36.6346C9.21008 36.1544 8.53794 35.6238 7.90754 35.0472C7.79439 34.9293 7.73132 34.773 7.73132 34.6106C7.73132 34.4482 7.79439 34.2921 7.90754 34.1741C8.02784 34.0578 8.18957 33.9926 8.35802 33.9926C8.52647 33.9926 8.6882 34.0578 8.8085 34.1741C9.39009 34.7189 10.0141 35.2178 10.6748 35.6663C10.8093 35.7647 10.8996 35.9108 10.9267 36.0738C10.9537 36.2368 10.9153 36.4039 10.8196 36.5394C10.7523 36.6151 10.6673 36.6734 10.572 36.7093C10.4767 36.7453 10.3739 36.7578 10.2726 36.7458Z'
        fill='#F75266'
      />
      <path
        d='M31.3167 36.7458C31.1822 36.7483 31.0504 36.7079 30.9408 36.6308C30.8313 36.5537 30.7497 36.4439 30.7083 36.3176C30.6668 36.1913 30.6676 36.0552 30.7105 35.9294C30.7534 35.8035 30.8362 35.6946 30.9467 35.6187C31.6039 35.1599 32.2275 34.6559 32.813 34.1107C32.9305 33.9953 33.0896 33.9305 33.2554 33.9305C33.4212 33.9305 33.5803 33.9953 33.6978 34.1107C33.7577 34.167 33.8054 34.2347 33.8379 34.3098C33.8705 34.3848 33.8873 34.4656 33.8873 34.5472C33.8873 34.6289 33.8705 34.7096 33.8379 34.7846C33.8054 34.8597 33.7577 34.9274 33.6978 34.9837C33.0688 35.5554 32.4024 36.0857 31.7028 36.5712C31.5981 36.6409 31.4752 36.6795 31.3489 36.6823'
        fill='#F75266'
      />
      <path
        d='M6.63658 33.4121C6.54345 33.4092 6.45207 33.3866 6.36873 33.3454C6.2854 33.3043 6.21211 33.2458 6.15392 33.174C5.60854 32.5239 5.11375 31.834 4.67376 31.1103C4.62151 31.0416 4.58431 30.963 4.56453 30.8793C4.54476 30.7957 4.54284 30.7089 4.55889 30.6245C4.57495 30.5401 4.60863 30.4599 4.6578 30.3891C4.70697 30.3182 4.77054 30.2581 4.84449 30.2128C4.91843 30.1675 5.00112 30.1379 5.0873 30.1258C5.17348 30.1137 5.26127 30.1194 5.34508 30.1426C5.42889 30.1658 5.5069 30.2059 5.57413 30.2605C5.64136 30.315 5.69636 30.3828 5.73561 30.4595C6.15828 31.1349 6.62588 31.7819 7.13533 32.3962C7.18734 32.4597 7.22611 32.5326 7.24942 32.6109C7.27273 32.6892 7.28011 32.7714 7.27114 32.8526C7.26217 32.9337 7.23702 33.0122 7.19714 33.0837C7.15727 33.1553 7.10346 33.2184 7.0388 33.2693C6.92652 33.3631 6.78371 33.4138 6.63658 33.4121Z'
        fill='#F75266'
      />
      <path
        d='M34.9366 33.3962C34.7702 33.3962 34.6106 33.331 34.4929 33.2149C34.3752 33.0988 34.3091 32.9414 34.3091 32.7772C34.3074 32.632 34.3588 32.4911 34.4539 32.3803C34.9604 31.7674 35.4227 31.1203 35.8376 30.4436C35.8809 30.3738 35.9377 30.313 36.0048 30.2649C36.0719 30.2167 36.148 30.1821 36.2286 30.1629C36.3093 30.1438 36.393 30.1405 36.475 30.1532C36.5569 30.166 36.6356 30.1945 36.7063 30.2373C36.7771 30.28 36.8387 30.336 36.8875 30.4023C36.9363 30.4685 36.9714 30.5436 36.9908 30.6232C37.0103 30.7028 37.0136 30.7854 37.0007 30.8663C36.9878 30.9471 36.9588 31.0247 36.9155 31.0945C36.4646 31.8162 35.9703 32.5106 35.4353 33.1741C35.3732 33.2442 35.2965 33.3002 35.2104 33.3386C35.1244 33.3769 35.031 33.3966 34.9366 33.3962Z'
        fill='#F75266'
      />
      <path
        d='M4.06243 29.2215C3.94036 29.2228 3.82054 29.189 3.71766 29.1241C3.61479 29.0593 3.53332 28.9662 3.48324 28.8563C3.12877 28.0599 2.83828 27.2372 2.61445 26.3958C2.5699 26.238 2.59062 26.0691 2.67204 25.9263C2.75347 25.7835 2.88897 25.6783 3.04885 25.6339C3.20885 25.59 3.37999 25.6103 3.52475 25.6907C3.66951 25.771 3.77608 25.9047 3.8211 26.0624C4.04824 26.8223 4.31678 27.5695 4.62554 28.3008C4.65994 28.3745 4.67889 28.4544 4.68126 28.5355C4.68362 28.6167 4.66936 28.6975 4.63931 28.7731C4.60926 28.8487 4.56405 28.9176 4.5064 28.9755C4.44874 29.0335 4.37982 29.0794 4.30376 29.1104L4.06243 29.2215Z'
        fill='#F75266'
      />
      <path
        d='M37.527 29.2055C37.444 29.2077 37.3614 29.1931 37.2842 29.1628C37.2071 29.1324 37.1371 29.0869 37.0784 29.029C37.0196 28.971 36.9735 28.902 36.9428 28.8259C36.912 28.7498 36.8973 28.6683 36.8995 28.5864C36.883 28.5078 36.883 28.4268 36.8995 28.3482C37.2224 27.6224 37.4912 26.8744 37.7039 26.11C37.749 25.9523 37.8555 25.8186 38.0003 25.7382C38.145 25.6579 38.3162 25.6374 38.4762 25.6814C38.5565 25.7014 38.6319 25.7374 38.6977 25.7871C38.7635 25.8368 38.8184 25.8991 38.859 25.9704C38.8997 26.0416 38.9252 26.1204 38.9341 26.2017C38.9429 26.283 38.9349 26.3652 38.9106 26.4434C38.6818 27.2606 38.397 28.0614 38.0579 28.8403C38.0078 28.9502 37.9263 29.0433 37.8235 29.1081C37.7206 29.173 37.6008 29.2068 37.4787 29.2055'
        fill='#F75266'
      />
      <path
        d='M2.71088 24.5067C2.55936 24.5016 2.41425 24.4452 2.29997 24.3469C2.18569 24.2486 2.10922 24.1144 2.08343 23.967C1.97375 23.13 1.92001 22.2869 1.92254 21.443C1.92039 21.3604 1.93504 21.2781 1.9656 21.2012C1.99617 21.1242 2.04205 21.0541 2.10053 20.9949C2.15901 20.9357 2.22891 20.8887 2.30611 20.8566C2.3833 20.8245 2.46623 20.808 2.55 20.808C2.71513 20.812 2.87237 20.8786 2.98916 20.9938C3.10596 21.1091 3.17338 21.2642 3.17746 21.4271C3.17497 22.2235 3.22873 23.0192 3.33835 23.8083C3.34961 23.8897 3.34435 23.9725 3.32285 24.0518C3.30136 24.1312 3.26407 24.2056 3.21316 24.2706C3.16224 24.3356 3.09872 24.3899 3.0263 24.4305C2.95388 24.471 2.87401 24.4969 2.79133 24.5067H2.71088Z'
        fill='#F75266'
      />
      <path
        d='M38.814 24.4907C38.6489 24.4867 38.4916 24.4202 38.3748 24.305C38.258 24.1898 38.1906 24.0345 38.1865 23.8716V23.7764C38.2967 22.9927 38.3504 22.2023 38.3474 21.4111C38.3515 21.2482 38.4189 21.0931 38.5357 20.9778C38.6525 20.8626 38.8097 20.796 38.9749 20.792C39.1413 20.792 39.3009 20.8573 39.4185 20.9734C39.5362 21.0895 39.6023 21.2469 39.6023 21.4111C39.5994 22.2554 39.5403 23.0986 39.4254 23.9352C39.4025 24.0839 39.3269 24.2197 39.212 24.3186C39.0971 24.4174 38.9503 24.4728 38.7979 24.4748'
        fill='#F75266'
      />
      <path
        d='M38.8141 19.5537C38.6616 19.5517 38.5149 19.4963 38.4 19.3974C38.2851 19.2986 38.2095 19.1627 38.1866 19.014C38.0738 18.2324 37.9126 17.4585 37.704 16.6964C37.6692 16.5418 37.6957 16.3799 37.7782 16.244C37.8606 16.1081 37.9927 16.0086 38.1473 15.9659C38.3019 15.9232 38.4671 15.9406 38.6091 16.0145C38.7511 16.0883 38.859 16.213 38.9106 16.363C39.1369 17.1774 39.3142 18.0043 39.4415 18.8394C39.4528 18.9208 39.4475 19.0036 39.426 19.0829C39.4046 19.1623 39.3673 19.2367 39.3163 19.3017C39.2654 19.3667 39.2019 19.421 39.1295 19.4616C39.0571 19.5021 38.9772 19.5281 38.8945 19.5378'
        fill='#F75266'
      />
      <path
        d='M9.58078 12.6645C9.58078 13.2924 9.39206 13.9062 9.03849 14.4284C8.68492 14.9505 8.18238 15.3574 7.59441 15.5977C7.00645 15.838 6.35947 15.9009 5.73529 15.7784C5.1111 15.6559 4.53776 15.3535 4.08775 14.9095C3.63774 14.4655 3.33128 13.8998 3.20712 13.2839C3.08297 12.668 3.14669 12.0297 3.39023 11.4496C3.63377 10.8695 4.0462 10.3736 4.57535 10.0247C5.10451 9.67588 5.72663 9.48962 6.36303 9.48962C7.21643 9.48962 8.03488 9.82416 8.63832 10.4196C9.24176 11.015 9.58078 11.8225 9.58078 12.6645Z'
        fill='white'
      />
      <path
        d='M12.6055 12.728C12.6487 11.8854 12.5177 11.0429 12.2207 10.2519C11.9236 9.46077 11.4667 8.73753 10.8775 8.12611C10.2884 7.51469 9.5794 7.02791 8.79365 6.69525C8.0079 6.36259 7.1618 6.19104 6.30677 6.19104C5.45174 6.19104 4.60564 6.36259 3.81989 6.69525C3.03414 7.02791 2.32515 7.51469 1.73601 8.12611C1.14688 8.73753 0.689896 9.46077 0.392844 10.2519C0.0957927 11.0429 -0.0351271 11.8854 0.00804464 12.728C0.00217888 13.9775 0.402545 15.196 1.15034 16.2044L5.97695 23.0621C6.0202 23.1212 6.07702 23.1692 6.14275 23.2023C6.20847 23.2355 6.28123 23.2527 6.35504 23.2527C6.42885 23.2527 6.5016 23.2355 6.56733 23.2023C6.63305 23.1692 6.68987 23.1212 6.73312 23.0621L11.5597 16.1092C11.5955 16.0731 11.623 16.0297 11.6402 15.9822C12.2801 15.015 12.6159 13.8829 12.6055 12.728ZM6.3309 15.1726C5.79314 15.1726 5.26745 15.0153 4.82031 14.7205C4.37318 14.4257 4.02468 14.0066 3.81888 13.5164C3.61309 13.0262 3.55925 12.4868 3.66416 11.9664C3.76907 11.446 4.02803 10.968 4.40829 10.5928C4.78855 10.2176 5.27302 9.9621 5.80045 9.85858C6.32789 9.75507 6.87459 9.80823 7.37142 10.0113C7.86825 10.2143 8.29289 10.5582 8.59166 10.9994C8.89043 11.4405 9.04989 11.9592 9.04989 12.4898C9.04989 13.2013 8.76342 13.8837 8.25352 14.3868C7.74361 14.8899 7.05202 15.1726 6.3309 15.1726Z'
        fill='#F75266'
      />
      <path
        d='M37.2051 6.45758C37.2051 7.08551 37.0164 7.69934 36.6628 8.22144C36.3092 8.74355 35.8067 9.1505 35.2187 9.3908C34.6307 9.6311 33.9838 9.6939 33.3596 9.5714C32.7354 9.4489 32.1621 9.14651 31.712 8.7025C31.262 8.25849 30.9556 7.69285 30.8314 7.07699C30.7073 6.46113 30.771 5.82272 31.0145 5.24259C31.2581 4.66246 31.6705 4.16659 32.1997 3.81774C32.7288 3.46888 33.3509 3.28271 33.9873 3.28271C34.8407 3.28271 35.6592 3.61716 36.2626 4.21256C36.8661 4.80796 37.2051 5.61555 37.2051 6.45758Z'
        fill='white'
      />
      <path
        d='M40.2619 6.53692C40.305 5.69435 40.1741 4.8519 39.8771 4.06082C39.58 3.26973 39.123 2.54658 38.5339 1.93517C37.9448 1.32375 37.2358 0.836874 36.45 0.504211C35.6643 0.171548 34.8182 0 33.9631 0C33.1081 0 32.262 0.171548 31.4763 0.504211C30.6905 0.836874 29.9815 1.32375 29.3924 1.93517C28.8032 2.54658 28.3463 3.26973 28.0492 4.06082C27.7522 4.8519 27.6212 5.69435 27.6644 6.53692C27.6528 7.77995 28.0477 8.99353 28.7906 9.9975L33.6172 16.8552C33.6581 16.9175 33.7141 16.9686 33.7801 17.004C33.8462 17.0395 33.9202 17.058 33.9953 17.058C34.0705 17.058 34.1445 17.0395 34.2105 17.004C34.2765 16.9686 34.3325 16.9175 34.3734 16.8552L39.2 9.91815L39.2965 9.79113C39.9296 8.84952 40.2707 7.7462 40.278 6.61627L40.2619 6.53692ZM33.9873 8.93395C33.4457 8.9371 32.9153 8.78152 32.4634 8.48691C32.0115 8.19229 31.6584 7.77191 31.449 7.27909C31.2395 6.78626 31.183 6.24321 31.2867 5.71869C31.3904 5.19416 31.6496 4.71179 32.0315 4.33278C32.4134 3.95378 32.9007 3.69516 33.4317 3.58974C33.9626 3.48432 34.5134 3.53682 35.0141 3.7406C35.5148 3.94438 35.9429 4.29026 36.2442 4.73439C36.5454 5.17852 36.7063 5.70089 36.7063 6.2353C36.7063 6.94828 36.4203 7.63227 35.9109 8.13791C35.4014 8.64355 34.7099 8.92976 33.9873 8.93395Z'
        fill='#F75266'
      />
    </>
  ),
});
