import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const ProyectosIcon = createIcon({
  displayName: 'ProyectosIcon',
  viewBox: '0 0 34 43',
  path: (
    <>
      <path
        d='M8.47981 8.29899C8.67252 8.11677 8.78495 7.86546 8.79237 7.60035C8.79979 7.33524 8.70159 7.07804 8.51937 6.88533L5.77118 3.97888C5.58896 3.78617 5.33765 3.67374 5.07254 3.66632C4.80743 3.6589 4.55023 3.7571 4.35752 3.93932C4.16481 4.12154 4.05238 4.37284 4.04496 4.63796C4.03754 4.90307 4.13574 5.16027 4.31795 5.35298L7.06615 8.25942C7.24837 8.45213 7.49967 8.56457 7.76479 8.57199C8.0299 8.5794 8.2871 8.4812 8.47981 8.29899Z'
        fill='white'
      />
      <path
        d='M11.6382 5.3952C11.6903 5.51575 11.7183 5.6454 11.7203 5.77674C11.7224 5.90808 11.6986 6.03855 11.6502 6.16068C11.6019 6.28282 11.53 6.39424 11.4386 6.48858C11.3471 6.58292 11.238 6.65833 11.1175 6.71051C10.9969 6.76268 10.8673 6.79061 10.736 6.79268C10.6046 6.79475 10.4741 6.77093 10.352 6.72259C10.2299 6.67424 10.1185 6.60231 10.0241 6.5109C9.92977 6.41949 9.85436 6.3104 9.80219 6.18985L8.21358 2.51863C8.1614 2.39808 8.13347 2.26843 8.1314 2.13709C8.12933 2.00575 8.15315 1.87528 8.20149 1.75314C8.24984 1.63101 8.32177 1.51959 8.41318 1.42525C8.50459 1.33091 8.61368 1.2555 8.73423 1.20332C8.85479 1.15114 8.98444 1.12322 9.11578 1.12115C9.24712 1.11908 9.37758 1.14289 9.49972 1.19124C9.62186 1.23959 9.73328 1.31152 9.82762 1.40293C9.92196 1.49434 9.99737 1.60343 10.0495 1.72398L11.6388 5.39592L11.6382 5.3952Z'
        fill='white'
      />
      <path
        d='M6.79563 10.7971C6.8545 10.6796 6.88966 10.5517 6.8991 10.4207C6.90853 10.2897 6.89206 10.1581 6.85061 10.0334C6.80917 9.90874 6.74356 9.79347 6.65756 9.69417C6.57155 9.59487 6.46681 9.51349 6.34934 9.45468L2.772 7.66392C2.65429 7.6021 2.52544 7.56436 2.39298 7.55293C2.26052 7.5415 2.12711 7.55659 2.00055 7.59733C1.87399 7.63807 1.75683 7.70364 1.65592 7.7902C1.555 7.87676 1.47236 7.98258 1.41283 8.10146C1.3533 8.22034 1.31807 8.3499 1.30921 8.48256C1.30034 8.61521 1.31802 8.74831 1.36121 8.87405C1.4044 8.99979 1.47222 9.11566 1.56072 9.21488C1.64923 9.3141 1.75662 9.39467 1.87663 9.45189L5.45394 11.2441C5.69114 11.3627 5.96575 11.3823 6.21736 11.2984C6.46897 11.2146 6.67698 11.0342 6.79563 10.7971Z'
        fill='white'
      />
      <path
        d='M28 18.92C28 25.359 23 26.359 23 32.36C23 35.458 19.877 35.719 17.5 35.719C15.447 35.719 10.914 34.94 10.914 32.358C10.914 26.36 6 25.36 6 18.92C6 12.889 11.285 8 17.083 8C22.883 8 28 12.889 28 18.92Z'
        fill='#FFB800'
      />
      <path
        d='M21.167 40.36C21.167 41.188 18.933 42.86 17 42.86C15.067 42.86 12.833 41.188 12.833 40.36C12.833 39.532 15.066 39.86 17 39.86C18.933 39.86 21.167 39.532 21.167 40.36Z'
        fill='#CCD6DD'
      />
      <path
        d='M21.707 18.1529C21.6142 18.06 21.504 17.9863 21.3827 17.9359C21.2613 17.8856 21.1313 17.8597 21 17.8597C20.8686 17.8597 20.7386 17.8856 20.6173 17.9359C20.496 17.9863 20.3858 18.06 20.293 18.1529L17 21.4459L13.707 18.1529C13.6141 18.0601 13.5039 17.9864 13.3826 17.9362C13.2613 17.886 13.1313 17.8601 13 17.8601C12.8687 17.8601 12.7387 17.886 12.6173 17.9362C12.496 17.9864 12.3858 18.0601 12.293 18.1529C12.2001 18.2458 12.1265 18.356 12.0762 18.4773C12.026 18.5986 12.0001 18.7286 12.0001 18.8599C12.0001 18.9912 12.026 19.1213 12.0762 19.2426C12.1265 19.3639 12.2001 19.4741 12.293 19.5669L16 23.2739V33.8599C16 34.1252 16.1053 34.3795 16.2929 34.5671C16.4804 34.7546 16.7348 34.8599 17 34.8599C17.2652 34.8599 17.5195 34.7546 17.7071 34.5671C17.8946 34.3795 18 34.1252 18 33.8599V23.2739L21.707 19.5669C21.7999 19.4742 21.8737 19.364 21.924 19.2426C21.9743 19.1213 22.0002 18.9913 22.0002 18.8599C22.0002 18.7286 21.9743 18.5986 21.924 18.4772C21.8737 18.3559 21.7999 18.2457 21.707 18.1529Z'
        fill='#D29700'
      />
      <path
        d='M23 38.86C23 39.3904 22.7893 39.8991 22.4142 40.2742C22.0391 40.6493 21.5304 40.86 21 40.86H13C12.4696 40.86 11.9609 40.6493 11.5858 40.2742C11.2107 39.8991 11 39.3904 11 38.86V32.86H23V38.86Z'
        fill='#99AAB5'
      />
      <path
        d='M10.999 39.86C10.7486 39.8591 10.5076 39.7642 10.3238 39.5942C10.1399 39.4242 10.0265 39.1914 10.006 38.9418C9.98555 38.6922 10.0595 38.444 10.2131 38.2463C10.3668 38.0486 10.5891 37.9157 10.836 37.874L22.836 35.874C22.9655 35.8517 23.0983 35.8553 23.2264 35.8847C23.3546 35.914 23.4756 35.9685 23.5826 36.0449C23.6895 36.1214 23.7803 36.2183 23.8495 36.33C23.9187 36.4418 23.9651 36.5662 23.986 36.696C24.0076 36.8255 24.0035 36.958 23.9738 37.0859C23.9442 37.2138 23.8897 37.3346 23.8133 37.4414C23.737 37.5482 23.6404 37.6389 23.5289 37.7083C23.4175 37.7777 23.2935 37.8245 23.164 37.846L11.164 39.846C11.1095 39.8556 11.0543 39.8602 10.999 39.86ZM10.999 35.86C10.7486 35.8591 10.5076 35.7642 10.3238 35.5942C10.1399 35.4242 10.0265 35.1914 10.006 34.9418C9.98555 34.6922 10.0595 34.444 10.2131 34.2463C10.3668 34.0486 10.5891 33.9157 10.836 33.874L22.836 31.874C22.9655 31.8518 23.0982 31.8556 23.2263 31.885C23.3544 31.9144 23.4754 31.9688 23.5823 32.0453C23.6893 32.1217 23.78 32.2185 23.8493 32.3302C23.9185 32.4419 23.965 32.5662 23.986 32.696C24.0076 32.8255 24.0035 32.958 23.9738 33.0859C23.9442 33.2138 23.8897 33.3346 23.8133 33.4414C23.737 33.5482 23.6404 33.6389 23.5289 33.7083C23.4175 33.7777 23.2935 33.8245 23.164 33.846L11.164 35.846C11.1095 35.8556 11.0543 35.8602 10.999 35.86Z'
        fill='#CCD6DD'
      />
      <path
        d='M34 11.8571V9.14286H31.1486C30.9736 8.29331 30.6363 7.48552 30.1552 6.76379L32.1774 4.74164L30.2584 2.82264L28.2362 4.84479C27.5145 4.36369 26.7067 4.02638 25.8571 3.85136V1H23.1429V3.85136C22.2933 4.02638 21.4855 4.36369 20.7638 4.84479L18.7416 2.82264L16.8226 4.74164L18.8448 6.76379C18.3637 7.48552 18.0264 8.29331 17.8514 9.14286H15V11.8571H17.8514C18.0264 12.7067 18.3637 13.5145 18.8448 14.2362L16.8226 16.2584L18.7416 18.1774L20.7638 16.1552C21.4855 16.6363 22.2933 16.9736 23.1429 17.1486V20H25.8571V17.1486C26.7067 16.9736 27.5145 16.6363 28.2362 16.1552L30.2584 18.1774L32.1774 16.2584L30.1552 14.2362C30.6363 13.5145 30.9736 12.7067 31.1486 11.8571H34ZM24.5 14.5714C23.6947 14.5714 22.9076 14.3326 22.238 13.8853C21.5685 13.4379 21.0466 12.802 20.7385 12.0581C20.4303 11.3141 20.3497 10.4955 20.5068 9.7057C20.6639 8.91592 21.0517 8.19046 21.6211 7.62106C22.1905 7.05167 22.9159 6.6639 23.7057 6.5068C24.4955 6.34971 25.3141 6.43033 26.0581 6.73849C26.802 7.04665 27.4379 7.56849 27.8853 8.23804C28.3326 8.90758 28.5714 9.69475 28.5714 10.5C28.5704 11.5795 28.1411 12.6144 27.3777 13.3777C26.6144 14.1411 25.5795 14.5704 24.5 14.5714Z'
        fill='#73B3AB'
      />
      <path
        d='M24.5 14.5714C23.6947 14.5714 22.9076 14.3326 22.238 13.8853C21.5685 13.4379 21.0466 12.802 20.7385 12.0581C20.4303 11.3141 20.3497 10.4955 20.5068 9.7057C20.6639 8.91592 21.0517 8.19046 21.6211 7.62106C22.1905 7.05167 22.9159 6.6639 23.7057 6.5068C24.4955 6.34971 25.3141 6.43033 26.0581 6.73849C26.802 7.04665 27.4379 7.56849 27.8853 8.23804C28.3326 8.90758 28.5714 9.69475 28.5714 10.5C28.5704 11.5795 28.1411 12.6144 27.3777 13.3777C26.6144 14.1411 25.5795 14.5704 24.5 14.5714Z'
        fill='#73B3AB'
      />
      <path
        d='M24.5 14.5714C23.6948 14.5714 22.9076 14.3327 22.2381 13.8853C21.5685 13.4379 21.0467 12.802 20.7385 12.0581C20.4304 11.3141 20.3497 10.4955 20.5068 9.70572C20.6639 8.91594 21.0517 8.19048 21.6211 7.62108C22.1905 7.05169 22.9159 6.66392 23.7057 6.50682C24.4955 6.34973 25.3141 6.43035 26.0581 6.73851C26.802 7.04667 27.4379 7.56851 27.8853 8.23806C28.3327 8.9076 28.5714 9.69477 28.5714 10.5C28.5704 11.5795 28.1411 12.6145 27.3778 13.3778C26.6145 14.1411 25.5795 14.5704 24.5 14.5714Z'
        fill='#F2F1F2'
      />
    </>
  ),
});
