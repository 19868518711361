import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const MarkerEnd = createIcon({
  displayName: 'MarkerEnd',
  viewBox: '0 0 15 20',
  path: (
    <>
      <ellipse
        cx='7.15987'
        cy='6.90099'
        rx='3.61739'
        ry='3.35167'
        fill='white'
      />
      <path
        d='M14.0222 7.20389C14.0222 3.22558 10.918 3.43323e-05 7.08939 3.43323e-05C3.25978 3.43323e-05 0.155579 3.22558 0.155579 7.20389C0.155579 8.73722 0.61764 10.0977 1.40286 11.1911L1.40189 11.1891L6.67074 19.0653C6.87235 19.3672 7.30547 19.3672 7.50805 19.0653L12.8492 11.0859C12.8811 11.0392 12.9129 10.9925 12.9438 10.9448L12.9544 10.929H12.9534C13.6306 9.88324 14.0222 8.61705 14.0222 7.20389ZM7.1029 10.0163C5.44565 10.0163 4.10288 8.63294 4.10288 6.92682C4.10288 5.2207 5.44565 3.83832 7.1029 3.83832C8.76015 3.83832 10.1039 5.2207 10.1039 6.92682C10.1039 8.63294 8.76015 10.0163 7.1029 10.0163Z'
        fill='#F55266'
      />
    </>
  ),
});
