import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const FormulariosIcon = createIcon({
  displayName: 'FormulariosIcon',
  viewBox: '0 0 34 35',
  path: (
    <>
      <path
        d='M12.8147 0.803823H33.3947V10.0531H12.8147V0.803823Z'
        fill='#5CC9BA'
      />
      <path
        d='M12.8147 24.7758H33.3947V34.0265H12.8147V24.7758Z'
        fill='#5CC9BA'
      />
      <path
        d='M12.8147 12.7891H33.3947V22.0398H12.8147V12.7891Z'
        fill='#FCD673'
      />
      <path
        d='M7.9768 2.14836C9.78746 3.95902 9.78746 6.89636 7.9768 8.70569C6.16613 10.5164 3.23013 10.5164 1.41946 8.70569C-0.391204 6.89636 -0.391204 3.95902 1.41946 2.14836C3.23013 0.339024 6.16613 0.339024 7.9768 2.14836Z'
        fill='#FCD673'
      />
      <path
        d='M7.9768 14.1353C9.78746 15.9459 9.78746 18.8833 7.9768 20.6926C6.16613 22.5033 3.23013 22.5033 1.41946 20.6926C-0.391204 18.8833 -0.391204 15.9459 1.41946 14.1353C3.23013 12.3259 6.16613 12.3259 7.9768 14.1353Z'
        fill='#5CC9BA'
      />
      <path
        d='M7.9768 26.1236C9.78746 27.9329 9.78746 30.8702 7.9768 32.6809C6.16613 34.4902 3.23013 34.4902 1.41946 32.6809C-0.391204 30.8702 -0.391204 27.9329 1.41946 26.1236C3.23013 24.3129 6.16613 24.3129 7.9768 26.1236Z'
        fill='#FCD673'
      />
    </>
  ),
});
