import {call, put, takeEvery} from 'redux-saga/effects';

import * as api from '../services/incidentsService';
import {
  addIncident,
  addIncidentError,
  addIncidentSuccess,
  disableIncident,
  disableIncidentError,
  disableIncidentSuccess,
  getIncidents,
  getIncidentsError,
  getIncidentsSuccess,
  updateIncident,
  updateIncidentError,
  updateIncidentSuccess,
} from '../slices/incidentSlice';

export function* getAllIncidents({payload}) {
  try {
    const result = yield call(api.getIncidents);
    yield put(getIncidentsSuccess(result));
  } catch (e) {
    yield put(getIncidentsError);
  }
}

function* addWorker({payload}) {
  try {
    yield call(api.createIncident, payload);
    yield put(addIncidentSuccess(payload));
  } catch (e: any) {
    yield put(addIncidentError(e.toString()));
  }
}

function* updateWorker({payload}) {
  try {
    yield call(api.updateIncident, payload);
    yield put(updateIncidentSuccess(payload));
  } catch (e: any) {
    yield put(updateIncidentError(e.toString()));
  }
}

function* disableWorker({payload}) {
  try {
    yield call(api.disableIncident, payload);
    yield put(disableIncidentSuccess(payload));
  } catch (e: any) {
    yield put(disableIncidentError(e.toString()));
  }
}

export default function* rootSaga() {
  yield takeEvery(getIncidents, getAllIncidents);
  yield takeEvery(addIncident, addWorker);
  yield takeEvery(updateIncident, updateWorker);
  yield takeEvery(disableIncident, disableWorker);
}
