import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const ViewHierarchy = createIcon({
  displayName: 'ViewHierarchy',
  viewBox: '0 0 35 27',
  path: (
    <>
      <path
        d='M28.4375 26.4461H22.6953C22.2441 26.4461 21.875 26.0742 21.875 25.6197V22.8649C21.875 22.4103 22.2441 22.0384 22.6953 22.0384H28.4375C29.6475 22.0384 30.625 21.0536 30.625 19.8346V6.61153C30.625 5.39253 29.6475 4.40769 28.4375 4.40769H22.6953C22.2441 4.40769 21.875 4.03579 21.875 3.58124V0.826441C21.875 0.371898 22.2441 0 22.6953 0H28.4375C32.0605 0 35 2.96141 35 6.61153V19.8346C35 23.4847 32.0605 26.4461 28.4375 26.4461ZM25.2246 12.6032L13.7402 1.03305C12.7148 0 10.9375 0.723136 10.9375 2.20384V8.81537H1.64062C0.731445 8.81537 0 9.55228 0 10.4683V17.0798C0 17.9958 0.731445 18.7327 1.64062 18.7327H10.9375V25.3442C10.9375 26.8249 12.7148 27.548 13.7402 26.515L25.2246 14.9448C25.8604 14.2974 25.8604 13.2506 25.2246 12.6032Z'
        fill='#73B3AB'
      />
    </>
  ),
});
