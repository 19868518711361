import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../../app/rootReducer';

export interface SectionSelected {
  section: string;
}

export interface SectionSelectedState {
  data: SectionSelected;
}

const initialState = {data: {section: ''}} as SectionSelectedState;
const sectionSelectedSlice = createSlice({
  name: 'sectionSelected',
  initialState,
  reducers: {
    addSectionSelected(state, action: PayloadAction<SectionSelected>) {
      state.data = action.payload;
    },
  },
});

export const getSectionSelectedSelector = (state: RootState) =>
  state.sectionSelected.data;

export default sectionSelectedSlice.reducer;
export const {addSectionSelected} = sectionSelectedSlice.actions;
