import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../../app/rootReducer';
import {Form} from '../../types/forms';

export interface CreateActiveState {
  state?: boolean;
  dataForm: Form | null;
}

export interface CreateActiveStates {
  data: CreateActiveState;
}

const initialState = {
  data: {status: false, dataForm: null},
} as CreateActiveStates;
const createActiveStateSlice = createSlice({
  name: 'createActiveState',
  initialState,
  reducers: {
    getCreateActiveState(state, action: PayloadAction<CreateActiveState>) {
      state.data = action.payload;
    },
  },
});

export const getCreateActiveStateSelector = (state: RootState) =>
  state.createActiveState.data;

export default createActiveStateSlice.reducer;
export const {getCreateActiveState} = createActiveStateSlice.actions;
