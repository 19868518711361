import {instance} from './index';

export async function getTours(data) {
  const url = `/maps/workday/${data.idUser}?fromDate=${data.fromDate}&toDate=${data.toDate}`;
  const response = await instance().get(url);
  return response.data;
}

export async function getCoordinates(data) {
  const response = await instance().get(
    `/geolocation/tracking/${data.idUser}?date=${data.date}`,
  );
  return response.data;
}

export function createCoordinate(data) {
  return instance().post('/geolocation/tracking/register-manual', data);
}

export function deleteCoordinate(id: number) {
  return instance().delete(`/geolocation/tracking/${id}`);
}
