import React from 'react';

import {Box, Flex, Skeleton} from '@chakra-ui/react';

interface SkeletonProps {
  amount: number;
}

export function AnimationSkeleton(props: SkeletonProps) {
  const {amount} = props;
  const lines = Array.from(
    {length: Math.round(amount / 2)},
    () => Math.random() * 10,
  );
  return (
    <Flex justifyContent='center' h='50vh' w='100%'>
      <Box w='100%' borderRadius='18px'>
        <>
          {lines.map(line => {
            return (
              <Skeleton
                key={line}
                startColor='gray.50'
                endColor='gray.200'
                height='55px'
                mt={2}
              />
            );
          })}
          {lines.map(line => {
            return (
              <Skeleton
                key={line}
                startColor='gray.50'
                endColor='gray.200'
                height='55px'
                mt={2}
              />
            );
          })}
        </>
      </Box>
    </Flex>
  );
}
