import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const HapCommuniLogo = createIcon({
  displayName: 'HapCommuniLogo',
  viewBox: '0 0 977 353',
  path: (
    <svg fill='none'>
      <path
        d='M404.937 129.739C410.619 129.739 415.224 125.325 415.224 119.878C415.224 114.433 410.619 110.017 404.937 110.017C399.256 110.017 394.651 114.433 394.651 119.878C394.651 125.325 399.256 129.739 404.937 129.739Z'
        fill='#F7F7F7'
      />
      <path
        d='M525.785 173.957C525.669 176.179 525.516 177.722 525.514 179.265C525.496 195.907 525.416 212.548 525.571 229.186C525.604 232.739 524.638 234.144 520.674 234.072C508.876 233.858 497.068 233.916 485.266 234.042C481.688 234.08 480.071 233.229 480.079 229.348C480.192 172.506 480.187 115.666 480.095 58.8236C480.087 55.176 481.287 53.9543 485.117 54.0164C496.918 54.2088 508.726 54.2168 520.523 54.01C524.318 53.9432 525.914 55.0201 525.541 58.7281C525.32 60.917 525.499 63.144 525.499 66.0965C527.2 65.0656 528.168 64.6282 528.947 63.9823C543.633 51.8339 560.656 49.6339 578.913 53.5216C596.786 57.3315 610.434 67.0859 619.504 82.2425C628.5 97.2734 630.961 113.61 628.692 130.76C626.696 145.856 620.943 159.183 609.815 170.215C590.419 189.444 557.149 194.065 534.913 180.517C531.929 178.699 529.233 176.453 525.785 173.957ZM524.897 119.612C524.615 137.413 536.509 149.454 554.528 149.609C572.008 149.761 584.132 138.073 584.528 120.688C584.93 103.127 572.745 90.6432 555.021 90.4586C537.256 90.2725 525.176 101.964 524.897 119.612Z'
        fill='#3854FE'
      />
      <path
        d='M45.7423 68.2856C47.3619 67.0417 48.3724 66.4277 49.1756 65.6212C58.6373 56.1324 70.3061 51.8565 83.8781 51.8692C105.445 51.8835 121.84 60.0011 130.276 79.7596C132.657 85.34 134.648 91.5168 134.776 97.4566C135.385 125.807 135.271 154.171 135.478 182.529C135.5 185.192 134.298 185.962 131.705 185.946C119.204 185.873 106.701 185.83 94.201 185.969C90.6566 186.008 90.1157 184.371 90.124 181.532C90.1903 160.493 90.1937 139.454 90.1323 118.416C90.114 112.147 89.6958 105.938 86.2128 100.299C81.4239 92.5524 73.7758 89.3391 62.9866 90.7501C55.7699 91.6934 48.6811 98.1788 47.0897 105.875C46.1505 110.412 45.52 115.087 45.4835 119.705C45.3126 140.209 45.3308 160.717 45.4818 181.222C45.5067 184.834 44.4812 186.078 40.5601 186.002C28.6209 185.768 16.6684 185.805 4.72592 185.984C1.02385 186.04 -0.00496017 184.79 1.7956e-05 181.349C0.0879648 124.491 0.0846456 67.6334 0.00665497 10.7772C0.00167685 7.40167 0.964114 6.00182 4.70766 6.05272C16.7896 6.21974 28.8748 6.21339 40.9584 6.05432C44.458 6.00978 45.4884 7.21078 45.4636 10.4607C45.3441 27.9031 45.4005 45.3472 45.4221 62.7912C45.4237 64.3342 45.5963 65.8741 45.7423 68.2856Z'
        fill='#3854FE'
      />
      <path
        d='M362.307 173.742V179.35C362.307 195.99 362.204 212.633 362.383 229.272C362.42 232.934 361.272 234.132 357.429 234.068C345.489 233.871 333.544 233.906 321.603 234.046C317.985 234.089 316.812 232.862 316.817 229.385C316.907 172.538 316.91 115.691 316.817 58.8446C316.81 55.1859 318.027 53.9611 321.848 54.0199C333.787 54.2013 345.737 54.2188 357.676 54.0072C361.446 53.9388 362.646 55.2337 362.34 58.6235C362.14 60.8331 362.302 63.0696 362.302 66.1573C364.066 64.9992 365.097 64.4377 365.986 63.7187C380.52 51.9615 397.312 49.5865 415.255 53.4297C436.488 57.9777 451.422 70.2757 459.976 89.3391C465.905 102.547 467.367 116.444 465.471 130.757C463.468 145.861 457.65 159.125 446.6 170.225C427.448 189.467 393.362 194.116 371.324 180.309C368.493 178.537 365.916 176.386 362.307 173.742ZM421.318 119.907C421.25 102.471 408.907 90.3206 391.406 90.459C373.556 90.5974 361.706 102.367 361.658 119.994C361.615 137.792 373.704 149.709 391.701 149.608C409.331 149.509 421.386 137.421 421.318 119.907Z'
        fill='#A0BFFF'
      />
      <path
        d='M253.394 174.783C247.188 180.32 240.106 184.23 231.896 186.308C205.623 192.952 177.075 182.322 162.728 160.384C151.277 142.87 149.383 123.749 153.001 103.911C156.198 86.3776 165.019 71.8701 180.842 62.1681C201.62 49.4263 223.288 47.7115 245.343 59.3668C248.31 60.9337 250.846 63.2435 254.496 65.8634C254.689 63.0144 255.051 60.8781 254.926 58.7655C254.73 55.4425 255.806 53.9631 259.605 54.0188C271.82 54.1985 284.041 54.1635 296.258 54.0331C299.386 53.9997 300.348 55.1148 300.345 57.994C300.288 99.3883 300.283 140.783 300.365 182.174C300.371 185.25 299.112 185.993 296.162 185.963C283.945 185.851 271.724 185.831 259.509 185.972C256.096 186.01 254.68 184.937 254.926 181.609C255.087 179.447 254.77 177.253 254.669 175.074C254.242 174.977 253.819 174.88 253.394 174.783ZM225.942 90.4467C208.628 90.442 195.869 102.999 195.857 120.055C195.844 137.124 208.57 149.618 225.96 149.608C243.767 149.597 256.217 137.307 256.124 119.837C256.03 102.625 243.531 90.4531 225.942 90.4467Z'
        fill='#A0BFFF'
      />
      <path
        d='M851.559 232.722C855.369 224.263 859.067 216.002 862.803 207.757C865.947 200.817 869.239 193.935 872.216 186.931C872.829 185.488 872.888 183.398 872.276 181.973C862.466 159.144 852.501 136.377 842.582 113.593C834.472 94.9752 826.364 76.3556 818.303 57.7169C817.855 56.6829 817.83 55.4803 817.487 53.7464H826.228C836.926 53.7464 847.623 53.8705 858.316 53.6748C861.626 53.6128 863.251 54.669 864.411 57.6262C871.329 75.2723 878.436 92.8484 885.461 110.455C888.033 116.904 890.519 123.383 893.138 129.812C893.554 130.83 894.47 131.662 895.693 133.299C898.7 125.75 901.4 119.01 904.073 112.259C911.446 93.6438 918.763 75.0098 926.265 56.4427C926.735 55.2846 928.595 53.8991 929.823 53.8848C943.573 53.7241 957.324 53.8084 971.074 53.8482C971.465 53.8482 971.853 54.09 972.945 54.4527C970.466 60.348 968.06 66.2114 965.543 72.0303C952.278 102.687 938.998 133.337 925.7 163.98C916.017 186.287 906.326 208.591 896.501 230.84C895.98 232.025 894.208 233.471 893.003 233.486C879.811 233.653 866.616 233.562 853.422 233.514C853.046 233.513 852.672 233.207 851.559 232.722Z'
        fill='#3854FE'
      />
      <path
        d='M773.882 137.617C773.881 152.126 773.796 166.633 773.942 181.141C773.979 184.652 773.061 186.09 769.045 186.004C757.249 185.762 745.441 185.764 733.642 186.002C729.464 186.088 728.538 184.564 728.553 180.885C728.674 152.135 728.623 123.386 728.616 94.6347C728.616 88.5978 728.601 88.5978 722.394 88.5772C719.34 88.5692 716.285 88.539 713.23 88.5629C710.826 88.5819 709.655 87.5257 709.661 85.1825C709.686 75.8656 709.691 66.547 709.666 57.23C709.661 55.0555 710.72 54.0803 712.962 54.0963C716.71 54.1249 720.467 53.9626 724.207 54.1217C727.375 54.2537 728.636 53.1577 728.712 50.0049C728.905 42.1625 729.577 34.4156 732.59 26.9216C737.363 15.0547 745.958 7.09622 758.467 3.41048C772.802 -0.812943 787.37 -1.19949 801.944 2.73123C805.87 3.78906 807.991 5.62955 807.807 10.0105C807.465 18.1169 807.602 26.2472 807.757 34.3631C807.818 37.6257 806.434 37.9073 803.436 37.6893C797.983 37.2933 792.399 36.9226 787.026 37.6289C779.834 38.5722 775.85 43.311 774.519 50.2244C773.96 53.1355 774.924 54.1249 777.868 54.101C786.059 54.0342 794.256 54.2346 802.442 54.0183C806.333 53.9149 807.908 55.0046 807.785 58.9544C807.529 67.1992 807.576 75.4615 807.768 83.7079C807.851 87.2982 806.627 88.7458 802.754 88.6344C795.54 88.4276 788.316 88.574 781.096 88.5772C773.887 88.5819 773.884 88.5851 773.884 95.691C773.882 109.666 773.882 123.64 773.882 137.617Z'
        fill='#3854FE'
      />
      <path
        d='M647.577 120.131C647.577 99.774 647.659 79.4157 647.506 59.059C647.478 55.281 648.648 53.9337 652.706 54.0084C664.498 54.2247 676.301 54.1914 688.094 54.0196C691.622 53.9687 692.994 54.8531 692.986 58.4673C692.881 99.5815 692.895 140.696 692.973 181.809C692.978 184.872 692.062 186.021 688.72 185.978C676.51 185.824 664.292 185.83 652.079 185.971C648.652 186.011 647.499 184.904 647.519 181.602C647.637 161.111 647.577 140.621 647.577 120.131Z'
        fill='#3854FE'
      />
      <path
        d='M670.44 43.3852C664.194 43.3852 657.949 43.3041 651.704 43.4186C648.714 43.4759 647.491 42.4546 647.531 39.4704C647.66 29.6269 647.639 19.7803 647.546 9.93517C647.519 7.22773 648.555 6.08081 651.427 6.09513C664.057 6.16035 676.686 6.16194 689.316 6.09035C692.031 6.07604 692.968 7.13388 692.947 9.66634C692.867 19.7755 692.859 29.8894 692.952 40.0001C692.976 42.6694 691.753 43.4536 689.176 43.4155C682.932 43.3216 676.686 43.3852 670.44 43.3852Z'
        fill='#3854FE'
      />
      <path
        d='M226.332 89.7686C209.02 89.7638 196.261 102.319 196.248 119.375C196.235 136.444 208.962 148.938 226.351 148.925C244.159 148.917 256.609 136.629 256.513 119.157C256.42 101.946 243.923 89.7749 226.332 89.7686Z'
        fill='white'
      />
      <path
        d='M391.876 89.7686C374.562 89.7638 361.805 102.319 361.792 119.375C361.778 136.444 374.506 148.938 391.894 148.925C409.703 148.917 422.153 136.629 422.055 119.157C421.964 101.946 409.465 89.7749 391.876 89.7686Z'
        fill='white'
      />
      <path
        d='M240.575 129.739C246.258 129.739 250.861 125.325 250.861 119.878C250.861 114.433 246.258 110.017 240.575 110.017C234.893 110.017 230.29 114.433 230.29 119.878C230.29 125.325 234.893 129.739 240.575 129.739Z'
        fill='#3854FE'
      />
      <path
        d='M406.119 129.739C411.8 129.739 416.405 125.325 416.405 119.878C416.405 114.433 411.8 110.017 406.119 110.017C400.437 110.017 395.832 114.433 395.832 119.878C395.832 125.325 400.437 129.739 406.119 129.739Z'
        fill='#3854FE'
      />
      <path
        d='M316.786 335.072C377.521 333.039 420.585 295.243 423.178 233.287'
        stroke='#FDD772'
        strokeWidth='35.5093'
        strokeMiterlimit='10'
      />
      <path
        d='M582.061 260.842C585.84 260.842 588.986 261.354 591.498 262.38C594.009 263.404 595.975 264.764 597.391 266.455C598.809 268.149 599.692 269.972 600.041 271.927L588.327 275.647C587.979 273.598 587.345 272.059 586.425 271.033C585.503 270.009 584.174 269.495 582.435 269.495C580.842 269.495 579.511 269.842 578.44 270.532C577.372 271.224 576.564 272.32 576.016 273.822C575.468 275.324 575.196 277.245 575.196 279.579C575.196 281.917 575.495 283.812 576.091 285.266C576.69 286.721 577.533 287.77 578.63 288.412C579.721 289.056 581.016 289.378 582.509 289.378C583.75 289.378 584.784 289.152 585.604 288.699C586.425 288.247 587.107 287.59 587.655 286.731C588.203 285.873 588.551 284.825 588.7 283.586L600.115 287.089C599.767 289.187 598.833 291.059 597.316 292.704C595.799 294.347 593.796 295.648 591.311 296.601C588.823 297.554 585.914 298.031 582.582 298.031C578.503 298.031 574.947 297.306 571.914 295.85C568.881 294.397 566.543 292.274 564.9 289.486C563.26 286.696 562.441 283.346 562.441 279.436C562.441 275.527 563.26 272.177 564.9 269.389C566.543 266.598 568.843 264.478 571.801 263.022C574.762 261.57 578.182 260.842 582.061 260.842Z'
        fill='#A0BFFF'
      />
      <path
        d='M624.435 269.496C622.991 269.496 621.75 269.83 620.705 270.496C619.659 271.166 618.866 272.238 618.317 273.714C617.769 275.194 617.497 277.101 617.497 279.436C617.497 281.773 617.769 283.669 618.317 285.122C618.866 286.577 619.659 287.651 620.705 288.341C621.75 289.032 622.991 289.378 624.435 289.378C625.927 289.378 627.195 289.032 628.24 288.341C629.285 287.651 630.079 286.577 630.626 285.122C631.175 283.669 631.448 281.773 631.448 279.436C631.448 277.101 631.175 275.194 630.626 273.714C630.079 272.238 629.285 271.166 628.24 270.496C627.195 269.83 625.927 269.496 624.435 269.496ZM624.435 260.842C628.364 260.842 631.808 261.571 634.77 263.023C637.727 264.479 640.038 266.599 641.706 269.389C643.372 272.178 644.207 275.528 644.207 279.436C644.207 283.346 643.372 286.696 641.706 289.485C640.038 292.274 637.727 294.396 634.77 295.85C631.808 297.305 628.364 298.031 624.435 298.031C620.605 298.031 617.2 297.305 614.215 295.85C611.23 294.396 608.905 292.274 607.239 289.485C605.571 286.696 604.74 283.346 604.74 279.436C604.74 275.528 605.571 272.178 607.239 269.389C608.905 266.599 611.23 264.479 614.215 263.023C617.2 261.571 620.605 260.842 624.435 260.842Z'
        fill='#A0BFFF'
      />
      <path
        d='M651.368 297.317V261.558H662.706L663.081 268.494C664.274 265.968 665.954 264.061 668.116 262.772C670.28 261.487 672.779 260.841 675.614 260.841C678.399 260.841 680.81 261.437 682.851 262.629C684.89 263.822 686.405 265.588 687.403 267.921C688.196 266.348 689.23 265.037 690.497 263.989C691.767 262.941 693.22 262.155 694.863 261.628C696.503 261.103 698.195 260.841 699.936 260.841C702.422 260.841 704.609 261.305 706.502 262.236C708.39 263.167 709.895 264.573 711.014 266.456C712.134 268.34 712.691 270.688 712.691 273.5V297.317H700.009V276.29C700.009 274.001 699.549 272.404 698.628 271.499C697.709 270.592 696.478 270.139 694.938 270.139C693.693 270.139 692.562 270.438 691.541 271.034C690.524 271.631 689.739 272.523 689.191 273.715C688.644 274.908 688.372 276.41 688.372 278.221V297.317H675.689V276.29C675.689 274.001 675.216 272.404 674.272 271.499C673.326 270.592 672.108 270.139 670.616 270.139C669.47 270.139 668.39 270.425 667.371 270.998C666.348 271.569 665.542 272.464 664.946 273.68C664.349 274.895 664.052 276.481 664.052 278.436V297.317H651.368Z'
        fill='#A0BFFF'
      />
      <path
        d='M721.57 297.317V261.558H732.909L733.284 268.494C734.477 265.968 736.156 264.061 738.318 262.772C740.482 261.487 742.981 260.841 745.817 260.841C748.601 260.841 751.012 261.437 753.053 262.629C755.093 263.822 756.608 265.588 757.605 267.921C758.398 266.348 759.432 265.037 760.7 263.989C761.969 262.941 763.423 262.155 765.066 261.628C766.705 261.103 768.398 260.841 770.138 260.841C772.624 260.841 774.811 261.305 776.705 262.236C778.593 263.167 780.098 264.573 781.216 266.456C782.336 268.34 782.894 270.688 782.894 273.5V297.317H770.211V276.29C770.211 274.001 769.752 272.404 768.831 271.499C767.912 270.592 766.68 270.139 765.14 270.139C763.896 270.139 762.764 270.438 761.744 271.034C760.726 271.631 759.942 272.523 759.394 273.715C758.846 274.908 758.574 276.41 758.574 278.221V297.317H745.892V276.29C745.892 274.001 745.419 272.404 744.474 271.499C743.529 270.592 742.311 270.139 740.819 270.139C739.672 270.139 738.592 270.425 737.573 270.998C736.551 271.569 735.744 272.464 735.149 273.68C734.551 274.895 734.254 276.481 734.254 278.436V297.317H721.57Z'
        fill='#A0BFFF'
      />
      <path
        d='M804.381 298.031C801.844 298.031 799.617 297.613 797.706 296.781C795.789 295.946 794.284 294.6 793.191 292.741C792.095 290.881 791.55 288.449 791.55 285.445V261.557H804.232V282.584C804.232 284.873 804.703 286.47 805.649 287.377C806.593 288.282 807.938 288.733 809.677 288.733C810.573 288.733 811.417 288.581 812.215 288.269C813.009 287.96 813.707 287.483 814.303 286.839C814.902 286.196 815.36 285.361 815.685 284.337C816.007 283.311 816.17 282.108 816.17 280.724V261.557H828.851V297.317H817.51L817.137 290.593C815.946 293.122 814.278 294.993 812.141 296.208C810.002 297.424 807.415 298.031 804.381 298.031Z'
        fill='#A0BFFF'
      />
      <path
        d='M837.952 297.317V261.558H849.292L849.962 273.858L848.547 272.499C849.043 269.924 849.927 267.78 851.193 266.063C852.463 264.345 854.054 263.047 855.967 262.164C857.884 261.283 860.036 260.841 862.422 260.841C865.008 260.841 867.259 261.318 869.174 262.273C871.089 263.225 872.594 264.632 873.688 266.491C874.781 268.351 875.329 270.688 875.329 273.5V297.317H862.646V276.72C862.646 274.24 862.149 272.523 861.153 271.569C860.159 270.616 858.891 270.139 857.348 270.139C856.157 270.139 855.036 270.414 853.991 270.963C852.947 271.51 852.128 272.38 851.532 273.572C850.933 274.765 850.636 276.362 850.636 278.365V297.317H837.952Z'
        fill='#A0BFFF'
      />
      <path
        d='M896.89 297.317H884.206V261.558H896.89V297.317ZM890.55 257.408C887.963 257.408 886.083 256.944 884.916 256.015C883.746 255.084 883.164 253.617 883.164 251.615C883.164 249.566 883.746 248.075 884.916 247.146C886.083 246.216 887.963 245.751 890.55 245.751C893.085 245.751 894.95 246.216 896.143 247.146C897.338 248.075 897.936 249.566 897.936 251.615C897.936 253.617 897.338 255.084 896.143 256.015C894.95 256.944 893.085 257.408 890.55 257.408Z'
        fill='#A0BFFF'
      />
      <path
        d='M921.509 250.185V284.586C921.509 286.303 921.945 287.532 922.813 288.269C923.686 289.008 924.89 289.379 926.434 289.379C927.627 289.379 928.659 289.249 929.528 288.985C930.398 288.724 931.181 288.329 931.878 287.804L933.222 295.314C931.979 296.172 930.41 296.841 928.521 297.317C926.631 297.793 924.691 298.031 922.703 298.031C919.867 298.031 917.417 297.649 915.354 296.887C913.29 296.125 911.698 294.91 910.578 293.24C909.46 291.573 908.874 289.355 908.824 286.589V253.546L921.509 250.185ZM932.623 270.211H902.409V261.559H932.623V270.211Z'
        fill='#A0BFFF'
      />
      <path
        d='M976.046 261.558L962.466 297.316C961.074 301.083 959.557 304.086 957.916 306.329C956.275 308.569 954.335 310.19 952.096 311.192C949.859 312.193 947.173 312.694 944.038 312.694C941.951 312.694 940.036 312.479 938.295 312.049C936.553 311.621 935.036 311.025 933.745 310.261L936.279 302.037C937.175 302.656 938.121 303.097 939.115 303.359C940.111 303.622 941.377 303.752 942.92 303.752C944.362 303.752 945.542 303.515 946.464 303.038C947.384 302.561 948.117 301.655 948.665 300.321L949.635 298.103L945.309 288.376L934.117 261.558H948.069L953.814 281.94L955.603 289.521L957.692 281.655L963.436 261.558H976.046Z'
        fill='#A0BFFF'
      />
    </svg>
  ),
});
