import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';

export interface ObjectiveState {
  state?: boolean;
}

export interface ObjectiveStates {
  data: ObjectiveState;
}

const initialState = {data: {status: false}} as ObjectiveStates;
const objectiveStateSlice = createSlice({
  name: 'objectiveState',
  initialState,
  reducers: {
    getObjectiveState(state, action: PayloadAction<ObjectiveState>) {
      state.data = action.payload;
    },
  },
});

export const getObjectiveStateSelector = (state: RootState) =>
  state.objectiveState.data;

export default objectiveStateSlice.reducer;
export const {getObjectiveState} = objectiveStateSlice.actions;
