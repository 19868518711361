import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';

export interface Feature {
  code?: string;
  name: string;
  description: string;
  permissions?: [{code: string; name: string; description: string}];
}

export interface FeatureState {
  data: Feature[];
  error: string | null;
}

const initialState = {data: [], error: null} as FeatureState;
const featureSlice = createSlice({
  name: 'feature',
  initialState,
  reducers: {
    getFeatures(state, action) {
      const x = '';
    },
    getFeaturesSuccess(state, action) {
      const newIds = action.payload.map(newElements => newElements.code);
      const newData = [
        ...action.payload,
        ...state.data.filter(current => !newIds.includes(current.code)),
      ];
      newData.sort((a, b) => a.code - b.code);
      state.data = newData;
    },
    getFeaturesError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export const getFeaturesSelector = (state: RootState) => state.feature.data;
export const getErrorSelector = state => state.feature.error;

export const getSingleFeatureSelector = (state: RootState, code: string) => {
  const index = state.feature.data.findIndex(feature => feature.code === code);
  return state.feature.data[index];
};

export default featureSlice.reducer;
export const {getFeatures, getFeaturesSuccess, getFeaturesError} =
  featureSlice.actions;
