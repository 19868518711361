import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';

export interface IncidentState {
  state?: boolean;
}

export interface IncidentStates {
  data: IncidentState;
}

const initialState = {data: {status: false}} as IncidentStates;
const incidentStateSlice = createSlice({
  name: 'incidentState',
  initialState,
  reducers: {
    getIncidentState(state, action: PayloadAction<IncidentState>) {
      state.data = action.payload;
    },
  },
});

export const getIncidentStateSelector = (state: RootState) =>
  state.incidentState.data;

export default incidentStateSlice.reducer;
export const {getIncidentState} = incidentStateSlice.actions;
