import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const GeoUbicacionIcon = createIcon({
  displayName: 'GeoUbicacionIcon',
  viewBox: '0 0 32 36',
  path: (
    <>
      <ellipse
        cx='24.1599'
        cy='6.90099'
        rx='3.61739'
        ry='3.35167'
        fill='white'
      />
      <path
        d='M31.0223 7.20389C31.0223 3.22558 27.9181 3.43323e-05 24.0894 3.43323e-05C20.2598 3.43323e-05 17.1556 3.22558 17.1556 7.20389C17.1556 8.73722 17.6177 10.0977 18.4029 11.1911L18.4019 11.1891L23.6708 19.0653C23.8724 19.3672 24.3055 19.3672 24.5081 19.0653L29.8493 11.0859C29.8811 11.0392 29.9129 10.9925 29.9438 10.9448L29.9544 10.929H29.9535C30.6306 9.88324 31.0223 8.61705 31.0223 7.20389ZM24.1029 10.0163C22.4457 10.0163 21.1029 8.63294 21.1029 6.92682C21.1029 5.2207 22.4457 3.83832 24.1029 3.83832C25.7602 3.83832 27.1039 5.2207 27.1039 6.92682C27.1039 8.63294 25.7602 10.0163 24.1029 10.0163Z'
        fill='#FA704D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 19.9206C0 11.0541 7.17733 3.84116 16 3.84116C16.0836 3.84116 16.1661 3.85174 16.2485 3.8623C16.3184 3.87127 16.3883 3.88023 16.4587 3.88269C16.1667 4.7148 16 5.58979 16 6.52106C14.6013 6.52106 13.144 8.01242 12.0413 10.5409H16.9573C17.3568 11.4493 17.7882 12.3431 18.2507 13.2208H11.1253C10.6747 14.9745 10.4093 16.771 10.3333 18.5806H20.4987C20.5991 19.0064 20.655 19.4416 20.6653 19.879V21.2605H10.332C10.4079 23.0701 10.6734 24.8666 11.124 26.6203H20.876C21.2221 25.2883 21.4584 23.9299 21.5827 22.5589H24.2093C24.0893 23.9739 23.92 25.3634 23.6267 26.6203H27.5413C28.4868 24.9775 29.0697 23.1496 29.2507 21.2605H27.3333V19.879C27.3333 19.4516 27.404 19.0295 27.5 18.5806H29.2493C29.1453 17.5368 28.916 16.5238 28.5827 15.5657C28.8234 15.0559 29.09 14.5439 29.3556 14.0341C29.446 13.8605 29.5363 13.6872 29.6253 13.5143C29.6814 13.406 29.7368 13.2998 29.7918 13.1944C29.9186 12.9514 30.043 12.7132 30.1667 12.4664C31.336 14.7001 32 17.2273 32 19.9206C32 28.787 24.8227 36 16 36C7.17733 36 0 28.787 0 19.9206ZM9.16667 10.5409C9.5226 9.56568 9.96901 8.62626 10.5 7.73505C9.00618 8.41494 7.65012 9.36617 6.5 10.5409H9.16667ZM8.37333 13.2208H4.45867C3.51263 14.8634 2.92964 16.6914 2.74933 18.5806H7.66533C7.73301 16.7756 7.97006 14.981 8.37333 13.2208ZM7.66667 21.2605H2.74933C2.93086 23.1495 3.51377 24.9772 4.45867 26.6203H8.37467C7.99067 24.9722 7.74533 23.1699 7.66667 21.2605ZM9.16667 29.3002H6.5C7.65012 30.475 9.00618 31.4262 10.5 32.1061C10 31.274 9.55067 30.34 9.16667 29.3002ZM16 33.3201C17.3987 33.3201 18.856 31.8287 19.9587 29.3002H12.0413C13.1427 31.8287 14.6013 33.3201 16 33.3201ZM25.5 29.3002H22.8333C22.4493 30.3414 22 31.2753 21.5 32.1061C22.9938 31.4262 24.3499 30.475 25.5 29.3002Z'
        fill='#73B3AB'
      />
    </>
  ),
});
