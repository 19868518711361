import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';
import {Document, DocumentPath} from '../types/documents';

export interface DocumentState {
  data: Document[];
  error: string | null;
  isLoading: boolean;
  startLoading: number;
  endLoading: number;
}

const initialState = {
  data: [],
  error: null,
  isLoading: false,
  startLoading: 0,
  endLoading: 0,
} as DocumentState;

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    getDocuments(state, action: PayloadAction<DocumentPath>) {
      state.startLoading++;
      state.isLoading = true;
    },
    getDocumentsSuccess(state, action: PayloadAction<Document[]>) {
      state.data = action.payload;
      state.endLoading++;
      state.isLoading = false;
    },
    getDocumentsError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.endLoading++;
      state.isLoading = false;
    },
  },
});

export const getDocumentSelector = (state: RootState) => {
  if (state.document.startLoading === state.document.endLoading) {
    return state.document.data;
  }
  return [];
};
export const isDocumentLoading = state => state.document.isLoading;
export const getErrorSelector = state => state.document.error;

export default documentSlice.reducer;
export const {getDocuments, getDocumentsSuccess, getDocumentsError} =
  documentSlice.actions;
