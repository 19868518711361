const parts = ['table', 'thead', 'tbody', 'tr', 'th', 'td', 'caption'];

type Dict = Record<string, any>;

const baseStyle = {
  table: {
    bg: 'gray.50',
    borderRadius: '17px',
    fontVariantNumeric: 'lining-nums tabular-nums',
    width: '100%',
  },
  th: {
    fontFamily: 'heading',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: 'wider',
    textAlign: 'left',
  },
  td: {
    textAlign: 'left',
  },
  caption: {
    mt: 4,
    fontFamily: 'heading',
    textAlign: 'left',
    fontWeight: 'medium',
  },
};

const numericStyles = {
  '&[data-is-numeric=true]': {
    textAlign: 'left',
  },
};

const simpleVariant = (props: Dict) => {
  const {colorScheme: c} = props;

  return {
    th: {
      borderRightWidth: 1,
      color: 'gray.600',
      ...numericStyles,
    },
    td: {
      ...numericStyles,
      borderRightWidth: 1,
      borderBottomWidth: 0,
    },
    caption: {
      color: 'gray.600',
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: {borderBottomWidth: 0},
        },
      },
    },
  };
};

const stripedVariant = (props: Dict) => {
  const {colorScheme: c} = props;

  return {
    th: {
      color: 'gray.600',
      ...numericStyles,
    },
    td: {
      ...numericStyles,
    },
    caption: {
      color: 'gray.600',
    },
    tbody: {
      tr: {
        '&:nth-of-type(odd)': {
          'th, td': {},
          td: {
            background: `${c}.100`,
          },
        },
      },
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: {borderBottomWidth: 0},
        },
      },
    },
  };
};

const variants = {
  simple: simpleVariant,
  striped: stripedVariant,
  unstyled: {},
};

const sizes = {
  sm: {
    th: {
      px: '4',
      py: '1',
      lineHeight: '4',
      fontSize: 'sm',
    },
    td: {
      px: '4',
      py: '2',
      fontSize: 'sm',
      lineHeight: '4',
    },
    caption: {
      px: '4',
      py: '2',
      fontSize: 'xs',
    },
  },
  md: {
    th: {
      px: '6',
      py: '3',
      lineHeight: '4',
      fontSize: 'md',
    },
    td: {
      px: '6',
      py: '4',
      lineHeight: '5',
    },
    caption: {
      px: '6',
      py: '2',
      fontSize: 'sm',
    },
  },
  lg: {
    th: {
      px: '8',
      py: '4',
      lineHeight: '5',
      fontSize: 'md',
    },
    td: {
      px: '8',
      py: '5',
      lineHeight: '6',
    },
    caption: {
      px: '6',
      py: '2',
      fontSize: 'md',
    },
  },
};

const defaultProps = {
  variant: 'simple',
  size: 'sm',
  colorScheme: 'gray',
};

export default {
  parts,
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
