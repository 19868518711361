import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  UseDisclosureProps,
} from '@chakra-ui/react';

import SidebarMenu from './SidebarMenu';

interface MobileMenuProps {
  disclosure: UseDisclosureProps;
}

export function MobileMenu(props: MobileMenuProps) {
  const {disclosure} = props;
  return (
    <Drawer
      isOpen={disclosure.isOpen!}
      placement='left'
      onClose={disclosure.onClose!}>
      <DrawerOverlay />
      <DrawerContent w='100px' maxW='100px'>
        <SidebarMenu />
      </DrawerContent>
    </Drawer>
  );
}
