import {Image} from '@chakra-ui/react';

import happifyBig from '../../assets/svg/happify-big-logo.svg';
import happifySm from '../../assets/svg/happify-small-logo.svg';

interface LogoProps {
  size?: string;
  onClick(): void;
}

function Logo(props: LogoProps) {
  const {size, ...rest} = props;

  if (size === 'sm') {
    return <Image src={happifySm} {...rest} height='55px' />;
  }

  return <Image src={happifyBig} {...rest} />;
}

export default Logo;
