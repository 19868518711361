import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const SupportCenterIcon = createIcon({
  displayName: 'SupportCenterIcon',
  viewBox: '0 0 33 34',
  path: (
    <>
      <path
        d='M27.5051 12.6379C25.9719 12.6378 24.4774 12.1427 23.2319 11.2225C23.1623 11.4144 23.0376 11.58 22.8745 11.6973C22.7113 11.8147 22.5174 11.8783 22.3184 11.8796H9.11596C8.85543 11.8796 8.60557 11.7731 8.42135 11.5835C8.23713 11.3939 8.13364 11.1367 8.13364 10.8686C8.13364 10.6005 8.23713 10.3433 8.42135 10.1537C8.60557 9.96409 8.85543 9.85757 9.11596 9.85757H21.8076C21.0045 8.83906 20.4734 7.62261 20.2667 6.32829C20.0599 5.03397 20.1848 3.70654 20.6288 2.47703C19.032 2.00481 17.3786 1.76656 15.7172 1.76931C7.05308 1.76931 0 8.1186 0 15.9238C0 23.7289 7.05308 30.0782 15.7172 30.0782C17.3382 30.0763 18.9512 29.845 20.5109 29.3907L25.9432 32.9496C26.0915 33.0475 26.2626 33.1026 26.4387 33.109C26.6148 33.1154 26.7893 33.073 26.944 32.9862C27.0987 32.8993 27.2278 32.7713 27.3179 32.6154C27.408 32.4596 27.4556 32.2816 27.4559 32.1003V25.296C28.6851 24.0825 29.6659 22.6281 30.3407 21.0185C31.0155 19.409 31.3706 17.6767 31.3852 15.9238C31.3809 14.547 31.1552 13.1805 30.7172 11.8796C29.7163 12.3801 28.6178 12.6394 27.5051 12.6379ZM19.4009 21.99H12.0335C11.7729 21.99 11.5231 21.8834 11.3389 21.6938C11.1546 21.5042 11.0511 21.2471 11.0511 20.9789C11.0511 20.7108 11.1546 20.4536 11.3389 20.264C11.5231 20.0744 11.7729 19.9679 12.0335 19.9679H19.4009C19.6614 19.9679 19.9113 20.0744 20.0955 20.264C20.2797 20.4536 20.3832 20.7108 20.3832 20.9789C20.3832 21.2471 20.2797 21.5042 20.0955 21.6938C19.9113 21.8834 19.6614 21.99 19.4009 21.99ZM22.5934 16.9348H8.84091C8.58038 16.9348 8.33052 16.8283 8.1463 16.6387C7.96208 16.4491 7.85859 16.1919 7.85859 15.9238C7.85859 15.6556 7.96208 15.3985 8.1463 15.2089C8.33052 15.0193 8.58038 14.9127 8.84091 14.9127H22.5934C22.854 14.9127 23.1038 15.0193 23.288 15.2089C23.4723 15.3985 23.5758 15.6556 23.5758 15.9238C23.5758 16.1919 23.4723 16.4491 23.288 16.6387C23.1038 16.8283 22.854 16.9348 22.5934 16.9348Z'
        fill='#F6704D'
      />
      <path
        d='M22.8745 11.6973C23.0376 11.58 23.1623 11.4144 23.2319 11.2225L21.8076 9.85757H9.11596C8.85543 9.85757 8.60557 9.96409 8.42135 10.1537C8.23713 10.3433 8.13364 10.6005 8.13364 10.8686C8.13364 11.1367 8.23713 11.3939 8.42135 11.5835C8.60557 11.7731 8.85543 11.8796 9.11596 11.8796H22.3184C22.5174 11.8783 22.7113 11.8147 22.8745 11.6973Z'
        fill='#F6704D'
      />
      <path
        d='M22.5934 16.9348H8.84091C8.58038 16.9348 8.33052 16.8283 8.1463 16.6387C7.96208 16.4491 7.85859 16.1919 7.85859 15.9238C7.85859 15.6556 7.96208 15.3985 8.1463 15.2089C8.33052 15.0193 8.58038 14.9127 8.84091 14.9127H22.5934C22.854 14.9127 23.1038 15.0193 23.288 15.2089C23.4723 15.3985 23.5758 15.6556 23.5758 15.9238C23.5758 16.1919 23.4723 16.4491 23.288 16.6387C23.1038 16.8283 22.854 16.9348 22.5934 16.9348Z'
        fill='#F6704D'
      />
      <path
        d='M19.4009 21.99H12.0335C11.7729 21.99 11.5231 21.8834 11.3389 21.6938C11.1546 21.5042 11.0511 21.2471 11.0511 20.9789C11.0511 20.7108 11.1546 20.4536 11.3389 20.264C11.5231 20.0744 11.7729 19.9679 12.0335 19.9679H19.4009C19.6614 19.9679 19.9113 20.0744 20.0955 20.264C20.2797 20.4536 20.3832 20.7108 20.3832 20.9789C20.3832 21.2471 20.2797 21.5042 20.0955 21.6938C19.9113 21.8834 19.6614 21.99 19.4009 21.99Z'
        fill='#F6704D'
      />
      <path
        d='M22.8745 11.6973C23.0376 11.5799 23.1623 11.4143 23.2319 11.2224L21.8076 9.85754H9.11596C8.85543 9.85754 8.60557 9.96406 8.42135 10.1537C8.23713 10.3433 8.13363 10.6004 8.13363 10.8686C8.13363 11.1367 8.23713 11.3939 8.42135 11.5835C8.60557 11.7731 8.85543 11.8796 9.11596 11.8796H22.3184C22.5174 11.8782 22.7113 11.8146 22.8745 11.6973Z'
        fill='white'
      />
      <path
        d='M22.5934 16.9348H8.84091C8.58038 16.9348 8.33052 16.8283 8.1463 16.6386C7.96208 16.449 7.85858 16.1919 7.85858 15.9237C7.85858 15.6556 7.96208 15.3984 8.1463 15.2088C8.33052 15.0192 8.58038 14.9127 8.84091 14.9127H22.5934C22.854 14.9127 23.1038 15.0192 23.288 15.2088C23.4723 15.3984 23.5758 15.6556 23.5758 15.9237C23.5758 16.1919 23.4723 16.449 23.288 16.6386C23.1038 16.8283 22.854 16.9348 22.5934 16.9348Z'
        fill='white'
      />
      <path
        d='M19.4009 21.9899H12.0335C11.7729 21.9899 11.5231 21.8834 11.3388 21.6938C11.1546 21.5042 11.0511 21.247 11.0511 20.9789C11.0511 20.7108 11.1546 20.4536 11.3388 20.264C11.5231 20.0744 11.7729 19.9679 12.0335 19.9679H19.4009C19.6614 19.9679 19.9113 20.0744 20.0955 20.264C20.2797 20.4536 20.3832 20.7108 20.3832 20.9789C20.3832 21.247 20.2797 21.5042 20.0955 21.6938C19.9113 21.8834 19.6614 21.9899 19.4009 21.9899Z'
        fill='white'
      />
      <path
        d='M27.505 10.1103C30.2176 10.1103 32.4166 7.84705 32.4166 5.05516C32.4166 2.26327 30.2176 0 27.505 0C24.7924 0 22.5934 2.26327 22.5934 5.05516C22.5934 7.84705 24.7924 10.1103 27.505 10.1103Z'
        fill='#73B3AB'
      />
      <path
        d='M27.4923 2.27478V5.75714'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <mask id='path-5-inside-1_350_409' fill='white'>
        <ellipse cx='27.4923' cy='7.39317' rx='0.570381' ry='0.568706' />
      </mask>
      <ellipse
        cx='27.4923'
        cy='7.39317'
        rx='0.570381'
        ry='0.568706'
        fill='white'
      />
      <path
        d='M27.0627 7.39317C27.0627 7.1522 27.2578 6.96187 27.4923 6.96187V8.96187C28.3568 8.96187 29.0627 8.26231 29.0627 7.39317H27.0627ZM27.4923 6.96187C27.7268 6.96187 27.9219 7.1522 27.9219 7.39317H25.9219C25.9219 8.26231 26.6278 8.96187 27.4923 8.96187V6.96187ZM27.9219 7.39317C27.9219 7.63414 27.7268 7.82446 27.4923 7.82446V5.82446C26.6278 5.82446 25.9219 6.52402 25.9219 7.39317H27.9219ZM27.4923 7.82446C27.2578 7.82446 27.0627 7.63414 27.0627 7.39317H29.0627C29.0627 6.52402 28.3568 5.82446 27.4923 5.82446V7.82446Z'
        fill='white'
        mask='url(#path-5-inside-1_350_409)'
      />
    </>
  ),
});
