import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const SupportEventIcon = createIcon({
  displayName: 'SupportEventIcon',
  viewBox: '0 0 42 39',
  path: (
    <>
      <path
        d='M30.2241 30.8H3.77778V11.6H30.2222L30.2241 30.8Z'
        fill='#3885FE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30.2222 5.2H26.4444H22.6667H11.3333H7.55555H3.77778C1.69433 5.2 0 6.6352 0 8.4V30.8C0 32.5648 1.69433 34 3.77778 34H30.2222C32.3057 34 34 32.5648 34 30.8V8.4C34 6.6352 32.3057 5.2 30.2222 5.2ZM30.2241 30.8H3.77778V11.6H30.2222L30.2241 30.8Z'
        fill='#3885FE'
      />
      <path d='M30.2242 31.6H3.77783V11.2H30.2223L30.2242 31.6Z' fill='white' />
      <path
        d='M6.8 4C6.8 3.44772 6.35229 3 5.8 3H1C0.447715 3 0 3.44772 0 4V8C0 8.55228 0.447716 9 1 9H33C33.5523 9 34 8.55228 34 8V4C34 3.44772 33.5523 3 33 3H28.2C27.6477 3 27.2 3.44772 27.2 4V5C27.2 5.55228 26.7523 6 26.2 6H24.8C24.2477 6 23.8 5.55228 23.8 5V4C23.8 3.44772 23.3523 3 22.8 3H11.2C10.6477 3 10.2 3.44772 10.2 4V5C10.2 5.55228 9.75229 6 9.2 6H7.8C7.24772 6 6.8 5.55228 6.8 5V4Z'
        fill='#F55266'
      />
      <path
        d='M27.5 2.33333V1C27.5 0.447715 27.0523 0 26.5 0H24.6667C24.1144 0 23.6667 0.447715 23.6667 1V2.33333V4.66667V6C23.6667 6.55228 24.1144 7 24.6667 7H26.5C27.0523 7 27.5 6.55228 27.5 6V4.66667V2.33333Z'
        fill='#3885FE'
      />
      <path
        d='M10.3333 2.33333V1C10.3333 0.447715 9.88562 0 9.33333 0H7.5C6.94771 0 6.5 0.447715 6.5 1V2.33333V4.66667V6C6.5 6.55228 6.94772 7 7.5 7H9.33333C9.88562 7 10.3333 6.55228 10.3333 6V4.66667V2.33333Z'
        fill='#3885FE'
      />
      <path
        d='M30.0164 24.9529C29.2568 23.9327 28.8317 22.7172 28.7958 21.4627C28.76 20.2083 29.115 18.9722 29.8152 17.9134C30.5154 16.8546 31.5288 16.0215 32.7251 15.5213C33.9214 15.021 35.2459 14.8766 36.5282 15.1064L33.4452 20.2423C33.1724 20.7229 33.1057 21.287 33.2595 21.8142C33.4133 22.3415 33.7753 22.7899 34.2681 23.0638C34.761 23.3376 35.3456 23.4152 35.8971 23.2799C36.4486 23.1446 36.9232 22.8071 37.2196 22.3396L40.3003 17.2037C41.1446 18.1542 41.6754 19.3252 41.8248 20.5672C41.9743 21.8092 41.7355 23.0659 41.1392 24.1768C40.5428 25.2877 39.6158 26.2024 38.4766 26.804C37.3374 27.4057 36.0376 27.6671 34.7433 27.5548L29.0828 36.9895C28.7214 37.5913 28.1263 38.0304 27.4283 38.2101C26.7304 38.3898 25.9869 38.2954 25.3613 37.9477C24.7358 37.5999 24.2794 37.0273 24.0926 36.3559C23.9058 35.6844 24.004 34.9691 24.3654 34.3672L30.0164 24.9529V24.9529Z'
        fill='#73B3AB'
      />
      <path
        d='M24.2858 22.6191V20.4643H21.9919C21.851 19.7899 21.5797 19.1486 21.1926 18.5757L22.8195 16.9704L21.2756 15.4469L19.6488 17.0522C19.0681 16.6703 18.4182 16.4025 17.7348 16.2636V14H15.5511V16.2636C14.8676 16.4025 14.2177 16.6703 13.6371 17.0522L12.0102 15.4469L10.4663 16.9704L12.0932 18.5757C11.7061 19.1486 11.4348 19.7899 11.294 20.4643H9V22.6191H11.294C11.4348 23.2936 11.7061 23.9348 12.0932 24.5078L10.4663 26.1131L12.0102 27.6365L13.6371 26.0312C14.2177 26.4132 14.8676 26.6809 15.5511 26.8199V29.0835H17.7348V26.8199C18.4182 26.6809 19.0681 26.4132 19.6488 26.0312L21.2756 27.6365L22.8195 26.1131L21.1926 24.5078C21.5797 23.9348 21.851 23.2936 21.9919 22.6191H24.2858ZM16.6429 24.7739C15.9951 24.7739 15.3618 24.5844 14.8231 24.2292C14.2845 23.874 13.8646 23.3692 13.6167 22.7786C13.3688 22.188 13.3039 21.5382 13.4303 20.9112C13.5567 20.2842 13.8687 19.7083 14.3268 19.2562C14.7848 18.8042 15.3685 18.4964 16.0039 18.3717C16.6393 18.247 17.2979 18.311 17.8964 18.5556C18.4949 18.8002 19.0065 19.2145 19.3664 19.746C19.7263 20.2776 19.9184 20.9025 19.9184 21.5417C19.9176 22.3987 19.5722 23.2203 18.9581 23.8263C18.344 24.4323 17.5114 24.7731 16.6429 24.7739Z'
        fill='#73B3AB'
      />
    </>
  ),
});
