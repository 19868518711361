import React, {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useState,
} from 'react';

import {
  Box,
  StylesProvider,
  VStack,
  useMultiStyleConfig,
  useStyles,
} from '@chakra-ui/react';

import {motion} from 'framer-motion';

export type SidebarProps = {
  children?: ReactNode;
  isCollapsed: boolean;
};

type HeaderProps = {
  children: ReactNode;
};

type MenuProps = {
  children: ReactElement[];
};

type ContextState = {
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
};

type ContextProviderProps = {
  children: ReactNode | ReactNode;
  isCollapsed: boolean;
};

const CollapsibleContext = createContext<ContextState | undefined>(undefined);

const CollapsibleProvider = (props: ContextProviderProps) => {
  const [isCollapsed, setIsCollapsed] = useState(props.isCollapsed);
  console.log({props, isCollapsed});

  const value = {isCollapsed, setIsCollapsed};
  return (
    <CollapsibleContext.Provider value={value}>
      {props.children}
    </CollapsibleContext.Provider>
  );
};

const useCollapsible = () => {
  const context = useContext(CollapsibleContext);
  if (context === undefined) {
    throw new Error('useCollapsible must be used within a CountProvider');
  }

  return context;
};

function Header(props: HeaderProps) {
  const {children, ...rest} = props;
  const styles = useStyles();
  return (
    <Box __css={styles.header} {...rest}>
      {children}
    </Box>
  );
}

function Menu(props: MenuProps) {
  const styles = useStyles();

  return <VStack __css={styles.menu}>{props.children}</VStack>;
}

type InternalSidebarProps = {
  children?: ReactNode;
  isCollapsed: boolean;
};

function InternalSidebar(props: InternalSidebarProps) {
  const {children, isCollapsed} = props;

  const styles = useMultiStyleConfig('SideBar', {});

  const animate = {
    width: isCollapsed
      ? `${styles.container.maxWidth}`
      : `${styles.container.minWidth}`,
  };

  return (
    <motion.div animate={animate} initial={false} style={{overflow: 'auto'}}>
      <Box __css={styles.container}>
        <StylesProvider value={styles}>{children}</StylesProvider>
      </Box>
    </motion.div>
  );
}

export class SideBar extends React.Component<SidebarProps> {
  static Header = Header;
  static Menu = Menu;

  render() {
    return (
      <InternalSidebar isCollapsed={this.props.isCollapsed}>
        {this.props.children}
      </InternalSidebar>
    );
  }
}
