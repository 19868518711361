import React from 'react';

import {ChakraProvider, ChakraProviderProps} from '@chakra-ui/react';

import '@fontsource/work-sans';

import theme from '../theme/dafault';

export type HappifyThemeProviderProps = ChakraProviderProps;

export function HappifyThemeProvider(props: HappifyThemeProviderProps) {
  return <ChakraProvider theme={theme}>{props.children}</ChakraProvider>;
}
